import { logger } from "hoc/logger";

class LocalStorageTools {

  // -----------------------------------------
  static getObject(key) {
    const value = localStorage.getItem(key);
    if (value == null) return false;

    var obj = JSON.parse(value);

    return obj;
  }

  // -----------------------------------------
  static getValue(key) {
    const value = localStorage.getItem(key);

    if (value == null) return "";
    return value;
  }

  // -----------------------------------------
  static saveObject(key, obj) {
    const value = JSON.stringify(obj);

    return LocalStorageTools.saveValue(key, value);
  }

  // -----------------------------------------
  static saveValue(key, value) {
    localStorage.setItem(key, value);

    return true;
  }

  // -----------------------------------------
}

export { LocalStorageTools };
