import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";
import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import uuid from "react-uuid";
import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";
// ----------------------------------

const AddGSACDashBoard = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const { LL } = useResource(false);

  return <>AddGSACDashBoard</>;
};

export { AddGSACDashBoard };
