import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypePunctTransformareDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypePunctTransformare";
  }

  constructor(data = null) {
    super(data);
  }
}
