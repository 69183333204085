import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO"; 

export class TypeInterventieDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeInterventie";
  }

  constructor(data = null) {
    super(data);
  }
}
