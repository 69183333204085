import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { useResource } from "hook/useResource";
import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { FilterTools } from "api/FilterTools";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";

// ---------------------------------

import { RolesDTO } from "dto/user/RolesDTO";
import { RolesACLDTO } from "dto/user/RolesACLDTO";

import {
  RolesACLListModules,
  RolesACLListGetForModule,
} from "api/RequestTools";

const MyRolesACLMethod = ({
  _RolesObjects,
  _Module,
  _Method,
  _Selecteds,
  _OtherData,
  _Group,
  _handleCheck,
  ...props
}) => {
  const { getACLObjSpecial } = useResource(false);

  const [isLoading, setIsLoading] = useState(false);

  const [rolesObjects, setRolesObjects] = useState(_RolesObjects);
  const [module, setModule] = useState(_Module);
  const [method, setMethod] = useState(_Method);
  const [idroles, setIdRoles] = useState(_Selecteds);
  const [otherdata, setOtherData] = useState(_OtherData);
  const [group, setGroup] = useState(_Group);
  const [hash, setHash] = useState(uuid());

  logger(
    "MyRolesACLMethod123123131222qqwwqq1122",
    _Module,
    _Method,
    _Selecteds
  );

  // -------------------------------------------------

  const setRoleToSession = (idrole, method, module) => {
    logger(
      "setRoleToSession12313331110000000000088888888888888",
      idrole,
      method,
      module,
      _Module
    );

    var t = idroles;
    t[t.length] = "" + idrole;

    setIdRoles(t);
    logger("setRoleToSccxx8888888", t);
    setHash(uuid());

    _handleCheck(method, t, otherdata);
  };

  const saveRole = (idrole, method) => {
    var t = getACLObjSpecial(method, "", otherdata);

    logger("saveRole3331111", idrole, method, t, module);

    RolesACLDTO.execAdd(t.aclmodule, t.aclmethod, idrole);
    // RolesACLDTO.execAdd(module, method, idrole);
  };

  const deleteRole = (idrole, method) => {
    var t = getACLObjSpecial(method, "", otherdata);

    RolesACLDTO.execDelete(t.aclmodule, t.aclmethod, idrole);
    // RolesACLDTO.execDelete(module, method, idrole);
  };

  const deleteRoleFromSession = (idrole, method, module) => {
    var t = idroles;

    var t1 = [];
    for (var i in t) {
      if (t[i] == "" + idrole) {
        continue;
      }
      t1[t1.length] = "" + t[i];
    }

    setIdRoles(t1);
    setHash(uuid());

    _handleCheck(method, t1, otherdata);
  };

  const handleChangeRole = (event, idrole, method, module) => {
    var checked = event.target.checked;

    if (checked) {
      setRoleToSession(idrole, method, module);
      saveRole(idrole, method);
    } else {
      deleteRoleFromSession(idrole, method, module);
      deleteRole(idrole, method);
    }
  };

  const processModuleMethodRole = (role, method, module) => {
    var k = uuid();
    var ckeck = idroles.indexOf("" + role.id) != -1 ? true : false;

    logger("setRoleToSession12313331110yyyyy111111:", ckeck, role.id, idroles);

    return (
      <TableCell sx={{ textAlign: "right" }} scope="row" key={k}>
        <FormControlLabel
          sx={{ p: 0, m: 0 }}
          className="cheboxRoleACL"
          control={
            <Checkbox
              sx={{ p: 0, m: 0 }}
              checked={ckeck}
              onChange={(event) => {
                handleChangeRole(event, role.id, method, module);
              }}
            />
          }
        />
      </TableCell>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <TableRow hover role="checkbox" tabIndex={-1} key={hash}>
      <TableCell scope="row">
        {group}{" "}
        {method}
      </TableCell>
      {rolesObjects.map((role, i) => {
        return processModuleMethodRole(role, method, module);
      })}
    </TableRow>
  );
};

export { MyRolesACLMethod };
