
import { logger } from "hoc/logger";

export class FilesDTO {
  id = "";
  uuid = "";

  parentclass = "";
  parentid = "";

  name = "";
  type = "";
  size = "";
  location = "";
  isurl = "";

  fullurl = "";
  locationgroup = "";

  candelete = "";

  constructor(data = null) {
    this.id = data?.id;
    this.uuid = data?.uuid;

    this.parentclass = data?.parentclass;
    this.parentid = data?.parentid;

    this.name = data?.name;
    this.type = data?.type;
    this.size = data?.size;
    this.location = data?.location;
    this.isurl = data?.isurl;

    this.fullurl = data?.fullurl;
    this.locationgroup = data?.locationgroup;

    this.candelete = data?.candelete;
  }
}
