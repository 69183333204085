import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeNumarCanaleRegistratoareDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeNumarCanaleRegistratoare";
  }

  constructor(data = null) {
    super(data);
  }
}
