import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useResource } from "hook/useResource";


import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { logger } from "hoc/logger";

const MyDatePicker = ({ 
  obj,
  defaultobjdto,
  otherdata,
  _fieldkey,
  _label,
  setObj,

  ...props
}) => {
  const [name, setName] = useState("");
  const [nameint, setNameInt] = useState("");
  const [fullname, setFullName] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState(null);
  
  const { LL } = useResource(false);

  useEffect(() => {
    if (!_fieldkey) {
      setName("");
      setNameInt("");
      setFullName("");
      return;
    }
    setName(_fieldkey);
    setNameInt(_fieldkey + "int");
    setFullName(_fieldkey + "microtime");
  }, [obj, _fieldkey, otherdata]);

  useEffect(() => {
    if (!name) return;

    if (_label) {
      setLabel(_label);
    } else {
      setLabel(LL(name));
    }

    if (!obj || !obj[fullname]) {
      setValue(null);
    } else {
      setValue(obj[fullname]);
    }
  }, [name]);

  const handleChange = (e) => {
    if (!name) return;
    
    if (e.$d == undefined) {
      var _obj = obj;
      _obj[fullname] = 0;
      _obj[nameint] = 0;
      setObj(_obj);

      setValue(null);
      
      return;
    }
    
    var d = e.$d;

    var timezone = d.getTimezoneOffset();
    var time = e.$d.getTime() + -60000 * timezone;

    var _obj = obj;
    _obj[fullname] = time;
    _obj[nameint] = time / 1000;
    setObj(_obj);

    setValue(time);
  };

  return (
    <DesktopDatePicker
      {...props}
      name={name}
      label={label}
      value={value}
      inputFormat="DD.MM.YYYY"
      onChange={handleChange}
      type="text"
      fullWidth
      color="secondary"
      size="small"
      margin="normal"
      variant="standard"
      renderInput={(params) => (
        <TextField
          variant="standard"
          color="secondary"
          size="small"
          margin="normal"
          fullWidth
          {...params}
        />
      )}
    />
  );
};

export { MyDatePicker };
