import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeHotarDTO } from "dto/nomenclatoare/TypeHotarDTO";

const TypeHotar = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new TypeHotarDTO());

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};;

export { TypeHotar };
