import React from "react";

import { useEffect, useState } from "react";
import { GenAutocompleteSimple } from "components/elements/Autocomplete/GenAutocompleteSimple";

import { useResource } from "hook/useResource";

import { TypeBunImobilDTO } from "dto/nomenclatoare/TypeBunImobilDTO";

const TypeBunImobilAutocomplete = ({
  obj,
  setObj,
  _idfield,
  _objfield,
  _label,
  _otherdata,
  ...props
}) => {
  const { LL } = useResource(false);

  const [label, setLabel] = useState("");

  var tidfield = _idfield != undefined ? _idfield : "idtypebunimobil";
  var tobjfield = _objfield != undefined ? _objfield : "typebunimobilobj";

  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeBunImobilDTO()
  );
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label]);

  return (
    <GenAutocompleteSimple
      obj={obj}
      setObj={setObj}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _otherdata={otherdata}
    />
  );
};

export { TypeBunImobilAutocomplete };
