import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeMarcaAutoDTO } from "dto/nomenclatoare/TypeMarcaAutoDTO";
import { TypeModelAutoDTO } from "dto/nomenclatoare/TypeModelAutoDTO";
import { TypeCombustibilDTO } from "dto/nomenclatoare/TypeCombustibilDTO";
import { TypeAutoDTO } from "dto/nomenclatoare/TypeAutoDTO";
import { FilesDTO } from "dto/files/FilesDTO";

import { DTOTools } from "api/DTOTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class TransportAutoDTO {
  

  getMainObjPart() { return "TransportAuto"; }
  getFieldsInt() {
    return [
      "id",      
      "anfabricare",
      "datadocumentint",
      "dataevidentaint",
      "idtypemarcaauto",
      "idtypemodelauto",
      "idtypecombustibil",
      "idtypeauto",
      "idscan",
      "datadocumentmicrotime",
      "dataevidentamicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numarinventar",
      "numarinmatriculare",
      "vin",
      "culoare",
      "numardocument",
      "typemarcaauto_text",
      "typemodelauto_text",
      "typecombustibil_text",
      "typeauto_text",
      "datadocument_text",
      "datadocumenttime_text",
      "dataevidenta_text",
      "dataevidentattime_text"
    ];
  }

  getFieldsFloat() {
    return [
      "consumpredefinitvara",
      "consumpredefinitiarna",
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "typemarcaautoobj", _key: "TypeMarcaAuto" },
      { field: "typemodelautoobj", _key: "TypeModelAuto" },
      { field: "typecombustibilobj", _key: "TypeCombustibil" },
      { field: "scanobj", _key: "Files" },
      { field: "typeautoobj", _key: "TypeAuto" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }

  getFieldsUploadArray() {
    return ["filescan"];
  }


  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);

  }

  getDefaultOrderBy() {
    return "numarinventar";
  }

  getLabel() {
    return this.numarinmatriculare + " " + this.numarinventar;
  }


  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "numarinventar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numarinventar"),
      },
      {
        id: "numarinmatriculare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Numar in matriculare"),
      },
      {
        id: "vin",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Vin"),
      },
      {
        id: "anfabricare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_An fabricare"),
      },
      {
        id: "consumpredefinit",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Consum predefinit"),
      },
      {
        id: "culoare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_culoare"),
      },
      {
        id: "numardocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Numar document"),
      },
      {
        id: "datadocumentint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Data document"),
      },
      {
        id: "dataevidentaint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_dataevidenta"),
      },
      {
        id: "idtypemarcaauto",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypemarcaauto"),
      },
      {
        id: "idtypemodelauto",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypemodelauto"),
      },
      {
        id: "idtypecombustibil",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypecombustibil"),
      },
      ,
      {
        id: "idtypeauto",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypeauto"),
      },
    ];

    return _headCells;
  }

}
