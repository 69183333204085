import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";
 
export class TypeStudiiDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeStudii";
  }

  constructor(data = null) {
    super(data);
  }
}
