import React from "react";

import Link from "@mui/material/Link";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

import { logger } from "hoc/logger";

const MyNavBt = ({
  children,
  href,
  className,
  _setmlgenobj,
  _setmlgenobjval,
  _otherdata,
  ...props
}) => {
  
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (_setmlgenobjval != undefined)
    {
      _setmlgenobj(_setmlgenobjval);
    } else
    {
      var od = _otherdata != undefined ? _otherdata : {};

      var _obj = {
        _hash: uuid(),
        _route: href,
        _hashroute: uuid(),
        _otherdata: od,
      };

      _setmlgenobj(_obj);
    }  
  };

  return (
    <Link href={href} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export { MyNavBt };
