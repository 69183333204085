import React from "react";

import { useEffect, useState } from "react";

import { logger } from "hoc/logger";

import { useNavigate } from "react-router-dom";


import { useResource } from "hook/useResource";

import uuid from "react-uuid";

import Button from "@mui/material/Button";

import { MessagesItem } from "components/elements/Messages/MessagesItem";

import Stack from "@mui/material/Stack";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { positions } from "@mui/system";

// import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";

// const MessagesContainerPopUpConfirmUpdate = (_objects, cb, ...props) => {
const MessagesContainerPopUpConfirmUpdate = (props) => {
  const cb = props.cb ? props.cb : false;
  const cbinfo = props._info ? props._info : false;
  
    logger("MessagesContainerPopUpConfirmUpdate121212", cb, cbinfo);

  const navigate = useNavigate();
  
  const { LL } = useResource(false);

  var [initobjects, setInitObjects] = useState(
    props._objects ? props._objects : []
  );
  var [objects, setObjects] = useState([]);

  useEffect(() => {
    var to = [];

    for (var i in initobjects) {
      var t = initobjects[i];
      t._key = uuid();
      to[to.length] = t;
    }

    setObjects(to);
  }, [initobjects]);

  const RemoveOne = (uuid) => {
    // logger("RemoveOne", uuid);
    var t = [];
    var to = objects;

    for (var i in to) {
      if (to[i]._key == uuid) continue;
      t[t.length] = to[i];
    }

    setObjects(t);
    if (!t.length) handleClear();
  };

  const handleClear = () => {
    if (!cb) return;
    cb(false);
  };

  const handleConfirmUpdate = () => {
    logger("handleConfirmUpdate123123cbconfirm", cbinfo);
    if (cbinfo && cbinfo.cbconfirm) cbinfo.cbconfirm(1, cbinfo.params);
    if (cb) cb(false);
  };

  const handleCancelUpdate = () => {
    if (cbinfo && cbinfo.cbconfirm) cbinfo.cbconfirm(-1, cbinfo.params);
    if (cb) cb(false);
  };

  const processItem = (item, i) => {
    var key = uuid();

    return <MessagesItem key={key} item={item} cb={RemoveOne} />;
  };

  const processRemoveButton = () => {
    if (objects.length <= 1) return <></>;
    return (
      <IconButton aria-label="CloseIcon" onClick={handleClear}>
        <CloseIcon size="small" />
      </IconButton>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0px",
          left: "0px",
          maxWidth: "100%",
          width: "100%",
          maxHeigth: "100%",
          height: "100%",
          p: 1,
          zIndex: "tooltip",
          background: "rgba(0,0,0, 0.5)",
          display:"flex",
          alignItems:"center",
          alignContent:"center",
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            p: 1,
            zIndex: "tooltip",
            boxShadow: 2,
            background: "#fff",
            borderRadius: "10px",
          }}
        >
          {processRemoveButton()}
          <Stack sx={{}} spacing={1}>
            {objects.map((item, i) => {
              return processItem(item, i);
            })}
          </Stack>

          <Button
            color="secondary"
            variant="contained"
            type="button"
            sx={{m :1}}
            onClick={handleConfirmUpdate}
          >
            {LL("BT_ConfirmUpdate")}
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{m:1}}
            onClick={handleCancelUpdate}
          >
            {LL("BT_CancelUpdate")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export { MessagesContainerPopUpConfirmUpdate };
