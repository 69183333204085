import React from "react";

import { useEffect, useState } from "react";

import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";


import TextField from "@mui/material/TextField";
import { DTOTools } from "api/DTOTools";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const GenAutocompleteSimple = ({
  obj,
  setObj,
  _setCount,
  _hideOnEmpty,
  _idfield,
  _objfield,
  _defaultobjdto,
  _label,
  _otherdata,
  _showfield,
  ...props
}) => {
  const { LL } = useResource(false);

  var thideOnEmpty = _hideOnEmpty != undefined ? _hideOnEmpty : false;
  var _tsetCount = _setCount != undefined ? _setCount : false;
  var tobject = obj != undefined && obj[_objfield] ? obj[_objfield] : null;
  var tvalue = obj != undefined && obj[_idfield] ? obj[_idfield] : null;
  var tshowfield =
    _showfield != undefined && _showfield ? _showfield : "denumire";

  const [hideOnEmpty, setHideOnEmpty] = useState(thideOnEmpty);
  const [tsetCount, setCount] = useState(_tsetCount);
  const [showfield, setShowField] = useState(tshowfield);
  const [idfield, setIdField] = useState(_idfield);
  const [objfield, setObjField] = useState(_objfield);

  const [defaultValues, setDefaultValues] = useState(tobject);
  const [objects, setObjects] = useState(tobject);

  const [label, setLabel] = useState("");

  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isloaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState("");

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(_defaultobjdto);
  const [otherdata, setOtherData] = React.useState(_otherdata);

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());
    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);

    setIdField(_idfield);
    setObjField(_objfield);
    setDefaultObjDTO(_defaultobjdto);
  }, [
    options,
    obj,
    _label,
    _idfield,
    _objfield,
    _defaultobjdto,
    _label,
    _otherdata,
  ]);

  useEffect(() => {
    var filter = otherdata && otherdata.filter ? otherdata.filter : {};
    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      filter,
      false,
      false,
      false,
      0,
      2000,
      setLoading,
      setOptions,
      false
    );
  }, [obj, _idfield, _objfield, otherdata]);

  useEffect(() => {
    if (objects) {
      setValues(objects.id);
    } else {
      setValues(0);
    }
  }, [objects]);

  useEffect(() => {
    if (isloaded) return;
    setDefaultValues(tobject);
    setObjects(tobject);
    setIsLoaded(true);
  }, [options]);

  useEffect(() => {
    if (!tsetCount) return;
    var t = tsetCount ? tsetCount.length : 0;
    tsetCount(t);
  }, [options]);

  useEffect(() => {
    logger("asdfasdfasdfasdfasdfasdf");
    if (!isloaded) return;
    if (!idfield) return;
    if (!objfield) return;
    if (!obj) return;
    if (!setObj) return;
    if (setObj == undefined) return;
    if (setObj == "undefined") return;

    logger("asdfasdfasdfasdfasdfasdf 33333, idfield", idfield, obj, setObj);
    var _obj = obj;

    _obj[idfield] = values;
    _obj[objfield] = objects;

    setObj(_obj);
  }, [values]);

  const handleChange = (value) => {
    setObjects(value);
  };

  return !isloaded || (hideOnEmpty && !options.length) ? (
    <></>
  ) : (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      defaultValue={defaultValues}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option[showfield] ? option[showfield] : "")}
      options={options}
      loading={loading}
      filterOptions={(x) => {
        if (!search) return x;
        x = x.filter((obj) => {
          if (!obj) return false;
          if (obj[showfield].toLowerCase().includes(search.toLowerCase()))
            return true;
          // return true;
          return false;
        });
        return x;
      }}
      renderInput={(params) => (
        <TextField
          color="secondary"
          margin="normal"
          variant="standard"
          {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export { GenAutocompleteSimple };
