import React from "react";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";


import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Grid from "@mui/material/Grid";

// -------------------------

const DetailGSACDashBoard = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const { LL } = useResource(false);

  return <>DetailGSACDashBoard</>;
};

export { DetailGSACDashBoard };
