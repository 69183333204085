import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";

import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

import { UserDTO } from "dto/user/UserDTO";
import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";


export class UserSacDTO {
  

  getMainObjPart() { return "UserSac"; }
  
  getFieldsInt() {
    return [
      "id",      
      "iduser",
      "idsac",
      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "userobj", _key: "User" },
      { field: "sacobj", _key: "DosarSAC" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  static handleExecUser(data, params, err) {
    logger("handleExecUser", data, params, err);
    if (params != undefined && params._lcb != undefined) {
      params._lcb(data, params, err);
    }
  }

  getDefaultOrderBy() {
    return "id";
  }



  
  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "iduser",
        numeric: false,
        disablePadding: true,
        label: LL("TD_iduser"),
      },
      {
        id: "idsac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idsac"),
      },
    ];

    return _headCells;
  }

}