import React from "react";
import { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";

import { logger } from "hoc/logger";

import { useResource } from "hook/useResource";

import PersonIcon from "@mui/icons-material/Person";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import { SacMenu } from "components/core/MainLayout/SacMenu";

import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { Typography } from "@mui/material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";


const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: 0,

  width: `calc(${theme.spacing(6.5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  flexDirection: "column",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MyMainDrawer = ({
  _setmlgenobj,
  _otherdata,
  _currentpath,
  _mainobject,
  DrawerHeader,
  ...props
}) => {
  const { LL, HASACL_METHOD } = useResource(false);

  const theme = useTheme();

  logger("MyMainDrawer_LL", LL);

  const [openMainDrawer, setOpenMainDrawer] = useState(false);

  const [menus, setMenus] = useState([]);

  const processItemGetIcon = (_key) => {
    if (_key == "mm_nomenclatoare") return <DisplaySettingsIcon />;
    if (_key == "mm_document") return <TextSnippetOutlinedIcon />;
    if (_key == "mm_contacte") return <ContactPhoneOutlinedIcon />;
    if (_key == "mm_bunimobil") return <FolderCopyOutlinedIcon />;
    if (_key == "mm_homepage") return <HomeOutlinedIcon />;
    if (_key == "mm_user") return <SettingsOutlinedIcon />;
    

    // general icon
    return <></>;
  };

  const checkselectedgeneral = (_key, _selected) => {
    _selected = _selected == undefined ? false : _selected;

    if (_selected) return _selected;

    var mo = _mainobject ? _mainobject.toLowerCase() : "";

    if (!_selected && mo == "add" + _key) _selected = true;
    if (!_selected && mo == "detail" + _key) _selected = true;
    if (!_selected && mo == "edit" + _key) _selected = true;
    if (!_selected && mo == _key) _selected = true;

    return _selected;
  };

  const getselected = (submenus) => {
    var _selected = false;
    for (var i in submenus) {
      _selected = checkselectedgeneral(submenus[i], _selected);
      if (_selected) break;
    }

    return _selected;
  };

  const hasaccess = (submenus) => {
    var rez = false;
    for (var i in submenus) {
      rez = HASACL_METHOD(submenus[i]) ? true : rez;
      if (rez) break;
    }

    return rez;
  };

  const processList_HomePage = () => {
    var _selected = false;

    var mo = _mainobject ? _mainobject.toLowerCase() : "";

    if (!_selected && mo == "homepage") _selected = true;

    var tobj = {};

    tobj.title = LL("MMTitle_HomePage");
    tobj.label = LL("MMLabel_HomePage");
    tobj.url = "/";
    tobj.icon = "mm_homepage";
    tobj.selected = _selected;

    return tobj;
  };

  const processList_Contacte = () => {
    var submenus = ["persoanafizica", "persoanajuridica"];

    var _ha = hasaccess(submenus);
    if (!_ha) return false;

    var tobj = {};

    tobj.title = LL("MMTitle_Contacte");
    tobj.label = LL("MMLabel_Contacte");
    tobj.url = "/persoanafizica";
    tobj.icon = "mm_contacte";
    tobj.selected = getselected(submenus);

    return tobj;
  };

  const processList_Document = () => {
    var submenus = ["document"];

    var _ha = hasaccess(submenus);
    if (!_ha) return false;

    var tobj = {};

    tobj.title = LL("MMTitle_Document");
    tobj.label = LL("MMLabel_Document");
    tobj.url = "/document";
    tobj.icon = "mm_document";
    tobj.selected = getselected(submenus);

    return tobj;
  };

  const processList_Registre = () => {
    var submenus = [
      "bunimobil",
      "transportauto",
      "dosarsac",
      "piloniluminare",
      "corpiluminarestradal",
      "mobilier",
      "utilajincalzire",
      "puncttransformare",
      "traseuelectric",
      "cameravideo",
      "registratorvideo",
      "aparatcafea",
      "utilajtehnologic",
      "panoupret",
      "generatorelectric",
      "transformatorelectric",
      "pistoldistribuitor",
    ];

    var _ha = hasaccess(submenus);
    if (!_ha) return false;

    var tobj = {};

    tobj.title = LL("MMTitle_Registre");
    tobj.label = LL("MMLabel_Registre");
    tobj.url = "/bunimobil";
    tobj.icon = "mm_bunimobil";
    tobj.selected = getselected(submenus);

    return tobj;
  };

  const processList_Nomenclatoare = () => {
    var submenus = [
      "typesetarepanoupret",
      "typefunctie",
      "typedistribuitoare",
      "typepistolpompa",
      "typemobilier",
      "typeindiceprezentassg",
      "typematerial",
      "typeinventargospodaresc",
      "typeechipamentantiincendiar",
      "typeutilajtehnologic",
      "typedestinatiemcc",
      "typetraseu",
      "typecameravideo",
      "typenumarcanaleregistratoare",
      "typesistemcasa",
      "typerobinetautomat",
      "typemontare",
      "typeutilajincalzire",
      "typepuncttransformare",
      "typereteaelectricaexterna",
      "typeiluminare",
      "typepanoupret",
      "typecorp",
      "typemotivinterventie",
      "typeinterventie",
      "typepilon",
      "typecarosabil",
      "typebrand",
      "typecombustibil",
      "typemarcaauto",
      "typeauto",
      "typemodelauto",
      "typephone",
      "typedocument",
      "typeum",
      "typebunimobil",
      "typesex",
      "typestudii",
      "typefolosinta",
      "typehotar",
      "banca",
      "street",
    ];

    var _ha = hasaccess(submenus);
    if (!_ha) return false;

    var tobj = {};

    tobj.title = LL("MMTitle_Nomenclatoare");
    tobj.label = LL("MMLabel_Nomenclatoare");
    tobj.url = "/typephone";
    tobj.icon = "mm_nomenclatoare";
    tobj.selected = getselected(submenus);

    return tobj;
  };

  const processList_User = () => {
    var submenus = ["user", "roles", "jurnal", "label", "rolesacl","usersac"];

    var _ha = hasaccess(submenus);
    if (!_ha) return false;

    var tobj = {};

    tobj.title = LL("MMTitle_User");
    tobj.label = LL("MMLabel_User");
    tobj.url = "/user";
    tobj.icon = "mm_user";
    tobj.selected = getselected(submenus);

    return tobj;
  };

  

  const processList = () => {
    var tmpmenus = [];
    var newmenus = [];

    tmpmenus[tmpmenus.length] = processList_HomePage();
    tmpmenus[tmpmenus.length] = processList_Registre();
    tmpmenus[tmpmenus.length] = processList_Contacte();
    tmpmenus[tmpmenus.length] = processList_Document();
    tmpmenus[tmpmenus.length] = processList_Nomenclatoare();
    tmpmenus[tmpmenus.length] = processList_User();
    

    for (var i in tmpmenus)
    {
      if (!tmpmenus[i]) continue;
      
      newmenus[newmenus.length] = tmpmenus[i];
    }

    setMenus(newmenus);
  };

  useEffect(() => {
    processList();
  }, [_mainobject]);

  const processMenuItem = (obj, i) => {
    var key = "main_menu_" + i;
    return (
      <MyNavBt
        href={obj.url}
        className={"menuPrimary"}
        _setmlgenobj={_setmlgenobj}
      >
        <Tooltip key={key} title={obj.title} placement="bottom">
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: openMainDrawer ? "initial" : "center",
                px: 2.5,
              }}
              className={obj.selected ? "menuPrimaryActive" : "menuPrimary"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: openMainDrawer ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {processItemGetIcon(obj.icon)}
              </ListItemIcon>
              <ListItemText
                className="menuPrimaryText"
                primary={obj.label}
                sx={{ opacity: openMainDrawer ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </MyNavBt>
    );
  };

  const processMenuList = () => {

    return (
      <List>
        {menus.map((obj, i) => {
          return processMenuItem(obj, i);
        })}
      </List>
    );
  };

  return (
    <Box sx={{ position: "absolute", zIndex: 1201 }}>
      <Drawer
        variant="permanent"
        PaperProps={{ elevation: 5 }}
        open={openMainDrawer}
        // open={true}
        onMouseEnter={() => {
          logger("openMainDrawer true");
          setOpenMainDrawer(true);
        }}
        onMouseLeave={() => {
          logger("openMainDrawer false");
          setOpenMainDrawer(false);
        }}
      >
        <DrawerHeader />
        {processMenuList()}
        <Divider />
        <SacMenu
          open={openMainDrawer}
          _mainobject={_mainobject}
          _setmlgenobj={_setmlgenobj}
          _otherdata={_otherdata}
        />
      </Drawer>
    </Box>
  );
};

export { MyMainDrawer };