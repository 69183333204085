import React from "react";

import { useEffect, useState } from "react";

import { logger } from "hoc/logger";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import IconButton from '@mui/material/IconButton';

const MessagesItem = (props) => {
  
  const item = props.item ? props.item : false;
  const cb = props.cb ? props.cb : false;

  var [_obj, setObj] = useState(item);

  const handleRemove = () => {
    logger("RemoveOne", cb, _obj);
    if (!cb) return;
    if (!_obj) return;
    cb(_obj._key);
  };

  
  useEffect(() => {
    if (_obj.type == 10)
    {
      setTimeout(function () { handleRemove(); }, 5000);
    }
    // var to = [];

    // for (var i in initobjects) {
    //   var t = initobjects[i];
    //   t._key = uuid();
    //   to[to.length] = t;
    // }

    // setObjects(to);
  }, [_obj]);

  const processObjectSuccess = () => {
    return (
      <>
        <Alert onClose={handleRemove} severity="success">
          <AlertTitle sx={{fontSize:13, fontWeight:'bold'}}>{_obj.code}</AlertTitle>
          {_obj.label}
        </Alert>
      </>
    );
  };
  
  const processObjectWarning = () => {
    return (
      <>
        <Alert onClose={handleRemove} severity="warning">
          <AlertTitle sx={{fontSize:13, fontWeight:'bold'}}>{_obj.code}</AlertTitle>
          {_obj.label}
        </Alert>
      </>
    );
  };
  
  const processObjectError = () => {
    return (
      <>
        <Alert onClose={handleRemove} severity="error">
          <AlertTitle sx={{fontSize:13, fontWeight:'bold'}}>{_obj.code}</AlertTitle>
          {_obj.label}
        </Alert>
      </>
    );
  };
  
  const processObject = () => {
    if (!_obj) return (<></>);
    
    if (_obj.type == 10) return processObjectSuccess();
    if (_obj.type == 20) return processObjectWarning();
    if (_obj.type == 30) return processObjectError();

    return (<></>);
  };

  return processObject();
};

export { MessagesItem };
