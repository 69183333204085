import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";



import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";


export class RolesDTO {
  

  getMainObjPart() { return "Roles"; }
  getFieldsInt() {
    return [
      "id",      
      "fixed",
      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "identifier",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
     
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }


  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "identifier";
  }

  getLabel() {
    return this.identifier;
  }


  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "identifier",
        numeric: false,
        disablePadding: true,
        label: LL("TD_identifier"),
      },
    ];

    return _headCells;
  }

}