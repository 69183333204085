import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useAuth } from "hook/useAuth";

import { UserDTO } from "dto/user/UserDTO";

import { useResource } from "hook/useResource";

import { logger } from "hoc/logger"; 
 
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";
 
const Login = () => {
  
  const { LL } = useResource(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/";
  
  const [messages, setMessages] = useState([]);

  
  const { user, signin } = useAuth();



  
  useEffect(() => {
    logger("Loginuser", user);
    if (user)
    {
      navigate('/');
    logger("Loginuser exist", user);
    }
  }, [user]);
  
  const processMessages = () => {
    if (!messages) return <></>;
    if (!messages.length) return <></>;

    return <MessagesContainer _objects={messages} cb={setMessages} />;
  };

  const execSubmit = (data, params, err) => {
    logger("LoginexecSubmit", data, params, err);

    if (err == 1)
    {
      logger("LoginexecSubmit 2222");
      setMessages(data.m);
    }
    
    // navigate(fromPage, { replace: true });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    var obj = new UserDTO();

    obj.identifier = form.identifier.value;
    obj.password = form.password.value;
    obj.remember = form.password.remember ? 1 : 0;

    logger("Login handleSubmit");
    logger(obj);

    // signin(obj, () => {
    //   navigate(fromPage, { replace: true });
    // });
    signin(obj, execSubmit);
  };



  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  return (
    <>
      <div className="login-page">
        <div className="login-box">
          <h1 className="text-center">{LL("Login Page")}</h1>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              id="identifier"
              name="identifier"
              label={LL("Identifier Login")}
              variant="outlined"
              color="secondary"
              size="small"
              margin="normal"
              value={formik.values.identifier}
              onChange={formik.handleChange}
              error={
                formik.touched.identifier && Boolean(formik.errors.identifier)
              }
              helperText={formik.touched.identifier && formik.errors.identifier}
            />

            <TextField
              fullWidth
              id="password"
              name="password"
              label={LL("Password")}
              type="password"
              variant="outlined"
              color="secondary"
              size="small"
              margin="normal"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <FormControlLabel
              control={
                <Checkbox
                  type="checkbox"
                  name="remember"
                  value="1"
                  size="small"
                  color="secondary"
                />
              }
              label={LL("Remember")}
            />

            <div className="mt-1">
              <Button
                color="secondary"
                fullWidth
                variant="contained"
                size="large"
                type="submit"
              >
                {LL("Bt Login")}
              </Button>
            </div>
          </form>
        </div>
      </div>

      {processMessages()}
    </>
  );
};

export { Login };
