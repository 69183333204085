import React from "react";
import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { GenAutocompleteSimple } from "components/elements/Autocomplete/GenAutocompleteSimple";
import { RevizieTehnicaDTO } from "dto/documents/RevizieTehnicaDTO";

const RevizieTehnicaAutocomplete = ({ obj,
  setObj,
  _idfield,
  _objfield,
  _label,
  _otherdata,
  ...props }) => {

  const { LL } = useResource(false);

  const [label, setLabel] = useState("");

  var tidfield = _idfield != undefined ? _idfield : "idrevizietehnica";
  var tobjfield = _objfield != undefined ? _objfield : "revizietehnicaobj";

  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new RevizieTehnicaDTO()
  );
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label]);

  return (
    <GenAutocompleteSimple
      obj={obj}
      setObj={setObj}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _otherdata={otherdata}
      _showfield={"numar"}
    />
  );
};

export { RevizieTehnicaAutocomplete };
