import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeInventarGospodarescDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeInventarGospodaresc";
  }

  constructor(data = null) {
    super(data);
  }
}
