import React from "react";

import { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";

import Typography from "@mui/material/Typography";




import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";



import TextField from "@mui/material/TextField";


import Box from "@mui/material/Box";




import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";

const MyDateRangePicker = ({ cb, filterobj, ...props }) => {
  
  const { LL } = useResource(false);
  
  var _startdate =
    filterobj &&
    filterobj.startdate != undefined &&
    filterobj.startdate &&
    filterobj.startdate != -1
      ? filterobj.startdate * 1000
      : "";
  var _enddate =
    filterobj &&
    filterobj.enddate != undefined &&
    filterobj.enddate &&
    filterobj.enddate != -1
      ? filterobj.enddate * 1000
      : "";
  var _label = filterobj && filterobj.label != undefined ? filterobj.label : "";
  var _labelfrom =
    filterobj && filterobj.labelfrom != undefined
      ? filterobj.labelfrom
      : LL("De la");
  var _labelto =
    filterobj && filterobj.labelto != undefined
      ? filterobj.labelto
      : LL("Pina la");

  const [filter, setFilter] = useState(filterobj);

  const [startdate, setStartDate] = useState(_startdate);
  const [enddate, setEndDate] = useState(_enddate);
  const [label, setLabel] = useState(_label);
  const [labelfrom, setLabelFrom] = useState(_labelfrom);
  const [labelto, setLabelTo] = useState(_labelto);

  logger("MyDateRangePicker___1111", filterobj);

  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    var value = "";
    var values = [];
    values[values.length] = startdate ? startdate / 1000 : -1;
    values[values.length] = enddate ? enddate / 1000 : -1;

    cb(filter, value, values);
  };

  useEffect(() => {
    ExecCallBack();
  }, [startdate, enddate]);

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  return (
  
    <Box >
      <Typography sx={{fontSize:12, fontWeight:'bold', mr:1}}>{label}</Typography>
      <Box sx={{display:'flex'}}>
        <MyDatePicker
          label={labelfrom}
          inputFormat="DD.MM.YYYY"
          value={startdate}
          onChange={handleStartDateChange}
          variant="standard"
          color="primary"
          sx={{marginRight:1}}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              color="primary"
              size="small"
              margin="normal"
              sx={{maxWidth:200}}

              {...params}
            />
          )}
        />
        <Box sx={{ mx: 1}}></Box>
        <MyDatePicker
          label={labelto}
          inputFormat="DD.MM.YYYY"
          value={enddate}
          onChange={handleEndDateChange}
          variant="standard"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              color="primary"
              size="small"
              sx={{maxWidth:200}}
              margin="normal"
              {...params}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export { MyDateRangePicker };
