import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { CommonTools } from "api/CommonTools";

import { CASCODTO } from "dto/documents/CASCODTO";
import { DTOTools } from "api/DTOTools";

export class CASCOLinkDTO {
  getMainObjPart() {
    return "CASCOLink";
  }
  getFieldsInt() {
    return [
      "id",
      "parentid",
      "idcasco",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
      "parentvalue"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [];
  }

  getFieldsDTOObjects() {
    return [
      { field: "cascoobj", _key: "CASCO" },
    ] 
  }



  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);

  }

  static getAddDataFromArr(rez, objects) {
    return rez;
  }

  getDefaultOrderBy() {
    return "id";
  }

  getLabel() {
    var label = this.id;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "parentid",
        numeric: false,
        disablePadding: true,
        label: LL("TD_parentid"),
      },
      {
        id: "idcasco",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idcasco"),
      },
      {
        id: "idpersoanajuridica",
        numeric: false,
        disablePadding: true,
        label: LL("TD_persoanajuridica"),
      },
      {
        id: "dataint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      },
      {
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      },
      {
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of casco"),
      },
    ];

    return _headCells;
  }
}
 