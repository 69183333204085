import { FetchGet, FetchPost } from "api/RequestTools";

import { DestinationUMDTO } from "dto/nomenclatoare/DestinationUMDTO";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class TypeUMDTO {
  

  getMainObjPart() { return "TypeUM"; }

  getFieldsInt() {
    return [
      "id",
      "iddestination", 
      "isdefaultfordestination", 
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
      "destination_text"
    ];
  }

  getFieldsFloat() {
    return [
      "koef",
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "destinationobj", _key: "DestinationUM" },
      
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    return this.denumire;
  }

 
  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "denumire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Denumire"),
      },
      {
        id: "iddestination",
        numeric: false,
        disablePadding: true,
        label: LL("TD_destination"),
      },
      {
        id: "koef",
        numeric: false,
        disablePadding: true,
        label: LL("TD_koef"),
      },
      {
        id: "isdefaultfordestination",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isdefaultfordestination"),
      },
    ];

    return _headCells;
  }

}