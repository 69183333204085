
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class AvizExpertizaEcologicaDTO {


  getMainObjPart() { return "AvizExpertizaEcologica"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "dataint",
      "dataexpirareint",
      "datamicrotime",
      "dataexpiraremicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "data_text",
      "datatime_text",
      "dataexpirare_text",
      "dataexpiraretime_text",
      "examinator"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [

    ];
  }

  getFieldsStrArray() {
    return [

    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);



  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.data_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
        otherdata.uuidsac != undefined &&
        otherdata.uuidsac
        ? otherdata.uuidsac
        : false;
    var _headCells = [];

    _headCells[_headCells.length] = {
      id: "numar",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Number_avizexpertizaecologica"),
    };

    _headCells[_headCells.length] = {
      id: "examinator",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Number_examinator"),
    };
    _headCells[_headCells.length] = {
      id: "idtypedocument",
      numeric: false,
      disablePadding: true,
      label: LL("TD_typedocument"),
    };
    _headCells[_headCells.length] = {
      id: "dataint",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Creating data"),
    };
    _headCells[_headCells.length] = {
      id: "dataexpirareint",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Expiration data"),
    };
    _headCells[_headCells.length] = {
      id: "idscan",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Scan of avizexpertizaecologica"),
    };
    if (!uuidsac) {
      _headCells[_headCells.length] = {
        id: "idsac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idsac"),
      }
    }


    return _headCells;
  }

}
