import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeAutoDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeAuto";
  }

  constructor(data = null) {
    super(data);
  }
}
