import { FetchGet } from "api/RequestTools";

import { logger } from "hoc/logger";



export class LocationDTO {
  getMainObjPart() {
    return "Location";
  }
  
  id = "";
  code = "";
  parentcode = "";
  statisticcode = "";
  statutcode = "";
  name = "";
  nameclear = "";

  candelete = "";

  constructor(data = null) {
    this.id = data?.id;
    this.code = data?.code;
    this.parentcode = data?.parentcode;
    this.statisticcode = data?.statisticcode;
    this.statutcode = data?.statutcode;
    this.name = data?.name;
    this.nameclear = data?.nameclear;

    this.candelete = data?.candelete;
  }

  static hsndleLoadLocation(data, params, err) {
    if (params._lcb != undefined) {
      params._lcb(data, params, err);
    }
    // FetchGet("loadlocation", handleSaveData, { parentcode: pc }, {});
    logger("hsndleLoadLocation", data, params, err);
  }

  static loadlocation(cb, parameters) {
    FetchGet("loadlocation", LocationDTO.hsndleLoadLocation, parameters, {
      _lcb: cb,
    });
  }
}
