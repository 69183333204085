import React from "react";

import uuid from "react-uuid";

import { useEffect, useState } from "react";

import { LocationDTO } from "dto/location/LocationDTO";

import { Loading } from "components/general/Loading";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { logger } from "hoc/logger";


import { useResource } from "hook/useResource";

const LocationSelect = ({ cb, parentcode, level, selectedcodes, ...props }) => {
  
  const { LL } = useResource(false);
  
  selectedcodes = selectedcodes == undefined ? [] : selectedcodes;
  parentcode = parentcode == undefined ? 0 : parentcode;
  level = level == undefined ? 1 : level;
  level = parseInt(level);

  // logger("LocationSelect: cb: ", cb);
  // logger("LocationSelect: parentcode: ", parentcode);
  // logger("LocationSelect: level: ", level);
  // logger("LocationSelect: selectedcodes: ", selectedcodes, parentcode);

  // logger("LocationSelect: selectedcodes11111: ", selectedcodes, parentcode);

  const [isLoading, setIsLoading] = useState(true);

  // const [value, setValue] = React.useState(tv);
  const [value, setValue] = React.useState('');
  const [isfounded, setIsFounded] = React.useState(-1);
  const [pc, setParentCode] = React.useState(parentcode);
  const [objects, setObjects] = React.useState([]);
  const [childs, setChilds] = React.useState([]);

  const [scodes, setSCodes] = React.useState([]);

  const lid = uuid();
  const lkey = uuid();
  const nextlevel = level + 1;

  useEffect(() => {
    // logger("LocationSelect: selectedcodes isfounded parentcode: ", parentcode);
    // logger("LocationSelect: selectedcodes isfounded selectedcodes: ", selectedcodes);
    // if (isfounded == -1) return;

    if (selectedcodes == undefined || !selectedcodes) {
      selectedcodes = [];
    }

    // var maxlen = 9999;
    // if (!parentcode) {
    //   maxlen = level - 1;
    // }

    // var t = [];
    // for (var i in selectedcodes)
    // {
    //   t[t.length] = selectedcodes[i];
    //   if (t.length >= maxlen) break;
    // }

    // setSCodes(t);

    // setValue('');

    var val = "";

    if (selectedcodes.length >= level) {
      if (level == 1) {
        val = selectedcodes[level - 1];
        setValue(selectedcodes[level - 1]);
      } else if (selectedcodes[level - 2] == pc) {
        val = selectedcodes[level - 1];
        setValue(selectedcodes[level - 1]);
      } else {
        setValue("");
      }

      // setValue(selectedcodes[level - 1]);
    } else {
      setValue("");
    }
    // logger("LocationSelect: selectedcodes isfounded level: ", level);
    // logger("LocationSelect: selectedcodes isfounded val: ", val);
    // logger("LocationSelect: selectedcodes isfounded val: ", val, selectedcodes);

    // setIsLoading(true);
    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);

    if (!val)
    {
      var t = [];
      for (var i in selectedcodes)
      {
        t[t.length] = selectedcodes[i];
        if (t.length >= level) break;
      }
      setSCodes(t);
    } else
    {
      
      setSCodes(selectedcodes);
    }

  }, [pc]);

  useEffect(() => {
    LoadData();
  }, [pc]);







  useEffect(() => {
    ExecCallBack();
  }, [value, childs]);

  useEffect(() => {
    

    if (!objects.length) setIsFounded(0);
    if (!objects.value) setIsFounded(0);


    var founded = 0;
    for (var i in objects)
    {
      if (objects[i].code != value) continue;

      setIsFounded(1);
      founded = 1;
      break;
    }

    if (!founded) setIsFounded(1);


  }, [value, objects]);

  useEffect(() => {
    if (scodes.length < level) return;
    
    var t = scodes;
    t[level - 1] = value;
    setSCodes(t);
  }, [value]);

  // useEffect(() => {
  //   selectedcodes
  // }, [value]);

  // useEffect(() => {
  //   LoadChilds();
  // }, [value]);

  const ExecCallBack = () => {
    // logger("LocationSelect: ExecCallBack value: ", value);
    // logger("LocationSelect: ExecCallBack childs: ", childs);
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    let tarr = [value, ...childs];
    tarr = tarr.filter((n) => n);

    cb(tarr);
  };

  const handleChange = (event) => {
    
    // var t = setSCodes(selectedcodes);
    var t = scodes;
    if (t.length >= level) {
      var t1 = [];
      for (var i in t)
      {
        if (i >= level - 1) break;
        t1[t1.length] = t[i];
      }
      setSCodes(t1);
    }


    setValue(event.target.value);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
    // setIsLoading(false);
  };

  const saveChilds = (values) => {
    setChilds(values);
  };

  const handleSaveData = (data, params, err) => {
    if (err) {
      return;
    }

    var items = [];
    try {
      for (var i in data.objects) {
        var tobj = new LocationDTO(data.objects[i]);
        items[items.length] = tobj;
      }
    } catch (err) {}

    setObjects(items);
    // ExecCallBack();
    setIsLoading(false);
  };

  const LoadData = () => {
    if (pc == -1) {
      setIsLoading(false);
      return;
    }

    LocationDTO.loadlocation(handleSaveData, { parentcode: pc });
  };

  const LoadChilds = () => {
    if (value == "") {
      return <></>;
    }

    return (
      <>
        <LocationSelect
          parentcode={value}
          cb={saveChilds}
          level={nextlevel}
          selectedcodes={scodes}
        />
      </>
    );
  };

  const processItem = (item, i) => {
    var key = "locationoption_" + item.code;

    var selected = false;
    if (selectedcodes.length >= level)
    {
      if (item.code == selectedcodes[level - 1])
      {
        // setValue(selectedcodes[level - 1]);
        selected = true;
      }
    }

    return (
      <MenuItem
        key={key}
        value={item.code}
        item={item}
        selected={selected ? true : false}
      >
        {item.name}
      </MenuItem>
    );
  };

  const GetLocationLevel = () => {
    if (level == 1) return LL("Raion");
    if (level == 2) return LL("Localitate / Oras / Comuna");
    if (level == 3) return LL("Localitate / sector / Sat");
    return "Localitate";
  };

  return isLoading ? (
    <Loading />
  ) : pc != -1 && objects.length ? (
    <>
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel id={lkey}>{GetLocationLevel()}</InputLabel>
        <Select
          labelId={lkey}
          id={lid}
          value={value}
          label={LL("Select location")}
          onChange={handleChange}
          color="secondary"
          variant="standard"       
        >
          <MenuItem value={""}>{LL("Select")}</MenuItem>

          {objects.map((item, i) => {
            return processItem(item, i);
          })}
        </Select>
      </FormControl>

      {LoadChilds()}
    </>
  ) : (
    <></>
  );
};

export { LocationSelect };
