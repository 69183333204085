
import React from 'react';

import { UserTopInfo } from "components/auth/UserTopInfo/UserTopInfo";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Box from '@mui/material/Box';

const TopPart = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {


  return (
    <Box sx={{display:'flex',  width:'100%'}}>
      <MyNavBt href="/" className="logo" _setmlgenobj={_setmlgenobj}></MyNavBt>
      <UserTopInfo />
    </Box>
  );
};

export {TopPart}