import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";
import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import uuid from "react-uuid";
import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";
// ----------------------------------

import { UtilajIncalzireDTO } from "dto/dosarsac/UtilajIncalzireDTO";


import { TypeUtilajIncalzireAutocomplete } from "components/elements/Autocomplete/TypeUtilajIncalzireAutocomplete";
import { SacAutocomplete } from "components/elements/Autocomplete/SacAutocomplete";
import { YesNoAutocomplete } from "components/elements/Autocomplete/YesNoAutocomplete";
import { DocumentAutocomplete } from "components/elements/Autocomplete/DocumentAutocomplete";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";

import { MyTextField } from "components/elements/MyFormComponents/MyTextField";  
const AddUtilajIncalzire = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  
 
  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new UtilajIncalzireDTO());
  const [otherdata, setOtherData] = React.useState(_mlotherdata);
  
  const [isLoading, setIsLoading] = useState(false);
  const [backdata, setBackData] = useState({});
  
  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(new UtilajIncalzireDTO());
  const setObj = (_obj) =>
  {
    updateObj(_obj);
    setSpecialHash(uuid());
  }

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
    { label: LL("crumb_Add" + defaultobjdto.getMainObjPart()) },
  ];
 
  useEffect(() => {
    if (_mlotherdata._backdata != undefined) {
      setBackData(_mlotherdata._backdata);
    }
  }, [_mlotherdata]);


  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <form
        onSubmit={(event) => {
          DTOTools.execAddObj(
            event,
            defaultobjdto,
            obj,
            otherdata,
            backdata,
            setObj,
            setIsLoading,
            _setmlmessages,
            _setmlgenobj,
            {}
          );
        }}
      >
        <Container maxWidth="md" className="containerBtnTop">
          <MyNavBt
            href={DTOTools.getListRoute(obj, otherdata)}
            _setmlgenobj={_setmlgenobj}
            _otherdata={backdata}
          >
            <IconButton aria-label="delete" color="primary">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </MyNavBt>
          <Typography variant="h6">
            {LL("TopTitle_Add_" + defaultobjdto.getMainObjPart())}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            {LL("BT_Save")}
          </Button>
        </Container>
        <Divider />

        <Container maxWidth="md">
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="numarinventar"
            required
          />

          <MyDatePicker
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            disableFuture
            _fieldkey="dataamplasare"
          />

          <MyDatePicker
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            disableFuture
            _fieldkey="dataexcludere"
          />

          <TypeUtilajIncalzireAutocomplete obj={obj} setObj={setObj} />
          <SacAutocomplete obj={obj} setObj={setObj} />
          <YesNoAutocomplete
            obj={obj}
            setObj={setObj}
            _nofirst
            _idfield={"isinuse"}
            _label={LL("Indice de utilizare")}
          />

          <DocumentAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="documentamplasareids"
            _objfield="documentamplasareobjects"
            _label="Document amplasare"
          />
          <DocumentAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="documentexcludereids"
            _objfield="documentexcludereobjects"
            _label="Document excludere"
          />
        </Container>
      </form>
    </>
  );
};

export { AddUtilajIncalzire };
