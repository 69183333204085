import React from "react";

import { GenAddTypeDenumire } from "components/elements/MyGenComponents/GenAddTypeDenumire";
// -----------------------------

import { TypePunctTransformareDTO } from "dto/nomenclatoare/TypePunctTransformareDTO";

const AddTypePunctTransformare = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypePunctTransformareDTO()
  );

  return (
    <GenAddTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { AddTypePunctTransformare };
