
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class EvidentaContorDTO {


  getMainObjPart() { return "EvidentaContor"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datavalabilitateint",
      "dataverificarestatutint",
      "datavalabilitatemicrotime",
      "dataverificarestatutmicrotime",
      "status",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "datavalabilitate_text",
      "datavalabilitatetime_text",
      "dataverificarestatut_text",
      "dataverificarestatuttime_text",
      
      "note",
      "organcontrol",
      "lastcontrol",
      "planedcontrol",
      "contor_apa",
      "contor_canalizare",
      "statie_epurare",
      "contract_apa_canalizare",
      "contract_evacuare_ape_uzate",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "statusobj", _key: "Status" },
    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);

  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];
  _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_evidentacontor"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "datavalabilitateint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Valabilitate data"),
      };
      _headCells[_headCells.length] ={
        id: "dataverificarestatutint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Verificarestatut data"),
      }
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of evidentacontor"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "organcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_organcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "lastcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_lastcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "planedcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_planedcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "status",
        numeric: false,
        disablePadding: true,
        label: LL("TD_status"),
      };
      _headCells[_headCells.length] ={
        id: "note",
        numeric: false,
        disablePadding: true,
        label: LL("TD_note"),
      };
      _headCells[_headCells.length] ={
        id: "contor_apa",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contor_apa"),
      };
      _headCells[_headCells.length] ={
        id: "contor_canalizare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contor_canalizare"),
      };
      _headCells[_headCells.length] ={
        id: "statie_epurare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_statie_epurare"),
      };
      _headCells[_headCells.length] ={
        id: "contract_apa_canalizare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contract_apa_canalizare"),
      };
      _headCells[_headCells.length] ={
        id: "contract_evacuare_ape_uzate",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contract_evacuare_ape_uzate"),
      };

    return _headCells;
  }

}
