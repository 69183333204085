import { FetchGet, FetchPost, GetSuffixUrl } from "api/RequestTools";



import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";
export class RolesACLDTO {
  getMainObjPart() {
    return "RolesACL";
  }
  getFieldsInt() {
    return ["id", "idrole", "moddate"];
  }

  getFieldsStr() {
    return ["siteid", "module", "method"];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [];
  }
  getFieldsDTOObjects() {
    return [];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  static handleGetRolesACLHash(data, params, err) {
    if (params._lcb == undefined || !params._lcb) return;
    params._lcb(data.hash);
  }

  static GetRolesACLHash(cb) {
    var cbparameters = {};
    cbparameters._lcb = cb;
    FetchGet(
      "getrolesaclhash",
      RolesACLDTO.handleGetRolesACLHash,
      {},
      cbparameters
    );
  }

  static handleLoadRolesACL(data, params, err) {
    logger("handleLoadRolesACLs111122223333", data, params, err);
    if (params._lcb == undefined || !params._lcb) return;

    params._lcb(data.objects, data.hash);
  }

  static LoadRolesACL(cb) {
    var cbparameters = {};
    cbparameters._lcb = cb;
    FetchGet(
      "loadallrolesacl",
      RolesACLDTO.handleLoadRolesACL,
      {},
      cbparameters
    );
  }

  // ------------------------------------------------

  getAddData() {
    var rez = {};
    if (this.id) rez.id = this.id;

    rez.module = this.module;
    rez.method = this.method;
    rez.idrole = this.idrole;

    return rez;
  }

  static handleExecAdd(data, params, err) {}

  static execAdd(_module, _method, _idrole) {
    var data = {};
    data.module = _module;
    data.method = _method;
    data.idrole = _idrole;

    var cbparameters = {};
    cbparameters._lcb = "";

    FetchPost("addrolesacl", RolesACLDTO.handleExecAdd, {}, data, cbparameters);
  }

  static execDelete(_module, _method, _idrole) {
    var data = {};
    data.module = _module;
    data.method = _method;
    data.idrole = _idrole;

    var cbparameters = {};
    cbparameters._lcb = "";

    FetchPost(
      "deleteobjectsrolesacl",
      RolesACLDTO.handleExecAdd,
      {},
      data,
      cbparameters
    );
  }

  static parseAccessMethod(identifier) {
    var suffix = GetSuffixUrl(identifier);

    logger("parseAccessMethod", identifier, suffix);

    var t = suffix ? suffix.split("?") : [];
    t = t[0];
    t = t ? t.split("/") : [];

    var rez = {};
    rez.identifier = identifier;
    rez.aclmodule = t[0];
    rez.aclmethod = t.length > 1 ? t[1] : -1;

    return rez;
  }

  static handleSetObj(data, params, err) {
    if (params._lcb == undefined || !params._lcb) return;

    params._lcb(data.value, data.identifier);
  }

  static SetObj(cb, _params, cbparameters) {
    logger("GetObj", cb, _params);
    cbparameters._lcb = cb;
    FetchGet("setrolesacl", RolesACLDTO.handleSetObj, _params, cbparameters);
  }

  static handleExecLoad(data, params, err) {
    if (params._lcb == undefined || !params._lcb) return;

    if (err) {
      params._lcb(data, params, err);
    }

    var objDTO = new RolesACLDTO(data.obj);

    params._lcb(objDTO, data, err);
  }

  static execLoadObj(cb, _uuid, cbparameters) {
    logger("execLoadObj", _uuid);

    cbparameters._lcb = cb;
    FetchGet(
      "loadrolesacl",
      RolesACLDTO.handleExecLoad,
      { uuid: _uuid },
      cbparameters
    );
  }

  static handleExecViewJurnal(data, params, err) {
    return;
  }

  static execViewJurnal(_uuid) {
    FetchGet(
      "setviewjurnalrolesacl",
      RolesACLDTO.handleExecViewJurnal,
      { uuid: _uuid },
      {}
    );
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "identifier",
        numeric: false,
        disablePadding: true,
        label: LL("TD_identifier"),
      },
      {
        id: "value",
        numeric: false,
        disablePadding: true,
        label: LL("TD_value"),
      },
      {
        id: "procesed",
        numeric: false,
        disablePadding: true,
        label: LL("TD_procesed"),
      },
    ];

    return _headCells;
  }

  static handleExecDeleteObjects(data, params, err) {
    if (params._lcb == undefined || !params._lcb) return;

    if (err) {
      params._lcb(data, params, err);
    }

    params._lcb({}, data, err);
  }
}
