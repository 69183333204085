import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";

import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

import { UserDTO } from "dto/user/UserDTO";
import { CommonTools } from "api/CommonTools";


export class JurnalDTO {
  

  getMainObjPart() { return "Jurnal"; }

  getFieldsInt() {
    return [
      "id",
      "dataint",
      "iduser",
      "idobject",
      "datamicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "jurnaltype",
      "parentclass",
      "fromvalue",
      "tovalue",
      "data_text",
      "datatime_text",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [
      "fromvalue_arr",
      "tovalue_arr",
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "userobj", _key: "User" },
      
    ];
  }
  getFieldsDTOObjectsArray() {
    return [ 
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [   
    ];
  }
 
  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);   
    
  }

  getDefaultOrderBy() {
    return "dataint";
  }

  getLabel() {
    return "datatime_text";
  }


  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "data",
        numeric: false,
        disablePadding: true,
        label: LL("TD_data"),
      },
      {
        id: "jurnaltype",
        numeric: false,
        disablePadding: true,
        label: LL("TD_jurnaltype"),
      },
      {
        id: "user",
        numeric: false,
        disablePadding: true,
        label: LL("TD_user"),
      },
      {
        id: "from",
        numeric: false,
        disablePadding: true,
        label: LL("TD_from"),
      },
      {
        id: "to",
        numeric: false,
        disablePadding: true,
        label: LL("TD_to"),
      },
    ];

    return _headCells;
  }

}
