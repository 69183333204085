import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { CommonTools } from "api/CommonTools";

import { DocumentDTO } from "dto/documents/DocumentDTO";
import { DTOTools } from "api/DTOTools";

export class DocumentLinkDTO {
  

  getMainObjPart() { return "DocumentLink"; }
  getFieldsInt() {
    return [
      "id",
      "parentid",
      "iddocument",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
      "parentvalue"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [];
  }
  getFieldsDTOObjects() {
    return [
      { field: "documentobj", _key: "Document" },
    ];
  }
  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  static getAddDataFromArr(rez, objects) {
    return rez;
  }

  getDefaultOrderBy() {
    return "id";
  }

  getLabel() {
    var label = this.id;
    return label;
  }

 

  

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "parentid",
        numeric: false,
        disablePadding: true,
        label: LL("TD_parentid"),
      },
      {
        id: "iddocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_iddocument"),
      },
      {
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument in list"),
      },
      {
        id: "dataint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      },
      {
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      },
      {
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of document"),
      },
    ];

    return _headCells;
  }

}
 