import { FetchGet, FetchPost } from "api/RequestTools";

import { PersoanaFizicaLinkDTO } from "dto/nomenclatoare/PersoanaFizicaLinkDTO";
import { PersoanaJuridicaLinkDTO } from "dto/nomenclatoare/PersoanaJuridicaLinkDTO";

import { TypeBunImobilDTO } from "dto/nomenclatoare/TypeBunImobilDTO";
import { TypeFolosintaDTO } from "dto/nomenclatoare/TypeFolosintaDTO";
import { TypeUMDTO } from "dto/nomenclatoare/TypeUMDTO";
import { TypeHotarDTO } from "dto/nomenclatoare/TypeHotarDTO";

import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";

import { CommonTools } from "api/CommonTools";


import { logger } from "hoc/logger";

import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class BunImobilDTO {
  getMainObjPart() {
    return "BunImobil";
  }

  getFieldsInt() {
    return [
      "id",
      "idtypebunimobil",
      "idtypefolosinta",
      "idtypeum",
      "idtypehotar",
      "idstreet",

      "dataestimareint",
      "dataestimaremicrotime",

      "datainregistrareint",
      "datainregistraremicrotime",

      "osmplaceid",
      "locationcode",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numarcadastral",
      "streetnumber",
      "codepostal",
      "fulllocation",

      "datainregistrare_text",
      "datainregistraretime_text",

      "dataestimare_text",
      "dataestimaretime_text",

      "sursavaloareestimata",
    ];
  }

  getFieldsFloat() {
    return ["suprafata", "valoareestimata", "lat", "lng"];
  }

  getFieldsIntArray() {
    return [
      "locationcodes",
      "persoanafizicaids",
      "persoanajuridicaids",
      "documentids",
    ];
  }

  getFieldsStrArray() {
    return ["persoanafizica", "persoanajuridica", "document"];
  }

  getFieldsDTOObjects() {
    return [
      { field: "streetobj", _key: "Street" },
      { field: "typehotarobj", _key: "TypeHotar" },
      { field: "typebunimobilobj", _key: "TypeBunImobil" },
      { field: "typefolosintaobj", _key: "TypeFolosinta" },
      { field: "typeumobj", _key: "TypeUM" },
    ];
  }

  getFieldsDTOObjectsArray() {
    return [{ field: "locationobjects", _key: "Location" }];
  }

  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "persoanafizicalinkobjects",
        _key: "PersoanaFizicaLink",
        mainobjfield: "persoanafizicaobjects",
        mainobjkey: "PersoanaFizica",
        mainobjobjparam: "persoanafizicaobj",
        mainidfield: "persoanafizicaids",
        mainobjidparam: "idpersoanafizica",
      },
      {
        field: "persoanajuridicalinkobjects",
        _key: "PersoanaJuridicaLink",
        mainobjfield: "persoanajuridicaobjects",
        mainobjkey: "PersoanaJuridica",
        mainobjobjparam: "persoanajuridicaobj",
        mainidfield: "persoanajuridicaids",
        mainobjidparam: "idpersoanajuridica",
      },
      {
        field: "documentlinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentids",
        mainobjidparam: "iddocument",
      },
    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "numarcadastral";
  }

  getLabel() {
    return this.numarcadastral;
  }

  GetListHeadCells(LL, otherdata) {
    logger("GetListHeadCells123", otherdata);
    const _headCells = [
      {
        id: "numarcadastral",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numarcadastral"),
      },
      {
        id: "idtypebunimobil",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Typebunimobil"),
      },
      {
        id: "idtypefolosinta",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typefolosinta"),
      },
      {
        id: "suprafata",
        numeric: false,
        disablePadding: true,
        label: LL("TD_suprafata"),
      },
      {
        id: "streetnumber",
        numeric: false,
        disablePadding: true,
        label: LL("TD_adress"),
      },
      // {
      //   id: "codepostal",
      //   numeric: false,
      //   disablePadding: true,
      //   label: LL("TD_codepostal"),
      // },
      {
        id: "datainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Data inregistrare"),
      },
      {
        id: "dataestimareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Data estimare"),
      },
      {
        id: "valoareestimata",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Valoare estimata"),
      },
      {
        id: "sursavaloareestimata",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Sursa valoare estimata"),
      },
    ];

    return _headCells;
  }
}
