import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeMarcaAutoDTO } from "dto/nomenclatoare/TypeMarcaAutoDTO";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class TypeModelAutoDTO {
  

  getMainObjPart() { return "TypeModelAuto"; }

  getFieldsInt() {
    return [
      "id",      
      "idtypemarcaauto",  
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
      "typemarcaauto_text"
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  

  getFieldsDTOObjects() {
    return [
      { field: "typemarcaautoobj", _key: "TypeMarcaAuto" },
      
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }
  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    return this.denumire;
  }

  getLabelFull() {
    var rez = this.denumire != undefined ? this.denumire : "";
    rez += rez ? ", " : "";
    rez += this.typemarcaauto_text != undefined ? this.typemarcaauto_text : "";
    return rez;
  }


  static GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "denumire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Denumire"),
      },
      {
        id: "idtypemarcaauto",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typemarcaauto"),
      },
    ];

    return _headCells;
  }

}