import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

// -----------------------------

import { TransportAutoDTO } from "dto/transportauto/TransportAutoDTO";

import { TypeCombustibilAutocomplete } from "components/elements/Autocomplete/TypeCombustibilAutocomplete";
import { TypeAutoAutocomplete } from "components/elements/Autocomplete/TypeAutoAutocomplete";
import { TypeMarcaAutoAutocomplete } from "components/elements/Autocomplete/TypeMarcaAutoAutocomplete";
import { TypeModelAutoAutocomplete } from "components/elements/Autocomplete/TypeModelAutoAutocomplete";
import { TypeModelAutoSelect } from "components/elements/Autocomplete/TypeModelAutoSelect";

import DownloadIcon from "@mui/icons-material/Download";

import { StreetSelect } from "components/elements/LocationSelect/StreetSelect";

import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";

import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { MyTextField } from "components/elements/MyFormComponents/MyTextField";
import uuid from "react-uuid";
import { MyFileField } from "components/elements/MyFormComponents/MyFileField";
const EditTransportAuto = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  // -------------------------------------------------------
  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TransportAutoDTO()
  );
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [backdata, setBackData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(false);
  // -------------------------------------------------------

  const setObj = (_obj) => {
    updateObj(_obj);
    setSpecialHash(uuid());
  };
  // -------------------------------------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
  ];
  // -------------------------------------------------------

  useEffect(() => {
    FilterTools.processOtherDataEdit(
      defaultobjdto,
      obj,
      otherdata,
      setBackData,
      setIsLoading,
      setObj,
      {}
    );
  }, [_mlotherdata]);
  // -------------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <form
        onSubmit={(event) => {
          DTOTools.execAddObj(
            event,
            defaultobjdto,
            obj,
            otherdata,
            backdata,
            setObj,
            setIsLoading,
            _setmlmessages,
            _setmlgenobj,
            {}
          );
        }}
      >
        <Container maxWidth="md" className="containerBtnTop">
          <MyNavBt
            href={DTOTools.getDetailRoute(obj, otherdata)}
            _setmlgenobj={_setmlgenobj}
            _otherdata={backdata}
            _setmlgenobjval={DTOTools.getDetailObject(obj, otherdata, backdata)}
          >
            <IconButton aria-label="delete" color="primary">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </MyNavBt>
          <Typography variant="h6">
            {LL("TopTitle_Edit_AparatCafea")}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            {LL("BT_Save")}
          </Button>
        </Container>
        <Divider />

        <Container maxWidth="md">
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="numarinventar"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="numarinmatriculare"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="vin"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="anfabricare"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="consumpredefinitvara"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="consumpredefinitiarna"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="culoare"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="numardocument"
            required
          />
          <MyDatePicker
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            disableFuture
            _fieldkey="datadocument"
          />
          <MyDatePicker
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            disableFuture
            _fieldkey="dataevidenta"
          />

          <MyFileField
            label={LL("Scan")}
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="filescan"
          />

          <TypeModelAutoAutocomplete
            obj={obj}
            setObj={setObj}
          />


          <TypeCombustibilAutocomplete
            obj={obj}
            setObj={setObj}
          />
          <TypeAutoAutocomplete
            obj={obj}
            setObj={setObj}
          />

          {/* {processScan()} */}
        </Container>
      </form>
    </>
  );
};

export { EditTransportAuto };
