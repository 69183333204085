import React from "react";


import Box from "@mui/material/Box";

import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Checkbox from "@mui/material/Checkbox";

import { visuallyHidden } from "@mui/utils";
import { logger } from "hoc/logger";



const EnhancedTableHead = ({
  order,
  orderBy,
  numSelected,
  rowCount,
  setOrder,
  setOrderBy,
  headCells,
  showCheckbox,
  objects,
  setSelected,
  ...props
}) => {
  showCheckbox = showCheckbox == undefined ? 1 : showCheckbox;

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    if (setOrder != undefined) setOrder(isAsc ? "desc" : "asc");
    if (setOrderBy != undefined) setOrderBy(property);
  };

  const processSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = objects.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const processCheckbox = () => {
    if (!showCheckbox) return <></>;

    return (
      <TableCell padding="checkbox">
        <Checkbox
          color="secondary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={processSelectAllClick}
          size="small"
          inputProps={{
            "aria-label": "select all",
          }}
        />
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {processCheckbox()}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 600 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { EnhancedTableHead };
