import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeStudiiDTO } from "dto/nomenclatoare/TypeStudiiDTO";

const TypeStudii = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new TypeStudiiDTO());

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};;

export { TypeStudii };
