import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";
export class DestinationUMDTO {
  getMainObjPart() { return "DestinationUM"; }
  getFieldsInt() {
    return [
      "id",
      "fixed",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "identifier",
      "name",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
   
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  
  getDefaultOrderBy() {
    return "name";
  }

  getLabel() {
    return this.name;
  }

}