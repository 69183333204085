import { createContext, useState } from "react";

import { useCookies } from "react-cookie";

import { FetchGet } from "api/RequestTools";
import { logger } from "hoc/logger";



export const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [cookies, setCookies] = useCookies();
  const [token, setToken] = useState(null);

  // ---------------------
  const getToken = (cb) => {
    logger("TokenProvider getToken");

    var t =
      cookies.token != undefined &&
      cookies.token.toLowerCase() != "undefined" &&
      cookies.token.toLowerCase() != "null"
        ? cookies.token
        : "";

    if (!t) {
      FetchGet("getJWT", handleSetToken, {}, { _cb: cb });
      return;
    }

    saveToken(token, cb);
  };

  // ---------------------
  const saveToken = (token, cb) => {
    logger("TokenProvider saveToken");

    setCookies("token", token, { path: "/" });
    setToken(token);

    if (cb != undefined && cb) {
      cb(token);
    }
  };

  // ---------------------
  const handleSetToken = (data, params, err) => {
    logger("TokenProvider handleSetToken");

    if (err) {
      logger("handleSetToken err");
      return;
    }

    let cb = params && params._cb ? params._cb : false;

    saveToken(data.jwt, cb);
  };

  // ---------------------

  // const value = { user, token, signin, signout, checkToken, getToken };
  const value = { token, getToken };

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
};
