import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeCombustibilDTO } from "dto/nomenclatoare/TypeCombustibilDTO";
import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";
import { TypeUMDTO } from "dto/nomenclatoare/TypeUMDTO";
import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";

import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class GeneratorElectricDTO {


  getMainObjPart() { return "GeneratorElectric"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypecombustibil",
      "dataamplasareint",
      "dataexcludereint",
      "idtypeumcapacitate",
      "idtypeumconsum",
      "isinuse",
      "dataamplasaremicrotime",
      "dataexcluderemicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "model",
      "numarinventar",
      "dataamplasare_text",
      "dataexcludere_text",
      "dataamplasaretime_text",
      "dataexcluderetime_text",
    ];
  }

  getFieldsFloat() {
    return [
      "capacitate",
      "consum",
    ];
  }

  getFieldsIntArray() {
    return ["documentids",];
  }

  getFieldsStrArray() {
    return ["document"];
  }

  
  getFieldsDTOObjects() {
    return [
      { field: "typecombustibilobj", _key: "TypeCombustibil" },
      { field: "typeumcapacitateobj", _key: "TypeUM" },
      { field: "typeumconsumobj", _key: "TypeUM" },
      { field: "sacobj", _key: "DosarSAC" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "documentamplasarelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentamplasareobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentamplasareids",
        mainobjidparam: "iddocument",
      },
      {
        field: "documentexcluderelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentexcludereobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentexcludereids",
        mainobjidparam: "iddocument",
      },
    ];
  }
  
  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  
  }

  getDefaultOrderBy() {
    return "numarinventar";
  }

  getLabel() {
    return this.numarinventar;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
        otherdata.uuidsac != undefined &&
        otherdata.uuidsac
        ? otherdata.uuidsac
        : false;
    const _headCells = [];
    _headCells[_headCells.length] = {
      id: "numarinventar",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Numarinventar"),
    }

    if (!uuidsac) {
      _headCells[_headCells.length] = {
        id: "idsac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idsac"),
      };
    }
    _headCells[_headCells.length] ={
      id: "idtypecombustibil",
        numeric: false,
          disablePadding: true,
            label: LL("TD_idtypecombustibil"),
      };
      _headCells[_headCells.length] ={
      id: "dataamplasareint",
        numeric: false,
          disablePadding: true,
            label: LL("TD_dataamplasareint"),
      };
      _headCells[_headCells.length] ={
      id: "capacitate",
        numeric: false,
          disablePadding: true,
            label: LL("TD_capacitate"),
      };
      _headCells[_headCells.length] ={
      id: "consum",
        numeric: false,
          disablePadding: true,
            label: LL("TD_consum"),
      };
      _headCells[_headCells.length] ={
      id: "model",
        numeric: false,
          disablePadding: true,
            label: LL("TD_model"),
      };
      _headCells[_headCells.length] ={
      id: "isinuse",
        numeric: false,
          disablePadding: true,
            label: LL("TD_isinuse"),
      };
      _headCells[_headCells.length] ={
      id: "dataexcludereint",
        numeric: false,
          disablePadding: true,
            label: LL("TD_dataexcludereint"),
      };
    

    return _headCells;
  }

}
