import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { MyTextField } from "components/elements/MyFormComponents/MyTextField";
import uuid from "react-uuid";
import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

// -----------------------------


import { StreetSelect } from "components/elements/LocationSelect/StreetSelect";
import { FullAddressSelect } from "components/elements/LocationSelect/FullAddressSelect";

import { MultiInputPhone } from "components/elements/MultiInput/MultiInputPhone";
import { MultiInputBanca } from "components/elements/MultiInput/MultiInputBanca";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";

import { PersoanaFizicaAutocomplete } from "components/elements/Autocomplete/PersoanaFizicaAutocomplete";
import { PersoanaJuridicaAutocomplete } from "components/elements/Autocomplete/PersoanaJuridicaAutocomplete";

import DownloadIcon from "@mui/icons-material/Download";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { MyFileField } from "components/elements/MyFormComponents/MyFileField";
const EditPersoanaJuridica = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  // -------------------------------------------------------
  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new PersoanaJuridicaDTO()
  );
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [backdata, setBackData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(false);
  // -------------------------------------------------------

  const setObj = (_obj) => {
    updateObj(_obj);
    setSpecialHash(uuid());
  };
  // -------------------------------------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
  ];
  // -------------------------------------------------------

  useEffect(() => {
    FilterTools.processOtherDataEdit(
      defaultobjdto,
      obj,
      otherdata,
      setBackData,
      setIsLoading,
      setObj,
      {}
    );
  }, [_mlotherdata]);
  // -------------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <form
                onSubmit={(event) => {
                    DTOTools.execAddObj(
                        event,
                        defaultobjdto,
                        obj,
                        otherdata,
                        backdata,
                        setObj,
                        setIsLoading,
                        _setmlmessages,
                        _setmlgenobj,
                        {}
                    );
                }}>
        <Container maxWidth="md" className="containerBtnTop">
          <MyNavBt
            href={DTOTools.getDetailRoute(obj, otherdata)}
            _setmlgenobj={_setmlgenobj}
            _otherdata={backdata}
            _setmlgenobjval={DTOTools.getDetailObject(obj, otherdata, backdata)}
          >
            <IconButton aria-label="delete" color="primary">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </MyNavBt>
          <Typography variant="h6">
            {LL("TopTitle_Edit_PersoanaJuridica")}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            {LL("BT_Save")}
          </Button>
        </Container>
        <Divider />

        <Container maxWidth="md">
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="denumire"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="idno"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="codtva"
            required
          />

          <MyFileField
            label={LL("Scan")}
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="filescan"
          />
          <FullAddressSelect obj={obj} setObj={setObj} />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="nroficiu"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="codepostal"
            required
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="email"
            required
          />

          <PersoanaFizicaAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="persoanafizicaids"
            _objfield="persoanafizicaobjects"
            _label={LL("Persoana de contact")}
          />
          <PersoanaFizicaAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="fondatorpersoanafizicaids"
            _objfield="fondatorpersoanafizicaobjects"
            _label={LL("Fondator persoana fizica")}
          />

          <PersoanaJuridicaAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="fondatorpersoanajuridicaids"
            _objfield="fondatorpersoanajuridicaobjects"
            _label={LL("Fondator persoana juridica")}
          />
          <PersoanaFizicaAutocomplete
            obj={obj}
            setObj={setObj}
            _idfield="administratorpersoanafizicaids"
            _objfield="administratorpersoanafizicaobjects"
            _label={LL("Administrator persoana fizica")}
          />

          <MultiInputPhone
            label={LL("phone")}
            typelabel={LL("TypePhone")}
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            _fieldkey="phonesobjects"
          />
          <MultiInputBanca
            label={LL("Cont Bancar")}
            typelabel={LL("Banca")}
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            _fieldkey="contbancarsobjects"
          />
        </Container>
      </form>
    </>
  );
};

export { EditPersoanaJuridica };
