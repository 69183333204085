import {
  React,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import "./leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";

import { Icon } from "leaflet";
// import covidData from "./data.json";
// import icon1 from "./images/covid19.svg";

import L from "leaflet";

import { logger } from "hoc/logger";

import { Loading } from "components/general/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MapOSSugestion from "components/elements/Map/MapOSSugestion";

import uuid from "react-uuid";

const MapOSSelectSugestion = ({
  obj,
  setObj,
  _latfield,
  _lngfield,
  _placeidfield,
  _zoom,
  _search,
  _init_lat,
  _init_lng,
  _label,
  _usecentermap,

  ...props
}) => {
  var tusecentermap = _usecentermap != undefined ? _usecentermap : false;

  var tlatfield = _latfield != undefined ? _latfield : "lat";
  var tlngfield = _lngfield != undefined ? _lngfield : "lng";
  var tplaceidfield = _placeidfield != undefined ? _placeidfield : "osmplaceid";

  _zoom = _zoom != undefined ? _zoom : 15;
  _search = _search != undefined ? _search : "";

  let _lat =
    obj && obj[tlatfield] != undefined && !isNaN(obj[tlatfield])
      ? obj[tlatfield]
      : 47.0255859;
  let _lng =
    obj && obj[tlngfield] != undefined && !isNaN(obj[tlngfield])
      ? obj[tlngfield]
      : 28.8309793;

  var tinit_lat = _init_lat != undefined ? _init_lat : _lat;
  var tinit_lng = _init_lng != undefined ? _init_lng : _lng;

  let _place_id =
    obj && obj[tplaceidfield] != undefined ? obj[tplaceidfield] : 0;

  _label = _label != undefined ? _label : "Map OS";

  const [latfield, setLatField] = useState(tlatfield);
  const [lngfield, setLngField] = useState(tlngfield);
  const [placeidfield, setPlaceIdField] = useState(tplaceidfield);

  const [usecentermap, setUseCenterMap] = useState(tusecentermap);
  const [hash, setHash] = useState(_search);
  const [lat, setLat] = useState(_lat);
  const [lng, setLng] = useState(_lng);
  const [place_id, setPlaceId] = useState(_place_id);
  const [centerlat, setCenterLat] = useState(_lat);
  const [centerlng, setCenterLng] = useState(_lng);
  const [zoom, setZoom] = useState(_zoom);
  const [search, setSearch] = useState(_search);

  const [initlat, setInitLat] = useState(tinit_lat);
  const [initlng, setInitLng] = useState(tinit_lng);

  const [center, setCenter] = useState({ lat: tinit_lat, lng: tinit_lng });

  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState({ lat: lat, lng: lng });

  const markerRef = useRef(null);

  var markerIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/imagesosm/marker-icon.png",
    iconRetinaUrl: process.env.PUBLIC_URL + "/imagesosm/marker-icon.png",
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 55],
  });

  // const [hash, setHash] = useState(uuid());

  // const [cb, setCb] = useState(_cb);

  useEffect(() => {
    logger("MapOSSelectSugestion1111122222useEffect_hash", lat, lng, search);
    if (!lat) return;
    if (!lng) return;

    setCenter({ lat: lat, lng: lng });
  }, [hash]);

  // useEffect(() => {
  //       logger("MapOSSelectSugestion1111122222useEffectsearch", search);
  // }, [search]);

  useEffect(() => {
    if (!lat || lat == initlat) return;
    if (!lng || lng == initlng) return;

    logger(
      "MapOSSelectSugestion1111122222useEffect",
      lat,
      lng,
      place_id,
      search
    );
    
    var _tobj = obj;

    _tobj[latfield] = lat;
    _tobj[lngfield] = lng;
    _tobj[placeidfield] = place_id;

    setObj(_tobj);
  }, [lat, lng, place_id, zoom]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());

          setLat(marker.getLatLng().lat);
          setLng(marker.getLatLng().lng);

          setPlaceId(0);
        }
      },
      // click() {

      //   logger(
      //       "MapOSSelectSugestion1111122222 cb111 click"
      //   );
      // },
    }),
    []
  );

  const DraggableMarker = () => {
    const map = useMapEvents({
      click(e) {
        logger("MapOSSelectSugestion1111122222_click click", e);
        setPosition(e.latlng);
        setLat(e.latlng.lat);
        setLng(e.latlng.lng);
      },

      // dragend() {
      //   logger("MapOSSelectSugestion1111122222 map dragend");
      // },

      // zoom(e) {
      //   logger("MapOSSelectSugestion1111122222_zoom", e.target);
      // },
      zoomend(e) {
        logger(
          "MapOSSelectSugestion1111122222_zoom zoomend",
          e.target._animateToZoom
        );
        setZoom(e.target._animateToZoom);
      },
      // locationfound(e) {
      //   alert("eeee aaaa 4444");
      //   // setPosition(e.latlng);
      //   // map.flyTo(e.latlng, map.getZoom());
      // },
    });

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={markerIcon}
      ></Marker>
    );
  };

  const setMapOsSugestion = (_place_id, _lat, _lng) => {
    if (!_lat) return;
    if (!_lng) return;

    setPlaceId(_place_id);

    setLat(_lat);
    setLng(_lng);

    setCenterLat(_lat);
    setCenterLng(_lng);

    var c = { lat: _lat, lng: _lng };
    setCenter(c);
    setPosition(c);
    setHash(uuid());
  };

  const setMapOsSugestionCenter = (_lat, _lng) => {
    if (!usecentermap) return;
    if (!_lat) return;
    if (!_lng) return;

    setLat(_lat);
    setLng(_lng);

    setCenterLat(_lat);
    setCenterLng(_lng);

    var c = { lat: _lat, lng: _lng };
    setCenter(c);
    setPosition(c);
    // setHash(uuid());
  };

  return (
    <>
      <MapOSSugestion
        _search={search}
        _cb={setMapOsSugestion}
        _cbcenter={setMapOsSugestionCenter}
      />
      <MapContainer
        key={"MapContainer_" + hash + "_" + centerlat + "_" + centerlng}
        center={[centerlat, centerlng]}
        zoom={zoom}
        style={{ height: "400px" }}
        // eventHandlers={eventHandlersMap}
        // onClick={addMarker}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
      </MapContainer>
    </>
  );
};
export default MapOSSelectSugestion;
