import { RolesDTO } from "dto/user/RolesDTO";
import { DTOTools } from "api/DTOTools";

export class UserRolesDTO {
  getMainObjPart() { return "UserRoles"; }
  getFieldsInt() {
    return [
      "id",      
      "iduser",
      "idroles",
      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }

  

 getFieldsDTOObjects() {
  return [
    { field: "rolesobj", _key: "Roles" },
  ];
}

getFieldsDTOObjectsArray() {
  return [
  ];
}

getFieldsDTOObjectsArrayLink() {
  return [

  ];
}

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
 
  }

  static getAddDataFromArr(rez, objects) {
    // if (Array.isArray(objects) && objects.length) {
    //   for (var i in objects) {
    //     if (objects[i].id) rez["contbancars[" + i + "][id]"] = objects[i].id;
    //     if (objects[i].uuid) rez["contbancars[" + i + "][uuid]"] = objects[i].uuid;
    //     if (objects[i].contbancar)
    //       rez["contbancars[" + i + "][contbancar]"] = objects[i].contbancar;
    //     if (objects[i].idpersoanafizica)
    //       rez["contbancars[" + i + "][idpersoanafizica]"] = objects[i].idpersoanafizica;
    //   }
    // }
    return rez;
  }

  getDefaultOrderBy() {
    return "id";
  }


}
