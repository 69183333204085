import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeCorpDTO } from "dto/nomenclatoare/TypeCorpDTO";
import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";

import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";


import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class CorpIluminareStradalDTO {
  

  getMainObjPart() { return "CorpIluminareStradal"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypecorp",
      "dataamplasareint",
      "dataexcludereint",
      "isinuse",
      "dataamplasaremicrotime",
      "dataexcluderemicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "code",
      "numarinventar",
      "dataamplasare_text",
      "dataexcludere_text",
      "dataamplasaretime_text",
      "dataexcluderetime_text",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return ["documentids",];
  }

  getFieldsStrArray() {
    return ["document"];
  }

  getFieldsDTOObjects() {
    return [
      { field: "typecorpobj", _key: "TypeCorp" },
      { field: "sacobj", _key: "DosarSAC" },
    ];
  }
  
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "documentamplasarelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentamplasareobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentamplasareids",
        mainobjidparam: "iddocument",
      },
      {
        field: "documentexcluderelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentexcludereobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentexcludereids",
        mainobjidparam: "iddocument",
      },
    ];
  }
  
  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  
  }

  getDefaultOrderBy() {
    return "numarinventar";
  }

  getLabel() {
    return this.code;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
      otherdata.uuidsac != undefined &&
      otherdata.uuidsac
        ? otherdata.uuidsac
        : false;
    const _headCells = [];
    _headCells[_headCells.length] ={
        id: "code",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Code"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "idtypecorp",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypecorp"),
      };
      _headCells[_headCells.length] ={
        id: "dataamplasareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_dataamplasareint"),
      };
      _headCells[_headCells.length] ={
        id: "numarinventar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numarinventar"),
      };
      _headCells[_headCells.length] ={
        id: "isinuse",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isinuse"),
      };
      _headCells[_headCells.length] ={
        id: "dataexcludereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_dataexcludereint"),
      };
    
    return _headCells;
  }

}
