import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import { FilterTools } from "api/FilterTools";

import uuid from "react-uuid";
import { DTOTools } from "api/DTOTools";

// ---------------------------------

import { RolesDTO } from "dto/user/RolesDTO";

import { MyRolesACLContainerBlock } from "components/core/RolesACL/MyRolesACLContainerBlock";

import {
  RolesACLListModules
} from "api/RequestTools";

const RolesACL = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const { LL } = useResource(false);
  

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new RolesDTO());
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [isLoading, setIsLoading] = useState(true);

  const [rolesObjects, setRolesObjects] = useState(-1);

  const [aclmodules, setACLModules] = useState([]);

  // ----------------------------------------------------------

  const [headCells, setHeadCells] = useState([]);

  const [dense, setDense] = React.useState(false);

  // --------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    { label: LL("crumb_RolesACL") },
  ];

  // -------------------------------------------------
  useEffect(() => {
    var t = RolesACLListModules();
    setACLModules(t);
  }, []);

  // -------------------------------------------------
  useEffect(() => {
    checkIsLoading();
    logger("aclmodulesaclmodules:", aclmodules);
  }, [rolesObjects, aclmodules]);

  const checkIsLoading = () => {
    if (rolesObjects == -1) return;
    if (!aclmodules.length) return;

    setIsLoading(false);
  };

  // -------------------------------------------------
  useEffect(() => {
    if (rolesObjects != -1) return;
    loadRolesData();
  }, []);

  const loadRolesData = () => {
    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      { foracl: 1 },
      [],
      false,
      false,
      0,
      99999,
      setIsLoading,
      setRolesObjects,
      false
    );
  };

  useEffect(() => {
    if (rolesObjects == -1) return;

    logger("rolesObjects", rolesObjects);

    const _headCells = [
      {
        id: "functionality",
        numeric: false,
        disablePadding: true,
        label: LL("TD_functionality"),
      },
    ];

    for (var i in rolesObjects) {
      var t = {};
      t.id = "td_role_id_" + rolesObjects[i].id;
      t.numeric = true;
      t.disablePadding = true;
      t.label = rolesObjects[i].identifier;

      _headCells[_headCells.length] = t;
    }

    setHeadCells(_headCells);
  }, [rolesObjects]);

  // -------------------------------------------------
  const processModule = (module) => {

    logger("processModule_1231231", module);

    var k = uuid();
    return (
      <MyRolesACLContainerBlock
        key={k}
        _RolesObjects={rolesObjects}
        _Module={module}
        headCells={headCells}
      />
    );
  };

  // -------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <Paper sx={{ width: "100%", }} elevation={0}>
        <TableContainer sx={{ minWidth: 750, maxHeight: '80vh' }}>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
            className="cursorPointer"
          >
            <EnhancedTableHead showCheckbox={false} headCells={headCells}/>

            <TableBody>
              {aclmodules.map((module, i) => {
                return processModule(module);
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={(e) => {FilterTools.changeDense(e, setDense);}} />}
        label={LL("VizualizareCompacta")}
      />
    </>
  );
};

export { RolesACL };
