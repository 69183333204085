import { DTOTools } from "api/DTOTools";

export class ControlDebitDTO {


    getMainObjPart() { return "ControlDebit"; }
    getFieldsInt() {
        return [
            "id",
            "parentid",
            "idtypecombustibil",
        ];
    }

    getFieldsStr() {
        return [
            "uuid",
            "parentclass",
            "nota",
            "cantitate"
        ];
    }

    getFieldsFloat() {
        return [];
    }

    getFieldsIntArray() {
        return [];
    }

    getFieldsStrArray() {
        return [];
    }
    getFieldsDTOObjects() {
        return [
            { field: "typecombustibilobj", _key: "TypeCombustibil" },
        ];
    }

    constructor(data = null) {
        DTOTools.parseConstructorData(this, data);
    }

    static getAddDataFromArr(rez, objects) {
        if (Array.isArray(objects) && objects.length) {
            for (var i in objects) {
                if (objects[i].id) rez["controldebit[" + i + "][id]"] = objects[i].id;
                if (objects[i].uuid) rez["controldebit[" + i + "][uuid]"] = objects[i].uuid;
                if (objects[i].nota)
                    rez["controldebit[" + i + "][nota]"] = objects[i].nota;
                if (objects[i].cantitate)
                    rez["controldebit[" + i + "][cantitate]"] = objects[i].cantitate;
                if (objects[i].idtypecombustibil)
                    rez["controldebit[" + i + "][idtypecombustibil]"] = objects[i].idtypecombustibil;
            }
        }
        return rez;
    }


    getDefaultOrderBy() {
        return "id";
    }

    getLabel() {
        var label = this.id;
        return label;
    }

}
