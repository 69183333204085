import { FetchGet, FetchGetExtern, FetchPost } from "api/RequestTools";

import { PersoanaFizicaLinkDTO } from "dto/nomenclatoare/PersoanaFizicaLinkDTO";
import { PersoanaJuridicaLinkDTO } from "dto/nomenclatoare/PersoanaJuridicaLinkDTO";

import { TypeFolosintaDTO } from "dto/nomenclatoare/TypeFolosintaDTO";
import { TypeUMDTO } from "dto/nomenclatoare/TypeUMDTO";
import { TypeHotarDTO } from "dto/nomenclatoare/TypeHotarDTO";

import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";



import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class LocationOSMDTO {
  getMainObjPart() {
    return "LocationOSM";
  };
  
  id = "";
  place_id = "";
  licence = "";
  osm_type = "";
  osm_id = "";
  boundingbox = [];
  lat = "";
  lon = "";
  display_name = "";
  label = "";
  title = "";
  class = "";
  type = "";
  importance = "";
  icon = "";
  address = {};

  constructor(data = null) {
    this.id = data?.id;
    this.place_id = data?.place_id;
    this.licence = data?.licence;
    this.osm_type = data?.osm_type;
    this.osm_id = data?.osm_id;
    this.boundingbox = data?.boundingbox;
    this.lat = data?.lat;
    this.lon = data?.lon;
    this.display_name = data?.display_name;
    this.label = data?.display_name;
    this.title = data?.display_name;
    this.class = data?.class;
    this.type = data?.type;
    this.importance = data?.importance;
    this.icon = data?.icon;
    this.address = data?.address;
  };



}
