import { FetchGet, FetchPost } from "api/RequestTools";



import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class StreetDTO {
  

  getMainObjPart() { return "Street"; }
  getFieldsInt() {
    return [
      "id",      
      "locationcode",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "name",
      "fulllocation"
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
      "locationcodes"
    ];
  }
  
  getFieldsDTOObjects() {
    return [
    ];
  }

  getFieldsDTOObjectsArray() {
    return [
      { field: "locationobjects", _key: "Location" },
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [
    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "name";
  }

  getLabel() {
    return this.name != undefined ? this.name : "";
  }

  getLabelFull() {
    var rez = this.name != undefined ? this.name : "";
    rez += rez ? ", " : "";
    rez += this.fulllocation != undefined ? this.fulllocation : "";
    return rez;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: LL("TD_name street"),
      },
      {
        id: "location",
        numeric: false,
        disablePadding: true,
        label: LL("TD_location street"),
      },
    ];

    return _headCells;
  }

}
