import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeCarosabilDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeCarosabil";
  }

  constructor(data = null) {
    super(data);
  }
}
