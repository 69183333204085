import { logger } from "hoc/logger";

export const RolesACLListGetForModule = (modul) => {
  var rez = [];

  rez[rez.length] = modul;
  rez[rez.length] = "add" + modul;
  rez[rez.length] = "load" + modul;
  rez[rez.length] = "deleteobjects" + modul;

  return rez;
};

export const RolesACLNonGestiuneModules = () => {
  var rez = [];
  
  rez[rez.length] = "dashboard";
  rez[rez.length] = "user";
  
  rez[rez.length] = "usersac";
  rez[rez.length] = "roles";
  rez[rez.length] = "rolesacl";
  rez[rez.length] = "jurnal";
  rez[rez.length] = "label";
  rez[rez.length] = "status";

  rez[rez.length] = "dosarsac";

  // rez[rez.length] = "persoanafizica";
  // rez[rez.length] = "persoanajuridica";
  // rez[rez.length] = "banca";
  // rez[rez.length] = "document";
  // rez[rez.length] = "bunimobil";
  // rez[rez.length] = "transportauto";
  // rez[rez.length] = "piloniluminare";
  // rez[rez.length] = "corpiluminarestradal";
  // rez[rez.length] = "mobilier";
  // rez[rez.length] = "utilajincalzire";
  // rez[rez.length] = "puncttransformare";
  // rez[rez.length] = "traseuelectric";
  // rez[rez.length] = "cameravideo";
  // rez[rez.length] = "registratorvideo";
  // rez[rez.length] = "aparatcafea";
  // rez[rez.length] = "utilajtehnologic";
  // rez[rez.length] = "panoupret";
  // rez[rez.length] = "generatorelectric";
  // rez[rez.length] = "transformatorelectric";
  // rez[rez.length] = "pistoldistribuitor";
  // rez[rez.length] = "street";

  rez[rez.length] = "typefunctie";
  rez[rez.length] = "typedistribuitoare";
  rez[rez.length] = "typepistolpompa";
  rez[rez.length] = "typenumarcanaleregistratoare";
  rez[rez.length] = "typemobilier";
  rez[rez.length] = "typeindiceprezentassg";
  rez[rez.length] = "typematerial";
  rez[rez.length] = "typeinventargospodaresc";
  rez[rez.length] = "typeechipamentantiincendiar";
  rez[rez.length] = "typeutilajtehnologic";
  rez[rez.length] = "typesetarepanoupret";
  rez[rez.length] = "typedestinatiemcc";
  rez[rez.length] = "typepuncttransformare";
  rez[rez.length] = "typetraseu";
  rez[rez.length] = "typereteaelectricaexterna";
  rez[rez.length] = "typecameravideo";
  rez[rez.length] = "typepanoupret";
  rez[rez.length] = "typesistemcasa";
  rez[rez.length] = "typerobinetautomat";
  rez[rez.length] = "typeiluminare";
  rez[rez.length] = "typeutilajincalzire";
  rez[rez.length] = "typemodelauto";
  rez[rez.length] = "typephone";
  rez[rez.length] = "typecombustibil";
  rez[rez.length] = "typeauto";
  rez[rez.length] = "typemarcaauto";
  rez[rez.length] = "typefolosinta";
  rez[rez.length] = "typehotar";

  rez[rez.length] = "typedocument";
  rez[rez.length] = "typeum";
  rez[rez.length] = "typebunimobil";
  rez[rez.length] = "typesex";
  rez[rez.length] = "typestudii";
  rez[rez.length] = "typemontare";
  rez[rez.length] = "typebrand";
  rez[rez.length] = "typecarosabil";
  rez[rez.length] = "typepilon";
  rez[rez.length] = "typecorp";
  rez[rez.length] = "typemotivinterventie";
  rez[rez.length] = "typeinterventie";

  return rez;
}

export const RolesACLListModules = () => {
  var rez = [];

  rez[rez.length] = "dashboard";

  rez[rez.length] = "user";
  rez[rez.length] = "usersac";
  rez[rez.length] = "roles";
  rez[rez.length] = "rolesacl";
  rez[rez.length] = "jurnal";
  rez[rez.length] = "label";
  rez[rez.length] = "status";

  rez[rez.length] = "dosarsac";
  rez[rez.length] = "persoanafizica";
  rez[rez.length] = "persoanajuridica";
  rez[rez.length] = "banca";
  rez[rez.length] = "document";
  rez[rez.length] = "bunimobil";
  rez[rez.length] = "transportauto";
  rez[rez.length] = "piloniluminare";
  rez[rez.length] = "corpiluminarestradal";
  rez[rez.length] = "mobilier";
  rez[rez.length] = "utilajincalzire";
  rez[rez.length] = "puncttransformare";
  rez[rez.length] = "traseuelectric";
  rez[rez.length] = "cameravideo";
  rez[rez.length] = "registratorvideo";
  rez[rez.length] = "aparatcafea";
  rez[rez.length] = "utilajtehnologic";
  rez[rez.length] = "panoupret";
  rez[rez.length] = "generatorelectric";
  rez[rez.length] = "transformatorelectric";
  rez[rez.length] = "pistoldistribuitor";
  rez[rez.length] = "street";

  rez[rez.length] = "typefunctie";
  rez[rez.length] = "typedistribuitoare";
  rez[rez.length] = "typepistolpompa";
  rez[rez.length] = "typenumarcanaleregistratoare";
  rez[rez.length] = "typemobilier";
  rez[rez.length] = "typeindiceprezentassg";
  rez[rez.length] = "typematerial";
  rez[rez.length] = "typeinventargospodaresc";
  rez[rez.length] = "typeechipamentantiincendiar";
  rez[rez.length] = "typeutilajtehnologic";
  rez[rez.length] = "typesetarepanoupret";
  rez[rez.length] = "typedestinatiemcc";
  rez[rez.length] = "typepuncttransformare";
  rez[rez.length] = "typetraseu";
  rez[rez.length] = "typereteaelectricaexterna";
  rez[rez.length] = "typecameravideo";
  rez[rez.length] = "typepanoupret";
  rez[rez.length] = "typesistemcasa";
  rez[rez.length] = "typerobinetautomat";
  rez[rez.length] = "typeiluminare";
  rez[rez.length] = "typeutilajincalzire";
  rez[rez.length] = "typemodelauto";
  rez[rez.length] = "typephone";
  rez[rez.length] = "typecombustibil";
  rez[rez.length] = "typeauto";
  rez[rez.length] = "typemarcaauto";
  rez[rez.length] = "typefolosinta";
  rez[rez.length] = "typehotar";

  rez[rez.length] = "typedocument";
  rez[rez.length] = "typeum";
  rez[rez.length] = "typebunimobil";
  rez[rez.length] = "typesex";
  rez[rez.length] = "typestudii";
  rez[rez.length] = "typemontare";
  rez[rez.length] = "typebrand";
  rez[rez.length] = "typecarosabil";
  rez[rez.length] = "typepilon";
  rez[rez.length] = "typecorp";
  rez[rez.length] = "typemotivinterventie";
  rez[rez.length] = "typeinterventie";

  rez[rez.length] = "rca";
  rez[rez.length] = "casco";
  rez[rez.length] = "avizexpertizaecologica";
  rez[rez.length] = "evidentadeserviretehnicapetrol";
  rez[rez.length] = "evidentadeserviretehnicagaz";
  rez[rez.length] = "taxacomercializarebenzia";
  rez[rez.length] = "autorizatieemisiepoluanti";
  rez[rez.length] = "evidentadistribuitoare";
  rez[rez.length] = "evidentacontrol";
  rez[rez.length] = "evidentasesizari";
  rez[rez.length] = "raportinterventie";
  rez[rez.length] = "evidentaautorizatiesanitara";
  rez[rez.length] = "evidentacontor";
  rez[rez.length] = "evidentaverificaremetrologica";
  rez[rez.length] = "evidentaechipamentantiincendiar";
  rez[rez.length] = "revizietehnica";
  rez[rez.length] = "deserviretehnica";
  rez[rez.length] = "transportautocascolink";
  rez[rez.length] = "transportautodocumentlink";
  rez[rez.length] = "transportautorcalink";
  rez[rez.length] = "transportautodeserviretehnicalink";
  rez[rez.length] = "transportautorevizietehnicalink";

  return rez;
};

export const RolesACLListSpecial = () => {
  var rez = [];

  return rez;
};

export const ProcessUrlPart = (name, type, cbparams) => {
  type = type != undefined && type ? type : "loadlist";

  cbparams = cbparams != undefined ? cbparams : {};
  var specialsuffix = cbparams.specialsuffix != undefined ? cbparams.specialsuffix : "";

  name = specialsuffix + name;

  var mod = "";

  // ---------------------------------
  if (["status", "jurnal"].indexOf(name) != -1) {
    mod = "main";
  }

  if (
    ["document",
      "rca",
      "casco",
      "deserviretehnica",
      "revizietehnica",
      "avizexpertizaecologica",
      "evidentadeserviretehnicapetrol",
      "evidentadeserviretehnicagaz",
      "autorizatieemisiepoluanti",
      "evidentadistribuitoare",
      "taxacomercializarebenzia",
      "evidentacontrol",
      "evidentasesizari",
      "raportinterventie",
      "evidentaautorizatiesanitara",
      "evidentacontor",
      "evidentaverificaremetrologica",
      "evidentaechipamentantiincendiar"
    ].indexOf(
      name
    ) != -1
  ) {
    mod = "documents";
  }

  if (
    [
      "location",
    ].indexOf(name) != -1
  ) {
    mod = "location";
  }

  if (
    [
      "transportauto",
      "transportautocascolink",
      "transportautodocumentlink",
      "transportautorcalink",
      "transportautodeserviretehnicalink",
      "transportautorevizietehnicalink",
    ].indexOf(name) != -1
  ) {
    mod = "cars";
  }

  if (
    [
      "destinationum",
      "persoanafizica",
      "persoanajuridica",
      "typefunctie",
      "typedistribuitoare",
      "typepistolpompa",
      "typenumarcanaleregistratoare",
      "typemobilier",
      "typeindiceprezentassg",
      "typematerial",
      "typeinventargospodaresc",
      "typeechipamentantiincendiar",
      "typeutilajtehnologic",
      "typesetarepanoupret",
      "typedestinatiemcc",
      "typepuncttransformare",
      "typetraseu",
      "typereteaelectricaexterna",
      "typecameravideo",
      "typepanoupret",
      "typesistemcasa",
      "typerobinetautomat",
      "typeiluminare",
      "typeutilajincalzire",
      "typemodelauto",
      "typephone",
      "typecombustibil",
      "typeauto",
      "typemarcaauto",
      "typefolosinta",
      "typehotar",
      "banca",
      "typedocument",
      "typeum",
      "typebunimobil",
      "typesex",
      "typestudii",
      "typemontare",
      "typebrand",
      "typecarosabil",
      "typepilon",
      "typecorp",
      "typemotivinterventie",
      "typeinterventie",
    ].indexOf(name) != -1
  ) {
    mod = "nomenclators";
  }

  if (["user", "roles", "usersac", "rolesacl"].indexOf(name) != -1) {
    mod = "user";
  }

  if (["bunimobil"].indexOf(name) != -1) {
    mod = "bunimobil";
  }

  if (
    [
      "dosarsac",
      "sac",
      "piloniluminare",
      "corpiluminarestradal",
      "mobilier",
      "utilajincalzire",
      "puncttransformare",
      "traseuelectric",
      "cameravideo",
      "registratorvideo",
      "aparatcafea",
      "utilajtehnologic",
      "panoupret",
      "generatorelectric",
      "transformatorelectric",
      "pistoldistribuitor",
    ].indexOf(name) != -1
  ) {
    mod = "sac";
  }

  if (["street"].indexOf(name) != -1) {
    mod = "location";
  }

  if (["label"].indexOf(name) != -1) {
    mod = "label";
  }

  // ---------------------------------

  // ---------------------------------
  var prefix = "ws_";

  if (type == "add") prefix = prefix + "add";
  if (type == "load") prefix = prefix + "load";
  if (type == "loadlist") prefix = prefix + "loadobjects";
  if (type == "dellist") prefix = prefix + "deleteobjects";
  if (type == "viewj") prefix = prefix + "setviewjurnal";
  // ---------------------------------

  // ---------------------------------
  var _surl = "";
  _surl += mod;
  _surl += "/";
  _surl += prefix + name;

  var method = prefix + name;
  var specialmethod = method;

  if (cbparams.otherdata != undefined && cbparams.otherdata.uuidsac != undefined) {
    _surl += "_gestiunesac";
    specialmethod += "_gestiunesac";
  }

  _surl += "?";

  if (
    cbparams.otherdata != undefined &&
    cbparams.otherdata.uuidsac != undefined
  ) {
    _surl += "uuidsac=" + cbparams.otherdata.uuidsac + "&";
  }
  // ---------------------------------

  return {
    surl: _surl,
    module: mod,
    method: method,
    specialmethod: specialmethod,
  };
};

export const GetSuffixUrl = (urlpart) => {

  if (urlpart instanceof Object && urlpart.surl != undefined) {
    return urlpart.surl;
  }



  if (urlpart == "opssearchlocation")
    return "https://nominatim.openstreetmap.org/search?format=json&polygon=1&addressdetails=1&";

  if (urlpart == "getJWT") return "main/ws_getjwt?";
  if (urlpart == "signin") return "user/ws_signin?";
  if (urlpart == "signout") return "user/ws_signout?";
  if (urlpart == "loadlocation") return "location/ws_getlocations?";

  // rolesacl
  if (urlpart == "loadallrolesacl") return "user/ws_loadallrolesacl?";
  if (urlpart == "getrolesaclhash") return "user/ws_getrolesaclhash?";
  if (urlpart == "setrolesacl") return "user/ws_setrolesacl?";
  if (urlpart == "addrolesacl") return "user/ws_addrolesacl?";
  if (urlpart == "deleteobjectsrolesacl") return "user/ws_deleteobjectsrolesacl?";
  // --------------------------

  // label
  if (urlpart == "loadalllabels") return "label/ws_loadalllabels?";
  if (urlpart == "getlabelhash") return "label/ws_getlabelhash?";
  if (urlpart == "getlabel") return "label/ws_getlabel?";
  // --------------------------

  // ####################################

  return false;
};

const GetUrl = (urlpart, parameters, useprefix) => {
  useprefix = useprefix != undefined ? useprefix : true;

  var rez = useprefix ? process.env.REACT_APP_API_ENDPOINT : "";
  rez += GetSuffixUrl(urlpart);

  logger("GetUrlGetUrlGetUrlGetUrl", urlpart, rez);

  if (
    parameters == undefined ||
    parameters == null ||
    !parameters ||
    typeof parameters != "object" ||
    Array.isArray(parameters)
  ) {
    parameters = {};
  }

  //   parameters.rt = "json";

  const queryString = Object.keys(parameters)
    .map((k) => {
      //   logger(`bbbb -> ${k}: -> ${parameters[k]}`);
      return `${k}=${encodeURIComponent(parameters[k])}`;
    })
    .join("&");
  rez += queryString;

  return rez;
};

const GetHeader = (rez) => {
  if (rez == undefined) rez = {};

  rez.Accept = "application/json";

  return rez;
};

const GetOptions = (rez, h) => {
  if (rez == undefined) rez = {};

  rez.credentials = "include";
  rez.headers = GetHeader(h);

  return rez;
};

export const FetchGet = async (
  urlpart,
  cb,
  parameters,
  cbparameters,
  useprefix
) => {
  useprefix = useprefix != undefined ? useprefix : 1;

  logger("FetchGetRRRR ", useprefix);

  var _token = cbparameters && cbparameters._token ? cbparameters._token : "";
  if (_token) parameters._authorization = _token;

  var url = GetUrl(urlpart, parameters, useprefix);

  logger("FetchGetRRRR ", url, urlpart, useprefix);

  // var options = GetOptions({ method: "GET" }, { Authorization: _token });
  var options = GetOptions({ method: "GET" }, {});

  var r = await fetch(url, options);

  logger("FetchGetRRRR", r);

  logger("AASSAAWWXXJHKKSSLLNNII");

  if (!r.ok) {
    logger("Looks like there was a problem. Status Code: " + r.status);
    cb({}, cbparameters, true);
    return;
  }

  logger("------------RRRR----------");
  // logger("FetchGet_____RRRR", r);
  logger("------------------------");

  var d = await r.json();

  logger("FetchGetRRRRyyyyyyyyyyyyyyyy", d, cb);

  var e = d.e ? 1 : 0;
  cb(d, cbparameters, e);

  return;
};

export const FetchGetExtern = async (
  urlpart,
  cb,
  parameters,
  cbparameters,
  useprefix
) => {
  useprefix = useprefix != undefined ? useprefix : 1;

  var url = GetUrl(urlpart, parameters, useprefix);

  var r = await fetch(url, {});

  if (!r.ok) {
    logger("Looks like there was a problem. Status Code: " + r.status);
    cb({}, cbparameters, true);
    return;
  }

  var d = await r.json();

  logger("FetchGetExtern2121", useprefix, url, parameters);
  logger("FetchGetExtern 2121", cb);
  logger("FetchGetExtern 11111111111111111111111111111111111111111111111111");
  logger("FetchGetExtern ", d);
  logger("FetchGetExtern ", cb);

  var e = d.e ? 1 : 0;
  cb(d, cbparameters, e);

  return;
};

export const FetchPost = async (
  urlpart,
  cb,
  parameters,
  postparams,
  cbparameters
) => {
  var _token = cbparameters && cbparameters._token ? cbparameters._token : "";
  if (_token) parameters._authorization = _token;

  var url = GetUrl(urlpart, parameters);

  const formData = new FormData();

  // const formData = postparams;
  var keys = Object.keys(postparams);

  var tobj = {};

  for (const i in keys) {
    // logger("FetchPost key", i, keys[i], postparams[keys[i]]);
    formData.append(keys[i], postparams[keys[i]]);
    tobj[keys[i]] = postparams[keys[i]];
  }

  // for (const name in postparams) {
  //   formData.append(name, postparams[name]);
  // }

  logger("FetchPost keys", keys);
  logger("FetchPost tobj", tobj);
  logger("FetchPost postparams", postparams);
  logger("FetchPost formData", formData);

  // var formBody = [];
  // for (var property in postparams) {
  //   logger("FetchPost property", property);
  //   // var encodedKey = encodeURIComponent(property);
  //   var encodedKey = encodeURIComponent(property);
  //   var encodedValue = encodeURIComponent(postparams[property]);
  //   formBody.push(encodedKey + "=" + encodedValue);
  // }
  // logger("FetchPost formBody", formBody);

  // formBody = formBody.join("&");

  // logger("FetchPost formBody", formBody);

  // if (otherformbody != undefined) {

  // }
  // var _token = cbparameters && cbparameters._token ? cbparameters._token : "";

  var options = {};

  options.Accept = "application/json";
  options.method = "POST";
  options.body = formData;
  options.credentials = "include";

  var options_ = GetOptions(
    // { method: "POST", body: JSON.stringify(postparams) },
    // { method: "POST", body: formData },
    { method: "POST", body: JSON.stringify(formData) },
    {
      // Authorization: _token,
      // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      // "Content-Type": "form/multipart",
      Accept: "application/json",
      // "type": "formData"
    }
    // { Authorization: _token }
  );

  logger("FetchPost options", options);

  var r = await fetch(url, options);

  logger("FetchPost r", r);

  if (!r.ok) {
    logger("Looks like there was a problem. Status Code: " + r.status);
    cb({}, cbparameters, true);
    return;
  }

  logger("------------RRRR----------");
  logger(r);
  logger("------------------------");

  var d = await r.json();

  logger("FetchPost d", d, cb);
  logger("FetchPost d1", cb);

  var e = d.e ? 1 : 0;
  cb(d, cbparameters, e);

  return;
};

export const FetchPostOld = async (
  urlpart,
  cb,
  parameters,
  postparams,
  cbparameters,
  otherformbody
) => {
  var _token = cbparameters && cbparameters._token ? cbparameters._token : "";
  if (_token) parameters._authorization = _token;

  var url = GetUrl(urlpart, parameters);

  var formBody = [];
  for (var property in postparams) {
    logger("FetchPost property", property);
    // var encodedKey = encodeURIComponent(property);
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(postparams[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  logger("FetchPost formBody", formBody);

  formBody = formBody.join("&");

  logger("FetchPost formBody", formBody);

  if (otherformbody != undefined) {
  }
  // var _token = cbparameters && cbparameters._token ? cbparameters._token : "";

  var options = GetOptions(
    // { method: "POST", body: JSON.stringify(postparams) },
    { method: "POST", body: formBody },
    {
      // Authorization: _token,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      // "Content-Type": "multipart/form-data",
    }
    // { Authorization: _token }
  );

  logger("FetchPost options", options);

  var r = await fetch(url, options);

  logger("FetchPost r", r);

  if (!r.ok) {
    logger("Looks like there was a problem. Status Code: " + r.status);
    cb({}, cbparameters, true);
    return;
  }

  logger("------------RRRR----------");
  logger(r);
  logger("------------------------");

  var d = await r.json();

  logger("FetchPost d", d, cb);
  logger("FetchPost d1", cb);

  var e = d.e ? 1 : 0;
  cb(d, cbparameters, e);

  return;
};
