import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeDocumentDTO } from "dto/nomenclatoare/TypeDocumentDTO";
import { FilesDTO } from "dto/files/FilesDTO";

import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class DocumentDTO {
  getMainObjPart() {
    return "Document";
  }
  getFieldsInt() {
    return [
      "id",
      "idtypedocument",
      "idscan",
      "dataint",
      "dataexpirareint",
      "idsac",
      "datamicrotime",
      "dataexpiraremicrotime",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "data_text",
      "datatime_text",
      "dataexpirare_text",
      "dataexpiraretime_text",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return ["persoanajuridicaids"];
  }

  getFieldsStrArray() {
    return ["persoanajuridica"];
  }

  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "persoanajuridicalinkobjects",
        _key: "PersoanaJuridicaLink",
        mainobjfield: "persoanajuridicaobjects",
        mainobjkey: "PersoanaJuridica",
        mainobjobjparam: "persoanajuridicaobj",
        mainidfield: "persoanajuridicaids",
        mainobjidparam: "idpersoanajuridica",
      },
    ];
  }

  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.data_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number"),
      },
      {
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument in list"),
      },
      {
        id: "dataint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      },
      {
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      },
      {
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of document"),
      },
    ];

    return _headCells;
  }
}
