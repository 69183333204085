import { FetchGet, FetchPost } from "api/RequestTools";

import { CommonTools } from "api/CommonTools";


import { StreetDTO } from "dto/nomenclatoare/StreetDTO";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class BancaDTO {
  

  getMainObjPart() { return "Banca"; }
  getFieldsInt() {
    return [
      "id",
      "locationcode",
      "idstreet",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "codbancar",
      "nume",
      "sucursala",
      "streetnumber",
      "codepostal",
      "fulllocation"
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [
      "locationcodes",
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "streetobj", _key: "Street" },
    
    ];
  }  
  getFieldsDTOObjectsArray() {
    return [
      { field: "locationobjects", _key: "Location" },
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }
  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "nume";
  }

  getLabel() {
    return this.nume + " " + this.sucursala;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "nume",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Name bank"),
      },
      {
        id: "sucursala",
        numeric: false,
        disablePadding: true,
        label: LL("TD_sucursala"),
      },
      {
        id: "codbancar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_codbancar"),
      },

      {
        id: "streetnumber",
        numeric: false,
        disablePadding: true,
        label: LL("TD_adress"),
      },
      {
        id: "codepostal",
        numeric: false,
        disablePadding: true,
        label: LL("TD_codepostal"),
      },
    ];

    return _headCells;
  }

}
