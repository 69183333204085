import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import Link from "@mui/material/Link";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";


const AutocompleteFilterYesNo = ({ cb, _object, filterobj,
    _tobj,_nofirst,_label, ...props }) => {

    const { LL } = useResource(false);
    var tobjects = null;
    var dvalue = filterobj.value != null ? filterobj.value : null;
    var _valuesT = dvalue ? dvalue : [];
    
    var label = _label != undefined ? _label : LL("isdefaultfordestination");
    
    if (filterobj.value != undefined) {
      if (filterobj.value == 1) {
        tobjects = { id: 1, label: LL("yes") };
      } else {
        tobjects = { id: -1, label: LL("no") };
      }
    }
    var nofirst = _nofirst != undefined ? 1 : 0;
    logger("YesNoAutocomplete 324", dvalue);
    const [defaultValues, setDefaultValues] = useState(tobjects);
    const [objects, setObjects] = useState(tobjects);
    const [valuesT, setValues] = useState(_valuesT);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(filterobj);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    useEffect(() => {
        loadData();
    }, []);
   
    useEffect(() => {
        if (objects == null) return;

        var tarr = null;
        // for (var i in objects) {
        tarr = objects.id;
        // }
        setValues(tarr);
    }, [objects]);


    const loadData = () => {
        var toptions = [];
        toptions[toptions.length] = { id: 1, label: LL("yes") };
        toptions[toptions.length] = { id: -1, label: LL("no") };

        if (nofirst) toptions = toptions.reverse();

        setOptions(toptions);

        setLoading(false);
    };

    const handleChange = (value) => {
        setObjects(value);
    };
    

    const ExecCallBack = () => {
        if (!cb) return;
        if (cb == undefined) return;
        if (cb == "undefined") return;
    
        var value = "";
        var values = [];
        values[values.length] = valuesT;
        
        
        logger("YesNoAutocomplete 324 ->>>>", values);
        
        cb(filter, valuesT, values);
      };
    
      useEffect(() => {
        ExecCallBack();
      }, [valuesT]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            fullWidth
            open={open}
            defaultValue={defaultValues}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                handleChange(value);
            }}
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            isOptionEqualToValue={(option, value) => option.id == value}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    color="secondary"
                    margin="normal"
                    variant="standard"
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export { AutocompleteFilterYesNo };
