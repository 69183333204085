
import { DTOTools } from "api/DTOTools";

export class RevizieTehnicaDTO {
  

  getMainObjPart() { return "RevizieTehnica"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idscan",
      "dataint",
      "dataexpirareint",
      "datamicrotime",
      "dataexpiraremicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "data_text",
      "datatime_text",
      "dataexpirare_text",
      "dataexpiraretime_text"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return ["persoanajuridicaids",];
  }

  getFieldsStrArray() {
    return ["persoanajuridica", ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "sacobj", _key: "DosarSAC" },
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "persoanajuridicalinkobjects",
        _key: "PersoanaJuridicaLink",
        mainobjfield: "persoanajuridicaobjects",
        mainobjkey: "PersoanaJuridica",
         mainobjobjparam: "persoanajuridicaobj",
        mainidfield: "persoanajuridicaids",
        mainobjidparam: "idpersoanajuridica",
      }
     
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }
  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
    

  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.data_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
        otherdata.uuidsac != undefined &&
        otherdata.uuidsac
        ? otherdata.uuidsac
        : false;
    var _headCells = [];

    _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_RevizieTehnica"),
      }
      _headCells[_headCells.length] ={
        id: "dataint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      };
      _headCells[_headCells.length] ={
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      }
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of revizietehnica"),
      }
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }

    return _headCells;
  }

}
 