import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeCorpDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeCorp";
  }

  constructor(data = null) {
    super(data);
  }
}
