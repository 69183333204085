import React from "react";

import { GenEditTypeDenumire } from "components/elements/MyGenComponents/GenEditTypeDenumire";
// -----------------------------

import { TypeMarcaAutoDTO } from "dto/nomenclatoare/TypeMarcaAutoDTO";

const EditTypeMarcaAuto = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeMarcaAutoDTO()
  );

  return (
    <GenEditTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { EditTypeMarcaAuto };
