import { FetchGet, FetchPost } from "api/RequestTools";

import { DTOTools } from "api/DTOTools";
import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class LabelDTO {
  

  getMainObjPart() { return "Label"; }
  getFieldsInt() {
    return [
      "id",
      "procesed",
      "moddate",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "identifier",
      "value",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [];
  }
  
  getFieldsDTOObjects() {
    return [
      
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }
  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "identifier";
  }

  getLabel() {
    var label = this.value;
    return label;
  }

 
  static handleGetLabelHash(data, params, err) {
    logger("handleGetLabelHash111122223333", data, params, err);
    if (params._lcb == undefined || !params._lcb) return;

    params._lcb(data.hash);
  }

  static GetLabelHash(cb) {
    var cbparameters = {};
    cbparameters._lcb = cb;
    FetchGet("getlabelhash", LabelDTO.handleGetLabelHash, {}, cbparameters);
  }

  static handleLoadLabels(data, params, err) {
    logger("handleLoadLabels111122223333", data, params, err);
    if (params._lcb == undefined || !params._lcb) return;

    params._lcb(data.objects, data.hash);
  }

  static LoadLabels(cb) {
    var cbparameters = {};
    cbparameters._lcb = cb;
    FetchGet("loadalllabels", LabelDTO.handleLoadLabels, {}, cbparameters);
  }

  static handleGetObj(data, params, err) {
    if (params._lcb == undefined || !params._lcb) return;

    params._lcb(data.value, data.identifier);
  }

  static GetObj(cb, _identifier, cbparameters) {
    logger("GetObj", cb, _identifier);
    cbparameters._lcb = cb;
    FetchGet(
      "getlabel",
      LabelDTO.handleGetObj,
      { identifier: _identifier },
      cbparameters
    );
  }

  
  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "identifier",
        numeric: false,
        disablePadding: true,
        label: LL("TD_identifier"),
      },
      {
        id: "value",
        numeric: false,
        disablePadding: true,
        label: LL("TD_value"),
      },
      {
        id: "procesed",
        numeric: false,
        disablePadding: true,
        label: LL("TD_procesed"),
      },
    ];

    return _headCells;
  }

}
 