import {
  ProcessUrlPart,
  FetchGet,
  FetchGetExtern,
  FetchPost,
} from "api/RequestTools";

// ---------------------------------------------------------
import { LabelDTO } from "dto/label/LabelDTO";

import { BunImobilDTO } from "dto/bunimobil/BunImobilDTO";

import { JurnalDTO } from "dto/core/JurnalDTO";
import { StatusDTO } from "dto/core/StatusDTO";

import { AvizExpertizaEcologicaDTO } from "dto/documents/AvizExpertizaEcologicaDTO";
import { EvidentaDeservireTehnicaPetrolDTO } from "dto/documents/EvidentaDeservireTehnicaPetrolDTO";
import { EvidentaDeservireTehnicaGazDTO } from "dto/documents/EvidentaDeservireTehnicaGazDTO";
import { AutorizatieEmisiePoluantiDTO } from "dto/documents/AutorizatieEmisiePoluantiDTO";
import { EvidentaDistribuitoareDTO } from "dto/documents/EvidentaDistribuitoareDTO";
import { EvidentaControlDTO } from "dto/documents/EvidentaControlDTO";
import { EvidentaSesizariDTO } from "dto/documents/EvidentaSesizariDTO";
import { RaportInterventieDTO } from "dto/documents/RaportInterventieDTO";
import { EvidentaAutorizatieSanitaraDTO } from "dto/documents/EvidentaAutorizatieSanitaraDTO";
import { EvidentaContorDTO } from "dto/documents/EvidentaContorDTO";
import { EvidentaVerificareMetrologicaDTO } from "dto/documents/EvidentaVerificareMetrologicaDTO";
import { EvidentaEchipamentAntiincendiarDTO } from "dto/documents/EvidentaEchipamentAntiincendiarDTO";
import { TaxaComercializareBenziaDTO } from "dto/documents/TaxaComercializareBenziaDTO";
import { CASCODTO } from "dto/documents/CASCODTO";
import { CASCOLinkDTO } from "dto/documents/CASCOLinkDTO";
import { DeservireTehnicaDTO } from "dto/documents/DeservireTehnicaDTO";
import { DeservireTehnicaLinkDTO } from "dto/documents/DeservireTehnicaLinkDTO";
import { DocumentDTO } from "dto/documents/DocumentDTO";
import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";
import { ControlDebitDTO } from "dto/documents/ControlDebitDTO";
import { RCADTO } from "dto/documents/RCADTO";
import { RCALinkDTO } from "dto/documents/RCALinkDTO";
import { RevizieTehnicaDTO } from "dto/documents/RevizieTehnicaDTO";
import { RevizieTehnicaLinkDTO } from "dto/documents/RevizieTehnicaLinkDTO";

import { AparatCafeaDTO } from "dto/dosarsac/AparatCafeaDTO";
import { CameraVideoDTO } from "dto/dosarsac/CameraVideoDTO";
import { CorpIluminareStradalDTO } from "dto/dosarsac/CorpIluminareStradalDTO";
import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";
import { GeneratorElectricDTO } from "dto/dosarsac/GeneratorElectricDTO";
import { MobilierDTO } from "dto/dosarsac/MobilierDTO";
import { PanouPretDTO } from "dto/dosarsac/PanouPretDTO";
import { PilonIluminareDTO } from "dto/dosarsac/PilonIluminareDTO";
import { PistolDistribuitorDTO } from "dto/dosarsac/PistolDistribuitorDTO";
import { PunctTransformareDTO } from "dto/dosarsac/PunctTransformareDTO";
import { RegistratorVideoDTO } from "dto/dosarsac/RegistratorVideoDTO";
import { TransformatorElectricDTO } from "dto/dosarsac/TransformatorElectricDTO";
import { TraseuElectricDTO } from "dto/dosarsac/TraseuElectricDTO";
import { UtilajIncalzireDTO } from "dto/dosarsac/UtilajIncalzireDTO";
import { UtilajTehnologicDTO } from "dto/dosarsac/UtilajTehnologicDTO";

import { FilesDTO } from "dto/files/FilesDTO";

import { LocationDTO } from "dto/location/LocationDTO";
import { LocationOSMDTO } from "dto/location/LocationOSMDTO";

import { BancaDTO } from "dto/nomenclatoare/BancaDTO";
import { ContBancarDTO } from "dto/nomenclatoare/ContBancarDTO";
import { DestinationUMDTO } from "dto/nomenclatoare/DestinationUMDTO";
import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";
import { PersoanaFizicaLinkDTO } from "dto/nomenclatoare/PersoanaFizicaLinkDTO";
import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";
import { PersoanaJuridicaLinkDTO } from "dto/nomenclatoare/PersoanaJuridicaLinkDTO";
import { PhonesDTO } from "dto/nomenclatoare/PhonesDTO";
import { StreetDTO } from "dto/nomenclatoare/StreetDTO";
import { TypeAutoDTO } from "dto/nomenclatoare/TypeAutoDTO";
import { TypeBrandDTO } from "dto/nomenclatoare/TypeBrandDTO";
import { TypeBunImobilDTO } from "dto/nomenclatoare/TypeBunImobilDTO";
import { TypeCameraVideoDTO } from "dto/nomenclatoare/TypeCameraVideoDTO";
import { TypeCarosabilDTO } from "dto/nomenclatoare/TypeCarosabilDTO";
import { TypeCombustibilDTO } from "dto/nomenclatoare/TypeCombustibilDTO";
import { TypeCorpDTO } from "dto/nomenclatoare/TypeCorpDTO";
import { TypeMotivInterventieDTO } from "dto/nomenclatoare/TypeMotivInterventieDTO";
import { TypeInterventieDTO } from "dto/nomenclatoare/TypeInterventieDTO";
import { TypeDestinatieMCCDTO } from "dto/nomenclatoare/TypeDestinatieMCCDTO";
import { TypeDistribuitoareDTO } from "dto/nomenclatoare/TypeDistribuitoareDTO";
import { TypeDocumentDTO } from "dto/nomenclatoare/TypeDocumentDTO";
import { TypeEchipamentAntiincendiarDTO } from "dto/nomenclatoare/TypeEchipamentAntiincendiarDTO";
import { TypeFolosintaDTO } from "dto/nomenclatoare/TypeFolosintaDTO";
import { TypeFunctieDTO } from "dto/nomenclatoare/TypeFunctieDTO";
import { TypeHotarDTO } from "dto/nomenclatoare/TypeHotarDTO";
import { TypeIluminareDTO } from "dto/nomenclatoare/TypeIluminareDTO";
import { TypeIndicePrezentaSSGDTO } from "dto/nomenclatoare/TypeIndicePrezentaSSGDTO";
import { TypeInventarGospodarescDTO } from "dto/nomenclatoare/TypeInventarGospodarescDTO";
import { TypeMarcaAutoDTO } from "dto/nomenclatoare/TypeMarcaAutoDTO";
import { TypeMaterialDTO } from "dto/nomenclatoare/TypeMaterialDTO";
import { TypeMobilierDTO } from "dto/nomenclatoare/TypeMobilierDTO";
import { TypeModelAutoDTO } from "dto/nomenclatoare/TypeModelAutoDTO";
import { TypeMontareDTO } from "dto/nomenclatoare/TypeMontareDTO";
import { TypeNumarCanaleRegistratoareDTO } from "dto/nomenclatoare/TypeNumarCanaleRegistratoareDTO";
import { TypePanouPretDTO } from "dto/nomenclatoare/TypePanouPretDTO";
import { TypePhoneDTO } from "dto/nomenclatoare/TypePhoneDTO";
import { TypePilonDTO } from "dto/nomenclatoare/TypePilonDTO";
import { TypePistolPompaDTO } from "dto/nomenclatoare/TypePistolPompaDTO";
import { TypePunctTransformareDTO } from "dto/nomenclatoare/TypePunctTransformareDTO";
import { TypeReteaElectricaExternaDTO } from "dto/nomenclatoare/TypeReteaElectricaExternaDTO";
import { TypeRobinetAutomatDTO } from "dto/nomenclatoare/TypeRobinetAutomatDTO";
import { TypeSetarePanouPretDTO } from "dto/nomenclatoare/TypeSetarePanouPretDTO";
import { TypeSexDTO } from "dto/nomenclatoare/TypeSexDTO";
import { TypeSistemCasaDTO } from "dto/nomenclatoare/TypeSistemCasaDTO";
import { TypeStudiiDTO } from "dto/nomenclatoare/TypeStudiiDTO";
import { TypeTraseuDTO } from "dto/nomenclatoare/TypeTraseuDTO";
import { TypeUMDTO } from "dto/nomenclatoare/TypeUMDTO";
import { TypeUtilajIncalzireDTO } from "dto/nomenclatoare/TypeUtilajIncalzireDTO";
import { TypeUtilajTehnologicDTO } from "dto/nomenclatoare/TypeUtilajTehnologicDTO";

import { TransportAutoDTO } from "dto/transportauto/TransportAutoDTO";

import { RolesACLDTO } from "dto/user/RolesACLDTO";
import { RolesDTO } from "dto/user/RolesDTO";
import { UserDTO } from "dto/user/UserDTO";
import { UserRolesDTO } from "dto/user/UserRolesDTO";
import { UserSacDTO } from "dto/user/UserSacDTO";

// ---------------------------------------------------------

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { logger } from "hoc/logger";

class DTOTools {
  // ---------------------------------------------------------
  static etest = (tval, tfunct) => {
    tfunct(tval + 1);
  };

  // ---------------------------------------------------------
  static DTOList = {
    Label: LabelDTO,
    RolesACL: RolesACLDTO,
    BunImobil: BunImobilDTO,
    Jurnal: JurnalDTO,
    Status: StatusDTO,
    CASCO: CASCODTO,
    AvizExpertizaEcologica: AvizExpertizaEcologicaDTO,
    EvidentaDeservireTehnicaPetrol: EvidentaDeservireTehnicaPetrolDTO,
    EvidentaDeservireTehnicaGaz: EvidentaDeservireTehnicaGazDTO,
    AutorizatieEmisiePoluanti: AutorizatieEmisiePoluantiDTO,
    EvidentaDistribuitoare: EvidentaDistribuitoareDTO,
    EvidentaControl: EvidentaControlDTO,
    EvidentaSesizari: EvidentaSesizariDTO,
    RaportInterventie: RaportInterventieDTO,
    EvidentaAutorizatieSanitara: EvidentaAutorizatieSanitaraDTO,
    EvidentaContor: EvidentaContorDTO,
    EvidentaVerificareMetrologica: EvidentaVerificareMetrologicaDTO,
    EvidentaEchipamentAntiincendiar: EvidentaEchipamentAntiincendiarDTO,
    TaxaComercializareBenzia: TaxaComercializareBenziaDTO,
    CASCOLink: CASCOLinkDTO,
    DeservireTehnica: DeservireTehnicaDTO,
    DeservireTehnicaLink: DeservireTehnicaLinkDTO,
    Document: DocumentDTO,
    DocumentLink: DocumentLinkDTO,
    ControlDebit: ControlDebitDTO,
    RCA: RCADTO,
    RCALink: RCALinkDTO,
    RevizieTehnica: RevizieTehnicaDTO,
    RevizieTehnicaLink: RevizieTehnicaLinkDTO,
    AparatCafea: AparatCafeaDTO,
    CameraVideo: CameraVideoDTO,
    CorpIluminareStradal: CorpIluminareStradalDTO,
    DosarSAC: DosarSACDTO,
    GeneratorElectric: GeneratorElectricDTO,
    Mobilier: MobilierDTO,
    PanouPret: PanouPretDTO,
    PilonIluminare: PilonIluminareDTO,
    PistolDistribuitor: PistolDistribuitorDTO,
    PunctTransformare: PunctTransformareDTO,
    RegistratorVideo: RegistratorVideoDTO,
    TransformatorElectric: TransformatorElectricDTO,
    TraseuElectric: TraseuElectricDTO,
    UtilajIncalzire: UtilajIncalzireDTO,
    UtilajTehnologic: UtilajTehnologicDTO,
    Files: FilesDTO,
    Location: LocationDTO,
    LocationOSM: LocationOSMDTO,
    Banca: BancaDTO,
    ContBancar: ContBancarDTO,
    DestinationUM: DestinationUMDTO,
    PersoanaFizica: PersoanaFizicaDTO,
    PersoanaFizicaLink: PersoanaFizicaLinkDTO,
    PersoanaJuridica: PersoanaJuridicaDTO,
    PersoanaJuridicaLink: PersoanaJuridicaLinkDTO,
    Phones: PhonesDTO,
    Street: StreetDTO,
    TypeAuto: TypeAutoDTO,
    TypeBrand: TypeBrandDTO,
    TypeBunImobil: TypeBunImobilDTO,
    TypeCameraVideo: TypeCameraVideoDTO,
    TypeCarosabil: TypeCarosabilDTO,
    TypeCombustibil: TypeCombustibilDTO,
    TypeCorp: TypeCorpDTO,
    TypeMotivInterventie: TypeMotivInterventieDTO,
    TypeInterventie: TypeInterventieDTO,
    TypeDestinatieMCC: TypeDestinatieMCCDTO,
    TypeDistribuitoare: TypeDistribuitoareDTO,
    TypeDocument: TypeDocumentDTO,
    TypeEchipamentAntiincendiar: TypeEchipamentAntiincendiarDTO,
    TypeFolosinta: TypeFolosintaDTO,
    TypeFunctie: TypeFunctieDTO,
    TypeHotar: TypeHotarDTO,
    TypeIluminare: TypeIluminareDTO,
    TypeIndicePrezentaSSG: TypeIndicePrezentaSSGDTO,
    TypeInventarGospodaresc: TypeInventarGospodarescDTO,
    TypeMarcaAuto: TypeMarcaAutoDTO,
    TypeMaterial: TypeMaterialDTO,
    TypeMobilier: TypeMobilierDTO,
    TypeModelAuto: TypeModelAutoDTO,
    TypeMontare: TypeMontareDTO,
    TypeNumarCanaleRegistratoare: TypeNumarCanaleRegistratoareDTO,
    TypePanouPret: TypePanouPretDTO,
    TypePhone: TypePhoneDTO,
    TypePilon: TypePilonDTO,
    TypePistolPompa: TypePistolPompaDTO,
    TypePunctTransformare: TypePunctTransformareDTO,
    TypeReteaElectricaExterna: TypeReteaElectricaExternaDTO,
    TypeRobinetAutomat: TypeRobinetAutomatDTO,
    TypeSetarePanouPret: TypeSetarePanouPretDTO,
    TypeSex: TypeSexDTO,
    TypeSistemCasa: TypeSistemCasaDTO,
    TypeStudii: TypeStudiiDTO,
    TypeTraseu: TypeTraseuDTO,
    TypeUM: TypeUMDTO,
    TypeUtilajIncalzire: TypeUtilajIncalzireDTO,
    TypeUtilajTehnologic: TypeUtilajTehnologicDTO,
    TransportAuto: TransportAutoDTO,
    Roles: RolesDTO,
    User: UserDTO,
    UserRoles: UserRolesDTO,
    UserSac: UserSacDTO,
  };

  // ---------------------------------------------------------
  static parseConstructorData = (obj, data) => {
    if (!obj) return;
    if (obj == null) return;
    if (obj == undefined) return;

    if (!data) {
      obj.id = 0;
      return;
    }
    if (data == null) {
      obj.id = 0;
      return;
    }
    if (data == undefined) {
      obj.id = 0;
      return;
    }

    // -----------------------------------------
    if (obj.getFieldsInt != undefined) {
      var t = obj.getFieldsInt();
      for (var i in t) {
        if (!data[t[i]]) continue;
        if (data[t[i]] == null) continue;
        if (data[t[i]] == undefined) continue;
        if (isNaN(data[t[i]])) continue;

        obj[t[i]] = parseInt(data[t[i]]);
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsFloat != undefined) {
      var t = obj.getFieldsFloat();
      for (var i in t) {
        if (!data[t[i]]) continue;
        if (data[t[i]] == null) continue;
        if (data[t[i]] == undefined) continue;
        if (isNaN(data[t[i]])) continue;

        obj[t[i]] = parseFloat(data[t[i]]);
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsStr != undefined) {
      var t = obj.getFieldsStr();
      for (var i in t) {
        if (!data[t[i]]) continue;
        if (data[t[i]] == null) continue;
        if (data[t[i]] == undefined) continue;

        obj[t[i]] = data[t[i]];
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsIntArray != undefined) {
      var t = obj.getFieldsIntArray();
      for (var i in t) {
        if (!data[t[i]]) continue;
        if (data[t[i]] == null) continue;
        if (data[t[i]] == undefined) continue;

        obj[t[i]] = data[t[i]];
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsStrArray != undefined) {
      var t = obj.getFieldsStrArray();
      for (var i in t) {
        if (!data[t[i]]) continue;
        if (data[t[i]] == null) continue;
        if (data[t[i]] == undefined) continue;

        obj[t[i]] = data[t[i]];
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsDTOObjects != undefined) {
      var t = obj.getFieldsDTOObjects();
      for (var i in t) {
        obj[t[i].field] = DTOTools.processObjFromData(
          data[t[i].field],
          t[i]._key
        );
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsDTOObjectsArray != undefined) {
      var t = obj.getFieldsDTOObjectsArray();
      for (var i in t) {
        obj[t[i].field] = DTOTools.processObjFromDataArray(
          data[t[i].field],
          t[i]._key
        );
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (obj.getFieldsDTOObjectsArrayLink != undefined) {
      var t = obj.getFieldsDTOObjectsArrayLink();
      for (var i in t) {
        obj = DTOTools.processObjFromDataArrayLink(obj, t[i], data);
      }
    }
    // -----------------------------------------

    if (data.candelete != undefined) obj.candelete = data.candelete;
  };

  // ---------------------------------------------------------
  static processObjFromDataArrayLink = (obj, arrobj, data) => {
    logger("processObjFromDataArrayLink1212", arrobj);

    var rez1 = [];
    var rez2 = [];
    var rez3 = [];

    if (arrobj.field == undefined) return obj;

    if (Array.isArray(data[arrobj.field])) {
      rez1 = DTOTools.processObjFromDataArray(data[arrobj.field], arrobj._key);

      for (var i in rez1) {
        if (
          arrobj.mainobjfield != undefined &&
          rez1[i][arrobj.mainobjobjparam] != undefined
        ) {
          rez2[rez2.length] = DTOTools.processObjFromData(
            rez1[i][arrobj.mainobjobjparam],
            arrobj.mainobjkey
          );
        }
        if (
          arrobj.mainidfield != undefined &&
          rez1[i][arrobj.mainobjidparam] != undefined
        ) {
          rez3[rez3.length] = rez1[i][arrobj.mainobjidparam];
        }
      }
    }

    obj[arrobj.field] = rez1;
    if (arrobj.mainobjfield != undefined) {
      obj[arrobj.mainobjfield] = rez2;
    }
    if (
      arrobj.mainidfield != undefined &&
      obj[arrobj.mainidfield] == undefined
    ) {
      obj[arrobj.mainidfield] = rez3;
    }

    return obj;
  };

  // ---------------------------------------------------------
  static processObjFromDataArray = (field, _key) => {
    var rez = [];

    if (!Array.isArray(field)) {
      return rez;
    }

    for (var i in field) {
      rez[rez.length] = DTOTools.processObjFromData(field[i], _key);
    }

    return rez;
  };

  // ---------------------------------------------------------
  static processObjFromData = (field, _key) => {
    if (!field || field == null || field == undefined) {
      return {};
    }

    if (Array.isArray(field)) {
      return new DTOTools.DTOList[_key](field);
    } else {
      if (
        field.constructor.name != undefined &&
        field.constructor.name != "Object"
      ) {
        return field;
      } else {
        return new DTOTools.DTOList[_key](field);
      }
    }

    return {};
  };

  // ---------------------------------------------------------
  static getListRoute = (obj, otherdata) => {
    if (!obj) return "";

    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    var rez = "/";

    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      rez += "gestiunesac/" + otherdata.uuidsac + "/";
    }

    rez += partname;

    return rez;
  };

  // ---------------------------------------------------------
  static getDetailRoute = (obj, otherdata) => {
    if (!obj) return "";

    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    var rez = "/";

    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      rez += "gestiunesac/" + otherdata.uuidsac + "/";
    }

    rez += partname + "/detail/" + obj.uuid;

    return rez;
  };

  // ---------------------------------------------------------
  static getEditRoute(obj, otherdata) {
    if (!obj) return "";

    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    var rez = "/";

    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      rez += "gestiunesac/" + otherdata.uuidsac + "/";
    }

    rez += partname + "/edit/" + obj.uuid;

    return rez;
  }

  // ---------------------------------------------------------
  static getAddRoute(obj, otherdata) {
    if (!obj) return "";

    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    var rez = "/";

    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      rez += "gestiunesac/" + otherdata.uuidsac;
    }

    rez += partname + "/add";

    return rez;
  }

  // ---------------------------------------------------------
  static getEditObject = (obj, otherdata, backdata) => {
    var specialsuffix =
      otherdata.specialsuffix != undefined ? otherdata.specialsuffix : "";
    var partname = obj.getMainObjPart();
    var mo = "Edit" + specialsuffix + partname;

    var _r = DTOTools.getEditRoute(obj, otherdata);
    var _h = DTOTools.getDetailHash(obj, otherdata);
    var _rh = MD5(_r).toString();
    backdata = backdata == undefined ? {} : backdata;
    return {
      _hash: _h,
      _route: _r,
      _hashroute: _rh,
      _mainobject: mo,
      _otherdata: {
        _mainobject: mo,
        _obj: obj,
        _uuid: obj.uuid,
        _backdata: backdata,
      },
    };
  };

  // ---------------------------------------------------------
  static getDetailObject = (obj, otherdata, backdata) => {
    backdata = backdata == undefined ? {} : backdata;
    var specialsuffix =
      otherdata.specialsuffix != undefined ? otherdata.specialsuffix : "";
    var partname = obj.getMainObjPart();
    var mo = "Detail" + specialsuffix + partname;

    var _r = DTOTools.getDetailRoute(obj, otherdata);
    var _h = DTOTools.getDetailHash(obj, otherdata);
    var _rh = MD5(_r).toString();
    return {
      _hash: _h,
      _route: _r,
      _hashroute: _rh,
      _mainobject: mo,
      _otherdata: {
        _mainobject: mo,
        _obj: obj,
        _uuid: obj.uuid,
        _backdata: backdata,
      },
    };
  };

  // ---------------------------------------------------------
  static getDetailHash(obj, otherdata) {
    if (!obj.uuid) return uuid();

    var ints = obj.getFieldsInt != undefined ? obj.getFieldsInt() : [];
    var strs = obj.getFieldsStr != undefined ? obj.getFieldsStr() : [];
    var floats = obj.getFieldsFloat != undefined ? obj.getFieldsFloat() : [];
    var arrints =
      obj.getFieldsIntArray != undefined ? obj.getFieldsIntArray() : [];
    var arrstrs =
      obj.getFieldsStrArray != undefined ? obj.getFieldsStrArray() : [];

    var all = [...ints, ...strs, ...floats];

    var str = "";
    for (var i in all) {
      str += str ? "," : "";
      str += obj[all[i]] ? obj[all[i]] : "-";
    }

    for (var i in arrints) {
      str += str ? "," : "";
      if (!obj[arrints[i]]) {
        str += "-";
        continue;
      }
      str += obj[arrints[i]].join(";");
    }

    if (!str) str = "-";

    return MD5(str).toString();
  }

  // ---------------------------------------------------------
  static handleDelObjects = (data, params, err) => {
    logger("handleDelObjects123", params);

    if (
      params.objects != undefined &&
      params.objects &&
      params.setObjects != undefined &&
      params.setObjects &&
      params.selected != undefined &&
      params.selected
    ) {
      var newobjects = [];
      for (var i in params.objects) {
        if (params.selected.indexOf(params.objects[i].uuid) != -1) continue;
        newobjects[newobjects.length] = params.objects[i];
      }
      params.setObjects(newobjects);
      params.setSelected([]);
    }

    if (params.setmess != undefined && params.setmess) params.setmess(data.m);
    if (params.sethash != undefined && params.sethash) params.sethash(uuid());
    if (params.setIsLoading != undefined && params.setIsLoading)
      params.setIsLoading(false);
  };

  // ---------------------------------------------------------
  static execDelObjects(
    obj,
    params,
    setmess,
    sethash,
    setIsLoading,
    selected,
    setSelected,
    objects,
    setObjects,
    otherdata,
    specialsuffix,
    cbparams
  ) {
    if (!obj) return;
    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    params = params != undefined ? params : {};

    selected = selected != undefined ? selected : [];
    setSelected = setSelected != undefined ? setSelected : false;

    objects = objects != undefined ? objects : [];
    setObjects = setObjects != undefined ? setObjects : false;

    setIsLoading = setIsLoading != undefined ? setIsLoading : false;
    setmess = setmess != undefined ? setmess : false;
    sethash = sethash != undefined ? sethash : false;
    otherdata = otherdata != undefined ? otherdata : {};
    specialsuffix =
      specialsuffix != undefined ? specialsuffix.toLowerCase() : "";
    cbparams = cbparams != undefined ? cbparams : {};

    // -------------------
    if (cbparams.selected == undefined) cbparams.selected = selected;
    if (cbparams.setSelected == undefined) cbparams.setSelected = setSelected;
    if (cbparams.objects == undefined) cbparams.objects = objects;
    if (cbparams.setObjects == undefined) cbparams.setObjects = setObjects;

    if (cbparams.setIsLoading == undefined)
      cbparams.setIsLoading = setIsLoading;
    if (cbparams.setmess == undefined) cbparams.setmess = setmess;
    if (cbparams.sethash == undefined) cbparams.sethash = sethash;
    if (cbparams.otherdata == undefined) cbparams.otherdata = otherdata;
    if (cbparams.specialsuffix == undefined)
      cbparams.specialsuffix = specialsuffix;
    // -------------------

    var urlinfo = ProcessUrlPart(partname, "dellist", cbparams);

    FetchGet(urlinfo, DTOTools.handleDelObjects, params, cbparams);
  }

  // ---------------------------------------------------------
  static handleLoadObjects = (data, params, err) => {
    // var t = params.defaultobj.clone();

    var objkey = params.defaultobj.getMainObjPart();

    var _tobjects = params && params._usedirectdata ? data : data.objects;

    if (err || _tobjects == undefined || !_tobjects) {
      if (params.setobjects) params.setobjects([]);
      if (params.settotal) params.setobjects(0);
      if (params.setloading) params.setloading(false);
      return;
    }

    var _objects = [];

    for (var i in _tobjects) {
      logger("asfasdfasfasdfasdf", objkey, DTOTools.DTOList[objkey]);
      var objDTO = new DTOTools.DTOList[objkey](_tobjects[i]);
      _objects[_objects.length] = objDTO;
    }

    if (params.setobjects) params.setobjects(_objects);

    if (params.settotal) {
      if (parseInt(data.total)) {
        params.settotal(parseInt(data.total));
      } else {
        params.settotal(parseInt(0));
      }
    }

    if (params.setloading) params.setloading(false);
  };

  // ---------------------------------------------------------
  static execLoadObjects(
    obj,
    otherdata,
    filter,
    filters,
    order,
    orderBy,
    page,
    rowsPerPage,

    setloading,
    setobjects,
    settotal,

    specialsuffix,
    cbparams,
    urlpart,
    isextern,
    useprefix
  ) {
    if (!obj) return;
    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    useprefix = useprefix != undefined && useprefix ? useprefix : 0;
    urlpart = urlpart != undefined && urlpart ? urlpart : false;
    isextern = isextern != undefined && isextern ? isextern : false;

    filter = filter != undefined && filter ? filter : {};
    filters = filters != undefined && filters ? filters : [];

    otherdata = otherdata != undefined && otherdata ? otherdata : {};
    setloading = setloading != undefined && setloading ? setloading : false;
    setobjects = setobjects != undefined && setobjects ? setobjects : false;
    settotal = settotal != undefined && settotal ? settotal : false;

    order = order != undefined && order ? order : false;
    orderBy = orderBy != undefined && orderBy ? orderBy : false;
    rowsPerPage = rowsPerPage != undefined && rowsPerPage ? rowsPerPage : 10;
    page = page != undefined && page ? page : 0;

    specialsuffix =
      specialsuffix != undefined ? specialsuffix.toLowerCase() : "";
    cbparams = cbparams != undefined ? cbparams : {};

    var params = filter;
    if (order) params.order = order;
    if (orderBy) params.orderBy = orderBy;
    if (page) params.page = page;
    if (rowsPerPage) params.rowsPerPage = rowsPerPage;
    params._hash = uuid();

    cbparams.defaultobj = obj;
    cbparams.otherdata = otherdata;

    // -------------------
    if (cbparams.otherdata == undefined) cbparams.otherdata = otherdata;
    if (cbparams.setloading == undefined) cbparams.setloading = setloading;
    if (cbparams.setobjects == undefined) cbparams.setobjects = setobjects;
    if (cbparams.settotal == undefined) cbparams.settotal = settotal;
    if (cbparams.specialsuffix == undefined)
      cbparams.specialsuffix = specialsuffix;
    // -------------------

    var urlinfo = urlpart
      ? urlpart
      : ProcessUrlPart(partname, "loadlist", cbparams);

    if (setloading) setloading(true);

    if (isextern) {
      FetchGetExtern(
        urlinfo,
        DTOTools.handleLoadObjects,
        params,
        cbparams,
        useprefix
      );
    } else {
      FetchGet(urlinfo, DTOTools.handleLoadObjects, params, cbparams);
    }
  }

  // ---------------------------------------------------------
  static handleObjViewJurnal = (data, params, err) => {
    return;
  };

  // ---------------------------------------------------------
  static execObjViewJurnal(obj, otherdata, cbparams) {
    if (!obj) return;
    cbparams = cbparams != undefined ? cbparams : {};

    var partname = obj.getMainObjPart();
    partname = partname.toLowerCase();

    var params = {};
    params.uuid = obj.uuid;
    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      params.uuidsac = otherdata.uuidsac;
    }
    params._hash = uuid();

    cbparams.defaultobj = obj;
    cbparams.otherdata = otherdata;

    var urlinfo = ProcessUrlPart(partname, "viewj", cbparams);

    logger("execObjViewJurnal11", urlinfo);

    FetchGet(urlinfo, DTOTools.handleLoadObjects, params, cbparams);
  }

  // ---------------------------------------------------------
  static handleLoadObj = (data, params, err) => {
    logger("handleLoadObj123123", data, params, err);

    var objkey = params.defaultobj.getMainObjPart();

    if (err) {
      if (params.setObj) params.setObj(false);
      if (params.setloading) params.setloading(false);
    }

    var objDTO = new DTOTools.DTOList[objkey](data.obj);

    if (params.setObj) params.setObj(objDTO);
    if (params.setloading) params.setloading(false);
  };

  // ---------------------------------------------------------
  static execLoadObj(defaultobjdto, otherdata, setIsLoading, setObj, cbparams) {
    if (!defaultobjdto) return;

    var partname = defaultobjdto.getMainObjPart();
    partname = partname.toLowerCase();

    var urlinfo = ProcessUrlPart(partname, "load", cbparams);

    var params = {};
    if (otherdata.uuid != undefined) params.uuid = otherdata.uuid;
    if (otherdata.id != undefined) params.id = otherdata.id;
    if (otherdata.id == undefined && otherdata.uuid == undefined)
      params.id = "-1";

    cbparams = cbparams != undefined ? cbparams : {};
    cbparams.defaultobj = defaultobjdto;
    cbparams.otherdata = otherdata;

    // -------------------
    if (cbparams.setloading == undefined) cbparams.setloading = setIsLoading;
    if (cbparams.setObj == undefined) cbparams.setObj = setObj;
    // -------------------

    if (setIsLoading) setIsLoading(true);

    FetchGet(urlinfo, DTOTools.handleLoadObj, params, cbparams);
  }

  // ---------------------------------------------------------
  static handleConfirmUpdate = (response, cbparams) => {
    logger("handleConfirmUpdate1231231123", response, cbparams);

    if (response == -1) {
      // clearForm();
      // cbparams.setObj(cbparams.defaultobj);
    } else if (response == 1) {
      var t = cbparams.objdto;
      t._forceupdate = 1;
      DTOTools.execAddObj(
        false,
        cbparams.defaultobj,
        t,
        cbparams.otherdata,
        cbparams.backdata,
        cbparams.setObj,
        cbparams.setloading,
        cbparams._setmlmessages,
        cbparams._setmlgenobj,
        cbparams
      );
    }
  };

  // ---------------------------------------------------------
  static handleExecAdd = (data, params, err) => {
    logger("handleExecAdd123123", data, params, err);

    var partname = params.defaultobj.getMainObjPart();
    var otherdata = params.otherdata ? params.otherdata : {};
    var backdata = params.backdata ? params.backdata : {};

    if (data.e == "1" && data.existobj != undefined) {
      if (params.setloading) params.setloading(false);
      if (params._setmlmessages) {
        params._setmlmessages(data.m, {
          type: "popupconfirmupdate",
          cbconfirm: DTOTools.handleConfirmUpdate,
          params: params,
        });
      }
      return;
    }

    if (params._setmlmessages) params._setmlmessages(data.m);
    var _obj = new DTOTools.DTOList[partname](data.obj);
    if (params.setObj) params.setObj(_obj);
    if (params._setmlgenobj)
      params._setmlgenobj(DTOTools.getDetailObject(_obj, otherdata, backdata));
    if (params.setloading) params.setloading(false);
  };

  // ---------------------------------------------------------
  static execAddObj(
    event,
    defaultobjdto,
    objdto,
    otherdata,
    backdata,
    setObj,
    setIsLoading,
    _setmlmessages,
    _setmlgenobj,
    cbparams
  ) {
    if (event) event.preventDefault();

    if (!defaultobjdto) return;
    if (!objdto) return;

    var data = DTOTools.getAddData(defaultobjdto, objdto);

    var partname = defaultobjdto.getMainObjPart();
    partname = partname.toLowerCase();

    cbparams = cbparams != undefined ? cbparams : {};
    cbparams.defaultobj = defaultobjdto;
    cbparams.objdto = objdto;
    cbparams.otherdata = otherdata;
    cbparams.backdata = backdata;

    // -------------------
    if (cbparams.setloading == undefined) cbparams.setloading = setIsLoading;
    if (cbparams.setObj == undefined) cbparams.setObj = setObj;
    if (cbparams._setmlmessages == undefined)
      cbparams._setmlmessages = _setmlmessages;
    if (cbparams._setmlgenobj == undefined)
      cbparams._setmlgenobj = _setmlgenobj;
    // -------------------

    if (setIsLoading) setIsLoading(true);

    var urlinfo = ProcessUrlPart(partname, "add", cbparams);

    // logger("execAddObj121212", data, objdto); 

    FetchPost(urlinfo, DTOTools.handleExecAdd, {}, data, cbparams);
  }

  // ---------------------------------------------------------
  static getAddData(defaultobjdto, objdto) {
    var rez = {};

    var t = [];

    if (
      (!objdto.id || objdto.id == undefined || isNaN(objdto.id)) &&
      (!objdto.uuid || objdto.uuid == undefined || isNaN(objdto.uuid))
    ) {
      objdto.id = 0;
      objdto.uuid = uuid();
    }

    if (objdto._forceupdate != undefined && objdto._forceupdate != null) {
      rez._forceupdate = objdto._forceupdate;
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsInt != undefined) {
      t = defaultobjdto.getFieldsInt();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]];
        }
      }
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsStr != undefined) {
      t = defaultobjdto.getFieldsStr();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]];
        }
      }
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsFloat != undefined) {
      t = defaultobjdto.getFieldsFloat();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]];
        }
      }
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsIntArray != undefined) {
      t = defaultobjdto.getFieldsIntArray();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]].join(",");
        }
      }
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsStrArray != undefined) {
      t = defaultobjdto.getFieldsStrArray();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]].join(",");
        }
      }
    }

    // -------------------------------------------
    if (defaultobjdto.getFieldsUploadArray != undefined) {
      t = defaultobjdto.getFieldsUploadArray();
      for (var i in t) {
        if (objdto[t[i]] != undefined && objdto[t[i]] != null) {
          rez[t[i]] = objdto[t[i]];
        }
      }
    }

    // -------------------------------------------

    // -----------------------------------------
    if (defaultobjdto.getFieldsDTOObjectsArray != undefined) {
      var t = defaultobjdto.getFieldsDTOObjectsArray();
      for (var i in t) {

        if (
          objdto[t[i].field] != undefined &&
          objdto[t[i].field] != null &&
          DTOTools.DTOList[t[i]._key] != undefined && 
          DTOTools.DTOList[t[i]._key] != null
        ) {
          var tobj = DTOTools.DTOList[t[i]._key];
          if (tobj.getAddDataFromArr != undefined)
          {
            rez = tobj.getAddDataFromArr(rez, objdto[t[i].field]);
          }
        };
      }
    }
    // -----------------------------------------

    // -----------------------------------------
    if (defaultobjdto.getFieldsDTOObjectsArrayLink != undefined) {
      var t = defaultobjdto.getFieldsDTOObjectsArrayLink();
      for (var i in t) {
        if (
          objdto[t[i].mainidfield] != undefined &&
          objdto[t[i].mainidfield] != null
        ) {
          if (Array.isArray(objdto[t[i].mainidfield]))
          {
            rez[t[i].mainidfield] = objdto[t[i].mainidfield].join();
          } else
          {
            rez[t[i].mainidfield] = "";
          }
        }
      }
    }
    // -----------------------------------------

    return rez;
  }

  // ---------------------------------------------------------
}

export { DTOTools };
