import React from "react";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Grid from "@mui/material/Grid";
import uuid from "react-uuid";

// -------------------------

import { EvidentaDeservireTehnicaPetrolDTO } from "dto/documents/EvidentaDeservireTehnicaPetrolDTO";

import Link from "@mui/material/Link";

const DetailEvidentaDeservireTehnicaPetrol = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
    // -------------------------------------------------

    const { LL } = useResource(false);

    const [defaultobjdto, setDefaultObjDTO] = React.useState(new EvidentaDeservireTehnicaPetrolDTO());
    const [otherdata, setOtherData] = React.useState(_mlotherdata);

    const [backdata, setBackData] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    var [specialHash, setSpecialHash] = useState(uuid());
    var [obj, updateObj] = useState(false);

    // -------------------------------------------------

    const setObj = (_obj) => {
        updateObj(_obj);
        setSpecialHash(uuid());
    };

    // -------------------------------------------------

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: DTOTools.getListRoute(obj, otherdata),
            label: LL("crumb_" + defaultobjdto.getMainObjPart()),
        },
    ];

    // -------------------------------------------------

    useEffect(() => {
        DTOTools.execObjViewJurnal(obj, otherdata);
    }, [obj]);

    useEffect(() => {
        FilterTools.processOtherDataDetail(
            defaultobjdto,
            obj,
            otherdata,
            setBackData,
            setIsLoading,
            setObj,
            {}
        );
    }, [_mlotherdata]);

    // -------------------------------------------------

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />
            <Container maxWidth="md" className="containerBtnTop">
                <MyNavBt
                    href={DTOTools.getListRoute(obj, otherdata)}
                    _setmlgenobj={_setmlgenobj}
                    _otherdata={backdata}
                >
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        sx={{ alignSelf: "start", mr: 2 }}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </MyNavBt>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {obj.numar}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {CommonTools.GenType(obj.typedocumentobj)}
                    </Typography>
                </Box>
                <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    sx={{
                        marginLeft: "auto",
                        alignSelf: "end",
                    }}
                    onClick={() =>
                        _setmlgenobj(DTOTools.getEditObject(obj, otherdata, backdata))
                    }
                >
                    {LL("BT_Edit")}
                </Button>
            </Container>
            <Divider />

            <Box
                maxWidth="md"
                component="div"
                sx={{ py: 1, px: 2, borderRadius: 2, my: 4, border: "1px solid #eee" }}
            >
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                    {LL("DetailBlock")}
                </Typography>
                <Divider />

                <Grid container spacing={2} sx={{ mt: 1 }}>
                
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>{LL("Data valabilitate aviz")}</Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.datavalabilitateaviz_text}</Typography>
                    </Grid>

                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data verificare statut")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.dataverificarestatut_text}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data exploatare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.dataexploatare_text}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data conservare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.dataconservare_text}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data expirare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.dataexpirare_text}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data expirare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.dataexpirare_text}</Typography>
                    </Grid>
                    
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Data defectos copie")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.datadefectoscopie_text}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Document scan")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{CommonTools.GenGetScan(obj.scanobj)}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>{LL("Dosar SAC")}</Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{CommonTools.GenType(obj.sacobj)}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("organcontrol")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.organcontrol}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("lastcontrol")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.lastcontrol}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("planedcontrol")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.planedcontrol}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("Tip montare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{CommonTools.GenType(obj.typemontareobj)}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("nrrezervoare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.nrrezervoare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("nrrezervoareinutilizare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.nrrezervoareinutilizare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("voluminutilizare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.voluminutilizare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("aninstalare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.aninstalare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("termenexploatare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.termenexploatare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("hasvalabiltabelcalibrare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.hasvalabiltabelcalibrare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("valabiltabelcalibrare")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.valabiltabelcalibrare}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("hasvalabilavizexpertiza")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.hasvalabilavizexpertiza}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("valabilavizexpertiza")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.valabilavizexpertiza}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("conservat")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.isconservat == 1 ? LL("yes") : LL("no")}</Typography>
                    </Grid>
                    <Grid item xs="3" align="right">
                        <Typography sx={{ color: "#aaa" }}>
                            {LL("expirat")}
                        </Typography>
                    </Grid>
                    <Grid item xs="9">
                        <Typography>{obj.isexpirat == 1 ? LL("yes") : LL("no")}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export { DetailEvidentaDeservireTehnicaPetrol };
