import { TypePhoneDTO } from "dto/nomenclatoare/TypePhoneDTO";
import { DTOTools } from "api/DTOTools";
export class PhonesDTO {
  getMainObjPart() { return "Phone"; }
  getFieldsInt() {
    return [
      "id",      
      "parentid",
      "idtypephone",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
      "phone",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "typephoneobj", _key: "TypePhone" },
    ];
  }

  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   
  }

  static getAddDataFromArr(rez, objects) {
    if (Array.isArray(objects) && objects.length) {
      for (var i in objects) {
        if (objects[i].id) rez["phones[" + i + "][id]"] = objects[i].id;
        if (objects[i].uuid) rez["phones[" + i + "][uuid]"] = objects[i].uuid;
        if (objects[i].phone)
          rez["phones[" + i + "][phone]"] = objects[i].phone;
        if (objects[i].idtypephone)
          rez["phones[" + i + "][idtypephone]"] = objects[i].idtypephone;
      }
    }
    return rez;
  }
}
