import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";  

export class TypeReteaElectricaExternaDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeReteaElectricaExterna";
  }

  constructor(data = null) {
    super(data);
  }
}
