import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";
import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import uuid from "react-uuid";

// ----------------------------------

import { SacAutocomplete } from "components/elements/Autocomplete/SacAutocomplete";

import { UtilizatorAutocomplete } from "components/elements/Autocomplete/UtilizatorAutocomplete";

import { UserSacDTO } from "dto/user/UserSacDTO";

import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";

import { MyTextField } from "components/elements/MyFormComponents/MyTextField";
import { DTOTools } from "api/DTOTools";

const AddUserSac = ({
    _setmlgenobj,
    _mlotherdata,
    _setmlmessages,
    ...props
}) => {

    const { LL } = useResource(false);

    const [defaultobjdto, setDefaultObjDTO] = React.useState(new UserSacDTO());
    const [otherdata, setOtherData] = React.useState(_mlotherdata);

    const [isLoading, setIsLoading] = useState(false);
    const [backdata, setBackData] = useState({});

    var [specialHash, setSpecialHash] = useState(uuid());
    var [obj, updateObj] = useState(new UserSacDTO());
    const setObj = (_obj) => {
        updateObj(_obj);
        setSpecialHash(uuid());
    }

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: DTOTools.getListRoute(obj, otherdata),
            label: LL("crumb_" + defaultobjdto.getMainObjPart()),
        },
        { label: LL("crumb_Add" + defaultobjdto.getMainObjPart()) },
    ];

    useEffect(() => {
        if (_mlotherdata._backdata != undefined) {
            setBackData(_mlotherdata._backdata);
        }
    }, [_mlotherdata]);


    return isLoading ? (
        <Loading />
    ) : (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

            <form
                onSubmit={(event) => {
                    DTOTools.execAddObj(
                        event,
                        defaultobjdto,
                        obj,
                        otherdata,
                        backdata,
                        setObj,
                        setIsLoading,
                        _setmlmessages,
                        _setmlgenobj,
                        {}
                    );
                }}
            >
                <Container maxWidth="md" className="containerBtnTop">
                    <MyNavBt
                        href={DTOTools.getListRoute(obj, otherdata)}
                        _setmlgenobj={_setmlgenobj}
                        _otherdata={backdata}
                    >
                        <IconButton aria-label="delete" color="primary">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </MyNavBt>
                    <Typography variant="h6">
                        {LL("TopTitle_Add_" + defaultobjdto.getMainObjPart())}
                    </Typography>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        style={{ marginLeft: "auto" }}
                    >
                        {LL("BT_Save")}
                    </Button>
                </Container>
                <Divider />

                <Container maxWidth="md">

                    <SacAutocomplete
                        obj={obj}
                        setObj={setObj}
                    />
                    <UtilizatorAutocomplete
                        obj={obj}
                        setObj={setObj}
                    />
                </Container>
            </form>
        </>
    );
};

export { AddUserSac };
