
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class TaxaComercializareBenziaDTO {


  getMainObjPart() { return "TaxaComercializareBenzia"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datainregistrareint",
      "dataexpirareint",
      "datainregistraremicrotime",
      "dataexpiraremicrotime",
      "dataachitareint",
      "dataachitaremicrotime",
      "achitat",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "datainregistrare_text",
      "datainregistraretime_text",
      "dataexpirare_text",
      "dataexpiraretime_text",
      "dataachitare_text",
      "dataachitaretime_text",
      "perioada",
      "taxaanuala",
      "taxatrimestriala",
      "nota",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   
  }

  getDefaultOrderBy() {
    return "datainregistrareint";
  }

  getLabel() {
    var label = this.numar + " " + this.datainregistrare_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];
  
  _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "datainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      };
      _headCells[_headCells.length] ={
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      };
      _headCells[_headCells.length] ={
        id: "dataachitareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Achitare data"),
      };
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of taxacomercializarebenzia"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "perioada",
        numeric: false,
        disablePadding: true,
        label: LL("TD_perioada"),
      };
      _headCells[_headCells.length] ={
        id: "taxaanuala",
        numeric: false,
        disablePadding: true,
        label: LL("TD_taxaanuala"),
      };
      _headCells[_headCells.length] ={
        id: "taxatrimestriala",
        numeric: false,
        disablePadding: true,
        label: LL("TD_taxatrimestriala"),
      };
      _headCells[_headCells.length] ={
        id: "nota",
        numeric: false,
        disablePadding: true,
        label: LL("TD_nota"),
      };
      _headCells[_headCells.length] ={
        id: "achitat",
        numeric: false,
        disablePadding: true,
        label: LL("TD_achitat"),
      };
    

    return _headCells;
  }

}
