import { createContext, useState } from "react";

import { useCookies } from "react-cookie";
// import { useCookiesContext } from "hook/useCookies";

import { UserDTO } from "dto/user/UserDTO";

import { logger } from "hoc/logger";



export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const [cookies, setCookies, removeCookies] = useCookiesContext();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [user, setUser] = useState(null);

  const signin = (newUser, cb) => {
    logger("AuthProvider signin", newUser, cookies.token, cookies);

    UserDTO.execsigin(handleSetUser, newUser, {
      _cb: cb,
      _token: cookies.token,
    });
  };

  const getCookiesItem = (key) =>
    document.cookie.split("; ").reduce((total, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
      return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, "");

  const checkUser = () => {
    logger("checkUser", cookies);

    var _userid = getCookiesItem("_userid");

    if (cookies._userid != _userid) {
      removeCookies(["_user"]);
      removeCookies(["_userid"]);
      removeCookies(["token"]);

      setUser(null);
    }

    if (!_userid) {
      return false;
    }

    return true;

    logger("checkUser", cookies);
  };

  // ---------------------
  const signout = (cb) => {
    logger("AuthProvider signout");

    UserDTO.execsignout();

    removeCookies(["_user"]);
    removeCookies(["_userid"]);
    removeCookies(["token"]);

    setUser(null);
    if (cb != undefined) cb();
  };

  // ---------------------
  const LoadUserByHash = (hash, cb) => {
    logger("AuthProvider LoadUserByHash");
  };

  // ---------------------
  const handleSetUser = (data, params, err) => {
    logger("AuthProviderhandleSetUser", data, params, err);

    var cb = params && params._cb ? params._cb : false;

    if (err) {
      logger("AuthProviderhandleSetUser err");
      if (cb) cb(data, params, err);
      return;
    }

    saveUser(data.obj, cb);
  };

  // ---------------------
  const saveUser = (_obj, cb) => {
    logger("AuthProvider saveUser");
    logger(_obj);

    var obj = new UserDTO(_obj);

    setCookies("_user", obj, { path: "/" });
    setCookies("_userid", obj.id, { path: "/" });
    setUser(obj);

    if (cb != undefined && cb) {
      cb(obj);
    }
  };

  // ---------------------
  const SetSavedUser = (cb) => {
    logger("AuthProvider SetSavedUser 1111111111111111111111", user);

    if (user) {
      logger("AuthProvider user is setted");
      cb(user);
      return;
    }

    var t_user = cookies._user;
    if (t_user && t_user != undefined && t_user != "undefined") {
      logger("----------------------------------------------------");
      logger(t_user);
      // t_user = JSON.parse(t_user);
      setUser(t_user);
      cb(t_user);
      return;
    }

    var tush = cookies.ush;
    if (tush && tush != undefined && tush != "undefined") {
      // load from current session
      LoadUserByHash(tush, cb);
      return;
    }

    var tux = cookies.x;
    if (tux && tux != undefined && tux != "undefined") {
      // load from permanent cokies
      LoadUserByHash(tux, cb);
      return;
    }

    logger("AuthProvider SetSavedUser");
    logger(tush);
    setUser(null);
    cb();
  };

  // ---------------------

  // const value = { user, token, signin, signout, checkToken, getToken };
  const value = { user, signin, signout, SetSavedUser, checkUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
