import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";
import { DTOTools } from "api/DTOTools";
export class PersoanaFizicaLinkDTO {
  getMainObjPart() { return "PersoanaFizicaLink"; }
  getFieldsInt() {
    return [
      "id",      
      "parentid",
      "idpersoanafizica",
      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
    ];
  }

  getFieldsFloat() {
    return [
      
    ];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "persoanafizicaobj", _key: "PersoanaFizica" },
      
    ];
  }

  getFieldsDTOObjectsArray() {
    return [
      
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  
  }

  static getAddDataFromArr(rez, objects) {
    // if (Array.isArray(objects) && objects.length) {
    //   for (var i in objects) {
    //     if (objects[i].id) rez["contbancars[" + i + "][id]"] = objects[i].id;
    //     if (objects[i].uuid) rez["contbancars[" + i + "][uuid]"] = objects[i].uuid;
    //     if (objects[i].contbancar)
    //       rez["contbancars[" + i + "][contbancar]"] = objects[i].contbancar;
    //     if (objects[i].idpersoanafizica)
    //       rez["contbancars[" + i + "][idpersoanafizica]"] = objects[i].idpersoanafizica;
    //   }
    // }
    return rez;
  }
}
