import { createContext, useState, useEffect } from "react";

import { ProcessUrlPart } from "api/RequestTools";

import { useCookies } from "react-cookie";
// import { useCookiesContext } from "hook/useCookies";

import { DTOTools } from "api/DTOTools";

import { RolesACLDTO } from "dto/user/RolesACLDTO";

import { LabelDTO } from "dto/label/LabelDTO";
import { LocalStorageTools } from "api/LocalStorageTools";

import { logger } from "hoc/logger";

import { Loading } from "components/general/Loading";

import { useAuth } from "hook/useAuth";

export const ResourceContext = createContext();
 
export const ResourceProvider = ({ children }) => {
  // const [cookies, setCookies, removeCookies] = useCookiesContext();

  const { user } = useAuth(false);

  const [serverrolesaclhash, setServerRolesACLHash] = useState("");
  const [localrolesaclhash, setLocalRolesACLHash] = useState("");
  const [rolesacl, setRolesACL] = useState("-1");

  const [aclobjects, setACLObjects] = useState({});

  const [serverlabelhash, setServerLabelHash] = useState("");
  const [locallabelhash, setLocalLabelHash] = useState("");

  // ----------------------------------------

  const [isLoading, setIsLoading] = useState(true);

  const [labels, setLabels] = useState("-1");

  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    LabelDTO.GetLabelHash(handleGetLabelHash);
    RolesACLDTO.GetRolesACLHash(handleGetRolesACLHash);
  }, []);

  const handleGetLabelHash = (hash) => {
    setServerLabelHash(hash);
  };

  const handleGetRolesACLHash = (hash) => {
    setServerRolesACLHash(hash);
  };
  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    checkServerRolesACLHash();
  }, [serverrolesaclhash]);

  const checkServerRolesACLHash = () => {
    if (!serverrolesaclhash) return;

    var t = LocalStorageTools.getValue("rolesaclhash");
    if (!t) t = "-1";
    setLocalRolesACLHash(t);
  };
  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    checkLocalRolesACLHash();
  }, [localrolesaclhash]);

  const checkLocalRolesACLHash = () => {
    if (!serverrolesaclhash) return;
    if (!localrolesaclhash) return;
    if (rolesacl != "-1") return;

    logger(
      "checkLocalRolesACLHashcheckLocalRolesACLHash",
      serverrolesaclhash,
      localrolesaclhash
    );

    if (serverrolesaclhash == localrolesaclhash) {
      var t = LocalStorageTools.getObject("rolesacl");
      setRolesACL(t);
    } else {
      // setRolesACL(-2);
      RolesACLDTO.LoadRolesACL(handleLoadRolesACL);
    }
  };

  const handleLoadRolesACL = (_objects, _hash) => {
    logger("handleLoadRolesACL222111333", _objects, _hash);
    setRolesACL(_objects);
    setLocalRolesACLHash(_hash);

    LocalStorageTools.saveObject("rolesacl", _objects);
    LocalStorageTools.saveValue("rolesaclhash", _hash);
  };
  // ----------------------------------------

  // ----------------------------------------
  const getACLObjSpecial = (identifier, type, cbparams) => {
    
    cbparams = cbparams != undefined ? cbparams : {};
    
    var initidentifier = identifier;

    logger(
      "getACLObjSpecialgetACLObjSpecial",
      identifier
    );

    var type = "loadlist";
    if (identifier.indexOf("add") == 0) 
    {
      type = "add";
      identifier = identifier.substring(3);
    }
    if (identifier.indexOf("load") == 0) {
      type = "load";
      identifier = identifier.substring(4);
    }
    if (identifier.indexOf("deleteobjects") == 0) {
      type = "dellist";
      identifier = identifier.substring(13);
    }
    if (identifier.indexOf("setviewjurnal") == 0) {
      type = "viewj";
      identifier = identifier.substring(13);
    }
    
    return getACLObj(identifier, type, cbparams, initidentifier);
  }

  // ----------------------------------------
  const getACLObj = (identifier, type, cbparams, initidentifier) => {
    // logger(
    //   "getACLObj11111getACLObj2211",
    //   identifier + "__" + type,
    //   identifier,
    //   type,
    //   aclobjects,
    //   rolesacl
    // );

    // if (
    //   aclobjects[identifier] != undefined &&
    //   aclobjects[identifier] != "undefined" &&
    //   aclobjects[identifier] &&
    //   aclobjects[identifier]["aclmodule"] != undefined &&
    //   aclobjects[identifier]["aclmodule"] != "undefined"
    // ) {
    //   return aclobjects[identifier];
    // }
    
    initidentifier =
      initidentifier != undefined
        ? initidentifier
        : identifier;

    type = type != undefined && type ? type : "loadlist";
    cbparams = cbparams != undefined ? cbparams : {};

    var urlpart = ProcessUrlPart(identifier, type, cbparams);

    var module = urlpart && urlpart.module ? urlpart.module : "-1";
    var method = urlpart && urlpart.specialmethod ? urlpart.specialmethod : "-1";

    logger(
      "getACLObjgetACLObj332221211112222",
      identifier + "__" + type,
      initidentifier,
      identifier,
      type,
      cbparams,
      module,
      method,
      urlpart
    );
    logger(
      identifier + "__" + type + "__",
      identifier,
      initidentifier,
      rolesacl[module]
    );

    if (
      rolesacl &&
      rolesacl[module] &&
      rolesacl[module] != undefined &&
      rolesacl[module][method] &&
      rolesacl[module][method] != undefined
    ) {
      var t = aclobjects;
      t[identifier] = {
        identifier: identifier,
        aclmodule: module,
        aclmethod: method,
        roles: rolesacl[module][method],
      };

      setACLObjects(t);

      return t[identifier];
    } else
    {
      var rez = {};
      rez.identifier = identifier;
      rez.aclmodule = module;
      rez.aclmethod = method;
      rez.roles = [];
      return rez;
    }

    return false;
  };

  // ----------------------------------------
  const updateRoleACL = (module, method, values, cbparams) => {
    logger("updateRoleACLupdateRoleACL", module, method, values);
    
    cbparams = cbparams != undefined ? cbparams : {};

    // ---------------------------------

    var initidentifier = method;
    var identifier = initidentifier;

    var type = "loadlist";
    if (identifier.indexOf("add") == 0) {
      type = "add";
      identifier = identifier.substring(3);
    }
    if (identifier.indexOf("load") == 0) {
      type = "load";
      identifier = identifier.substring(4);
    }
    if (identifier.indexOf("deleteobjects") == 0) {
      type = "dellist";
      identifier = identifier.substring(13);
    }
    if (identifier.indexOf("setviewjurnal") == 0) {
      type = "viewj";
      identifier = identifier.substring(13);
    }

    var urlpart = ProcessUrlPart(identifier, type, cbparams);

    var _module = urlpart && urlpart.module ? urlpart.module : "-1";
    var _method = urlpart && urlpart.specialmethod ? urlpart.specialmethod : "-1";

    // ---------------------------------

    var tr = rolesacl;
    if (tr == undefined) tr = {};
    if (tr[_module] == undefined) tr[_module] = {};

    // ---------------------------------

    logger(
      "updateRoleACLupdateRoleACL1111111111111111",
      cbparams,
      module,
      method,
      _module,
      _method,
      values,
      urlpart,
      tr[_module]
    );

    tr[_module][_method] = values;

    setRolesACL(tr);

    // var t = aclobjects;
    // t[_method] = {
    //   identifier: identifier,
    //   aclmodule: _module,
    //   aclmethod: _method,
    //   roles: rolesacl[_module][_method],
    // };

    // setACLObjects(t);

    // setRolesACL(_objects);

    return;

    // type = type != undefined && type ? type : "loadlist";
    // cbparams = cbparams != undefined ? cbparams : {};

    // var urlpart = ProcessUrlPart(identifier, type, cbparams);

    // logger(
    //   "getACLObjgetACLObj332221211112222",
    //   identifier + "__" + type,
    //   identifier,
    //   type,
    //   cbparams,
    //   urlpart
    // );

    // var module = urlpart && urlpart.module ? urlpart.module : "-1";
    // var method = urlpart && urlpart.specialmethod ? urlpart.specialmethod : "-1";

    // if (
    //   rolesacl &&
    //   rolesacl[module] &&
    //   rolesacl[module] != undefined &&
    //   rolesacl[module][method] &&
    //   rolesacl[module][method] != undefined
    // ) {
    //   var t = aclobjects;
    //   t[identifier] = {
    //     identifier: identifier,
    //     aclmodule: module,
    //     aclmethod: method,
    //     roles: rolesacl[module][method],
    //   };

    //   setACLObjects(t);

    //   return t[identifier];
    // }

    // return false;
  };

  // ----------------------------------------
  const HASACL_METHOD = (identifier, type, otherdata) => {
    type = type != undefined && type ? type : "loadlist";
    var cbparams = {};
    if (otherdata != undefined)
    {
      cbparams.otherdata = otherdata;
    } 

    var permitted = getPermittedACLSpecial(identifier, type, cbparams);

    if (!Array.isArray(permitted)) {
      permitted = [];
    }

    if (!permitted.length) return true;

    var userroles = user.roles ? user.roles : [];

    if (!Array.isArray(userroles)) {
      userroles = [];
    }

    var rez = false;
    for (var i in permitted) {
      if (userroles.indexOf(permitted[i]) == -1) continue;
      rez = true;
      break;
    }

    return rez;
  };

  
  // ----------------------------------------
  const getPermittedACLSpecial = (identifier, type, cbparams) => {
    cbparams = cbparams != undefined ? cbparams : {};

    var initidentifier = identifier;
    
    logger(
      "getPermittedACLSpecialgetPermittedACLSpecial_" + identifier,
      identifier,
      aclobjects,
      rolesacl
    );

    var type = "loadlist";
    if (identifier.indexOf("add") == 0) {
      type = "add";
      identifier = identifier.substring(3);
    }
    if (identifier.indexOf("load") == 0) {
      type = "load";
      identifier = identifier.substring(4);
    }
    if (identifier.indexOf("deleteobjects") == 0) {
      type = "dellist";
      identifier = identifier.substring(13);
    }
    if (identifier.indexOf("setviewjurnal") == 0) {
      type = "viewj";
      identifier = identifier.substring(13);
    }

    return getPermittedACL(identifier, type, cbparams, initidentifier);
  };

  // ----------------------------------------
  const getPermittedACL = (identifier, type, cbparams, initidentifier) => {
    type = type != undefined && type ? type : "loadlist";
    initidentifier =
      initidentifier != undefined
        ? initidentifier
        : identifier;
    cbparams = cbparams != undefined ? cbparams : {};

    var aclobj = getACLObj(identifier, type, cbparams, initidentifier);

    logger(
      "getPermittedACLgetPermittedACL_" + identifier,
      "\n identifier: ",
      identifier,
      "\n type: ",
      type,
      "\n cbparams: ",
      cbparams,
      "\n aclobj: ",
      aclobj
    );

    if (!aclobj) return true;

    if (!aclobj.aclmodule || aclobj.aclmodule == "-1") return true;
    if (!aclobj.aclmethod || aclobj.aclmethod == "-1") return true;
    if (!aclobj.roles || !aclobj.roles.length) return true;

    return aclobj.roles;
  };

  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
  }, [
    serverlabelhash,
    locallabelhash,
    labels,
    serverrolesaclhash,
    localrolesaclhash,
    rolesacl,
  ]);

  const checkIsLoading = () => {
    if (!isLoading) return;

    if (typeof labels !== "object" || labels == null) {
      return;
    }

    if (typeof rolesacl !== "object" || rolesacl == null) {
      return;
    }

    setIsLoading(false);
  };
  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    checkServerLabelHash();
  }, [serverlabelhash]);

  const checkServerLabelHash = () => {
    if (!serverlabelhash) return;

    var t = LocalStorageTools.getValue("labelhash");
    if (!t) t = "-1";
    setLocalLabelHash(t);
  };
  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    checkLocalLabelHash();
  }, [locallabelhash]);

  const checkLocalLabelHash = () => {
    if (!serverlabelhash) return;
    if (!locallabelhash) return;
    if (labels != "-1") return;

    if (serverlabelhash == locallabelhash) {
      // use from localstorage
      setLabels(-2);
      var t = LocalStorageTools.getObject("labels");
      if (!t) setLabels({});
      setLabels(t);
    } else {
      setLabels(-2);
      LabelDTO.LoadLabels(handleLoadLabels);
    }
  };

  const handleLoadLabels = (_objects, _hash) => {
    setLabels(_objects);
    setLocalLabelHash(_hash);

    LocalStorageTools.saveObject("labels", _objects);
    LocalStorageTools.saveValue("labelhash", _hash);
  };
  // ----------------------------------------

  // ----------------------------------------
  const prepareIdentifier = (identifier) => {
    identifier = identifier.replace(/\s/gi, "");
    identifier = identifier.replace(/[^a-zA-Z0-9_]/gi, "");
    identifier = identifier.toLowerCase();

    return identifier;
  };

  const handleGetLoad = (value, identifier) => {
    labels[identifier] = value;
  };

  const LL = (identifier) => {
    var initidentifier = identifier;
    identifier = prepareIdentifier(identifier);

    if (labels[identifier] != undefined) {
      return labels[identifier];
    }

    LabelDTO.GetObj(handleGetLoad, identifier, {});
    return initidentifier;
  };

  const value = {
    LL,
    HASACL_METHOD,
    getPermittedACL,
    getPermittedACLSpecial,
    getACLObj,
    getACLObjSpecial,
    updateRoleACL,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
