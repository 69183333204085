import React from "react";

import { useEffect } from "react";

import Button from "@mui/material/Button";

import { logger } from "hoc/logger";

import { useAuth } from "hook/useAuth";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const UserTopInfo = () => {
  const { user } = useAuth(false);
  
  const { signout } = useAuth();

  logger("UserTopInfo");

  useEffect(() => {
    logger("UserTopInfo user", user);
  }, [user]);

  const handleLogOut = () => {
    
    logger("UserTopInfo handleLogOut");

    signout();
  }

  return !user ? (
    <></>
  ) : (
    <Box
      sx={{
        display:'flex',
        alignSelf:'center',
        justifyContent:"flex-end",
        alignItems:'center',
        marginLeft:'auto'


      }}
    >
      {user.identifier}
      <IconButton
        aria-label="delete"
        onClick={handleLogOut}
        variant="outlined"
        sx={{color:'#c1564c', marginLeft:2}}
      >
        <ExitToAppIcon />
      </IconButton>

    </Box>
  );
};

export { UserTopInfo };
