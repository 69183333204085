import React from "react";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";


import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Grid from "@mui/material/Grid";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { CommonTools } from "api/CommonTools";
// -------------------------

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";
import MapOSShow from "components/elements/Map/MapOSShow";

import Link from "@mui/material/Link";

import uuid from "react-uuid";

const DetailPersoanaJuridica = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  // -------------------------------------------------

  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new PersoanaJuridicaDTO()
  );
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [backdata, setBackData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(false);

  // -------------------------------------------------

  const setObj = (_obj) => {
    updateObj(_obj);
    setSpecialHash(uuid());
  };

  // -------------------------------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
  ];

  // -------------------------------------------------

  useEffect(() => {
    DTOTools.execObjViewJurnal(obj, otherdata);
  }, [obj]);

  useEffect(() => {
    FilterTools.processOtherDataDetail(
      defaultobjdto,
      obj,
      otherdata,
      setBackData,
      setIsLoading,
      setObj,
      {}
    );
  }, [_mlotherdata]);

  // -------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />
      <Container maxWidth="md" className="containerBtnTop">
        <MyNavBt
          href={DTOTools.getListRoute(obj, otherdata)}
          _setmlgenobj={_setmlgenobj}
          _otherdata={backdata}
        >
          <IconButton
            aria-label="delete"
            color="primary"
            sx={{ alignSelf: "start", mr: 2 }}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </MyNavBt>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {obj.denumire}
          </Typography>
          <Typography variant="h6">
            {obj.idno} {obj.codtva}
          </Typography>
          <Typography>
            {obj.streetobj.getLabelFull()}, {obj.streetnumber}, {obj.nroficiu},{" "}
            {obj.codepostal}
          </Typography>
        </Box>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          sx={{
            marginLeft: "auto",
            alignSelf: "end",
          }}
          onClick={() =>
            _setmlgenobj(DTOTools.getEditObject(obj, otherdata, backdata))
          }
        >
          {LL("BT_Edit")}
        </Button>
      </Container>
      <Divider />

      <Box
        maxWidth="md"
        component="div"
        sx={{ py: 1, px: 2, borderRadius: 2, my: 4, border: "1px solid #eee" }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
          {LL("DetailBlock")}
        </Typography>
        <Divider />

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("Telefon")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {obj.phonesobjects.map((item, i) => {
                return CommonTools.GenPhone(item);
              })}
            </Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("email")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>{obj.email}</Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("Cont bancar")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {obj.contbancarsobjects.map((item, i) => {
                return CommonTools.GenContBancar(item);
              })}
            </Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>
              {LL("Persoane de contact")}
            </Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {obj.persoanafizicaobjects.map((item, i) => {
                return CommonTools.GenGetLabel(item, true);
              })}
            </Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("Fondatori")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {obj.fondatorpersoanafizicaobjects.map((item, i) => {
                return CommonTools.GenGetLabel(item, true);
              })}
              ,
              {obj.fondatorpersoanajuridicaobjects.map((item, i) => {
                return CommonTools.GenGetLabel(item, true);
              })}
            </Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>
              {LL("Administratori")}
            </Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {obj.administratorpersoanafizicaobjects.map((item, i) => {
                return CommonTools.GenGetLabel(item, true);
              })}
            </Typography>
          </Grid>

          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>
              {LL("Document scan")}
            </Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>{CommonTools.GenGetScan(obj.scanobj)}</Typography>
          </Grid>
        </Grid>
        <MapOSShow
          _lat={obj.lat}
          _lng={obj.lng}
          _zoom={16}
          _place_id={obj.osmplaceid}
        />
      </Box>
    </>
  );
};;;

export { DetailPersoanaJuridica };
