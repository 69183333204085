import { FetchGet, FetchPost } from "api/RequestTools";

import { ContBancarDTO } from "dto/nomenclatoare/ContBancarDTO";
import { PhonesDTO } from "dto/nomenclatoare/PhonesDTO";
import { PersoanaFizicaLinkDTO } from "dto/nomenclatoare/PersoanaFizicaLinkDTO";
import { PersoanaJuridicaLinkDTO } from "dto/nomenclatoare/PersoanaJuridicaLinkDTO";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";

import { CommonTools } from "api/CommonTools";

import { DTOTools } from "api/DTOTools";
import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

import { FilesDTO } from "dto/files/FilesDTO";

export class PersoanaJuridicaDTO {
  

  getMainObjPart() { return "PersoanaJuridica"; }
  getFieldsInt() {
    return [
      "id",      
      "idstreet",
      "idscan",
      "osmplaceid",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
      "idno",
      "codtva",
      "nroficiu",
      "streetnumber",
      "codepostal",
      "email",
      "locationcode",
      "fulllocation"
    ];
  }

  getFieldsFloat() {
    return [
      "lat",
      "lng",
    ];
  }

  getFieldsIntArray() {
    return [
      "persoanafizicaids",
      "persoanajuridicaids",
      "documentids",
    ];
  }

  getFieldsStrArray() {
    return [
      "locationcodes",
      "contbancars",
      "phones",
      "persoanafizica", "persoanajuridica", "document"
    ];
  }
  
  getFieldsDTOObjects() {
    return [
      { field: "streetobj", _key: "Street" },
      { field: "scanobj", _key: "Files" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
      { field: "locationobjects", _key: "Location" },
      { field: "contbancarsobjects", _key: "ContBancar" },
      { field: "phonesobjects", _key: "Phones" },
      { field: "fondatorpersoanafizicaobjects", _key: "PersoanaFizica" },
      { field: "administratorpersoanafizicaobjects", _key: "PersoanaFizica" },
      { field: "fondatorpersoanajuridicaobjects", _key: "PersoanaJuridica" },
      
    ];
  }
 
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "persoanafizicalinkobjects",
        _key: "PersoanaFizicaLink",
        mainobjfield: "persoanafizicaobjects",
        mainobjkey: "PersoanaFizica",
        mainobjobjparam: "persoanafizicaobj",
        mainidfield: "persoanafizicaids",
        mainobjidparam: "idpersoanafizica",
      },
      {
        field: "fondatorpersoanafizicalinkobjects",
        _key: "PersoanaFizicaLink",
        mainobjfield: "fondatorpersoanafizicaobjects",
        mainobjkey: "PersoanaFizica",
        mainobjobjparam: "persoanafizicaobj",
        mainidfield: "fondatorpersoanafizicaids",
        mainobjidparam: "idpersoanafizica",
      },
      {
        field: "fondatorpersoanajuridicalinkobjects",
        _key: "PersoanaJuridicaLink",
        mainobjfield: "fondatorpersoanajuridicaobjects",
        mainobjkey: "PersoanaJuridica",
        mainobjobjparam: "persoanajuridicaobj",
        mainidfield: "fondatorpersoanajuridicaids",
        mainobjidparam: "idpersoanajuridica",
      },

      {
        field: "administratorpersoanafizicalinkobjects",
        _key: "PersoanaFizicaLink",
        mainobjfield: "administratorpersoanafizicaobjects",
        mainobjkey: "PersoanaFizica",
        mainobjobjparam: "persoanafizicaobj",
        mainidfield: "administratorpersoanafizicaids",
        mainobjidparam: "idpersoanafizica",
      },
    ];
  }

  

  getFieldsUploadArray() {
    return ["filescan"];
  }

  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    var label = this.denumire + this.getPhone();
    return label;
  }

  getPhone() {
    if (this.phones == undefined) return "";
    if (!this.phones) return "";

    if (this.phones.length) return this.phones[0];
    return "";
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "denumire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Denumire"),
      },
      {
        id: "idno",
        numeric: false,
        disablePadding: true,
        label: LL("TD_IDNO"),
      },
      {
        id: "locationcode",
        numeric: false,
        disablePadding: true,
        label: LL("TD_adress"),
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Telefon"),
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: LL("TD_email"),
      },
    ];

    return _headCells;
  }

}
 