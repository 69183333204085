import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

class FilterTools {
  // -----------------------------------------
  static changePage = (newPage, cb) => {
    cb(newPage);
  };

  // -----------------------------------------
  static changeRowsPerPage = (e, cb, cbpage) => {
    cb(parseInt(e.target.value, 10));
    cbpage(0);
  };

  // -----------------------------------------
  static changeDense = (e, cb) => {
    cb(e.target.checked);
  };

  // -----------------------------------------
  static putListURL = (
    obj,
    otherdata,
    setgenobj,
    filters,
    order,
    orderBy,
    page,
    rowsPerPage,
    localHash,
    specialsuffix,
    cbparams
  ) => {
    logger("putListURL123123123");

    if (!obj) return;
    var mo = obj.getMainObjPart();
    var partname = mo.toLowerCase();
    var defaultorderby = obj.getDefaultOrderBy();

    cbparams = cbparams != undefined ? cbparams : {};
    specialsuffix = specialsuffix != undefined ? specialsuffix : "";

    mo = specialsuffix + mo;

    specialsuffix = specialsuffix.toLowerCase();

    var suffix = FilterTools.getFiltersUrl(filters);

    if (order && order != "asc") {
      suffix += suffix ? "&" : "?";
      suffix += "order" + "=" + encodeURIComponent(order);
    }
    if (orderBy && orderBy != defaultorderby) {
      suffix += suffix ? "&" : "?";
      suffix += "orderBy" + "=" + encodeURIComponent(orderBy);
    }
    if (page && page > 0) {
      suffix += suffix ? "&" : "?";
      suffix += "page" + "=" + encodeURIComponent(page);
    }
    if (rowsPerPage && rowsPerPage != 10) {
      suffix += suffix ? "&" : "?";
      suffix += "rowsPerPage" + "=" + encodeURIComponent(rowsPerPage);
    }
    if (suffix) {
      suffix += suffix ? "&" : "?";
      suffix += "localHash" + "=" + encodeURIComponent(localHash);
    }

    var url = "/";

    if (otherdata != undefined && otherdata.uuidsac != undefined) {
      url += "gestiunesac/" + otherdata.uuidsac + "/";
    }

    url += specialsuffix + partname + suffix;

    var routeobj = {
      _hash: localHash,
      _route: url,
      _hashroute: localHash,
      _mainobject: mo,
    };

    logger("putListURL123123123", routeobj);

    setgenobj(routeobj);
  };

  // -----------------------------------------
  static processOtherDataList = (
    obj,
    otherdata,
    setPage,
    processFilters,
    setOrder,
    setOrderBy,
    setRowsPerPage,
    setLocalHash,
    cbparams
  ) => {
    logger(
      "processOtherDataList11222",
      obj,
      otherdata,
      setPage,
      processFilters,
      setOrder,
      setOrderBy,
      setRowsPerPage,
      setLocalHash,
      cbparams
    );
    if (typeof otherdata !== "object") {
      setPage(0);
      processFilters({});
      return;
    }
    if (otherdata === null) {
      setPage(0);
      return;
    }

    if (otherdata.order != undefined) setOrder(otherdata.order);
    if (otherdata.orderBy != undefined) setOrderBy(otherdata.orderBy);
    if (otherdata.page != undefined) {
      setPage(otherdata.page);
    } else {
      setPage(0);
    }

    if (otherdata.rowsPerPage != undefined)
      setRowsPerPage(otherdata.rowsPerPage);

    if (otherdata.localHash != undefined) setLocalHash(otherdata.localHash);

    if (otherdata.filters != undefined) {
      var tfilters = FilterTools.parseFiltersUrl(otherdata.filters);
      processFilters(tfilters);
    } else {
      processFilters({});
    }
  };

  // -----------------------------------------
  static processOtherDataEdit = (
    defaultobjdto,
    obj,
    otherdata,
    setBackData,
    setIsLoading,
    setObj,
    cbparams
  ) => {
         
    var objkey = defaultobjdto.getMainObjPart();

    if (otherdata._backdata != undefined) {
      setBackData(otherdata._backdata);
    }

    if (otherdata._obj != undefined) {
      var tobj = new DTOTools.DTOList[objkey](otherdata._obj);

      setObj(tobj);
      setIsLoading(false);

      return;
    }
    if (!otherdata.uuid) {
      setObj(false);
      return;
    }

    logger(
      "processOtherDataEdit123123",
      defaultobjdto,
      otherdata,
      setIsLoading,
      setObj,
      cbparams
    );

    DTOTools.execLoadObj(
      defaultobjdto,
      otherdata,
      setIsLoading,
      setObj,
      cbparams
    );
  };

  // -----------------------------------------
  static processOtherDataDetail = (
    defaultobjdto,
    obj,
    otherdata,
    setBackData,
    setIsLoading,
    setObj,
    cbparams
  ) => {
    logger("processOtherDataList11222", obj, otherdata, cbparams);

    var objkey = defaultobjdto.getMainObjPart();

    if (otherdata._backdata != undefined) {
      setBackData(otherdata._backdata);
    }

    if (otherdata._obj != undefined) {
      var tobj = new DTOTools.DTOList[objkey](otherdata._obj);

      setObj(tobj);
      setIsLoading(false);

      return;
    }

    if (!otherdata.uuid) {
      setObj(false);
      return;
    }

    DTOTools.execLoadObj(
      defaultobjdto,
      otherdata,
      setIsLoading,
      setObj,
      cbparams
    );
  };

  // -----------------------------------------
  static parseBackData = (_params) => {
    var _order = _params._order != undefined ? _params._order : false;
    var _page = _params._page != undefined ? _params._page : 0;
    var _rowsPerPage = _params._rpp != undefined ? _params._rpp : 10;
    var _filters = _params._filters != undefined ? _params._filters : false;
    var _orderBy = _params._orderBy != undefined ? _params._orderBy : false;
    var _defaultObjDTO =
      _params._defaultObjDTO != undefined ? _params._defaultObjDTO : false;
    var _filter = _params._filter != undefined ? _params._filter : false;
    var _otherdata =
      _params._otherdata != undefined ? _params._otherdata : false;

    var _defaultOrderBy = _defaultObjDTO
      ? _defaultObjDTO.getDefaultOrderBy()
      : false;

    var rez = {};

    if (_order && _order != "asc") {
      rez.order = _order;
    }

    if (_page && _page != 0) {
      rez.page = _page;
    }

    if (_orderBy && _defaultOrderBy && _orderBy != _defaultOrderBy) {
      rez.orderBy = _orderBy;
    }

    if (_rowsPerPage && _rowsPerPage != 10) {
      rez.rowsPerPage = _rowsPerPage;
    }

    if (_filters) {
      rez.filters = FilterTools.getFiltersUrlPart(_filters);
    }

    return rez;
  };

  // -----------------------------------------
  static getFiltersUrlPart(filters) {
    logger("getFiltersUrlPart3433333", filters);

    var str = "";
    var exist = [];

    for (var i in filters) {
      logger("getFiltersUrlPart22222", str, filters[i]);

      if (exist.indexOf(filters[i].key) != -1) continue;
      if (!Array.isArray(filters[i].values)) continue;
      if (!filters[i].values.length) continue;

      var tf = filters[i].values.filter((n) => n);
      if (!tf.length) continue;

      exist[exist.length] = filters[i].key;

      var tstr = "";
      tstr += filters[i].key;
      for (var j in tf) {
        tstr += ",";
        tstr += encodeURIComponent(tf[j]);
      }

      str += str ? "|" : "";
      str += tstr;

      logger("getFiltersUrlPart33333", str, tf);
    }

    return str;
  }

  // -----------------------------------------
  static getFiltersUrl(filters, suffix) {
    suffix = suffix != undefined ? suffix : "";
    if (!Array.isArray(filters)) return suffix;

    var str = FilterTools.getFiltersUrlPart(filters);
    if (!str) return suffix;

    suffix += suffix ? "&" : "?";
    suffix += "filters=" + str;

    return suffix;
  }

  // -----------------------------------------
  static parseFiltersUrl(values) {
    logger("FilterToolsparseFiltersUrl", values);
    if (values == undefined) return {};
    if (!values) return {};

    var tvals = values.split("|");
    var rez = {};
    for (var i in tvals) {
      if (!tvals[i]) continue;
      var tf = tvals[i].split(",");
      tf = tf.filter((n) => n);
      // if (tf.length < 2) continue;

      var key = tf.shift();
      // rez[key] = tf;
      rez[key] = [];
      for (var j in tf) {
        rez[key][rez[key].length] = decodeURIComponent(tf[j]);
      }
    }

    logger("FilterTools parseFiltersUrl rez", rez);

    return rez;
  }

  // -----------------------------------------
  static parseFiltersObject_TextValue(LL, tfilters, key, label, type) {
    logger("FilterTools parseFiltersObject_TextValue", tfilters, key, label);

    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "textvalue";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    logger("FilterTools parseFiltersObject_TextValue", _tfobj);

    return _tfobj;
  }
  // -----------------------------------------
  static parseFiltersObject_RangeDate(
    LL,
    tfilters,
    key,
    label,
    labelfrom,
    labelto,
    type
  ) {
    logger("FilterTools parseFiltersObject_RangeDate", tfilters, key, label);

    label = label != undefined ? label : LL("FilterLabel_" + key);
    labelfrom = labelfrom != undefined ? labelfrom : LL("De la");
    labelto = labelto != undefined ? labelto : LL("Pina la");

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.labelfrom = labelfrom;
    _tfobj.labelto = labelto;
    _tfobj.type = type != undefined ? type : "rangedate";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    _tfobj.startdate = "";
    _tfobj.enddate = "";

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;

          _tfobj.startdate = _tfobj.values[0] != -1 ? _tfobj.values[0] : "";
          if (_tfobj.values.length > 1)
            _tfobj.enddate = _tfobj.values[1] != -1 ? _tfobj.values[1] : "";
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    logger("FilterTools parseFiltersObject_TextValue", _tfobj);

    return _tfobj;
  }
  // -----------------------------------------

  static parseFiltersObject_AutocompleteTypeImobil(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypeimobil";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject_AutocompleteTypeFolosinta(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypefolosita";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject_AutocompleteTypeCombustibil(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypecombustibil";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject_AutocompleteTypeModelAuto(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypemodelauto";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject_AutocompleteTypeMarcaAuto(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypemarcaauto";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }
  static parseFiltersObject_RangeSlider(LL, tfilters, key, label, type) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "rangeslider";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }
  static parseFiltersObject__AutocompleteTypeSex(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypesex";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteTypeStudii(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypestudii";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteFondator(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletefondator";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteStreet(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletestreet";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteYesNo(LL, tfilters, key, label, type) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompleteyesno";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteUser(LL, tfilters, key, label, type) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompleteuser";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }

  static parseFiltersObject__AutocompleteTypeJurnal(
    LL,
    tfilters,
    key,
    label,
    type
  ) {
    label = label != undefined ? label : LL("FilterLabel_" + key);

    var _tfobj = {};

    _tfobj.key = key;
    _tfobj.label = label;
    _tfobj.type = type != undefined ? type : "autocompletetypejurnal";
    _tfobj.exist = false;
    _tfobj.value = "";
    _tfobj.values = [];

    if (tfilters[key] != undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values[_tfobj.values.length] = tfilters[key];
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }
}

export { FilterTools };
