import React from "react";

import { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import TextField from "@mui/material/TextField";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";

import { TypeSexDTO } from "dto/nomenclatoare/TypeSexDTO";
import { DTOTools } from "api/DTOTools";



const AutocompleteFilterTypeSexShow = ({
  cb,
  filterobj,
  _object,
  _mlotherdata,
  ...props
}) => {
  const { LL } = useResource(false);

  var _values =
    filterobj && filterobj.values != undefined && filterobj.values
      ? filterobj.values
      : "";

  var _label = filterobj && filterobj.label != undefined ? filterobj.label : "";
  const [showedvalues, setShowedValues] = React.useState([]);
  const [showedvaluestmp, setShowedValuesTmp] = React.useState([]);
  const [values, setValues] = React.useState(_values);

  const [filter, setFilter] = useState(filterobj);
  const [label, setLabel] = useState(_label);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeSexDTO()
  );

  const handleExecLoad = (_obj) => {
    var _showedvaluestmp = showedvaluestmp;
    _showedvaluestmp[_showedvaluestmp.length] = _obj.denumire;

    _showedvaluestmp = _showedvaluestmp.filter((v, i, a) => a.indexOf(v) === i);

    setShowedValuesTmp(_showedvaluestmp);
  };

  useEffect(() => {
    if (!values) return;
    if (!values.length) return;
    if (!showedvaluestmp) return;
    if (!showedvaluestmp.length) return;
    if (values.length != showedvaluestmp.length) return;

    setShowedValues(showedvaluestmp);
  }, [showedvaluestmp]);

  useEffect(() => {
    if (!values) return;

    for (var i in values) {
      DTOTools.execLoadObj(defaultobjdto, {id: values[i]}, false, handleExecLoad, {});
    }
  }, [values]);

  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    cb(filter, "", false, true, "", false);
  };

  const showValue = () => {
    return (
      <>
        {showedvalues.map(function (item) {
          var denumire = item;

          var text = denumire + " ";
          return text;
        })}
      </>
    );
  };

  return !showedvalues || !showedvalues.length ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
        {label}:
      </Typography>
      <Typography sx={{ marginRight: 1 }}>{showValue()}</Typography>

      <IconButton
        color="primary"
        aria-label="close"
        component="span"
        size="small"
        sx={{ marginRight: 3 }}
        onClick={() => {
          ExecCallBack();
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export { AutocompleteFilterTypeSexShow };
