import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { MyTableCellList } from "components/elements/TableTools/MyTableCellList";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";

import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { FilterTools } from "api/FilterTools";
import Button from '@mui/material/Button';

// ---------------------------------

import { TransportAutoDTO } from "dto/transportauto/TransportAutoDTO";

const TransportAuto = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {

  const { LL } = useResource(false);

  const [isLoading, setIsLoading] = useState(true);

  const [objects, setObjects] = useState([]);
  const [localHash, setLocalHash] = useState(uuid());
  const [filter, setFilter] = useState({});
  const [filters, setFilters] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(-1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);

  // --------------------------
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("numarinmatriculare");
  // --------------------------

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TransportAutoDTO()
  );
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    { label: LL("crumb_TransportAuto") },
  ];

  useEffect(() => {
    setHeadCells(defaultobjdto.GetListHeadCells(LL, otherdata));
  }, [otherdata, defaultobjdto]);

  useEffect(() => {
    FilterTools.processOtherDataList(
      defaultobjdto,
      otherdata,
      setPage,
      processFilters,
      setOrder,
      setOrderBy,
      setRowsPerPage,
      setLocalHash
    );
  }, [_mlotherdata]);

  const processFilters = (tfilters, setfirstpage) => {
    setfirstpage = setfirstpage ? setfirstpage : false;

    var _filter = {};
    var _filters = [];

    var key = "search";
    var _tfobj = FilterTools.parseFiltersObject_TextValue(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist) {
      _filter[key] = _tfobj.value;
    }

    var key = "idtypecombustibil";
    var _tfobj = FilterTools.parseFiltersObject_AutocompleteTypeCombustibil(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist) {
      _filter[key] = _tfobj.value;
    }

    var key = "idtypemodelauto";
    var _tfobj = FilterTools.parseFiltersObject_AutocompleteTypeModelAuto(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist) {
      _filter[key] = _tfobj.value;
    }

    var key = "idtypemarcaauto";
    var _tfobj = FilterTools.parseFiltersObject_AutocompleteTypeMarcaAuto(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist) {
      _filter[key] = _tfobj.value;
    }

    var key = "anfabricare";
    var _tfobj = FilterTools.parseFiltersObject_RangeSlider(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist && ((_tfobj.values[0] != 1990) || (_tfobj.values[1] != new Date().getFullYear()))) {
      logger("Anfabricare 9999999999", _tfobj.values);
      _filter[key] = _tfobj.value;
    }

    setFilter(_filter);
    setFilters(_filters);
    if (setfirstpage) setPage(0);
  };




  useEffect(() => {
    DTOTools.execLoadObjects(defaultobjdto, otherdata, filter, filters, order, orderBy, page, rowsPerPage, setIsLoading, setObjects, setTotal);
    FilterTools.putListURL(defaultobjdto, otherdata, _setmlgenobj, filters, order, orderBy, page, rowsPerPage, localHash);
  }, [filter, filters, order, orderBy, page, rowsPerPage, localHash]);



  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <MyNavBt
        className={"addBtnGeneral"}
        href="/transportauto/add"
        _setmlgenobj={_setmlgenobj}
        _otherdata={{
          _backdata: FilterTools.parseBackData({
            _order: order,
            _page: page,
            _rpp: rowsPerPage,
            _filter: filter,
            _filters: filters,
            _orderBy: orderBy,
            _otherdata: otherdata,
            _defaultObjDTO: defaultobjdto,
          })
        }}
      >
        <Button variant="contained" color="secondary" startIcon={<AddIcon />}>
          {LL("BT_Add")}
        </Button>
      </MyNavBt>

      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={0}>
        <EnhancedTableToolbar selected={selected} setSelected={setSelected} setIsLoading={setIsLoading} defaultobjdto={defaultobjdto} _setmlmessages={_setmlmessages} otherdata={otherdata} objects={objects} setObjects={setObjects}
          numSelected={selected.length}

          _processFilters={processFilters}
          filters={filters}
          label={LL("TopTitle_List_TransportAuto")}
        />

        <TableContainer sx={{ minWidth: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
            className="cursorPointer"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              objects={objects} setSelected={setSelected}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              rowCount={CommonTools.getRowCountForCheckbox(rowsPerPage, objects)}
              headCells={headCells}
            />

            <TableBody>
              {CommonTools.stableSort(objects, order, orderBy).map((obj, i) => {
                const isItemSelected = CommonTools.isSelected(
                  obj.uuid,
                  selected
                );
                const labelId = `enhanced-table-checkbox-${obj.uuid}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={obj.uuid}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) =>
                        CommonTools.handleClickSelect(
                          event,
                          obj.uuid,
                          selected,
                          setSelected
                        )
                      }
                    >
                      {CommonTools.processCheckBox(
                        obj,
                        isItemSelected,
                        labelId
                      )}
                    </TableCell>

                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="numarinventar"
                      headCells={headCells}
                    >
                      {obj.numarinventar}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="numarinmatriculare"
                      headCells={headCells}
                    >
                      {obj.numarinmatriculare}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="vin"
                      headCells={headCells}
                    >
                      {obj.vin}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="anfabricare"
                      headCells={headCells}
                    >
                      {obj.anfabricare}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="consumpredefinit"
                      headCells={headCells}
                    >
                      {obj.consumpredefinitvara} / {obj.consumpredefinitiarna}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="culoare"
                      headCells={headCells}
                    >
                      {obj.culoare}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="numardocument"
                      headCells={headCells}
                    >
                      {obj.numardocument}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="datadocumentint"
                      headCells={headCells}
                    >
                      {obj.datadocument_text}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="dataevidentaint"
                      headCells={headCells}
                    >
                      {obj.dataevidenta_text}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idtypemarcaauto"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.typemarcaautoobj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idtypemodelauto"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.typemodelautoobj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idtypecombustibil"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.typecombustibilobj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "7%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idtypeauto"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.typeautoobj)}
                    </MyTableCellList>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => { FilterTools.changePage(newPage, setPage); }}
          onRowsPerPageChange={(e) => { FilterTools.changeRowsPerPage(e, setRowsPerPage, setPage); }}
        />
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={(e) => { FilterTools.changeDense(e, setDense); }} />}
        label={LL("VizualizareCompacta")}
      />
    </>
  );
};;

export { TransportAuto };
