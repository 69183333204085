import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";

import { useResource } from "hook/useResource";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const NumericAutocomplete = ({
    obj,
    setObj,
    _idfield,
    _label,
    _nofirst,
    _min,
    _max,
    ...props
}) => {
    const { LL } = useResource(false);

    var nofirst = _nofirst != undefined ? 1 : 0;
    var min = _min != undefined ? _min : 0;
    var max = _max != undefined ? _max : 23;

    const [label, setLabel] = useState("");

    var tidfield = _idfield != undefined ? _idfield : "orainregistrareint";

    const [idfield, setIdField] = useState(tidfield);

    var _tsetCount = false;
    var tvalue = obj != undefined && obj[tidfield] ? obj[tidfield] : null;
    var tshowfield = "label";

    if (tvalue != undefined && tvalue) {
     
        for (var i = min; i <= max; i++) {
            if (tvalue == i) {
                var tobject = { id: i, label: `${i}` };
            }
        }
    }

    const [tsetCount, setCount] = useState(_tsetCount);
    const [showfield, setShowField] = useState(tshowfield);

    const [defaultValues, setDefaultValues] = useState(tobject);
    const [objects, setObjects] = useState(tobject);

    const [values, setValues] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isloaded, setIsLoaded] = useState(false);
    const [search, setSearch] = useState("");

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    useEffect(() => {
        if (obj == undefined) return;
        if (!obj) return;

        var L = _label != undefined && _label ? _label : LL("orainregistrareint");

        setLabel(L);

        setIdField(_idfield);
    }, [options, obj, _label, _idfield, _label]);

    useEffect(() => {
        var toptions = [];
        for (var i = min; i <= max; i++) {
            toptions[toptions.length] = { id: i, label: `${i}` };
        }


        if (nofirst) toptions = toptions.reverse();

        setOptions(toptions);
    }, [obj, _idfield]);

    useEffect(() => {
        if (objects) {
            setValues(objects.id);
        } else {
            setValues(0);
        }
    }, [objects]);

    useEffect(() => {
        if (isloaded) return;
        setDefaultValues(tobject);
        setObjects(tobject);
        setIsLoaded(true);
    }, [options]);

    useEffect(() => {
        if (!isloaded) return;
        if (!idfield) return;
        if (!obj) return;
        if (!setObj) return;
        if (setObj == undefined) return;
        if (setObj == "undefined") return;

        var _obj = obj;

        _obj[idfield] = values;

        setObj(_obj);
    }, [values]);

    const handleChange = (value) => {
        setObjects(value);
    };

    return !isloaded ? (
        <></>
    ) : (
        <Autocomplete
            id="asynchronous-demo"
            fullWidth
            open={open}
            defaultValue={defaultValues}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                handleChange(value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option[showfield] ? option[showfield] : "")}
            options={options}
            loading={loading}
            filterOptions={(x) => {
                if (!search) return x;
                x = x.filter((obj) => {
                    if (!obj) return false;
                    if (obj[showfield].toLowerCase().includes(search.toLowerCase()))
                        return true;
                    // return true;
                    return false;
                });
                return x;
            }}
            renderInput={(params) => (
                <TextField
                    color="secondary"
                    margin="normal"
                    variant="standard"
                    {...params}
                    label={label}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );


};

export { NumericAutocomplete };
