import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";
import { DTOTools } from "api/DTOTools";

export class PersoanaJuridicaLinkDTO {
  getMainObjPart() { return "PersoanaJuridicaLink"; }
  getFieldsInt() {
    return [
      "id",      
      "parentid",
      "idpersoanajuridica",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  
 getFieldsDTOObjects() {
  return [
    { field: "persoanajuridicaobj", _key: "PersoanaJuridica" },
  ];
}
  
getFieldsDTOObjectsArray() {
  return [
  ];
}

getFieldsDTOObjectsArrayLink() {
  return [

  ];
}

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  static getAddDataFromArr(rez, objects) {
    // if (Array.isArray(objects) && objects.length) {
    //   for (var i in objects) {
    //     if (objects[i].id) rez["contbancars[" + i + "][id]"] = objects[i].id;
    //     if (objects[i].uuid) rez["contbancars[" + i + "][uuid]"] = objects[i].uuid;
    //     if (objects[i].contbancar)
    //       rez["contbancars[" + i + "][contbancar]"] = objects[i].contbancar;
    //     if (objects[i].idpersoanajuridica)
    //       rez["contbancars[" + i + "][idpersoanajuridica]"] = objects[i].idpersoanajuridica;
    //   }
    // }
    return rez;
  }
}
