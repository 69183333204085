
import { useState } from 'react';
import uuid from "react-uuid";
import { logger } from "hoc/logger";

import Box from "@mui/material/Box";

const MyJurnalDetail = ({ value, item, level, ...props }) => {
  
  value = value == undefined ? '' : value;
  item = item == undefined ? "" : item;
  level = level == undefined ? 0 : level;

  logger("MyJurnalDetail", value, item, level);
  
  const [_value, setValue] = useState(value);
  const [_item, setItem] = useState(item);
  const [_level, setLevel] = useState(level);
  
  const processValueArray = () => {
    if (!value.length) return <></>;
    
    return (
      <>
        {(_item) ?
          <Box>
            <b>{_item}:</b>
          </Box>
          : <></>}
        {value.map((val, i) => {
          var tid = uuid();
          return <MyJurnalDetail key={tid} level={_level + 1} value={val} />;
        })}
      </>
    );
  };
  
  const processValueObject = () => {
    var keys = Object.keys(_value);
    
    if (!keys.length) return <></>;
    
    return keys.map((field, i) => {
      var tid = uuid();

      var cfield =
        (field &&
        (_value[field] instanceof Object || Array.isArray(_value[field])))
          ? field
          : "";
      
  logger("MyJurnalDetailprocessValueObject", field, _value[field]);
      // return (
      //   <MyJurnalDetail
      //     key={tid}
      //     level={_level + 1}
      //     item={field}
      //     value={_value[field]}
      //   />
      // );
       
      return (
        <>
          {cfield ? (
            <Box>
              <b>{cfield}:</b>
            </Box>
          ) : (
            <></>
          )}

          <MyJurnalDetail
            key={tid}
            level={_level + 1}
            item={cfield ? '' : field}
            value={_value[field]}
          />
        </>
      );
    });
  };
  
  const processValueSimple = () => {
    if (!_value) return <></>;
    if (_value == '') return <></>;
    if (_value == '0') return <></>;

    return _item ? (
      <Box>
        <b>{_item}:</b> {_value}
      </Box>
    ) : (
      <Box>{_value}</Box>
    );
  };
  
  const processObject = () => {
    if (Array.isArray(_value)) return processValueArray();
    if (_value instanceof Object) return processValueObject();

    return processValueSimple();
  };

  return <Box paddingLeft={_level}>{processObject()}</Box>;
};

export { MyJurnalDetail };
