import { FetchGet, FetchPost } from "api/RequestTools";

import { ContBancarDTO } from "dto/nomenclatoare/ContBancarDTO";
import { PhonesDTO } from "dto/nomenclatoare/PhonesDTO";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";
import { TypeSexDTO } from "dto/nomenclatoare/TypeSexDTO";
import { TypeStudiiDTO } from "dto/nomenclatoare/TypeStudiiDTO";

import { FilesDTO } from "dto/files/FilesDTO";

import { CommonTools } from "api/CommonTools";
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";
import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class PersoanaFizicaDTO {
  getMainObjPart() {
    return "PersoanaFizica";
  }
  getFieldsInt() {
    return [
      "id",
      "idstreet",
      "dobint",
      "idtypesex",
      "idtypestudii",
      "idphoto",
      "idscan",
      "osmplaceid",
      "dobmicrotime",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "nume",
      "prenume",
      "idnp",
      "streetnumber",
      "codepostal",
      "codmatricol",
      "nrap",
      "locationcode",
      "fulllocation",
      "dob_text",
      "dobtime_text",
    ];
  }

  getFieldsFloat() {
    return ["lat", "lng"];
  }

  getFieldsIntArray() {
    return ["locationcodes"];
  }

  getFieldsStrArray() {
    return ["contbancars", "phones"];
  }

  getFieldsDTOObjects() {
    return [
      { field: "streetobj", _key: "Street" },
      { field: "typesexobj", _key: "TypeSex" },
      { field: "typestudiiobj", _key: "TypeStudii" },
      { field: "photoobj", _key: "Files" },
      { field: "scanobj", _key: "Files" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
      { field: "locationobjects", _key: "Location" },
      { field: "contbancarsobjects", _key: "ContBancar" },
      { field: "phonesobjects", _key: "Phones" },
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [];
  }
  getFieldsUploadArray() {
    return ["filescan", "filephoto"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "nume";
  }

  getLabel() {
    var label = this.nume + " " + this.prenume + " " + this.getPhone();
    return label;
  }

  getPhone() {
    var phone ="";
    if (this.phones != undefined && this.phones.length) phone = this.phones[0];
    
    return phone;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "nume",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Nume"),
      },
      {
        id: "prenume",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Prenume"),
      },
      {
        id: "idnp",
        numeric: false,
        disablePadding: true,
        label: LL("TD_IDNP"),
      },
      {
        id: "locationcode",
        numeric: false,
        disablePadding: true,
        label: LL("TD_adress"),
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Telefon"),
      },
      {
        id: "dobint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Data nastere"),
      },
    ];

    return _headCells;
  }
}
