

import Checkbox from "@mui/material/Checkbox";
import { logger } from "hoc/logger";
import uuid from "react-uuid";
import { FilterTools } from "api/FilterTools";
import { DTOTools } from "api/DTOTools";
import Link from "@mui/material/Link";
import { MyPhoto } from "components/elements/MyPhoto/MyPhoto";

import Typography from "@mui/material/Typography";

class CommonTools {
  // -----------------------------------------
  static GenField = (obj, field, withTypography) => {
    withTypography = withTypography ? withTypography : false;
    var key = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;
    if (obj[field] == undefined) return <></>;
    if (!obj[field]) return <></>;

    return withTypography ? (
      <Typography key={key}>{obj[field]}</Typography>
    ) : (
      <>{obj[field]}</>
    );
  };

  // -----------------------------------------
  static GenType = (obj, withTypography) => {
    return CommonTools.GenField(obj, "denumire", withTypography);
  };

  // -----------------------------------------
  static GenGetLabel = (obj, withTypography) => {
    withTypography = withTypography ? withTypography : false;
    var key = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;
    if (obj.getLabel == undefined) return <></>;
    if (!obj.getLabel) return <></>;

    return withTypography ? (
      <Typography key={key}>{obj.getLabel()}</Typography>
    ) : (
      <>{obj.getLabel()}</>
    );
  };

  // -----------------------------------------
  static GenPF = (obj) => {
    var key1 = uuid();
    var key2 = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;
    if (obj.getPhone == undefined) return <></>;
    if (!obj.getPhone) return <></>;

    return (
      <>
        <Typography key={key1}>
          {obj.nume} {obj.prenume}
        </Typography>
        <Typography key={key2}>{obj.getPhone()}</Typography>
      </>
    );
  };

  // -----------------------------------------
  static GenPhone = (obj) => {
    var key1 = uuid();
    var key2 = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;

    return (
      <>
        <Typography key={key1}>{obj.typephoneobj.denumire}</Typography>
        <Typography key={key2} sx={{ fontSize: 14 }}>
          {obj.phone}
        </Typography>
      </>
    );
  };

  // -----------------------------------------
  static GenContBancar = (obj) => {
    var key1 = uuid();
    var key2 = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;

    return (
      <>
        <Typography key={key1}>{obj.contbancar}</Typography>
        <Typography key={key2} sx={{ fontSize: 14 }}>
          {obj.bancaobj.nume}, {obj.bancaobj.sucursala},{" "}
          {obj.bancaobj.codbancar}
        </Typography>
      </>
    );
  };

  // -----------------------------------------
  static GenContPhoto = (obj, w, h, withfull) => {
    withfull = withfull ? withfull : true;
    w = w ? w : 100;
    h = h ? h : 100;

    if (obj == undefined) return <></>;
    if (!obj) return <></>;

    return (
      <MyPhoto withfull={withfull} fileobj={obj} w={100} h={100} />
    );
  };

  // -----------------------------------------
  static GenGetScan = (obj) => {
    var key = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;
    if (obj.fullurl == undefined) return <></>;
    if (!obj.fullurl) return <></>;

    return (
      <Link
        key={key}
        href={obj.fullurl}
        target="_blank"
        color="primary"
        underline="always"
      >
        {obj.name}
      </Link>
    );
  };

  // -----------------------------------------
  static genClickCell = (
    event,
    obj,
    defaultobjdto,
    otherdata,
    order,
    page,
    rowsPerPage,
    filter,
    filters,
    orderBy,
    _setmlgenobj,
    setIsLoading,
    cbparams
  ) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    var backdata = FilterTools.parseBackData({
      _order: order,
      _page: page,
      _rpp: rowsPerPage,
      _filter: filter,
      _filters: filters,
      _orderBy: orderBy,
      _otherdata: otherdata,
      _defaultObjDTO: defaultobjdto,
    });
    _setmlgenobj(DTOTools.getDetailObject(obj, otherdata, backdata));
  };

  // -----------------------------------------
  static processCheckBox(obj, _isItemSelected, _labelId) {
    if (!obj) return <></>;
    if (!obj.candelete) return <></>;

    return (
      <Checkbox
        color="secondary"
        size="small"
        checked={_isItemSelected}
        inputProps={{
          "aria-labelledby": _labelId,
        }}
      />
    );
  }

  static getRowCountForCheckbox(rowsPerPage, objects) {
    if (!objects) return 0;
    if (objects == undefined) return 0;
    if (!objects.length) return 0;

    objects = objects.filter((n) => {
      console.log("getRowCountForCheckbox", n);
      if (n.candelete == undefined) return true;
      if (n.candelete == "1") return true;
      return false;
    });

    return Math.min(rowsPerPage, objects.length);
  }

  static descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  static getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => CommonTools.descendingComparator(a, b, orderBy)
      : (a, b) => -CommonTools.descendingComparator(a, b, orderBy);
  }

  // static stableSort(array, comparator) {
  static stableSort(array, order, orderBy) {
    
    if (!array) return [];
    if (array == undefined) return [];
    if (!array.length) return [];


    const comparator = CommonTools.getComparator(order, orderBy);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  static handleClickSelect = (event, uuid, selected, setSelected) => {
    logger("handleClickSelect12312312321");
    event.preventDefault();

    const selectedIndex = selected.indexOf(uuid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  static isSelected = (uuid, selected) => selected.indexOf(uuid) !== -1;
};

export { CommonTools };
