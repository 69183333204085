import React from "react";

import { useEffect, useState } from "react";

// import { useLocation } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { FooterPart } from "components/general/FooterPart";

import { logger } from "hoc/logger";

import { useReactPath } from "hook/useReactPath";

import { RouteTools } from "api/RouteTools";

import MD5 from "crypto-js/md5";

import { MessagesContainer } from "components/elements/Messages/MessagesContainer";
import { MessagesContainerPopUpConfirmUpdate } from "components/elements/Messages/MessagesContainerPopUpConfirmUpdate";

import { MyAppBar } from "components/core/MainLayout/MyAppBar";
import { MyMainDrawer } from "components/core/MainLayout/MyMainDrawer";
import { MySecondDrawer } from "components/core/MainLayout/MySecondDrawer";

import { useAuth } from "hook/useAuth";
import { useCookies } from "react-cookie";


import uuid from "react-uuid";

import { useResource } from "hook/useResource";

// ************************************************************
// ************************************************************
// ************************************************************
// ************************************************************


// GestiuneSac - DashBoard
import { GSACDashBoard } from "components/dosarsac/GestiuneSac/GSACDashBoard/GSACDashBoard";
import { AddGSACDashBoard } from "components/dosarsac/GestiuneSac/GSACDashBoard/AddGSACDashBoard";
import { DetailGSACDashBoard } from "components/dosarsac/GestiuneSac/GSACDashBoard/DetailGSACDashBoard";
import { EditGSACDashBoard } from "components/dosarsac/GestiuneSac/GSACDashBoard/EditGSACDashBoard";
// -------------------------------


// GSACAparatCafea 
import { GSACAparatCafea } from "components/dosarsac/GestiuneSac/GSACAparatCafea/GSACAparatCafea";
import { AddGSACAparatCafea } from "components/dosarsac/GestiuneSac/GSACAparatCafea/AddGSACAparatCafea";
import { EditGSACAparatCafea } from "components/dosarsac/GestiuneSac/GSACAparatCafea/EditGSACAparatCafea";
import { DetailGSACAparatCafea } from "components/dosarsac/GestiuneSac/GSACAparatCafea/DetailGSACAparatCafea";
// -------------------------------

// ************************************************************
// ************************************************************
// ************************************************************
// ************************************************************

import { HomePage } from "components/core/HomePage/HomePage";

// persoana fizica
import { PersoanaFizica } from "components/nomenclatoare/PersoanaFizica/PersoanaFizica";
import { AddPersoanaFizica } from "components/nomenclatoare/PersoanaFizica/AddPersoanaFizica";
import { DetailPersoanaFizica } from "components/nomenclatoare/PersoanaFizica/DetailPersoanaFizica";
import { EditPersoanaFizica } from "components/nomenclatoare/PersoanaFizica/EditPersoanaFizica";
// -------------------------------

// PersoanaJuridica
import { PersoanaJuridica } from "components/nomenclatoare/PersoanaJuridica/PersoanaJuridica";
import { AddPersoanaJuridica } from "components/nomenclatoare/PersoanaJuridica/AddPersoanaJuridica";
import { DetailPersoanaJuridica } from "components/nomenclatoare/PersoanaJuridica/DetailPersoanaJuridica";
import { EditPersoanaJuridica } from "components/nomenclatoare/PersoanaJuridica/EditPersoanaJuridica";
// -------------------------------

// TypeFunctie
import { TypeFunctie } from "components/nomenclatoare/TypeFunctie/TypeFunctie";
import { AddTypeFunctie } from "components/nomenclatoare/TypeFunctie/AddTypeFunctie";
import { DetailTypeFunctie } from "components/nomenclatoare/TypeFunctie/DetailTypeFunctie";
import { EditTypeFunctie } from "components/nomenclatoare/TypeFunctie/EditTypeFunctie";
// -------------------------------

// TypeDistribuitoare
import { TypeDistribuitoare } from "components/nomenclatoare/TypeDistribuitoare/TypeDistribuitoare";
import { AddTypeDistribuitoare } from "components/nomenclatoare/TypeDistribuitoare/AddTypeDistribuitoare";
import { DetailTypeDistribuitoare }
  from "components/nomenclatoare/TypeDistribuitoare/DetailTypeDistribuitoare";
import { EditTypeDistribuitoare } from "components/nomenclatoare/TypeDistribuitoare/EditTypeDistribuitoare";
// -------------------------------

// TypePistolPompa
import { TypePistolPompa } from "components/nomenclatoare/TypePistolPompa/TypePistolPompa";
import { AddTypePistolPompa } from "components/nomenclatoare/TypePistolPompa/AddTypePistolPompa";
import { DetailTypePistolPompa } from "components/nomenclatoare/TypePistolPompa/DetailTypePistolPompa";
import { EditTypePistolPompa } from "components/nomenclatoare/TypePistolPompa/EditTypePistolPompa";
// -------------------------------

// TypeNumarCanaleRegistratoare
import { TypeNumarCanaleRegistratoare }
  from "components/nomenclatoare/TypeNumarCanaleRegistratoare/TypeNumarCanaleRegistratoare";
import { AddTypeNumarCanaleRegistratoare }
  from "components/nomenclatoare/TypeNumarCanaleRegistratoare/AddTypeNumarCanaleRegistratoare";
import { DetailTypeNumarCanaleRegistratoare }
  from "components/nomenclatoare/TypeNumarCanaleRegistratoare/DetailTypeNumarCanaleRegistratoare";
import { EditTypeNumarCanaleRegistratoare }
  from "components/nomenclatoare/TypeNumarCanaleRegistratoare/EditTypeNumarCanaleRegistratoare";
// -------------------------------

// TypeMobilier
import { TypeMobilier }
  from "components/nomenclatoare/TypeMobilier/TypeMobilier";
import { AddTypeMobilier }
  from "components/nomenclatoare/TypeMobilier/AddTypeMobilier";
import { DetailTypeMobilier }
  from "components/nomenclatoare/TypeMobilier/DetailTypeMobilier";
import { EditTypeMobilier }
  from "components/nomenclatoare/TypeMobilier/EditTypeMobilier";
// -------------------------------

// TypeIndicePrezentaSSG
import { TypeIndicePrezentaSSG }
  from "components/nomenclatoare/TypeIndicePrezentaSSG/TypeIndicePrezentaSSG";
import { AddTypeIndicePrezentaSSG }
  from "components/nomenclatoare/TypeIndicePrezentaSSG/AddTypeIndicePrezentaSSG";
import { DetailTypeIndicePrezentaSSG }
  from "components/nomenclatoare/TypeIndicePrezentaSSG/DetailTypeIndicePrezentaSSG";
import { EditTypeIndicePrezentaSSG }
  from "components/nomenclatoare/TypeIndicePrezentaSSG/EditTypeIndicePrezentaSSG";
// -------------------------------

// TypeMaterial
import { TypeMaterial }
  from "components/nomenclatoare/TypeMaterial/TypeMaterial";
import { AddTypeMaterial }
  from "components/nomenclatoare/TypeMaterial/AddTypeMaterial";
import { DetailTypeMaterial }
  from "components/nomenclatoare/TypeMaterial/DetailTypeMaterial";
import { EditTypeMaterial }
  from "components/nomenclatoare/TypeMaterial/EditTypeMaterial";
// -------------------------------

// TypeInventarGospodaresc
import { TypeInventarGospodaresc }
  from "components/nomenclatoare/TypeInventarGospodaresc/TypeInventarGospodaresc";
import { AddTypeInventarGospodaresc }
  from "components/nomenclatoare/TypeInventarGospodaresc/AddTypeInventarGospodaresc";
import { DetailTypeInventarGospodaresc }
  from "components/nomenclatoare/TypeInventarGospodaresc/DetailTypeInventarGospodaresc";
import { EditTypeInventarGospodaresc }
  from "components/nomenclatoare/TypeInventarGospodaresc/EditTypeInventarGospodaresc";
// -------------------------------

// TypeEchipamentAntiincendiar
import { TypeEchipamentAntiincendiar }
  from "components/nomenclatoare/TypeEchipamentAntiincendiar/TypeEchipamentAntiincendiar";
import { AddTypeEchipamentAntiincendiar }
  from "components/nomenclatoare/TypeEchipamentAntiincendiar/AddTypeEchipamentAntiincendiar";
import { DetailTypeEchipamentAntiincendiar }
  from "components/nomenclatoare/TypeEchipamentAntiincendiar/DetailTypeEchipamentAntiincendiar";
import { EditTypeEchipamentAntiincendiar }
  from "components/nomenclatoare/TypeEchipamentAntiincendiar/EditTypeEchipamentAntiincendiar";
// -------------------------------

// TypeUtilajTehnologic
import { TypeUtilajTehnologic }
  from "components/nomenclatoare/TypeUtilajTehnologic/TypeUtilajTehnologic";
import { AddTypeUtilajTehnologic }
  from "components/nomenclatoare/TypeUtilajTehnologic/AddTypeUtilajTehnologic";
import { DetailTypeUtilajTehnologic }
  from "components/nomenclatoare/TypeUtilajTehnologic/DetailTypeUtilajTehnologic";
import { EditTypeUtilajTehnologic }
  from "components/nomenclatoare/TypeUtilajTehnologic/EditTypeUtilajTehnologic";
// -------------------------------

// TypeDestinatieMCC
import { TypeDestinatieMCC }
  from "components/nomenclatoare/TypeDestinatieMCC/TypeDestinatieMCC";
import { AddTypeDestinatieMCC }
  from "components/nomenclatoare/TypeDestinatieMCC/AddTypeDestinatieMCC";
import { DetailTypeDestinatieMCC }
  from "components/nomenclatoare/TypeDestinatieMCC/DetailTypeDestinatieMCC";
import { EditTypeDestinatieMCC }
  from "components/nomenclatoare/TypeDestinatieMCC/EditTypeDestinatieMCC";
// -------------------------------

// TypePunctTransformare
import { TypePunctTransformare }
  from "components/nomenclatoare/TypePunctTransformare/TypePunctTransformare";
import { AddTypePunctTransformare }
  from "components/nomenclatoare/TypePunctTransformare/AddTypePunctTransformare";
import { DetailTypePunctTransformare }
  from "components/nomenclatoare/TypePunctTransformare/DetailTypePunctTransformare";
import { EditTypePunctTransformare }
  from "components/nomenclatoare/TypePunctTransformare/EditTypePunctTransformare";
// -------------------------------

// TypeTraseu
import { TypeTraseu } from "components/nomenclatoare/TypeTraseu/TypeTraseu";
import { AddTypeTraseu } from "components/nomenclatoare/TypeTraseu/AddTypeTraseu";
import { DetailTypeTraseu } from "components/nomenclatoare/TypeTraseu/DetailTypeTraseu";
import { EditTypeTraseu } from "components/nomenclatoare/TypeTraseu/EditTypeTraseu";
// -------------------------------

// TypeReteaElectricaExterna
import { TypeReteaElectricaExterna }
  from "components/nomenclatoare/TypeReteaElectricaExterna/TypeReteaElectricaExterna";
import { AddTypeReteaElectricaExterna }
  from "components/nomenclatoare/TypeReteaElectricaExterna/AddTypeReteaElectricaExterna";
import { DetailTypeReteaElectricaExterna }
  from "components/nomenclatoare/TypeReteaElectricaExterna/DetailTypeReteaElectricaExterna";
import { EditTypeReteaElectricaExterna }
  from "components/nomenclatoare/TypeReteaElectricaExterna/EditTypeReteaElectricaExterna";
// -------------------------------

// TypeCameraVideo
import { TypeCameraVideo } from "components/nomenclatoare/TypeCameraVideo/TypeCameraVideo";
import { AddTypeCameraVideo } from "components/nomenclatoare/TypeCameraVideo/AddTypeCameraVideo";
import { DetailTypeCameraVideo } from "components/nomenclatoare/TypeCameraVideo/DetailTypeCameraVideo";
import { EditTypeCameraVideo } from "components/nomenclatoare/TypeCameraVideo/EditTypeCameraVideo";
// -------------------------------

// TypePanouPret
import { TypePanouPret } from "components/nomenclatoare/TypePanouPret/TypePanouPret";
import { AddTypePanouPret } from "components/nomenclatoare/TypePanouPret/AddTypePanouPret";
import { DetailTypePanouPret } from "components/nomenclatoare/TypePanouPret/DetailTypePanouPret";
import { EditTypePanouPret } from "components/nomenclatoare/TypePanouPret/EditTypePanouPret";
// -------------------------------

// TypeSistemCasa
import { TypeSistemCasa } from "components/nomenclatoare/TypeSistemCasa/TypeSistemCasa";
import { AddTypeSistemCasa } from "components/nomenclatoare/TypeSistemCasa/AddTypeSistemCasa";
import { DetailTypeSistemCasa } from "components/nomenclatoare/TypeSistemCasa/DetailTypeSistemCasa";
import { EditTypeSistemCasa } from "components/nomenclatoare/TypeSistemCasa/EditTypeSistemCasa";
// -------------------------------

// TypeRobinetAutomat
import { TypeRobinetAutomat } from "components/nomenclatoare/TypeRobinetAutomat/TypeRobinetAutomat";
import { AddTypeRobinetAutomat } from "components/nomenclatoare/TypeRobinetAutomat/AddTypeRobinetAutomat";
import { DetailTypeRobinetAutomat } from "components/nomenclatoare/TypeRobinetAutomat/DetailTypeRobinetAutomat";
import { EditTypeRobinetAutomat } from "components/nomenclatoare/TypeRobinetAutomat/EditTypeRobinetAutomat";
// -------------------------------

// TypeSetarePanouPret
import { TypeSetarePanouPret } from "components/nomenclatoare/TypeSetarePanouPret/TypeSetarePanouPret";
import { AddTypeSetarePanouPret } from "components/nomenclatoare/TypeSetarePanouPret/AddTypeSetarePanouPret";
import { DetailTypeSetarePanouPret } from "components/nomenclatoare/TypeSetarePanouPret/DetailTypeSetarePanouPret";
import { EditTypeSetarePanouPret } from "components/nomenclatoare/TypeSetarePanouPret/EditTypeSetarePanouPret";
// -------------------------------

// TypeMontare
import { TypeMontare } from "components/nomenclatoare/TypeMontare/TypeMontare";
import { AddTypeMontare } from "components/nomenclatoare/TypeMontare/AddTypeMontare";
import { DetailTypeMontare } from "components/nomenclatoare/TypeMontare/DetailTypeMontare";
import { EditTypeMontare } from "components/nomenclatoare/TypeMontare/EditTypeMontare";
// -------------------------------

// TypeIluminare
import { TypeIluminare } from "components/nomenclatoare/TypeIluminare/TypeIluminare";
import { AddTypeIluminare } from "components/nomenclatoare/TypeIluminare/AddTypeIluminare";
import { DetailTypeIluminare } from "components/nomenclatoare/TypeIluminare/DetailTypeIluminare";
import { EditTypeIluminare } from "components/nomenclatoare/TypeIluminare/EditTypeIluminare";
// -------------------------------

// TypePhone
import { TypePhone } from "components/nomenclatoare/TypePhone/TypePhone";
import { AddTypePhone } from "components/nomenclatoare/TypePhone/AddTypePhone";
import { DetailTypePhone } from "components/nomenclatoare/TypePhone/DetailTypePhone";
import { EditTypePhone } from "components/nomenclatoare/TypePhone/EditTypePhone";
// -------------------------------

// TypeModelAuto
import { TypeModelAuto } from "components/nomenclatoare/TypeModelAuto/TypeModelAuto";
import { AddTypeModelAuto } from "components/nomenclatoare/TypeModelAuto/AddTypeModelAuto";
import { DetailTypeModelAuto } from "components/nomenclatoare/TypeModelAuto/DetailTypeModelAuto";
import { EditTypeModelAuto } from "components/nomenclatoare/TypeModelAuto/EditTypeModelAuto";
// -------------------------------

// TypeMarcaAuto
import { TypeMarcaAuto } from "components/nomenclatoare/TypeMarcaAuto/TypeMarcaAuto";
import { AddTypeMarcaAuto } from "components/nomenclatoare/TypeMarcaAuto/AddTypeMarcaAuto";
import { DetailTypeMarcaAuto } from "components/nomenclatoare/TypeMarcaAuto/DetailTypeMarcaAuto";
import { EditTypeMarcaAuto } from "components/nomenclatoare/TypeMarcaAuto/EditTypeMarcaAuto";
// -------------------------------

// TypeCorp
import { TypeCorp } from "components/nomenclatoare/TypeCorp/TypeCorp";
import { AddTypeCorp } from "components/nomenclatoare/TypeCorp/AddTypeCorp";
import { DetailTypeCorp } from "components/nomenclatoare/TypeCorp/DetailTypeCorp";
import { EditTypeCorp } from "components/nomenclatoare/TypeCorp/EditTypeCorp";
// -------------------------------

// TypeMotivInterventie
import { TypeMotivInterventie } from "components/nomenclatoare/TypeMotivInterventie/TypeMotivInterventie";
import { AddTypeMotivInterventie } from "components/nomenclatoare/TypeMotivInterventie/AddTypeMotivInterventie";
import { DetailTypeMotivInterventie } from "components/nomenclatoare/TypeMotivInterventie/DetailTypeMotivInterventie";
import { EditTypeMotivInterventie } from "components/nomenclatoare/TypeMotivInterventie/EditTypeMotivInterventie";
// -------------------------------

// TypeInterventie
import { TypeInterventie } from "components/nomenclatoare/TypeInterventie/TypeInterventie";
import { AddTypeInterventie } from "components/nomenclatoare/TypeInterventie/AddTypeInterventie";
import { DetailTypeInterventie } from "components/nomenclatoare/TypeInterventie/DetailTypeInterventie";
import { EditTypeInterventie } from "components/nomenclatoare/TypeInterventie/EditTypeInterventie";
// -------------------------------
// TypeAuto
import { TypeAuto } from "components/nomenclatoare/TypeAuto/TypeAuto";
import { AddTypeAuto } from "components/nomenclatoare/TypeAuto/AddTypeAuto";
import { DetailTypeAuto } from "components/nomenclatoare/TypeAuto/DetailTypeAuto";
import { EditTypeAuto } from "components/nomenclatoare/TypeAuto/EditTypeAuto";
// -------------------------------

// TypeUtilajIncalzire
import { TypeUtilajIncalzire } from "components/nomenclatoare/TypeUtilajIncalzire/TypeUtilajIncalzire";
import { AddTypeUtilajIncalzire } from "components/nomenclatoare/TypeUtilajIncalzire/AddTypeUtilajIncalzire";
import { DetailTypeUtilajIncalzire } from "components/nomenclatoare/TypeUtilajIncalzire/DetailTypeUtilajIncalzire";
import { EditTypeUtilajIncalzire } from "components/nomenclatoare/TypeUtilajIncalzire/EditTypeUtilajIncalzire";
// -------------------------------

// TypePilon
import { TypePilon } from "components/nomenclatoare/TypePilon/TypePilon";
import { AddTypePilon } from "components/nomenclatoare/TypePilon/AddTypePilon";
import { DetailTypePilon } from "components/nomenclatoare/TypePilon/DetailTypePilon";
import { EditTypePilon } from "components/nomenclatoare/TypePilon/EditTypePilon";
// -------------------------------

// TypeCombustibil
import { TypeCombustibil } from "components/nomenclatoare/TypeCombustibil/TypeCombustibil";
import { AddTypeCombustibil } from "components/nomenclatoare/TypeCombustibil/AddTypeCombustibil";
import { DetailTypeCombustibil } from "components/nomenclatoare/TypeCombustibil/DetailTypeCombustibil";
import { EditTypeCombustibil } from "components/nomenclatoare/TypeCombustibil/EditTypeCombustibil";
// -------------------------------

// Banca
import { Banca } from "components/nomenclatoare/Banca/Banca";
import { AddBanca } from "components/nomenclatoare/Banca/AddBanca";
import { DetailBanca } from "components/nomenclatoare/Banca/DetailBanca";
import { EditBanca } from "components/nomenclatoare/Banca/EditBanca";
// -------------------------------

// TypeDocument
import { TypeDocument } from "components/nomenclatoare/TypeDocument/TypeDocument";
import { AddTypeDocument } from "components/nomenclatoare/TypeDocument/AddTypeDocument";
import { EditTypeDocument } from "components/nomenclatoare/TypeDocument/EditTypeDocument";
import { DetailTypeDocument } from "components/nomenclatoare/TypeDocument/DetailTypeDocument";
// -------------------------------

// TypeUM
import { TypeUM } from "components/nomenclatoare/TypeUM/TypeUM";
import { AddTypeUM } from "components/nomenclatoare/TypeUM/AddTypeUM";
import { EditTypeUM } from "components/nomenclatoare/TypeUM/EditTypeUM";
import { DetailTypeUM } from "components/nomenclatoare/TypeUM/DetailTypeUM";
// -------------------------------

// Label
import { Label } from "components/core/Label/Label";
import { EditLabel } from "components/core/Label/EditLabel";
import { DetailLabel } from "components/core/Label/DetailLabel";
// -------------------------------

// TypeBunImobil
import { TypeBunImobil } from "components/nomenclatoare/TypeBunImobil/TypeBunImobil";
import { AddTypeBunImobil } from "components/nomenclatoare/TypeBunImobil/AddTypeBunImobil";
import { EditTypeBunImobil } from "components/nomenclatoare/TypeBunImobil/EditTypeBunImobil";
import { DetailTypeBunImobil } from "components/nomenclatoare/TypeBunImobil/DetailTypeBunImobil";
// -------------------------------

// TypeSex
import { TypeSex } from "components/nomenclatoare/TypeSex/TypeSex";
import { AddTypeSex } from "components/nomenclatoare/TypeSex/AddTypeSex";
import { EditTypeSex } from "components/nomenclatoare/TypeSex/EditTypeSex";
import { DetailTypeSex } from "components/nomenclatoare/TypeSex/DetailTypeSex";
// -------------------------------

// TypeStudii
import { TypeStudii } from "components/nomenclatoare/TypeStudii/TypeStudii";
import { AddTypeStudii } from "components/nomenclatoare/TypeStudii/AddTypeStudii";
import { EditTypeStudii } from "components/nomenclatoare/TypeStudii/EditTypeStudii";
import { DetailTypeStudii } from "components/nomenclatoare/TypeStudii/DetailTypeStudii";
// -------------------------------

// TypeFolosinta
import { TypeFolosinta } from "components/nomenclatoare/TypeFolosinta/TypeFolosinta";
import { AddTypeFolosinta } from "components/nomenclatoare/TypeFolosinta/AddTypeFolosinta";
import { EditTypeFolosinta } from "components/nomenclatoare/TypeFolosinta/EditTypeFolosinta";
import { DetailTypeFolosinta } from "components/nomenclatoare/TypeFolosinta/DetailTypeFolosinta";
// -------------------------------

// TypeHotar
import { TypeHotar } from "components/nomenclatoare/TypeHotar/TypeHotar";
import { AddTypeHotar } from "components/nomenclatoare/TypeHotar/AddTypeHotar";
import { EditTypeHotar } from "components/nomenclatoare/TypeHotar/EditTypeHotar";
import { DetailTypeHotar } from "components/nomenclatoare/TypeHotar/DetailTypeHotar";
// -------------------------------

// Document
import { Document } from "components/documents/Document/Document";
import { AddDocument } from "components/documents/Document/AddDocument";
import { EditDocument } from "components/documents/Document/EditDocument";
import { DetailDocument } from "components/documents/Document/DetailDocument";
// -------------------------------

// RCA
import { RCA } from "components/documents/RCA/RCA";
import { AddRCA } from "components/documents/RCA/AddRCA";
import { EditRCA } from "components/documents/RCA/EditRCA";
import { DetailRCA } from "components/documents/RCA/DetailRCA";
// -------------------------------

// CASCO
import { CASCO } from "components/documents/CASCO/CASCO";
import { AddCASCO } from "components/documents/CASCO/AddCASCO";
import { EditCASCO } from "components/documents/CASCO/EditCASCO";
import { DetailCASCO } from "components/documents/CASCO/DetailCASCO";
// -------------------------------

// AvizExpertizaEcologica
import { AvizExpertizaEcologica } from "components/documents/AvizExpertizaEcologica/AvizExpertizaEcologica";
import { AddAvizExpertizaEcologica } from "components/documents/AvizExpertizaEcologica/AddAvizExpertizaEcologica";
import { EditAvizExpertizaEcologica } from "components/documents/AvizExpertizaEcologica/EditAvizExpertizaEcologica";
import { DetailAvizExpertizaEcologica } from "components/documents/AvizExpertizaEcologica/DetailAvizExpertizaEcologica";
// ---------------
// AutorizatieEmisiePoluanti
import { AutorizatieEmisiePoluanti } from "components/documents/AutorizatieEmisiePoluanti/AutorizatieEmisiePoluanti";
import { AddAutorizatieEmisiePoluanti } from "components/documents/AutorizatieEmisiePoluanti/AddAutorizatieEmisiePoluanti";
import { EditAutorizatieEmisiePoluanti } from "components/documents/AutorizatieEmisiePoluanti/EditAutorizatieEmisiePoluanti";
import { DetailAutorizatieEmisiePoluanti } from "components/documents/AutorizatieEmisiePoluanti/DetailAutorizatieEmisiePoluanti";
// --------------
// EvidentaControl
import { EvidentaControl } from "components/documents/EvidentaControl/EvidentaControl";
import { AddEvidentaControl } from "components/documents/EvidentaControl/AddEvidentaControl";
import { EditEvidentaControl } from "components/documents/EvidentaControl/EditEvidentaControl";
import { DetailEvidentaControl } from "components/documents/EvidentaControl/DetailEvidentaControl";
// --------------
// EvidentaSesizari
import { EvidentaSesizari } from "components/documents/EvidentaSesizari/EvidentaSesizari";
import { AddEvidentaSesizari } from "components/documents/EvidentaSesizari/AddEvidentaSesizari";
import { EditEvidentaSesizari } from "components/documents/EvidentaSesizari/EditEvidentaSesizari";
import { DetailEvidentaSesizari } from "components/documents/EvidentaSesizari/DetailEvidentaSesizari";
// --------------
// RaportInterventie
import { RaportInterventie } from "components/documents/RaportInterventie/RaportInterventie";
import { AddRaportInterventie } from "components/documents/RaportInterventie/AddRaportInterventie";
import { EditRaportInterventie } from "components/documents/RaportInterventie/EditRaportInterventie";
import { DetailRaportInterventie } from "components/documents/RaportInterventie/DetailRaportInterventie";
// --------------
// EvidentaAutorizatieSanitara
import { EvidentaAutorizatieSanitara } from "components/documents/EvidentaAutorizatieSanitara/EvidentaAutorizatieSanitara";
import { AddEvidentaAutorizatieSanitara } from "components/documents/EvidentaAutorizatieSanitara/AddEvidentaAutorizatieSanitara";
import { EditEvidentaAutorizatieSanitara } from "components/documents/EvidentaAutorizatieSanitara/EditEvidentaAutorizatieSanitara";
import { DetailEvidentaAutorizatieSanitara } from "components/documents/EvidentaAutorizatieSanitara/DetailEvidentaAutorizatieSanitara";
// --------------
// EvidentaContor
import { EvidentaContor } from "components/documents/EvidentaContor/EvidentaContor";
import { AddEvidentaContor } from "components/documents/EvidentaContor/AddEvidentaContor";
import { EditEvidentaContor } from "components/documents/EvidentaContor/EditEvidentaContor";
import { DetailEvidentaContor } from "components/documents/EvidentaContor/DetailEvidentaContor";
// --------------
// EvidentaVerificareMetrologica
import { EvidentaVerificareMetrologica } from "components/documents/EvidentaVerificareMetrologica/EvidentaVerificareMetrologica";
import { AddEvidentaVerificareMetrologica } from "components/documents/EvidentaVerificareMetrologica/AddEvidentaVerificareMetrologica";
import { EditEvidentaVerificareMetrologica } from "components/documents/EvidentaVerificareMetrologica/EditEvidentaVerificareMetrologica";
import { DetailEvidentaVerificareMetrologica } from "components/documents/EvidentaVerificareMetrologica/DetailEvidentaVerificareMetrologica";
// --------------
// EvidentaEchipamentAntiincendiar
import { EvidentaEchipamentAntiincendiar } from "components/documents/EvidentaEchipamentAntiincendiar/EvidentaEchipamentAntiincendiar";
import { AddEvidentaEchipamentAntiincendiar } from "components/documents/EvidentaEchipamentAntiincendiar/AddEvidentaEchipamentAntiincendiar";
import { EditEvidentaEchipamentAntiincendiar } from "components/documents/EvidentaEchipamentAntiincendiar/EditEvidentaEchipamentAntiincendiar";
import { DetailEvidentaEchipamentAntiincendiar } from "components/documents/EvidentaEchipamentAntiincendiar/DetailEvidentaEchipamentAntiincendiar";
// --------------
// EvidentaDistribuitoare
import { EvidentaDistribuitoare } from "components/documents/EvidentaDistribuitoare/EvidentaDistribuitoare";
import { AddEvidentaDistribuitoare } from "components/documents/EvidentaDistribuitoare/AddEvidentaDistribuitoare";
import { EditEvidentaDistribuitoare } from "components/documents/EvidentaDistribuitoare/EditEvidentaDistribuitoare";
import { DetailEvidentaDistribuitoare } from "components/documents/EvidentaDistribuitoare/DetailEvidentaDistribuitoare";
// --------------
// EvidentaDeservireTehnicaGaz
import { EvidentaDeservireTehnicaGaz } from "components/documents/EvidentaDeservireTehnicaGaz/EvidentaDeservireTehnicaGaz";
import { AddEvidentaDeservireTehnicaGaz } from "components/documents/EvidentaDeservireTehnicaGaz/AddEvidentaDeservireTehnicaGaz";
import { EditEvidentaDeservireTehnicaGaz } from "components/documents/EvidentaDeservireTehnicaGaz/EditEvidentaDeservireTehnicaGaz";
import { DetailEvidentaDeservireTehnicaGaz } from "components/documents/EvidentaDeservireTehnicaGaz/DetailEvidentaDeservireTehnicaGaz";
// --------------
// EvidentaDeservireTehnicaPetrol
import { EvidentaDeservireTehnicaPetrol } from "components/documents/EvidentaDeservireTehnicaPetrol/EvidentaDeservireTehnicaPetrol";
import { AddEvidentaDeservireTehnicaPetrol } from "components/documents/EvidentaDeservireTehnicaPetrol/AddEvidentaDeservireTehnicaPetrol";
import { EditEvidentaDeservireTehnicaPetrol } from "components/documents/EvidentaDeservireTehnicaPetrol/EditEvidentaDeservireTehnicaPetrol";
import { DetailEvidentaDeservireTehnicaPetrol } from "components/documents/EvidentaDeservireTehnicaPetrol/DetailEvidentaDeservireTehnicaPetrol";
// --------------
// TaxaComercializareBenzia
import { TaxaComercializareBenzia } from "components/documents/TaxaComercializareBenzia/TaxaComercializareBenzia";
import { AddTaxaComercializareBenzia } from "components/documents/TaxaComercializareBenzia/AddTaxaComercializareBenzia";
import { EditTaxaComercializareBenzia } from "components/documents/TaxaComercializareBenzia/EditTaxaComercializareBenzia";
import { DetailTaxaComercializareBenzia } from "components/documents/TaxaComercializareBenzia/DetailTaxaComercializareBenzia";
// --------------

// DeservireTehnica
import { DeservireTehnica } from "components/documents/DeservireTehnica/DeservireTehnica";
import { AddDeservireTehnica } from "components/documents/DeservireTehnica/AddDeservireTehnica";
import { EditDeservireTehnica } from "components/documents/DeservireTehnica/EditDeservireTehnica";
import { DetailDeservireTehnica } from "components/documents/DeservireTehnica/DetailDeservireTehnica";
// -------------------------------

// RevizieTehnica
import { RevizieTehnica } from "components/documents/RevizieTehnica/RevizieTehnica";
import { AddRevizieTehnica } from "components/documents/RevizieTehnica/AddRevizieTehnica";
import { EditRevizieTehnica } from "components/documents/RevizieTehnica/EditRevizieTehnica";
import { DetailRevizieTehnica } from "components/documents/RevizieTehnica/DetailRevizieTehnica";
// -------------------------------

// BunImobil
import { BunImobil } from "components/bunimobil/BunImobil/BunImobil";
import { AddBunImobil } from "components/bunimobil/BunImobil/AddBunImobil";
import { EditBunImobil } from "components/bunimobil/BunImobil/EditBunImobil";
import { DetailBunImobil } from "components/bunimobil/BunImobil/DetailBunImobil";
// -------------------------------

// TransportAuto
import { TransportAuto } from "components/transportauto/TransportAuto/TransportAuto";
import { AddTransportAuto } from "components/transportauto/TransportAuto/AddTransportAuto.jsx";
import { EditTransportAuto } from "components/transportauto/TransportAuto/EditTransportAuto";
import { DetailTransportAuto } from "components/transportauto/TransportAuto/DetailTransportAuto";
// -------------------------------

// TransportAutoCASCOLink
import { TransportAutoCASCOLink }
  from "components/transportauto/TransportAutoCASCOLink/TransportAutoCASCOLink";
import { AddTransportAutoCASCOLink }
  from "components/transportauto/TransportAutoCASCOLink/AddTransportAutoCASCOLink";
import { DetailTransportAutoCASCOLink }
  from "components/transportauto/TransportAutoCASCOLink/DetailTransportAutoCASCOLink";
// -------------------------------

// TransportAutoDocumentLink
import { AddTransportAutoDocumentLink }
  from "components/transportauto/TransportAutoDocumentLink/AddTransportAutoDocumentLink";
import { TransportAutoDocumentLink }
  from "components/transportauto/TransportAutoDocumentLink/TransportAutoDocumentLink";
import { DetailTransportAutoDocumentLink }
  from "components/transportauto/TransportAutoDocumentLink/DetailTransportAutoDocumentLink";
// -------------------------------

// TransportAutoRCALink
import { TransportAutoRCALink }
  from "components/transportauto/TransportAutoRCALink/TransportAutoRCALink";
import { AddTransportAutoRCALink }
  from "components/transportauto/TransportAutoRCALink/AddTransportAutoRCALink";
import { DetailTransportAutoRCALink }
  from "components/transportauto/TransportAutoRCALink/DetailTransportAutoRCALink";
// -------------------------------

// TransportAutoRevizieTehnicaLink
import { TransportAutoRevizieTehnicaLink }
  from "components/transportauto/TransportAutoRevizieTehnicaLink/TransportAutoRevizieTehnicaLink";
import { AddTransportAutoRevizieTehnicaLink }
  from "components/transportauto/TransportAutoRevizieTehnicaLink/AddTransportAutoRevizieTehnicaLink";
import { DetailTransportAutoRevizieTehnicaLink }
  from "components/transportauto/TransportAutoRevizieTehnicaLink/DetailTransportAutoRevizieTehnicaLink";
// -------------------------------

// TransportAutoDeservireTehnicaLink
import { TransportAutoDeservireTehnicaLink }
  from "components/transportauto/TransportAutoDeservireTehnicaLink/TransportAutoDeservireTehnicaLink";
import { AddTransportAutoDeservireTehnicaLink }
  from "components/transportauto/TransportAutoDeservireTehnicaLink/AddTransportAutoDeservireTehnicaLink";
import { DetailTransportAutoDeservireTehnicaLink }
  from "components/transportauto/TransportAutoDeservireTehnicaLink/DetailTransportAutoDeservireTehnicaLink";
// -------------------------------

// DosarSAC
import { DosarSAC } from "components/dosarsac/DosarSAC/DosarSAC";
import { AddDosarSAC } from "components/dosarsac/DosarSAC/AddDosarSAC";
import { EditDosarSAC } from "components/dosarsac/DosarSAC/EditDosarSAC";
import { DetailDosarSAC } from "components/dosarsac/DosarSAC/DetailDosarSAC";
// -------------------------------

// PilonIluminare
import { PilonIluminare } from "components/dosarsac/PilonIluminare/PilonIluminare";
import { AddPilonIluminare } from "components/dosarsac/PilonIluminare/AddPilonIluminare";
import { EditPilonIluminare } from "components/dosarsac/PilonIluminare/EditPilonIluminare";
import { DetailPilonIluminare } from "components/dosarsac/PilonIluminare/DetailPilonIluminare";
// -------------------------------

// CorpIluminareStradal
import { CorpIluminareStradal } from "components/dosarsac/CorpIluminareStradal/CorpIluminareStradal";
import { AddCorpIluminareStradal } from "components/dosarsac/CorpIluminareStradal/AddCorpIluminareStradal";
import { EditCorpIluminareStradal } from "components/dosarsac/CorpIluminareStradal/EditCorpIluminareStradal";
import { DetailCorpIluminareStradal } from "components/dosarsac/CorpIluminareStradal/DetailCorpIluminareStradal";
// -------------------------------

// Mobilier
import { Mobilier } from "components/dosarsac/Mobilier/Mobilier";
import { AddMobilier } from "components/dosarsac/Mobilier/AddMobilier";
import { EditMobilier } from "components/dosarsac/Mobilier/EditMobilier";
import { DetailMobilier } from "components/dosarsac/Mobilier/DetailMobilier";
// -------------------------------

// UtilajIncalzire
import { UtilajIncalzire } from "components/dosarsac/UtilajIncalzire/UtilajIncalzire";
import { AddUtilajIncalzire } from "components/dosarsac/UtilajIncalzire/AddUtilajIncalzire";
import { EditUtilajIncalzire } from "components/dosarsac/UtilajIncalzire/EditUtilajIncalzire";
import { DetailUtilajIncalzire } from "components/dosarsac/UtilajIncalzire/DetailUtilajIncalzire";
// -------------------------------

// PunctTransformare
import { PunctTransformare } from "components/dosarsac/PunctTransformare/PunctTransformare";
import { AddPunctTransformare } from "components/dosarsac/PunctTransformare/AddPunctTransformare";
import { EditPunctTransformare } from "components/dosarsac/PunctTransformare/EditPunctTransformare";
import { DetailPunctTransformare } from "components/dosarsac/PunctTransformare/DetailPunctTransformare";
// -------------------------------

// TraseuElectric
import { TraseuElectric } from "components/dosarsac/TraseuElectric/TraseuElectric";
import { AddTraseuElectric } from "components/dosarsac/TraseuElectric/AddTraseuElectric";
import { EditTraseuElectric } from "components/dosarsac/TraseuElectric/EditTraseuElectric";
import { DetailTraseuElectric } from "components/dosarsac/TraseuElectric/DetailTraseuElectric";
// -------------------------------

// CameraVideo
import { CameraVideo } from "components/dosarsac/CameraVideo/CameraVideo";
import { AddCameraVideo } from "components/dosarsac/CameraVideo/AddCameraVideo";
import { EditCameraVideo } from "components/dosarsac/CameraVideo/EditCameraVideo";
import { DetailCameraVideo } from "components/dosarsac/CameraVideo/DetailCameraVideo";
// -------------------------------

// RegistratorVideo
import { RegistratorVideo } from "components/dosarsac/RegistratorVideo/RegistratorVideo";
import { AddRegistratorVideo } from "components/dosarsac/RegistratorVideo/AddRegistratorVideo";
import { EditRegistratorVideo } from "components/dosarsac/RegistratorVideo/EditRegistratorVideo";
import { DetailRegistratorVideo } from "components/dosarsac/RegistratorVideo/DetailRegistratorVideo";
// -------------------------------

// AparatCafea
import { AparatCafea } from "components/dosarsac/AparatCafea/AparatCafea";
import { AddAparatCafea } from "components/dosarsac/AparatCafea/AddAparatCafea";
import { EditAparatCafea } from "components/dosarsac/AparatCafea/EditAparatCafea";
import { DetailAparatCafea } from "components/dosarsac/AparatCafea/DetailAparatCafea";
// -------------------------------

// UtilajTehnologic
import { UtilajTehnologic } from "components/dosarsac/UtilajTehnologic/UtilajTehnologic";
import { AddUtilajTehnologic } from "components/dosarsac/UtilajTehnologic/AddUtilajTehnologic";
import { EditUtilajTehnologic } from "components/dosarsac/UtilajTehnologic/EditUtilajTehnologic";
import { DetailUtilajTehnologic } from "components/dosarsac/UtilajTehnologic/DetailUtilajTehnologic";
// -------------------------------

// PanouPret
import { PanouPret } from "components/dosarsac/PanouPret/PanouPret";
import { AddPanouPret } from "components/dosarsac/PanouPret/AddPanouPret";
import { EditPanouPret } from "components/dosarsac/PanouPret/EditPanouPret";
import { DetailPanouPret } from "components/dosarsac/PanouPret/DetailPanouPret";
// -------------------------------

// GeneratorElectric
import { GeneratorElectric } from "components/dosarsac/GeneratorElectric/GeneratorElectric";
import { AddGeneratorElectric } from "components/dosarsac/GeneratorElectric/AddGeneratorElectric";
import { EditGeneratorElectric } from "components/dosarsac/GeneratorElectric/EditGeneratorElectric";
import { DetailGeneratorElectric } from "components/dosarsac/GeneratorElectric/DetailGeneratorElectric";
// -------------------------------

// TransformatorElectric
import { TransformatorElectric } from "components/dosarsac/TransformatorElectric/TransformatorElectric";
import { AddTransformatorElectric } from "components/dosarsac/TransformatorElectric/AddTransformatorElectric";
import { EditTransformatorElectric } from "components/dosarsac/TransformatorElectric/EditTransformatorElectric";
import { DetailTransformatorElectric } from "components/dosarsac/TransformatorElectric/DetailTransformatorElectric";
// -------------------------------

// PistolDistribuitor
import { PistolDistribuitor } from "components/dosarsac/PistolDistribuitor/PistolDistribuitor";
import { AddPistolDistribuitor } from "components/dosarsac/PistolDistribuitor/AddPistolDistribuitor";
import { EditPistolDistribuitor } from "components/dosarsac/PistolDistribuitor/EditPistolDistribuitor";
import { DetailPistolDistribuitor } from "components/dosarsac/PistolDistribuitor/DetailPistolDistribuitor";
// -------------------------------

// TypeBrand
import { TypeBrand } from "components/nomenclatoare/TypeBrand/TypeBrand";
import { AddTypeBrand } from "components/nomenclatoare/TypeBrand/AddTypeBrand";
import { EditTypeBrand } from "components/nomenclatoare/TypeBrand/EditTypeBrand";
import { DetailTypeBrand } from "components/nomenclatoare/TypeBrand/DetailTypeBrand";
// -------------------------------

// TypeCarosabil
import { TypeCarosabil } from "components/nomenclatoare/TypeCarosabil/TypeCarosabil";
import { AddTypeCarosabil } from "components/nomenclatoare/TypeCarosabil/AddTypeCarosabil";
import { EditTypeCarosabil } from "components/nomenclatoare/TypeCarosabil/EditTypeCarosabil";
import { DetailTypeCarosabil } from "components/nomenclatoare/TypeCarosabil/DetailTypeCarosabil";
// -------------------------------

// User
import { User } from "components/user/User/User";
import { AddUser } from "components/user/User/AddUser";
import { EditUser } from "components/user/User/EditUser";
import { DetailUser } from "components/user/User/DetailUser";
// -------------------------------

// UserSac
import { UserSac } from "components/user/UserSac/UserSac";
import { AddUserSac } from "components/user/UserSac/AddUserSac";
import { DetailUserSac } from "components/user/UserSac/DetailUserSac";
// -------------------------------

// Roles
import { Roles } from "components/user/Roles/Roles";
import { AddRoles } from "components/user/Roles/AddRoles";
import { EditRoles } from "components/user/Roles/EditRoles";
import { DetailRoles } from "components/user/Roles/DetailRoles";
// -------------------------------

// jurnal
import { Jurnal } from "components/core/Jurnal/Jurnal";
// -------------------------------

// RolesACL
import { RolesACL } from "components/core/RolesACL/RolesACL";
// -------------------------------

// Street
import { Street } from "components/nomenclatoare/Street/Street";
import { AddStreet } from "components/nomenclatoare/Street/AddStreet";
import { EditStreet } from "components/nomenclatoare/Street/EditStreet";
import { DetailStreet } from "components/nomenclatoare/Street/DetailStreet";
// -------------------------------

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MainLayout = ({ children, ...props }) => {

  const { LL } = useResource(false);

  const theme = useTheme();

  const navigate = useNavigate();
  const { checkUser } = useAuth();


  const { user } = useAuth(false);

  // const [cookies] = useCookies();
  // const [cookiesFlag, setCookiesFlag] = useState(1);
  // const [_cookies, setCookiesLocal] = useState({});

  const routesInfo = {};

  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState(false);
  const [_otherdata, setOtherData] = React.useState(false);

  const [localhash, setLocalHash] = useState(uuid());
  const [componenthash, setComponenetHash] = useState(uuid());
  const [messages, setMessages] = useState([]);
  const [mesinfo, setMesInfo] = useState(false);

  const location = useLocation();

  const pathObject = useReactPath();

  // useEffect(() => {
  //   logger("cookiesFlag", cookiesFlag);
  //   var tc = useCookies();
  //   logger("cookiesFlag", tc);
  // }, [cookiesFlag]);

  useEffect(() => {
    if (pathObject.path == _currentpath) return;

    var h = MD5(pathObject.path).toString();
    var hr = MD5(pathObject.path).toString();

    var routeobj = RouteTools.parseLocation(location);
    logger("whisorypath2222_111", pathObject, _otherdata, routeobj);

    // if (pathObject.path == _currentpath) {
    //   var h1 = (_otherdata && _otherdata._hashinfo  && _otherdata._hashinfo != undefined) ? _otherdata._hashinfo :  false;
    //   var h2 = (routeobj && routeobj._info != undefined && routeobj._info._hashinfo != undefined) ? routeobj._info._hashinfo : false;


    // logger("whisorypath2222_111_hhhh", h1, h2);

    //   if (h1 == h2) return;
    // }

    var mainobj = routeobj._mainobject;
    var od = routeobj._info;

    logger("whisorypath2222 222", pathObject);

    if (pathObject.historystate instanceof Object) {
      if (pathObject.historystate._hashroute != undefined) {
        hr = pathObject.historystate._hashroute;
      }
      if (pathObject.historystate._hash != undefined) {
        h = pathObject.historystate._hash;
      }
      if (pathObject.historystate._otherdata != undefined) {
        // logger("whisorypath od 3333", od, pathObject.historystate._otherdata);
        var _tod = pathObject.historystate._otherdata;
        od = Object.assign({}, _tod, od);
        // logger("whisorypath od 4444", od);
      }
      if (pathObject.historystate._mainobject != undefined) {
        mainobj = pathObject.historystate._mainobject;
      }
    }

    logger("whisorypath2222 333", pathObject);

    if (mainobj && mainobj == _mainobject) {
      var h1 = (_otherdata && _otherdata._hashinfo && _otherdata._hashinfo != undefined) ? _otherdata._hashinfo : false;
      var h2 = (routeobj && routeobj._info != undefined && routeobj._info._hashinfo != undefined) ? routeobj._info._hashinfo : false;


      logger("whisorypath2222_111_hhhh", h1, h2);

      if (h1 == h2) return;
    }

    if (routesInfo.hr != undefined && routesInfo.hr != h) {
      var routeobj2 = RouteTools.parseLocation(location);
      od = routeobj2._info;
    }

    logger("whisorypath2222 444", pathObject);

    routesInfo.hr = h;


    logger("whisorypath2222444fffffffggghhjj", pathObject.path, od, mainobj);

    setOtherData(od);
    setCurrentPath(pathObject.path);
    setMainObject(mainobj);
    setComponenetHash(uuid());

    logger("whisorypath", pathObject.path, mainobj, od, routesInfo.hr, h);
    logger("whisorypath", od);
  }, [pathObject]);
  // }, [cookiesFlag]);


  useEffect(() => {
    setLocalHash(uuid());
  }, [messages, mesinfo]);

  const handleSetGeneralData = (_tobj) => {
    logger("handleSetGeneralData", _tobj);

    if (_tobj == undefined) return;

    logger("checkUser WWWW", user);

    var existuser = checkUser();

    logger("checkUser XXXX", user);


    if (!existuser) {
      navigate("/login");
      return;
    }

    RouteTools.setHistory(_tobj, location);
  };

  const handleSetMessages = (_messages, _mesinfo) => {

    var mi = _mesinfo == undefined ? false : _mesinfo;
    setMesInfo(mi);

    logger("handleSetMessages_XXXX", _messages, _mesinfo, mi);

    var mes = messages;

    if (!Array.isArray(mes)) {
      mes = [];
    }

    if (Array.isArray(_messages)) {
      mes = [...mes, ..._messages];
    } else {
      mes[mes.length] = _messages;
    }

    setMessages(mes);
    setMesInfo(mi);
  };

  const processMessages = () => {
    if (!messages) return <></>;
    if (!messages.length) return <></>;

    // var _k = uuid();

    logger("processMessages_XXXX", mesinfo);

    if (mesinfo && mesinfo.type == "popupconfirmupdate") {
      logger("processMessages_XXXX", mesinfo);

      return (
        <MessagesContainerPopUpConfirmUpdate
          key={localhash}
          _objects={messages}
          cb={setMessages}
          _info={mesinfo}
        />
      );
    }

    return (
      <MessagesContainer key={localhash} _objects={messages} cb={setMessages} />
    );
  };

  const processMainObject_TypeDocument = () => {
    if (_mainobject == "TypeDocument")
      return (
        <TypeDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeDocument")
      return (
        <AddTypeDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeDocument")
      return (
        <DetailTypeDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeDocument")
      return (
        <EditTypeDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeFunctie = () => {
    logger("processMainObject_TypeFunctie", _mainobject);
    if (_mainobject == "TypeFunctie")
      return (
        <TypeFunctie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeFunctie")
      return (
        <AddTypeFunctie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeFunctie")
      return (
        <DetailTypeFunctie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeFunctie")
      return (
        <EditTypeFunctie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeDistribuitoare = () => {
    logger("processMainObject_TypeDistribuitoare", _mainobject);
    if (_mainobject == "TypeDistribuitoare")
      return (
        <TypeDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeDistribuitoare")
      return (
        <AddTypeDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeDistribuitoare")
      return (
        <DetailTypeDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeDistribuitoare")
      return (
        <EditTypeDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypePistolPompa = () => {
    logger("processMainObject_TypePistolPompa", _mainobject);
    if (_mainobject == "TypePistolPompa")
      return (
        <TypePistolPompa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypePistolPompa")
      return (
        <AddTypePistolPompa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypePistolPompa")
      return (
        <DetailTypePistolPompa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypePistolPompa")
      return (
        <EditTypePistolPompa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeNumarCanaleRegistratoare = () => {
    logger("processMainObject_TypeNumarCanaleRegistratoare", _mainobject);
    if (_mainobject == "TypeNumarCanaleRegistratoare")
      return (
        <TypeNumarCanaleRegistratoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeNumarCanaleRegistratoare")
      return (
        <AddTypeNumarCanaleRegistratoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeNumarCanaleRegistratoare")
      return (
        <DetailTypeNumarCanaleRegistratoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeNumarCanaleRegistratoare")
      return (
        <EditTypeNumarCanaleRegistratoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeMobilier = () => {
    logger("processMainObject_TypeMobilier", _mainobject);
    if (_mainobject == "TypeMobilier")
      return (
        <TypeMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeMobilier")
      return (
        <AddTypeMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeMobilier")
      return (
        <DetailTypeMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeMobilier")
      return (
        <EditTypeMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeIndicePrezentaSSG = () => {
    logger("processMainObject_TypeIndicePrezentaSSG", _mainobject);
    if (_mainobject == "TypeIndicePrezentaSSG")
      return (
        <TypeIndicePrezentaSSG
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeIndicePrezentaSSG")
      return (
        <AddTypeIndicePrezentaSSG
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeIndicePrezentaSSG")
      return (
        <DetailTypeIndicePrezentaSSG
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeIndicePrezentaSSG")
      return (
        <EditTypeIndicePrezentaSSG
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeMaterial = () => {
    logger("processMainObject_TypeMaterial", _mainobject);
    if (_mainobject == "TypeMaterial")
      return (
        <TypeMaterial
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeMaterial")
      return (
        <AddTypeMaterial
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeMaterial")
      return (
        <DetailTypeMaterial
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeMaterial")
      return (
        <EditTypeMaterial
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeInventarGospodaresc = () => {
    logger("processMainObject_TypeInventarGospodaresc", _mainobject);
    if (_mainobject == "TypeInventarGospodaresc")
      return (
        <TypeInventarGospodaresc
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeInventarGospodaresc")
      return (
        <AddTypeInventarGospodaresc
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeInventarGospodaresc")
      return (
        <DetailTypeInventarGospodaresc
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeInventarGospodaresc")
      return (
        <EditTypeInventarGospodaresc
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeEchipamentAntiincendiar = () => {
    logger("processMainObject_TypeEchipamentAntiincendiar", _mainobject);
    if (_mainobject == "TypeEchipamentAntiincendiar")
      return (
        <TypeEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeEchipamentAntiincendiar")
      return (
        <AddTypeEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeEchipamentAntiincendiar")
      return (
        <DetailTypeEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeEchipamentAntiincendiar")
      return (
        <EditTypeEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeUtilajTehnologic = () => {
    logger("processMainObject_TypeUtilajTehnologic", _mainobject);
    if (_mainobject == "TypeUtilajTehnologic")
      return (
        <TypeUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeUtilajTehnologic")
      return (
        <AddTypeUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeUtilajTehnologic")
      return (
        <DetailTypeUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeUtilajTehnologic")
      return (
        <EditTypeUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeDestinatieMCC = () => {
    logger("processMainObject_TypeDestinatieMCC", _mainobject);
    if (_mainobject == "TypeDestinatieMCC")
      return (
        <TypeDestinatieMCC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeDestinatieMCC")
      return (
        <AddTypeDestinatieMCC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeDestinatieMCC")
      return (
        <DetailTypeDestinatieMCC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeDestinatieMCC")
      return (
        <EditTypeDestinatieMCC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypePunctTransformare = () => {
    logger("processMainObject_TypePunctTransformare", _mainobject);
    if (_mainobject == "TypePunctTransformare")
      return (
        <TypePunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypePunctTransformare")
      return (
        <AddTypePunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypePunctTransformare")
      return (
        <DetailTypePunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypePunctTransformare")
      return (
        <EditTypePunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeTraseu = () => {
    logger("processMainObject_TypeTraseu", _mainobject);
    if (_mainobject == "TypeTraseu")
      return (
        <TypeTraseu
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeTraseu")
      return (
        <AddTypeTraseu
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeTraseu")
      return (
        <DetailTypeTraseu
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeTraseu")
      return (
        <EditTypeTraseu
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeReteaElectricaExterna = () => {
    logger("processMainObject_TypeReteaElectricaExterna", _mainobject);
    if (_mainobject == "TypeReteaElectricaExterna")
      return (
        <TypeReteaElectricaExterna
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeReteaElectricaExterna")
      return (
        <AddTypeReteaElectricaExterna
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeReteaElectricaExterna")
      return (
        <DetailTypeReteaElectricaExterna
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeReteaElectricaExterna")
      return (
        <EditTypeReteaElectricaExterna
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeCameraVideo = () => {
    logger("processMainObject_TypeCameraVideo", _mainobject);
    if (_mainobject == "TypeCameraVideo")
      return (
        <TypeCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeCameraVideo")
      return (
        <AddTypeCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeCameraVideo")
      return (
        <DetailTypeCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeCameraVideo")
      return (
        <EditTypeCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypePanouPret = () => {
    logger("processMainObject_TypePanouPret", _mainobject);
    if (_mainobject == "TypePanouPret")
      return (
        <TypePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypePanouPret")
      return (
        <AddTypePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypePanouPret")
      return (
        <DetailTypePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypePanouPret")
      return (
        <EditTypePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeSistemCasa = () => {
    logger("processMainObject_TypeSistemCasa", _mainobject);
    if (_mainobject == "TypeSistemCasa")
      return (
        <TypeSistemCasa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeSistemCasa")
      return (
        <AddTypeSistemCasa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeSistemCasa")
      return (
        <DetailTypeSistemCasa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeSistemCasa")
      return (
        <EditTypeSistemCasa
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeRobinetAutomat = () => {
    logger("processMainObject_TypeRobinetAutomat", _mainobject);
    if (_mainobject == "TypeRobinetAutomat")
      return (
        <TypeRobinetAutomat
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeRobinetAutomat")
      return (
        <AddTypeRobinetAutomat
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeRobinetAutomat")
      return (
        <DetailTypeRobinetAutomat
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeRobinetAutomat")
      return (
        <EditTypeRobinetAutomat
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeSetarePanouPret = () => {
    logger("processMainObject_TypeSetarePanouPret", _mainobject);
    if (_mainobject == "TypeSetarePanouPret")
      return (
        <TypeSetarePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeSetarePanouPret")
      return (
        <AddTypeSetarePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeSetarePanouPret")
      return (
        <DetailTypeSetarePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeSetarePanouPret")
      return (
        <EditTypeSetarePanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeMontare = () => {
    logger("processMainObject_TypeMontare", _mainobject);
    if (_mainobject == "TypeMontare")
      return (
        <TypeMontare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeMontare")
      return (
        <AddTypeMontare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeMontare")
      return (
        <DetailTypeMontare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeMontare")
      return (
        <EditTypeMontare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeIluminare = () => {
    logger("processMainObject_TypeIluminare", _mainobject);
    if (_mainobject == "TypeIluminare")
      return (
        <TypeIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeIluminare")
      return (
        <AddTypeIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeIluminare")
      return (
        <DetailTypeIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeIluminare")
      return (
        <EditTypeIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeUtilajIncalzire = () => {
    logger("processMainObject_TypeUtilajIncalzire", _mainobject);
    if (_mainobject == "TypeUtilajIncalzire")
      return (
        <TypeUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeUtilajIncalzire")
      return (
        <AddTypeUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeUtilajIncalzire")
      return (
        <DetailTypeUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeUtilajIncalzire")
      return (
        <EditTypeUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypePilon = () => {
    logger("processMainObject_TypePilon", _mainobject);
    if (_mainobject == "TypePilon")
      return (
        <TypePilon
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypePilon")
      return (
        <AddTypePilon
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypePilon")
      return (
        <DetailTypePilon
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypePilon")
      return (
        <EditTypePilon
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
  }

  const processMainObject_TypeCorp = () => {
    logger("processMainObject_TypeCorp", _mainobject);
    if (_mainobject == "TypeCorp")
      return (
        <TypeCorp
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeCorp")
      return (
        <AddTypeCorp
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeCorp")
      return (
        <DetailTypeCorp
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeCorp")
      return (
        <EditTypeCorp
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_TypeMotivInterventie = () => {
    logger("processMainObject_TypeMotivInterventie", _mainobject);
    if (_mainobject == "TypeMotivInterventie")
      return (
        <TypeMotivInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeMotivInterventie")
      return (
        <AddTypeMotivInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeMotivInterventie")
      return (
        <DetailTypeMotivInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeMotivInterventie")
      return (
        <EditTypeMotivInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_TypeInterventie = () => {
    logger("processMainObject_TypeInterventie", _mainobject);
    if (_mainobject == "TypeInterventie")
      return (
        <TypeInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeInterventie")
      return (
        <AddTypeInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeInterventie")
      return (
        <DetailTypeInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeInterventie")
      return (
        <EditTypeInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_TypeCarosabil = () => {
    logger("processMainObject_TypeCarosabil", _mainobject);
    if (_mainobject == "TypeCarosabil")
      return (
        <TypeCarosabil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeCarosabil")
      return (
        <AddTypeCarosabil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeCarosabil")
      return (
        <DetailTypeCarosabil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeCarosabil")
      return (
        <EditTypeCarosabil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeBrand = () => {
    logger("processMainObject_TypeBrand", _mainobject);
    if (_mainobject == "TypeBrand")
      return (
        <TypeBrand
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeBrand")
      return (
        <AddTypeBrand
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeBrand")
      return (
        <DetailTypeBrand
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeBrand")
      return (
        <EditTypeBrand
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeAuto = () => {
    logger("processMainObject_TypeAuto", _mainobject);
    if (_mainobject == "TypeAuto")
      return (
        <TypeAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeAuto")
      return (
        <AddTypeAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeAuto")
      return (
        <DetailTypeAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeAuto")
      return (
        <EditTypeAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeMarcaAuto = () => {
    logger("processMainObject_TypeMarcaAuto", _mainobject);
    if (_mainobject == "TypeMarcaAuto")
      return (
        <TypeMarcaAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeMarcaAuto")
      return (
        <AddTypeMarcaAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeMarcaAuto")
      return (
        <DetailTypeMarcaAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeMarcaAuto")
      return (
        <EditTypeMarcaAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeModelAuto = () => {
    if (_mainobject == "TypeModelAuto")
      return (
        <TypeModelAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeModelAuto")
      return (
        <AddTypeModelAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeModelAuto")
      return (
        <DetailTypeModelAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeModelAuto")
      return (
        <EditTypeModelAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeCombustibil = () => {
    logger("processMainObject_TypeCombustibil", _mainobject);
    if (_mainobject == "TypeCombustibil")
      return (
        <TypeCombustibil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeCombustibil")
      return (
        <AddTypeCombustibil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeCombustibil")
      return (
        <DetailTypeCombustibil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeCombustibil")
      return (
        <EditTypeCombustibil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypePhone = () => {
    if (_mainobject == "TypePhone")
      return (
        <TypePhone
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypePhone")
      return (
        <AddTypePhone
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypePhone")
      return (
        <DetailTypePhone
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypePhone")
      return (
        <EditTypePhone
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeUM = () => {
    if (_mainobject == "TypeUM")
      return (
        <TypeUM
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeUM")
      return (
        <AddTypeUM
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeUM")
      return (
        <DetailTypeUM
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeUM")
      return (
        <EditTypeUM
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Label = () => {
    if (_mainobject == "Label")
      return (
        <Label
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailLabel")
      return (
        <DetailLabel
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditLabel")
      return (
        <EditLabel
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeBunImobil = () => {
    if (_mainobject == "TypeBunImobil")
      return (
        <TypeBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeBunImobil")
      return (
        <AddTypeBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeBunImobil")
      return (
        <DetailTypeBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeBunImobil")
      return (
        <EditTypeBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeSex = () => {
    if (_mainobject == "TypeSex")
      return (
        <TypeSex
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeSex")
      return (
        <AddTypeSex
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeSex")
      return (
        <DetailTypeSex
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeSex")
      return (
        <EditTypeSex
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeStudii = () => {
    if (_mainobject == "TypeStudii")
      return (
        <TypeStudii
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeStudii")
      return (
        <AddTypeStudii
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeStudii")
      return (
        <DetailTypeStudii
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeStudii")
      return (
        <EditTypeStudii
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeFolosinta = () => {
    if (_mainobject == "TypeFolosinta")
      return (
        <TypeFolosinta
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeFolosinta")
      return (
        <AddTypeFolosinta
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeFolosinta")
      return (
        <DetailTypeFolosinta
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeFolosinta")
      return (
        <EditTypeFolosinta
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TypeHotar = () => {
    if (_mainobject == "TypeHotar")
      return (
        <TypeHotar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTypeHotar")
      return (
        <AddTypeHotar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTypeHotar")
      return (
        <DetailTypeHotar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTypeHotar")
      return (
        <EditTypeHotar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Street = () => {
    if (_mainobject == "Street")
      return (
        <Street
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddStreet")
      return (
        <AddStreet
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailStreet")
      return (
        <DetailStreet
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditStreet")
      return (
        <EditStreet
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_BunImobil = () => {
    if (_mainobject == "BunImobil")
      return (
        <BunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddBunImobil")
      return (
        <AddBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailBunImobil")
      return (
        <DetailBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditBunImobil")
      return (
        <EditBunImobil
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAuto = () => {
    if (_mainobject == "TransportAuto")
      return (
        <TransportAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAuto")
      return (
        <AddTransportAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAuto")
      return (
        <DetailTransportAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTransportAuto")
      return (
        <EditTransportAuto
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAutoCASCOLink = () => {
    if (_mainobject == "TransportAutoCASCOLink")
      return (
        <TransportAutoCASCOLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAutoCASCOLink")
      return (
        <AddTransportAutoCASCOLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAutoCASCOLink")
      return (
        <DetailTransportAutoCASCOLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAutoDocumentLink = () => {
    if (_mainobject == "TransportAutoDocumentLink")
      return (
        <TransportAutoDocumentLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAutoDocumentLink")
      return (
        <AddTransportAutoDocumentLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAutoDocumentLink")
      return (
        <DetailTransportAutoDocumentLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAutoRCALink = () => {
    if (_mainobject == "TransportAutoRCALink")
      return (
        <TransportAutoRCALink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAutoRCALink")
      return (
        <AddTransportAutoRCALink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAutoRCALink")
      return (
        <DetailTransportAutoRCALink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAutoRevizieTehnicaLink = () => {
    if (_mainobject == "TransportAutoRevizieTehnicaLink")
      return (
        <TransportAutoRevizieTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAutoRevizieTehnicaLink")
      return (
        <AddTransportAutoRevizieTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAutoRevizieTehnicaLink")
      return (
        <DetailTransportAutoRevizieTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransportAutoDeservireTehnicaLink = () => {
    if (_mainobject == "TransportAutoDeservireTehnicaLink")
      return (
        <TransportAutoDeservireTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransportAutoDeservireTehnicaLink")
      return (
        <AddTransportAutoDeservireTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransportAutoDeservireTehnicaLink")
      return (
        <DetailTransportAutoDeservireTehnicaLink
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_DosarSAC = () => {
    if (_mainobject == "DosarSAC")
      return (
        <DosarSAC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddDosarSAC")
      return (
        <AddDosarSAC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailDosarSAC")
      return (
        <DetailDosarSAC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditDosarSAC")
      return (
        <EditDosarSAC
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PilonIluminare = () => {
    if (_mainobject == "PilonIluminare")
      return (
        <PilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPilonIluminare")
      return (
        <AddPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPilonIluminare")
      return (
        <DetailPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPilonIluminare")
      return (
        <EditPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Mobilier = () => {
    if (_mainobject == "Mobilier")
      return (
        <Mobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddMobilier")
      return (
        <AddMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailMobilier")
      return (
        <DetailMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditMobilier")
      return (
        <EditMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_UtilajIncalzire = () => {
    if (_mainobject == "UtilajIncalzire")
      return (
        <UtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddUtilajIncalzire")
      return (
        <AddUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailUtilajIncalzire")
      return (
        <DetailUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditUtilajIncalzire")
      return (
        <EditUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_CameraVideo = () => {
    if (_mainobject == "CameraVideo")
      return (
        <CameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddCameraVideo")
      return (
        <AddCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailCameraVideo")
      return (
        <DetailCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditCameraVideo")
      return (
        <EditCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PunctTransformare = () => {
    if (_mainobject == "PunctTransformare")
      return (
        <PunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPunctTransformare")
      return (
        <AddPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPunctTransformare")
      return (
        <DetailPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPunctTransformare")
      return (
        <EditPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TraseuElectric = () => {
    if (_mainobject == "TraseuElectric")
      return (
        <TraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTraseuElectric")
      return (
        <AddTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTraseuElectric")
      return (
        <DetailTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTraseuElectric")
      return (
        <EditTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_CorpIluminareStradal = () => {
    if (_mainobject == "CorpIluminareStradal")
      return (
        <CorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddCorpIluminareStradal")
      return (
        <AddCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailCorpIluminareStradal")
      return (
        <DetailCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditCorpIluminareStradal")
      return (
        <EditCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_RegistratorVideo = () => {
    if (_mainobject == "RegistratorVideo")
      return (
        <RegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddRegistratorVideo")
      return (
        <AddRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailRegistratorVideo")
      return (
        <DetailRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditRegistratorVideo")
      return (
        <EditRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_AparatCafea = () => {
    if (_mainobject == "AparatCafea")
      return (
        <AparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddAparatCafea")
      return (
        <AddAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailAparatCafea")
      return (
        <DetailAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditAparatCafea")
      return (
        <EditAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_UtilajTehnologic = () => {
    if (_mainobject == "UtilajTehnologic")
      return (
        <UtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddUtilajTehnologic")
      return (
        <AddUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailUtilajTehnologic")
      return (
        <DetailUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditUtilajTehnologic")
      return (
        <EditUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PanouPret = () => {
    if (_mainobject == "PanouPret")
      return (
        <PanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPanouPret")
      return (
        <AddPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPanouPret")
      return (
        <DetailPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPanouPret")
      return (
        <EditPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GeneratorElectric = () => {
    if (_mainobject == "GeneratorElectric")
      return (
        <GeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGeneratorElectric")
      return (
        <AddGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGeneratorElectric")
      return (
        <DetailGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGeneratorElectric")
      return (
        <EditGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_TransformatorElectric = () => {
    if (_mainobject == "TransformatorElectric")
      return (
        <TransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTransformatorElectric")
      return (
        <AddTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTransformatorElectric")
      return (
        <DetailTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTransformatorElectric")
      return (
        <EditTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PistolDistribuitor = () => {
    if (_mainobject == "PistolDistribuitor")
      return (
        <PistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPistolDistribuitor")
      return (
        <AddPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPistolDistribuitor")
      return (
        <DetailPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPistolDistribuitor")
      return (
        <EditPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_User = () => {
    if (_mainobject == "User")
      return (
        <User
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddUser")
      return (
        <AddUser
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailUser")
      return (
        <DetailUser
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditUser")
      return (
        <EditUser
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_UserSac = () => {
    if (_mainobject == "UserSac")
      return (
        <UserSac
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddUserSac")
      return (
        <AddUserSac
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailUserSac")
      return (
        <DetailUserSac
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );


    return false;
  };

  const processMainObject_Roles = () => {
    if (_mainobject == "Roles")
      return (
        <Roles
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddRoles")
      return (
        <AddRoles
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailRoles")
      return (
        <DetailRoles
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditRoles")
      return (
        <EditRoles
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Jurnal = () => {
    if (_mainobject == "Jurnal")
      return (
        <Jurnal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_RolesACL = () => {
    if (_mainobject == "RolesACL")
      return (
        <RolesACL
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PersoanaFizica = () => {
    if (_mainobject == "PersoanaFizica")
      return (
        <PersoanaFizica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPersoanaFizica")
      return (
        <AddPersoanaFizica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPersoanaFizica")
      return (
        <DetailPersoanaFizica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPersoanaFizica")
      return (
        <EditPersoanaFizica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_PersoanaJuridica = () => {
    if (_mainobject == "PersoanaJuridica")
      return (
        <PersoanaJuridica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddPersoanaJuridica")
      return (
        <AddPersoanaJuridica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailPersoanaJuridica")
      return (
        <DetailPersoanaJuridica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditPersoanaJuridica")
      return (
        <EditPersoanaJuridica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Banca = () => {
    if (_mainobject == "Banca")
      return (
        <Banca
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddBanca")
      return (
        <AddBanca
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailBanca")
      return (
        <DetailBanca
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditBanca")
      return (
        <EditBanca
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_Document = () => {
    if (_mainobject == "Document")
      return (
        <Document
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddDocument")
      return (
        <AddDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailDocument")
      return (
        <DetailDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditDocument")
      return (
        <EditDocument
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_RCA = () => {
    if (_mainobject == "RCA")
      return (
        <RCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddRCA")
      return (
        <AddRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailRCA")
      return (
        <DetailRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditRCA")
      return (
        <EditRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_CASCO = () => {
    if (_mainobject == "CASCO")
      return (
        <CASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddCASCO")
      return (
        <AddCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailCASCO")
      return (
        <DetailCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditCASCO")
      return (
        <EditCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_AvizExpertizaEcologica = () => {
    if (_mainobject == "AvizExpertizaEcologica")
      return (
        <AvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddAvizExpertizaEcologica")
      return (
        <AddAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailAvizExpertizaEcologica")
      return (
        <DetailAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditAvizExpertizaEcologica")
      return (
        <EditAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_AutorizatieEmisiePoluanti = () => {
    if (_mainobject == "AutorizatieEmisiePoluanti")
      return (
        <AutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddAutorizatieEmisiePoluanti")
      return (
        <AddAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailAutorizatieEmisiePoluanti")
      return (
        <DetailAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditAutorizatieEmisiePoluanti")
      return (
        <EditAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaControl = () => {
    if (_mainobject == "EvidentaControl")
      return (
        <EvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaControl")
      return (
        <AddEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaControl")
      return (
        <DetailEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaControl")
      return (
        <EditEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaSesizari = () => {
    if (_mainobject == "EvidentaSesizari")
      return (
        <EvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaSesizari")
      return (
        <AddEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaSesizari")
      return (
        <DetailEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaSesizari")
      return (
        <EditEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_RaportInterventie = () => {
    if (_mainobject == "RaportInterventie")
      return (
        <RaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddRaportInterventie")
      return (
        <AddRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailRaportInterventie")
      return (
        <DetailRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditRaportInterventie")
      return (
        <EditRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaAutorizatieSanitara = () => {
    if (_mainobject == "EvidentaAutorizatieSanitara")
      return (
        <EvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaAutorizatieSanitara")
      return (
        <AddEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaAutorizatieSanitara")
      return (
        <DetailEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaAutorizatieSanitara")
      return (
        <EditEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaContor = () => {
    if (_mainobject == "EvidentaContor")
      return (
        <EvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaContor")
      return (
        <AddEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaContor")
      return (
        <DetailEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaContor")
      return (
        <EditEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaVerificareMetrologica = () => {
    if (_mainobject == "EvidentaVerificareMetrologica")
      return (
        <EvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaVerificareMetrologica")
      return (
        <AddEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaVerificareMetrologica")
      return (
        <DetailEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaVerificareMetrologica")
      return (
        <EditEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaEchipamentAntiincendiar = () => {
    if (_mainobject == "EvidentaEchipamentAntiincendiar")
      return (
        <EvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaEchipamentAntiincendiar")
      return (
        <AddEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaEchipamentAntiincendiar")
      return (
        <DetailEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaEchipamentAntiincendiar")
      return (
        <EditEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaDistribuitoare = () => {
    if (_mainobject == "EvidentaDistribuitoare")
      return (
        <EvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaDistribuitoare")
      return (
        <AddEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaDistribuitoare")
      return (
        <DetailEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaDistribuitoare")
      return (
        <EditEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaDeservireTehnicaGaz = () => {
    if (_mainobject == "EvidentaDeservireTehnicaGaz")
      return (
        <EvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaDeservireTehnicaGaz")
      return (
        <AddEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaDeservireTehnicaGaz")
      return (
        <DetailEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaDeservireTehnicaGaz")
      return (
        <EditEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_EvidentaDeservireTehnicaPetrol = () => {
    if (_mainobject == "EvidentaDeservireTehnicaPetrol")
      return (
        <EvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddEvidentaDeservireTehnicaPetrol")
      return (
        <AddEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailEvidentaDeservireTehnicaPetrol")
      return (
        <DetailEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditEvidentaDeservireTehnicaPetrol")
      return (
        <EditEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_TaxaComercializareBenzia = () => {
    if (_mainobject == "TaxaComercializareBenzia")
      return (
        <TaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddTaxaComercializareBenzia")
      return (
        <AddTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailTaxaComercializareBenzia")
      return (
        <DetailTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditTaxaComercializareBenzia")
      return (
        <EditTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_DeservireTehnica = () => {
    if (_mainobject == "DeservireTehnica")
      return (
        <DeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddDeservireTehnica")
      return (
        <AddDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailDeservireTehnica")
      return (
        <DetailDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditDeservireTehnica")
      return (
        <EditDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_RevizieTehnica = () => {
    if (_mainobject == "RevizieTehnica")
      return (
        <RevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddRevizieTehnica")
      return (
        <AddRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailRevizieTehnica")
      return (
        <DetailRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditRevizieTehnica")
      return (
        <EditRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GestiuneSac_DashBoard = () => {

    logger("processMainObject_GestiuneSac_DashBoard", _otherdata);


    if (_mainobject == "GSACDashBoard")
      return (
        <GSACDashBoard
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACDashBoard")
      return (
        <AddGSACDashBoard
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACDashBoard")
      return (
        <DetailGSACDashBoard
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACDashBoard")
      return (
        <EditGSACDashBoard
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACAparatCafea = () => {
    logger("processMainObject_GSACAparatCafea", _mainobject, _otherdata);
    if (_mainobject == "GSACAparatCafea")
      return (
        <AparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACAparatCafea")
      return (
        <AddAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACAparatCafea")
      return (
        <DetailAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACAparatCafea")
      return (
        <EditAparatCafea
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACCameraVideo = () => {
    logger("processMainObject_GSACCameraVideo", _mainobject, _otherdata);
    if (_mainobject == "GSACCameraVideo")
      return (
        <CameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACCameraVideo")
      return (
        <AddCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACCameraVideo")
      return (
        <DetailCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACCameraVideo")
      return (
        <EditCameraVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACPilonIluminare = () => {
    logger("processMainObject_GSACPilonIluminare", _mainobject, _otherdata);
    if (_mainobject == "GSACPilonIluminare")
      return (
        <PilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACPilonIluminare")
      return (
        <AddPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACPilonIluminare")
      return (
        <DetailPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACPilonIluminare")
      return (
        <EditPilonIluminare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACCorpIluminareStradal = () => {
    logger("processMainObject_GSACCorpIluminareStradal", _mainobject, _otherdata);
    if (_mainobject == "GSACCorpIluminareStradal")
      return (
        <CorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACCorpIluminareStradal")
      return (
        <AddCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACCorpIluminareStradal")
      return (
        <DetailCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACCorpIluminareStradal")
      return (
        <EditCorpIluminareStradal
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACMobilier = () => {
    logger("processMainObject_GSACMobilier", _mainobject, _otherdata);
    if (_mainobject == "GSACMobilier")
      return (
        <Mobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACMobilier")
      return (
        <AddMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACMobilier")
      return (
        <DetailMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACMobilier")
      return (
        <EditMobilier
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACUtilajIncalzire = () => {
    logger("processMainObject_GSACUtilajIncalzire", _mainobject, _otherdata);
    if (_mainobject == "GSACUtilajIncalzire")
      return (
        <UtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACUtilajIncalzire")
      return (
        <AddUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACUtilajIncalzire")
      return (
        <DetailUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACUtilajIncalzire")
      return (
        <EditUtilajIncalzire
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACPunctTransformare = () => {
    logger("processMainObject_GSACPunctTransformare", _mainobject, _otherdata);
    if (_mainobject == "GSACPunctTransformare")
      return (
        <PunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACPunctTransformare")
      return (
        <AddPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACPunctTransformare")
      return (
        <DetailPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACPunctTransformare")
      return (
        <EditPunctTransformare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACTraseuElectric = () => {
    logger("processMainObject_GSACTraseuElectric", _mainobject, _otherdata);
    if (_mainobject == "GSACTraseuElectric")
      return (
        <TraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACTraseuElectric")
      return (
        <AddTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACTraseuElectric")
      return (
        <DetailTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACTraseuElectric")
      return (
        <EditTraseuElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACRegistratorVideo = () => {
    logger("processMainObject_GSACRegistratorVideo", _mainobject, _otherdata);
    if (_mainobject == "GSACRegistratorVideo")
      return (
        <RegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACRegistratorVideo")
      return (
        <AddRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACRegistratorVideo")
      return (
        <DetailRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACRegistratorVideo")
      return (
        <EditRegistratorVideo
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACUtilajTehnologic = () => {
    logger("processMainObject_GSACUtilajTehnologic", _mainobject, _otherdata);
    if (_mainobject == "GSACUtilajTehnologic")
      return (
        <UtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACUtilajTehnologic")
      return (
        <AddUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACUtilajTehnologic")
      return (
        <DetailUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACUtilajTehnologic")
      return (
        <EditUtilajTehnologic
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACPanouPret = () => {
    logger("processMainObject_GSACPanouPret", _mainobject, _otherdata);
    if (_mainobject == "GSACPanouPret")
      return (
        <PanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACPanouPret")
      return (
        <AddPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACPanouPret")
      return (
        <DetailPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACPanouPret")
      return (
        <EditPanouPret
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACAvizExpertizaEcologica = () => {
    logger("processMainObject_GSACAvizExpertizaEcologica", _mainobject, _otherdata);
    if (_mainobject == "GSACAvizExpertizaEcologica")
      return (
        <AvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACAvizExpertizaEcologica")
      return (
        <AddAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACAvizExpertizaEcologica")
      return (
        <DetailAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACAvizExpertizaEcologica")
      return (
        <EditAvizExpertizaEcologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACGeneratorElectric = () => {
    logger("processMainObject_GSACGeneratorElectric", _mainobject, _otherdata);
    if (_mainobject == "GSACGeneratorElectric")
      return (
        <GeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACGeneratorElectric")
      return (
        <AddGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACGeneratorElectric")
      return (
        <DetailGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACGeneratorElectric")
      return (
        <EditGeneratorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACPistolDistribuitor = () => {
    logger("processMainObject_GSACPistolDistribuitor", _mainobject, _otherdata);
    if (_mainobject == "GSACPistolDistribuitor")
      return (
        <PistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACPistolDistribuitor")
      return (
        <AddPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACPistolDistribuitor")
      return (
        <DetailPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACPistolDistribuitor")
      return (
        <EditPistolDistribuitor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACTransformatorElectric = () => {
    logger("processMainObject_GSACTransformatorElectric", _mainobject, _otherdata);
    if (_mainobject == "GSACTransformatorElectric")
      return (
        <TransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACTransformatorElectric")
      return (
        <AddTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACTransformatorElectric")
      return (
        <DetailTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACTransformatorElectric")
      return (
        <EditTransformatorElectric
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACEvidentaDeservireTehnicaPetrol = () => {
    logger("processMainObject_GSACEvidentaDeservireTehnicaPetrol", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaDeservireTehnicaPetrol")
      return (
        <EvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaDeservireTehnicaPetrol")
      return (
        <AddEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaDeservireTehnicaPetrol")
      return (
        <DetailEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaDeservireTehnicaPetrol")
      return (
        <EditEvidentaDeservireTehnicaPetrol
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACEvidentaDeservireTehnicaGaz = () => {
    logger("processMainObject_GSACEvidentaDeservireTehnicaGaz", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaDeservireTehnicaGaz")
      return (
        <EvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaDeservireTehnicaGaz")
      return (
        <AddEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaDeservireTehnicaGaz")
      return (
        <DetailEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaDeservireTehnicaGaz")
      return (
        <EditEvidentaDeservireTehnicaGaz
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };


  const processMainObject_GSACEvidentaControl = () => {
    logger("processMainObject_GSACEvidentaControl", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaControl")
      return (
        <EvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaControl")
      return (
        <AddEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaControl")
      return (
        <DetailEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaControl")
      return (
        <EditEvidentaControl
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACEvidentaSesizari = () => {
    logger("processMainObject_GSACEvidentaSesizari", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaSesizari")
      return (
        <EvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaSesizari")
      return (
        <AddEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaSesizari")
      return (
        <DetailEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaSesizari")
      return (
        <EditEvidentaSesizari
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACRaportInterventie = () => {
    logger("processMainObject_GSACRaportInterventie", _mainobject, _otherdata);
    if (_mainobject == "GSACRaportInterventie")
      return (
        <RaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACRaportInterventie")
      return (
        <AddRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACRaportInterventie")
      return (
        <DetailRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACRaportInterventie")
      return (
        <EditRaportInterventie
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACRevizieTehnica = () => {
    logger("processMainObject_GSACRevizieTehnica", _mainobject, _otherdata);
    if (_mainobject == "GSACRevizieTehnica")
      return (
        <RevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACRevizieTehnica")
      return (
        <AddRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACRevizieTehnica")
      return (
        <DetailRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACRevizieTehnica")
      return (
        <EditRevizieTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACRCA = () => {
    logger("processMainObject_GSACRCA", _mainobject, _otherdata);
    if (_mainobject == "GSACRCA")
      return (
        <RCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACRCA")
      return (
        <AddRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACRCA")
      return (
        <DetailRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACRCA")
      return (
        <EditRCA
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACCASCO = () => {
    logger("processMainObject_GSACCASCO", _mainobject, _otherdata);
    if (_mainobject == "GSACCASCO")
      return (
        <CASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACCASCO")
      return (
        <AddCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACCASCO")
      return (
        <DetailCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACCASCO")
      return (
        <EditCASCO
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACDeservireTehnica = () => {
    logger("processMainObject_GSACDeservireTehnica", _mainobject, _otherdata);
    if (_mainobject == "GSACDeservireTehnica")
      return (
        <DeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACDeservireTehnica")
      return (
        <AddDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACDeservireTehnica")
      return (
        <DetailDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACDeservireTehnica")
      return (
        <EditDeservireTehnica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACEvidentaAutorizatieSanitara = () => {
    logger("processMainObject_GSACEvidentaAutorizatieSanitara", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaAutorizatieSanitara")
      return (
        <EvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaAutorizatieSanitara")
      return (
        <AddEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaAutorizatieSanitara")
      return (
        <DetailEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaAutorizatieSanitara")
      return (
        <EditEvidentaAutorizatieSanitara
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACEvidentaContor = () => {
    logger("processMainObject_GSACEvidentaContor", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaContor")
      return (
        <EvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaContor")
      return (
        <AddEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaContor")
      return (
        <DetailEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaContor")
      return (
        <EditEvidentaContor
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACEvidentaVerificareMetrologica = () => {
    logger("processMainObject_GSACEvidentaVerificareMetrologica", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaVerificareMetrologica")
      return (
        <EvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaVerificareMetrologica")
      return (
        <AddEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaVerificareMetrologica")
      return (
        <DetailEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaVerificareMetrologica")
      return (
        <EditEvidentaVerificareMetrologica
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GSACEvidentaEchipamentAntiincendiar = () => {
    logger("processMainObject_GSACEvidentaEchipamentAntiincendiar", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaEchipamentAntiincendiar")
      return (
        <EvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaEchipamentAntiincendiar")
      return (
        <AddEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaEchipamentAntiincendiar")
      return (
        <DetailEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaEchipamentAntiincendiar")
      return (
        <EditEvidentaEchipamentAntiincendiar
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACAutorizatieEmisiePoluanti = () => {
    logger("processMainObject_GSACAutorizatieEmisiePoluanti", _mainobject, _otherdata);
    if (_mainobject == "GSACAutorizatieEmisiePoluanti")
      return (
        <AutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACAutorizatieEmisiePoluanti")
      return (
        <AddAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACAutorizatieEmisiePoluanti")
      return (
        <DetailAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACAutorizatieEmisiePoluanti")
      return (
        <EditAutorizatieEmisiePoluanti
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACEvidentaDistribuitoare = () => {
    logger("processMainObject_GSACEvidentaDistribuitoare", _mainobject, _otherdata);
    if (_mainobject == "GSACEvidentaDistribuitoare")
      return (
        <EvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACEvidentaDistribuitoare")
      return (
        <AddEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACEvidentaDistribuitoare")
      return (
        <DetailEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACEvidentaDistribuitoare")
      return (
        <EditEvidentaDistribuitoare
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };

  const processMainObject_GSACTaxaComercializareBenzia = () => {
    logger("processMainObject_GSACTaxaComercializareBenzia", _mainobject, _otherdata);
    if (_mainobject == "GSACTaxaComercializareBenzia")
      return (
        <TaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "AddGSACTaxaComercializareBenzia")
      return (
        <AddTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "DetailGSACTaxaComercializareBenzia")
      return (
        <DetailTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );
    if (_mainobject == "EditGSACTaxaComercializareBenzia")
      return (
        <EditTaxaComercializareBenzia
          _setmlgenobj={handleSetGeneralData}
          _setmlmessages={handleSetMessages}
          _mlotherdata={_otherdata}
        />
      );

    return false;
  };
  const processMainObject_GestiuneSac = () => {
    var t = false;

    t = processMainObject_GestiuneSac_DashBoard();
    if (t) return t;

    t = processMainObject_GSACAparatCafea();
    if (t) return t;
    t = processMainObject_GSACCameraVideo();
    if (t) return t;
    t = processMainObject_GSACPilonIluminare();
    if (t) return t;
    t = processMainObject_GSACCorpIluminareStradal();
    if (t) return t;
    t = processMainObject_GSACMobilier();
    if (t) return t;
    t = processMainObject_GSACUtilajIncalzire();
    if (t) return t;
    t = processMainObject_GSACPunctTransformare();
    if (t) return t;
    t = processMainObject_GSACTraseuElectric();
    if (t) return t;
    t = processMainObject_GSACRegistratorVideo();
    if (t) return t;
    t = processMainObject_GSACUtilajTehnologic();
    if (t) return t;
    t = processMainObject_GSACPanouPret();
    if (t) return t;
    t = processMainObject_GSACGeneratorElectric();
    if (t) return t;
    t = processMainObject_GSACTransformatorElectric();
    if (t) return t;
    t = processMainObject_GSACPistolDistribuitor();
    if (t) return t;
    t = processMainObject_GSACAvizExpertizaEcologica();
    if (t) return t;
    t = processMainObject_GSACEvidentaDeservireTehnicaPetrol();
    if (t) return t;
    t = processMainObject_GSACEvidentaDeservireTehnicaGaz();
    if (t) return t;
    t = processMainObject_GSACEvidentaControl();
    if (t) return t;
    t = processMainObject_GSACEvidentaSesizari();
    if (t) return t;
    t = processMainObject_GSACRaportInterventie();
    if (t) return t;
    t = processMainObject_GSACRevizieTehnica();
    if (t) return t;
    t = processMainObject_GSACRCA();
    if (t) return t;
    t = processMainObject_GSACCASCO();
    if (t) return t;
    t = processMainObject_GSACDeservireTehnica();
    if (t) return t;
    t = processMainObject_GSACEvidentaAutorizatieSanitara();
    if (t) return t;
    t = processMainObject_GSACEvidentaContor();
    if (t) return t;
    t = processMainObject_GSACEvidentaVerificareMetrologica();
    if (t) return t;
    t = processMainObject_GSACEvidentaEchipamentAntiincendiar();
    if (t) return t;
    t = processMainObject_GSACAutorizatieEmisiePoluanti();
    if (t) return t;
    t = processMainObject_GSACEvidentaDistribuitoare();
    if (t) return t;
    t = processMainObject_GSACTaxaComercializareBenzia();
    if (t) return t;
    return t;
  };

  const processMainObject = () => {
    var t = false;

    t = processMainObject_GestiuneSac();
    if (t) return t;

    t = processMainObject_User();
    if (t) return t;

    t = processMainObject_UserSac();
    if (t) return t;

    t = processMainObject_Roles();
    if (t) return t;

    t = processMainObject_Jurnal();
    if (t) return t;

    t = processMainObject_RolesACL();
    if (t) return t;

    t = processMainObject_BunImobil();
    if (t) return t;

    t = processMainObject_TransportAuto();
    if (t) return t;

    t = processMainObject_TransportAutoCASCOLink();
    if (t) return t;
    
    t = processMainObject_TransportAutoDocumentLink();
    if (t) return t;

    t = processMainObject_TransportAutoRCALink();
    if (t) return t;
    
    t = processMainObject_TransportAutoRevizieTehnicaLink();
    if (t) return t;
    
    t = processMainObject_TransportAutoDeservireTehnicaLink();
    if (t) return t;

    t = processMainObject_DosarSAC();
    if (t) return t;

    t = processMainObject_PilonIluminare();
    if (t) return t;

    t = processMainObject_Mobilier();
    if (t) return t;

    t = processMainObject_UtilajIncalzire();
    if (t) return t;

    t = processMainObject_CameraVideo();
    if (t) return t;

    t = processMainObject_PunctTransformare();
    if (t) return t;

    t = processMainObject_CorpIluminareStradal();
    if (t) return t;

    t = processMainObject_Street();
    if (t) return t;

    t = processMainObject_PersoanaFizica();
    if (t) return t;

    t = processMainObject_PersoanaJuridica();
    if (t) return t;

    t = processMainObject_Banca();
    if (t) return t;

    t = processMainObject_Document();
    if (t) return t;

    t = processMainObject_RCA();
    if (t) return t;

    t = processMainObject_CASCO();
    if (t) return t;
    
    t = processMainObject_AvizExpertizaEcologica();
    if (t) return t;
    t = processMainObject_AutorizatieEmisiePoluanti();
    if (t) return t;
    t = processMainObject_EvidentaControl();
    if (t) return t;
    t = processMainObject_EvidentaSesizari();
    if (t) return t;
    t = processMainObject_RaportInterventie();
    if (t) return t;
    t = processMainObject_EvidentaAutorizatieSanitara();
    if (t) return t;
    t = processMainObject_EvidentaContor();
    if (t) return t;
    t = processMainObject_EvidentaVerificareMetrologica();
    if (t) return t;
    t = processMainObject_EvidentaEchipamentAntiincendiar();
    if (t) return t;
    t = processMainObject_EvidentaDistribuitoare();
    if (t) return t;
    t = processMainObject_EvidentaDeservireTehnicaGaz();
    if (t) return t;
    t = processMainObject_EvidentaDeservireTehnicaPetrol();
    if (t) return t;
    t = processMainObject_TaxaComercializareBenzia();
    if (t) return t;
    t = processMainObject_RevizieTehnica();
    if (t) return t;

    t = processMainObject_DeservireTehnica();
    if (t) return t;

    t = processMainObject_TypeFunctie();
    if (t) return t;

    t = processMainObject_TypeDistribuitoare();
    if (t) return t;

    t = processMainObject_TypePistolPompa();
    if (t) return t;

    t = processMainObject_TypeNumarCanaleRegistratoare();
    if (t) return t;

    t = processMainObject_TypeMobilier();
    if (t) return t;

    t = processMainObject_TypeIndicePrezentaSSG();
    if (t) return t;

    t = processMainObject_TypeMaterial();
    if (t) return t;

    t = processMainObject_TypeInventarGospodaresc();
    if (t) return t;

    t = processMainObject_TypeEchipamentAntiincendiar();
    if (t) return t;

    t = processMainObject_TypeUtilajTehnologic();
    if (t) return t;

    t = processMainObject_TypeDestinatieMCC();
    if (t) return t;

    t = processMainObject_TraseuElectric();
    if (t) return t;

    t = processMainObject_RegistratorVideo();
    if (t) return t;

    t = processMainObject_AparatCafea();
    if (t) return t;

    t = processMainObject_UtilajTehnologic();
    if (t) return t;

    t = processMainObject_PanouPret();
    if (t) return t;

    t = processMainObject_GeneratorElectric();
    if (t) return t;

    t = processMainObject_TransformatorElectric();
    if (t) return t;

    t = processMainObject_PistolDistribuitor();
    if (t) return t;

    t = processMainObject_TypePunctTransformare();
    if (t) return t;

    t = processMainObject_TypeTraseu();
    if (t) return t;

    t = processMainObject_TypePhone();
    if (t) return t;

    t = processMainObject_TypeReteaElectricaExterna();
    if (t) return t;

    t = processMainObject_TypeCameraVideo();
    if (t) return t;

    t = processMainObject_TypePanouPret();
    if (t) return t;

    t = processMainObject_TypeRobinetAutomat();
    if (t) return t;


    t = processMainObject_TypeSetarePanouPret();
    if (t) return t;

    t = processMainObject_TypeSistemCasa();
    if (t) return t;

    t = processMainObject_TypeMontare();
    if (t) return t;

    t = processMainObject_TypeIluminare();
    if (t) return t;

    t = processMainObject_TypePilon();
    if (t) return t;

    t = processMainObject_TypeUtilajIncalzire();
    if (t) return t;

    t = processMainObject_TypeCorp();
    if (t) return t;
    t = processMainObject_TypeMotivInterventie();
    if (t) return t;
    t = processMainObject_TypeInterventie();
    if (t) return t;

    t = processMainObject_TypeCarosabil();
    if (t) return t;

    t = processMainObject_TypeModelAuto();
    if (t) return t;

    t = processMainObject_TypeAuto();
    if (t) return t;

    t = processMainObject_TypeBrand();
    if (t) return t;

    t = processMainObject_TypeCombustibil();
    if (t) return t;

    t = processMainObject_TypeMarcaAuto();
    if (t) return t;

    t = processMainObject_TypeDocument();
    if (t) return t;

    t = processMainObject_TypeUM();
    if (t) return t;

    t = processMainObject_TypeBunImobil();
    if (t) return t;

    t = processMainObject_TypeSex();
    if (t) return t;

    t = processMainObject_TypeStudii();
    if (t) return t;

    t = processMainObject_TypeFolosinta();
    if (t) return t;

    t = processMainObject_TypeHotar();
    if (t) return t;

    t = processMainObject_Label();
    if (t) return t;

    return <HomePage />;
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MyAppBar
          _setmlgenobj={handleSetGeneralData}
          _otherdata={_otherdata}
          _currentpath={_currentpath}
          _mainobject={_mainobject}
        />
        <Box sx={{ display: "flex", flexDirection: "row" }} W>
          <MyMainDrawer
            _setmlgenobj={handleSetGeneralData}
            _otherdata={_otherdata}
            _currentpath={_currentpath}
            _mainobject={_mainobject}
            DrawerHeader={DrawerHeader}
          />
          <MySecondDrawer
            _setmlgenobj={handleSetGeneralData}
            _otherdata={_otherdata}
            _currentpath={_currentpath}
            _mainobject={_mainobject}
            DrawerHeader={DrawerHeader}
          />
        </Box>
        <Box key={componenthash + 'id'} component="main" sx={{ flexGrow: 1, pt: 3, pr: 3, pb: 3, pl: 3 }}>
          <DrawerHeader />
          {processMainObject()}
          <FooterPart />
        </Box>
      </Box>

      {processMessages()}
    </>
  );
};

export { MainLayout };
