import { FetchGet, FetchPost } from "api/RequestTools";

import { TypeBrandDTO } from "dto/nomenclatoare/TypeBrandDTO";
import { TypeCarosabilDTO } from "dto/nomenclatoare/TypeCarosabilDTO";
import { StatusDTO } from "dto/core/StatusDTO";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class DosarSACDTO {
  

  getMainObjPart() { return "DosarSAC"; }
  getFieldsInt() {
    return [
      "id",
      "idtypebrand",
      "idtypecarosabil",
      "dataconstructieint",
      "datareconstructieint",
      "status",
      "dataconstructiemicrotime",
      "datareconstructiemicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
      "dataconstructie_text",
      "dataconstructie_text",
      "datareconstructie_text",
      "datareconstructietime_text",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return ["documentids",];
  }

  getFieldsStrArray() {
    return ["document"];
  }
  
  getFieldsDTOObjects() {
    return [
      { field: "typebrandobj", _key: "TypeBrand" },
      { field: "typecarosabilobj", _key: "TypeCarosabil" },
      { field: "statusobj", _key: "Status" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "documentamplasarelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentamplasareobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentamplasareids",
        mainobjidparam: "iddocument",
      },
      {
        field: "documentexcluderelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentexcludereobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentexcludereids",
        mainobjidparam: "iddocument",
      },
    ];
  }
  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    return this.denumire;
  }
  
  GetListHeadCells(LL, otherdata) {
  
    const _headCells = [
      {
        id: "denumire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Denumire"),
      },
      {
        id: "idtypebrand",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypebrand"),
      },
      {
        id: "idtypecarosabil",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypecarosabil"),
      },
      {
        id: "dataconstructieint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_dataconstructieint"),
      },
      {
        id: "datareconstructieint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_datareconstructieint"),
      },
      {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: LL("TD_status"),
      },
    ];

    return _headCells;
  }

}
