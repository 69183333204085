import React from "react";

import uuid from "react-uuid";

import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { MultiInputItemType } from "components/elements/MultiInput/MultiInputItemType";

import { Loading } from "components/general/Loading";

import { TypePhoneDTO } from "dto/nomenclatoare/TypePhoneDTO";

import { DTOTools } from "api/DTOTools";

import { logger } from "hoc/logger";

const MultiInputPhone = ({
  label,
  typelabel,
  setObj,
  obj,
  defaultobjdto,
  _fieldkey,
  ...props
}) => {
  const { LL } = useResource(false);

  var tfieldkey = _fieldkey != undefined ? _fieldkey : "phones";
  var tobj = obj != undefined ? obj : {};

  const [typedefaultobjdto, setTypeDefaultObjDTO] = React.useState(
    new TypePhoneDTO()
  );

  const [mainObj, setMainObj] = useState(tobj);

  const [fieldkey, setFieldKey] = useState(tfieldkey);

  const [isLoading, setIsLoading] = useState(true);
  const [minelems, setMinElems] = useState(1);
  const [maxelems, setMaxElems] = useState(-1);

  const [types, setTypes] = useState([]);
  const [elements, setElements] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    DTOTools.execLoadObjects(
      typedefaultobjdto,
      false,
      false,
      false,
      false,
      false,
      0,
      1000,
      setIsLoading,
      setTypes
    );
  }, []);

  useEffect(() => {
    SetIntialObjects();
    setIsLoading(false);
  }, [types]);

  useEffect(() => {
    ExecCallBack();
  }, [values, elements]);

  const ExecCallBack = () => {
    if (!setObj) return;
    if (setObj == undefined) return;
    if (setObj == "undefined") return;

    var elem = values.filter((n) => n);
    var objects = elements.filter((n) => n.phone);
    
    mainObj[fieldkey] = objects;
    setObj(mainObj);
  };

  const SetIntialObjects = () => {
    // -----------------------------
    var mie = 1;
    if (props.min != undefined && props.min && props.min != null) {
      mie = parseInt(props.min);
    }
    if (isNaN(mie) || mie < 1) mie = 1;
    setMinElems(mie);
    // -----------------------------

    // -----------------------------
    var mae = -1;
    if (props.max != undefined && props.max && props.max != null) {
      mae = parseInt(props.max);
    }
    if (isNaN(mae) || mae < -1) mae = -1;
    setMaxElems(mae);
    // -----------------------------

    var objects = [];

    if (mainObj[fieldkey] != undefined)
    {
      if (Array.isArray(mainObj[fieldkey])) {
        objects = mainObj[fieldkey];
      } else if (typeof mainObj[fieldkey] === "object") {
        objects[objects.length] = mainObj[fieldkey];
      }
    }

    while (objects.length < mie) {
      objects[objects.length] = {};
    }
    
    var telems = [];

    for (var i in objects) {
      var obj = {};
      obj.key = objects[i].uuid ? objects[i].uuid : uuid();
      obj.id = objects[i].id ? objects[i].id : 0;
      obj.phone = objects[i].phone ? objects[i].phone : "";
      obj.idtypephone = objects[i].idtypephone ? objects[i].idtypephone : 0;

      telems[telems.length] = obj;

      AddElement(objects[i]);
    }
    setElements(telems);
  };

  const SetValue = (_key, _value, _idtype) => {
    var elems = elements;
    var vals = [];

    for (var i in elems) {
      if (elems[i].key != _key) {
        vals[vals.length] = elems[i].phone;
        continue;
      }

      vals[vals.length] = _value;
      elems[i].phone = _value;
      elems[i].idtypephone = _idtype;
      // break;
    }
    setElements(elems);
    setValues(vals);
  };

  const DeleteElement = (_key) => {
    if (elements.length <= 1) return;
    setIsLoading(true);

    var elem = [];
    var elems = elements;
    for (var i in elems) {
      if (elems[i].key == _key) continue;

      elem[elem.length] = elems[i];
    }

    setElements(elem);
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
  };

  const AddElement = (_obj) => {
    setIsLoading(true);
    if (_obj == undefined) {
      var obj = { key: uuid(), id: 0, phone: "", idtypephone: 0 };
    } else {
      var obj = {};
      obj.key = _obj.key != undefined ? _obj.key : uuid();
      obj.id = _obj.id != undefined ? _obj.id : 0;

      obj.phone = _obj.phone ? _obj.phone : "";
      obj.idtypephone = _obj.idtypephone ? _obj.idtypephone : 0;
    }

    var elems = elements;
    elems[elems.length] = obj;

    setElements(elems);
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
  };

  const processItem = (item, i) => {
    var islast = i == elements.length - 1 ? true : false;
    return (
      <MultiInputItemType
        key={item.key}
        _key={item.key}
        islast={islast}
        _value={item.phone}
        _idtype={item.idtypephone}
        min={minelems}
        max={maxelems}
        _label={label}
        _typelabel={typelabel}
        _types={types}
        cbsetvalue={SetValue}
        cbdeleteelement={DeleteElement}
        cbaddelement={AddElement}
        _totalelement={elements.length}
      />
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {elements.map((item, i) => {
        return processItem(item, i);
      })}
    </>
  );
};

export { MultiInputPhone };
