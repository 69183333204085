import React from "react";

import { useEffect, useState } from "react";

import { Loading } from "components/general/Loading";

import { useResource } from "hook/useResource";

import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

import { UserSacDTO } from "dto/user/UserSacDTO";

import { logger } from "hoc/logger";

import { DTOTools } from "api/DTOTools";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { useAuth } from "hook/useAuth";

import uuid from "react-uuid";

const SacMenu = ({ open, _mainobject, _setmlgenobj, _otherdata, ...props }) => {
  const { user } = useAuth(false);
  const { LL, HASACL_METHOD } = useResource(false);

  let [isLoading, setIsLoading] = useState(false);

  const [openMainDrawer, setOpenMainDrawer] = useState(open);
  const [objects, setObjects] = useState([]);
  
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new UserSacDTO());

  useEffect(() => {
    DTOTools.execLoadObjects(
      defaultobjdto,
      false,
      { iduser: user.id },
      false,
      false,
      false,
      false,
      500,
      setIsLoading,
      setObjects
    );
  }, [user]);

  // -----------------------------------------

  const processMenuItem = (obj, i) => {
    var key = "main_menu_sac_" + i;
    var selected =
      _otherdata != undefined &&
      _otherdata.uuidsac != undefined &&
      _otherdata.uuidsac == obj.sacobj.uuid
        ? true
        : false;

    logger("processMenuItem4444333322", _otherdata);

    // _otherdata;
    return (
      <MyNavBt
        href={"/gestiunesac/" + obj.sacobj.uuid + "/dashboard"}
        className={"menuPrimary"}
        _setmlgenobj={_setmlgenobj}
      >
        <Tooltip key={key} title={obj.sacobj.denumire} placement="bottom">
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: openMainDrawer ? "initial" : "center",
                px: 2.5,
              }}
              className={selected ? "menuPrimaryActive" : "menuPrimary"}
            >
              <LocalGasStationIcon />
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: openMainDrawer ? "initial" : "center",
                  justifyContent: "center",
                }}
              ></ListItemIcon>
              <ListItemText
                className="menuPrimaryText"
                primary={obj.sacobj.denumire}
              />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </MyNavBt>
    );
  };

  const processMenuList = () => {
    return (
      <List>
        {objects.map((obj, i) => {
          return processMenuItem(obj, i);
        })}
      </List>
    );
  };

  return isLoading ? <Loading /> : <List>{processMenuList()}</List>;
};

export { SacMenu };