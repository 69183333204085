import { React, useState, useRef, useMemo, useCallback } from "react";
import "./leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
// import covidData from "./data.json";
// import icon1 from "./images/covid19.svg";

import L from "leaflet";

import { logger } from "hoc/logger";

import { Loading } from "components/general/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MapOSShow = ({ _lat, _zoom, _lng, _place_id, _label, ...props }) => {
  
  _lat = _lat != undefined ? _lat : false;
  _lng = _lng != undefined ? _lng : false;
  _zoom = _zoom != undefined ? _zoom : 13;

  _label = _label != undefined ? _label : "Map OS";

  var markerIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/imagesosm/marker-icon.png",
    iconRetinaUrl: process.env.PUBLIC_URL + "/imagesosm/marker-icon.png",
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 55],
  });

  const [center, setCenter] = useState({
    lat: _lat,
    lng: _lng,
  });

  return (
    <>
      <MapContainer center={center} zoom={_zoom} style={{ height: "400px" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <Marker position={[51.505, -0.09]} icon={L.Icon.Default._getIconUrl()}> */}
        <Marker position={center} icon={markerIcon}/>
      </MapContainer>
    </>
  );
};
export default MapOSShow;
