import React from "react";

import { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";


import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import TextField from "@mui/material/TextField";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import DateObject from "react-date-object";
import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";

const MyDateRangePickerShow = ({ cb, filterobj, ...props }) => {
  
  const { LL } = useResource(false);
  
  var _startdate =
    filterobj &&
    filterobj.startdate != undefined &&
    filterobj.startdate &&
    filterobj.startdate != -1
      ? filterobj.startdate * 1000
      : "";
  var _enddate =
    filterobj &&
    filterobj.enddate != undefined &&
    filterobj.enddate &&
    filterobj.enddate != -1
      ? filterobj.enddate * 1000
      : "";
 
  var _label = filterobj && filterobj.label != undefined ? filterobj.label : "";
  var _labelfrom =
    filterobj && filterobj.labelfrom != undefined
      ? filterobj.labelfrom
      : LL("De la");
  var _labelto =
    filterobj && filterobj.labelto != undefined
      ? filterobj.labelto
      : LL("Pina la");

  const [filter, setFilter] = useState(filterobj);

  const [startdate, setStartDate] = useState(_startdate);
  const [enddate, setEndDate] = useState(_enddate);
  const [label, setLabel] = useState(_label);
  const [labelfrom, setLabelFrom] = useState(_labelfrom);
  const [labelto, setLabelTo] = useState(_labelto);

  logger("MyDateRangePickerShow___1111", filterobj);

  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    cb(filter);
  };

  const showValue = () => {
    var startdateshow = new DateObject({
      date: startdate,
      format: "DD.MM.YYYY",
    });
    var enddateshow = new DateObject({
      date: enddate,
      format: "DD.MM.YYYY",
    });
    return (
      <>
        {startdateshow.format()} - {enddateshow.format()}
      </>
    );
  };

  return ( !startdate && !enddate) ? <></> : (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
        {label}:
      </Typography>
      <Typography sx={{ marginRight: 1 }}>{showValue()}</Typography>

      <IconButton
        color="primary"
        aria-label="close"
        component="span"
        size="small"
        sx={{ marginRight: 3 }}
        onClick={() => {
          ExecCallBack();
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export { MyDateRangePickerShow };
