import { FetchGet, FetchPost } from "api/RequestTools";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";
import { PersoanaJuridicaLinkDTO } from "dto/nomenclatoare/PersoanaJuridicaLinkDTO";
import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";
import { FilesDTO } from "dto/files/FilesDTO";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class CASCODTO {


  getMainObjPart() { return "CASCO"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idpersoanajuridica",
      "idscan",
      "dataint",
      "dataexpirareint",
      "datamicrotime",
      "dataexpiraremicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "data_text",
      "datatime_text",
      "dataexpirare_text",
      "dataexpiraretime_text"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      "persoanajuridicaids",
    ];
  }

  getFieldsStrArray() {
    return [
      "persoanajuridica",
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "persoanajuridicaobj", _key: "PersoanaJuridica" },
      { field: "sacobj", _key: "DosarSAC" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "persoanajuridicalinkobjects",
        _key: "PersoanaJuridicaLink",
        mainobjfield: "persoanajuridicaobjects",
        mainobjkey: "PersoanaJuridica",
        mainobjobjparam: "persoanajuridicaobj",
        mainidfield: "persoanajuridicaids",
        mainobjidparam: "idpersoanajuridica",
      }

    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   


  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.data_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
        otherdata.uuidsac != undefined &&
        otherdata.uuidsac
        ? otherdata.uuidsac
        : false;
    var _headCells = [];

    _headCells[_headCells.length] = 
      {
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_CASCO"),
      };
      _headCells[_headCells.length] ={
        id: "idpersoanajuridica",
        numeric: false,
        disablePadding: true,
        label: LL("TD_persoanajuridica"),
      };
      _headCells[_headCells.length] ={
        id: "dataint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
        };
        _headCells[_headCells.length] ={
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expiration data"),
      };
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of casco"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }

    return _headCells;
  }

}
