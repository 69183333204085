import React from "react";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { logger } from "hoc/logger";

import { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";

import uuid from "react-uuid";

import FormControl from '@mui/material/FormControl';

const MultiInputItemType = ({
  _key,
  _value,
  _idtype,
  _label,
  _typelabel,
  min,
  max,
  islast,
  cbsetvalue,
  cbdeleteelement,
  cbaddelement,
  _totalelement,
  _types,
  ...props
}) => {
  
  const [lkey, setLKey] = useState(uuid());
  const [lid, setLId] = useState(uuid());
  const [value, setValue] = useState(_value);
  const [idtype, setIdType] = useState(_idtype);
  const [label, setLabel] = useState(_label);
  const [typelabel, setTypeLabel] = useState(_typelabel);
  const [objects, setObjects] = useState(_types);
  const [infocus, setInFocus] = useState(false);

  useEffect(() => {
    cbsetvalue(_key, value, idtype);
  }, [value, idtype]);

  const handleAddElement = () => {
    logger("MultiInputItemType handleAddElement");
    cbaddelement();
  };

  const handleDeleteElement = () => {
    logger("MultiInputItemType handleDeleteElement");
    cbdeleteelement(_key);
  };

  const handleFocus = () => {
    logger("MultiInputItemType handleFocus");
    setInFocus(true);
  };

  const handleBlur = () => {
    logger("MultiInputItemType handleBlur");
    setTimeout(function () {
      setInFocus(false);
    }, 200);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    logger("setIdType " + e.target.value);
    setIdType(e.target.value);
  };

  const processDeleteBTN = () => {
    logger("MultiInputItemType processDeleteBTN");
    if (_totalelement <= min) {
      return <></>;
    }
    if (!infocus) {
      return <></>;
    }

    return (
      <IconButton
        className="btnMultiItem"
        size="small"
        color="primary"
        aria-label="remove"
      >
        <RemoveIcon
          className="removeBtn"
          fontSize="small"
          onClick={handleDeleteElement}
        />
      </IconButton>
    );
  };

  const processPlusBTN = () => {
    logger("MultiInputItemType processPlusBTN");
    if ((_totalelement >= parseInt(max) && parseInt(max) != -1) || !islast) {
      return <></>;
    }

    if (!infocus) {
      return <></>;
    }

    if (!value) {
      return <></>;
    }

    return (
      <IconButton
        className="btnMultiItem"
        size="small"
        onClick={handleAddElement}
        color="secondary"
        aria-label="add"
      >
        <AddIcon
          className="addBtn"
          fontSize="small"
          sx={{ borderRadius: "50%", border: 2 }}
        />
      </IconButton>
    );
  };

  const processItem = (item, i) => {
    var key = "chils_" + item.id;
    

    var selected = (item.id == idtype) ? true : false;
    logger("MultiInputItemTypekey: ", item, idtype, selected);
    if (selected) logger("setIdType selected ", item.id, item.getLabel(), idtype);

    return (
      <MenuItem
        key={key}
        value={item.id}
        item={item}
        selected={selected ? true : false}
      >
        {item.getLabel()}
      </MenuItem>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <TextField
          type="text"
          fullWidth
          label={label}
          color="secondary"
          size="small"
          margin="normal"
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          variant="standard"
          sx={{ width: "65%" }}
        />
        <FormControl
          variant="standard"
          color="secondary"
          sx={{ width: "35%", marginLeft: 2, marginTop: "5px" }}
        >
          <InputLabel id={lkey}>{typelabel}</InputLabel>
          <Select
            onChange={handleSelectChange}
            labelId={lkey}
            id={lid}
            value={idtype}
            label={typelabel}
          >
            <MenuItem value={""}></MenuItem>

            {objects.map((item, i) => {
              return processItem(item, i);
            })}
          </Select>
        </FormControl>
        {processDeleteBTN()}
        {processPlusBTN()}
      </Box>
    </>
  );
};

export { MultiInputItemType };
