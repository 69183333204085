import React from "react";

import uuid from "react-uuid";

import { useEffect, useState } from "react";

import { LocationDTO } from "dto/location/LocationDTO";

import { Loading } from "components/general/Loading";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { logger } from "hoc/logger";


import { useResource } from "hook/useResource";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { DTOTools } from "api/DTOTools";

const LocationFullSelect = ({
  cb,
  setloaded,
  parentcode,
  level,
  selectedcodes,
  ...props
}) => {
  
  const { LL } = useResource(false);
  
  setloaded = setloaded == undefined ? false : setloaded;
  selectedcodes = selectedcodes == undefined ? [] : selectedcodes;
  parentcode = parentcode == undefined ? 0 : parentcode;
  level = level == undefined ? 1 : level;
  level = parseInt(level);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadedLocation, setIsLoadedLocation] = useState(false);

  const [value, setValue] = React.useState("");
  const [isfounded, setIsFounded] = React.useState(-1);
  const [pc, setParentCode] = React.useState(parentcode);
  const [objects, setObjects] = React.useState([]);
  const [childs, setChilds] = React.useState([]);

  const [scodes, setSCodes] = React.useState([]);
  
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new LocationDTO());
  const [otherdata, setOtherData] = React.useState({});

  const lid = uuid();
  const lkey = uuid();
  const nextlevel = level + 1;

  useEffect(() => {
    if (selectedcodes == undefined || !selectedcodes) {
      selectedcodes = [];
    }

    var val = "";

    if (selectedcodes.length >= level) {
      if (level == 1) {
        val = selectedcodes[level - 1];
        var tobj = { code: val };
        setValue(tobj);
      } else if (selectedcodes[level - 2] == pc) {
        val = selectedcodes[level - 1];
        var tobj = { code: val };
        setValue(tobj);
      } else {
        setValue("");
      }
    } else {
      setValue("");
    }

    if (!val) {
      var t = [];
      for (var i in selectedcodes) {
        t[t.length] = selectedcodes[i];
        if (t.length >= level) break;
      }
      setSCodes(t);
    } else {
      setSCodes(selectedcodes);
    }
  }, [pc]);

  useEffect(() => {
    LoadData();
  }, [pc]);

  useEffect(() => {
    ExecCallBack();
  }, [value, childs]);

  useEffect(() => {
    if (!objects) return;
    if (!objects.length) return;
    if (!value) return;
    if (!value.code) return;
    if (value.name) return;

    for (var i in objects) {
      if (objects[i].code != value.code) continue;
      setValue(objects[i]);
      break;
    }
  }, [objects]);

  useEffect(() => {
    if (!objects.length) setIsFounded(0);
    if (!objects.value) setIsFounded(0);

    var founded = 0;
    for (var i in objects) {
      if (objects[i].code != value) continue;

      setIsFounded(1);
      founded = 1;
      break;
    }

    if (!founded) setIsFounded(1);
  }, [value, objects]);

  useEffect(() => {
    logger("LocationFullSelect_11111111111_useEffect", scodes, value);
    if (scodes.length < level) return;

    logger("LocationFullSelect_11111111111_useEffect_2", scodes, value);

    var t = scodes;
    t[level - 1] = value ? value.code : "";
    setSCodes(t);
  }, [value]);

  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    let tarr = [value, ...childs];

    var rez = [];
    var exist = false;
    for (var i in tarr) {
      if (!tarr[i] && exist) continue;
      if (!exist && tarr[i]) exist = true;
      rez[rez.length] = tarr[i];
    }
    // tarr = tarr.filter((n) => n);

    cb(rez);
  };

  const handleChange = (event) => {
    var t = scodes;
    if (t.length >= level) {
      var t1 = [];
      for (var i in t) {
        if (i >= level - 1) break;
        t1[t1.length] = t[i];
      }
      setSCodes(t1);
    }

    setValue(event.target.value);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
  };

  const saveChilds = (values) => {
    setChilds(values);
  };

  useEffect(() => {
    if (!setloaded) return;
    if (setloaded == undefined) return;

    setloaded(isLoadedLocation);
  }, [isLoadedLocation]);

  useEffect(() => {
    if (!objects || !objects.length) {
      setIsLoadedLocation(true);
      return;
    }

    setIsLoadedLocation(false);
  }, [objects]);

  const setLoadedObj = (val) => {
    setIsLoadedLocation(val);
  };

  // const handleSaveData = (data, params, err) => {
  //   if (err) {
  //     return;
  //   }

  //   var items = [];
  //   try {
  //     for (var i in data.objects) {
  //       var tobj = new LocationDTO(data.objects[i]);
  //       items[items.length] = tobj;
  //     }
  //   } catch (err) {}

  //   setObjects(items);
  //   // ExecCallBack();
  //   setIsLoading(false);
  //   if (!items.length)
  //   {
  //     setIsLoadedLocation(true);
  //   } else
  //   {
  //     setIsLoadedLocation(false);
  //   }
  // };

  const LoadData = () => {
    if (pc == -1) {
      setIsLoading(false);
      return;
    }

    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      { parentcode: pc },
      false,
      false,
      false,
      0,
      2000,
      setIsLoading,
      setObjects,
      false
    );
  };

  const LoadChilds = () => {
    if (value == "") {
      return <></>;
    }

    return (
      <>
        <LocationFullSelect
          parentcode={value.code}
          cb={saveChilds}
          setloaded={setLoadedObj}
          level={nextlevel}
          selectedcodes={scodes}
        />
      </>
    );
  };

  const processItem = (item, i) => {
    var key = "locationoption_" + item.code;

    var selected = false;
    if (selectedcodes.length >= level) {
      if (item.code == selectedcodes[level - 1]) {
        selected = true;
      }
    }

    return (
      <MenuItem
        key={key}
        value={item}
        item={item}
        selected={selected ? true : false}
      >
        {item.name}
      </MenuItem>
    );
  };

  const GetLocationLevel = () => {
    if (level == 1) return LL("Raion");
    if (level == 2) return LL("Localitate / Oras / Comuna");
    if (level == 3) return LL("Localitate / sector / Sat");
    return "Localitate";
  };

  return isLoading ? (
    <Loading />
  ) : pc != -1 && objects.length ? (
    <>
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel id={lkey}>{GetLocationLevel()}</InputLabel>
        <Select
          labelId={lkey}
          id={lid}
          value={value}
          label={LL("Select location")}
          onChange={handleChange}
          color="secondary"
          variant="standard"
        >
          <MenuItem value={""}>{LL("Select")}</MenuItem>

          {objects.map((item, i) => {
            return processItem(item, i);
          })}
        </Select>
      </FormControl>

      {LoadChilds()}
    </>
  ) : (
    <></>
  );
};

export { LocationFullSelect };
