import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeBunImobilDTO } from "dto/nomenclatoare/TypeBunImobilDTO";

const TypeBunImobil = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeBunImobilDTO()
  );

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};;

export { TypeBunImobil };
