import React from "react";

import { useEffect, useState } from "react";
import { GenAutocompleteSimple } from "components/elements/Autocomplete/GenAutocompleteSimple";

import { useResource } from "hook/useResource";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";
import { logger } from "hoc/logger";

const StreetAutocomplete = ({
  obj,
  setObj,
  _setCount,
  _hideOnEmpty,
  _idfield,
  _objfield,
  _label,
  _otherdata,
  ...props
}) => {
  logger("StreetAutocomplete122333", _idfield, _otherdata);
  const { LL } = useResource(false);

  const [label, setLabel] = useState("");

  var thideOnEmpty = _hideOnEmpty != undefined ? _hideOnEmpty : false;
  var _tsetCount = _setCount != undefined ? _setCount : false;
  var tidfield = _idfield != undefined ? _idfield : "idstreet";
  var tobjfield = _objfield != undefined ? _objfield : "streetobj";

  const [hideOnEmpty, setHideOnEmpty] = useState(thideOnEmpty);
  const [tsetCount, setCount] = useState(_tsetCount);
  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new StreetDTO());
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label]);

  return (
    <GenAutocompleteSimple
      obj={obj}
      setObj={setObj}
      _hideOnEmpty={hideOnEmpty}
      _setCount={tsetCount}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _showfield="name"
      _otherdata={otherdata}
    />
  );
};

export { StreetAutocomplete };
  
