
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class EvidentaDeservireTehnicaPetrolDTO {


  getMainObjPart() { return "EvidentaDeservireTehnicaPetrol"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datavalabilitateavizint",
      "dataverificarestatutint",
      "dataexploatareint",
      "dataeeint",
      "isexpirat",
      "idtypemontare",
      "dataexpirareint",
      "isconservat",
      "dataconservareint",
      "dataverificareizolaregazoductint",
      "semnalizatorgaze",
      
      "datavalabilitateavizmicrotime",
      "dataverificarestatutmicrotime",
      "dataexploataremicrotime",
      "dataexpiraremicrotime",

      "dataconservaremicrotime",
      "dataverificareizolaregazoductmicrotime",
      "dataeemicrotime",

      "datadefectoscopieint",
      "datadefectoscopiemicrotime",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",

      "datavalabilitateaviz_text",
      "datavalabilitateaviztime_text",

      "dataverificarestatut_text",
      "dataverificarestatuttime_text",

      "dataexploatare_text",
      "dataexploataretime_text",

      "dataexpirare_text",
      "dataexpiraretime_text",

      "dataconservare_text",
      "dataconservaretime_text",

      "dataverificareizolaregazoduct_text",
      "dataverificareizolaregazoducttime_text",

      "dataee_text",
      "dataeetime_text",

      "organcontrol",
      "lastcontrol",
      "planedcontrol",
      
      "protectiecorozie",
      "verificareizolaregazoduct",
      "anfabricare",

      "nrrezervoare",
      "nrrezervoareinutilizare",
      "voluminutilizare",
      "aninstalare",
      "haspasaportuzina",
      "pasaportuzina",
      "termenexploatare",

      "hasvalabiltabelcalibrare",
      "valabiltabelcalibrare",

      "hasvalabilavizexpertiza",
      "valabilavizexpertiza",

      "datadefectoscopie_text",
      "datadefectoscopietime_text"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "typemontareobj", _key: "TypeMontare" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   


  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.datainregistrare_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];
  _headCells[_headCells.length] = {
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_evidentadeserviretehnicapetrol"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "idtypemontare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypemontare"),
      };
      _headCells[_headCells.length] ={
        id: "datavalabilitateavizint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Valabilitateaviz data"),
      };
      _headCells[_headCells.length] ={
        id: "dataverificarestatutint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Verificarestatut data"),
      };
      _headCells[_headCells.length] ={
        id: "dataexploatareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Exploatare data"),
      };
     
      _headCells[_headCells.length] ={
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expirare data"),
      };
      _headCells[_headCells.length] ={
        id: "dataconservareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Conservare data"),
      };
     
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of evidentadeserviretehnicapetrol"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "organcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_organcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "lastcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_lastcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "planedcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_planedcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "isexpirat",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isexpirat"),
      };
      _headCells[_headCells.length] ={
        id: "isconservat",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isconservat"),
      };
      _headCells[_headCells.length] ={
        id: "anfabricare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_anfabricare"),
      }
      
      
      _headCells[_headCells.length] ={
        id: "datadefectoscopieint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_defectoscopie"),
      };
      _headCells[_headCells.length] ={
        id: "aninstalare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_aninstalare"),
      };
      _headCells[_headCells.length] ={
        id: "nrrezervoare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_nrrezervoare"),
      };
      _headCells[_headCells.length] ={
        id: "nrrezervoareinutilizare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_nrrezervoareinutilizare"),
      };
      _headCells[_headCells.length] ={
        id: "voluminutilizare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_voluminutilizare"),
      };
      _headCells[_headCells.length] ={
        id: "haspasaportuzina",
        numeric: false,
        disablePadding: true,
        label: LL("TD_haspasaportuzina"),
      }
      _headCells[_headCells.length] ={
        id: "pasaportuzina",
        numeric: false,
        disablePadding: true,
        label: LL("TD_pasaportuzina"),
      };
      _headCells[_headCells.length] ={
        id: "termenexploatare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_termenexploatare"),
      };
      _headCells[_headCells.length] ={
        id: "hasvalabiltabelcalibrare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_hasvalabiltabelcalibrare"),
      };
      _headCells[_headCells.length] ={
        id: "valabiltabelcalibrare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_valabiltabelcalibrare"),
      };
      _headCells[_headCells.length] ={
        id: "hasvalabilavizexpertiza",
        numeric: false,
        disablePadding: true,
        label: LL("TD_hasvalabilavizexpertiza"),
      };
      _headCells[_headCells.length] ={
        id: "valabilavizexpertiza",
        numeric: false,
        disablePadding: true,
        label: LL("TD_valabilavizexpertiza"),
      };
    

    return _headCells;
  }

}
