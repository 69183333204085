

import React from "react";

import { useEffect, useState, useMemo } from "react";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { MainLayout } from "components/core/MainLayout/MainLayout";
import { UserPage } from "components/user/UserPage/UserPage";

import { useResource } from "hook/useResource";

import uuid from "react-uuid";

const HomePage = () => {
  
  const { LL } = useResource(false);
  
  let [isLoading, setIsLoading] = useState(false);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") }
  ];

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} />
      <UserPage/>
    </>
  );
};

export { HomePage };
