import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeEchipamentAntiincendiarDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeEchipamentAntiincendiar";
  }

  constructor(data = null) {
    super(data);
  }
}
