import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

export class TypeFunctieDTO {
  

  getMainObjPart() { return "TypeFunctie"; }

  getFieldsInt() {
    return [
      "id",      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
      "code",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
     
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    return this.denumire;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "denumire",
        numeric: false,
        disablePadding: true,
        label: "Denumire",
      },
      {
        id: "code",
        numeric: false,
        disablePadding: true,
        label: "Code",
      },
    ];

    return _headCells;
  }

}