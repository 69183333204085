import { FetchGet, FetchPost } from "api/RequestTools";

import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";

import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";

import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";
import { UserRolesDTO } from "dto/user/UserRolesDTO";
import { RolesDTO } from "dto/user/RolesDTO";
import { StatusDTO } from "dto/core/StatusDTO";


export class UserDTO {
  

  getMainObjPart() { return "User"; }
  getFieldsInt() {
    return [
      "id",      
      "status",
      "idpersoanafizica",
      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "identifier",
      "password",
      "secrethash"
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [ 
      "roles"
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "statusobj", _key: "Status" },
      { field: "persoanafizicaobj", _key: "PersoanaFizica" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
      { field: "userrolesobjects", _key: "UserRoles" },
      { field: "rolesobjects", _key: "Roles" },
    ];
  }
  
  remember = "";


  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }


  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
    this.remember = data?.remember;
  }

  getSingInData() {
    var rez = {};
    rez.identifier = this.identifier;
    rez.password = this.password;
    rez.remember = this.remember;
    return rez;
  }

  static handleExecUser(data, params, err) {
    logger("handleExecUser", data, params, err);
    if (params != undefined && params._lcb != undefined) {
      params._lcb(data, params, err);
    }
    // if (params._lcb != undefined) {
    //   params._lcb(data, params, err);
    // }
    // // FetchGet("loadlocation", handleSaveData, { parentcode: pc }, {});
    // logger("hsndleLoadLocation", data, params, err);
  }

  static execsignout() {
    FetchPost("signout", UserDTO.handleExecUser, {}, {}, {});
  }

  static execsigin(cb, objDto, cbparameters) {
    var data = objDto.getSingInData();

    cbparameters._lcb = cb;
    FetchPost("signin", UserDTO.handleExecUser, {}, data, cbparameters);

    // FetchGet("loadlocation", LocationDTO.hsndleLoadLocation, parameters, {
    //   _lcb: cb,
    // });
  }

  getDefaultOrderBy() {
    return "identifier";
  }

  getLabel() {
    if (!this) return '';
    return this.identifier;
  }

  GetListHeadCells(LL, otherdata) {
    const _headCells = [
      {
        id: "identifier",
        numeric: false,
        disablePadding: true,
        label: LL("TD_identifier"),
      },
      {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: LL("TD_status"),
      },
      {
        id: "persoanafizica",
        numeric: false,
        disablePadding: true,
        label: LL("TD_persoanafizica"),
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: true,
        label: LL("TD_phone"),
      },
      {
        id: "roles",
        numeric: false,
        disablePadding: true,
        label: LL("TD_roles"),
      },
    ];

    return _headCells;
  }

}
 