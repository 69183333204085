import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { useResource } from "hook/useResource";
import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { FilterTools } from "api/FilterTools";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";

// ---------------------------------

import { RolesDTO } from "dto/user/RolesDTO";
import { RolesACLDTO } from "dto/user/RolesACLDTO";

import { MyRolesACLMethod } from "components/core/RolesACL/MyRolesACLMethod";

import {
  RolesACLListModules,
  RolesACLNonGestiuneModules,
  RolesACLListGetForModule,
} from "api/RequestTools";
import { Typography } from "@mui/material";

const MyRolesACLContainerBlock = ({
  _RolesObjects,
  _Module,
  headCells,
  ...props
}) => {
  const { LL, getPermittedACLSpecial, updateRoleACL } = useResource(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpened, setIsOpened] = useState(false);

  const [rolesObjects, setRolesObjects] = useState(_RolesObjects);
  const [module, setModule] = useState(_Module);
  const [hasgestiunesac, setHasGestiuneSac] = useState(true);

  const [methods, setMethods] = useState([]);
  const [methodsACL, setMethodsACL] = useState({});

  // -------------------------------------------------
  useEffect(() => {
    var _methods = RolesACLListGetForModule(module);

    var allroles = [];
    // for (var i in rolesObjects)
    // {
    //   allroles[allroles.length] = ""+rolesObjects[i].id;
    // }
    logger("MyRolesACLContainerBlock_methods_rolesObjects", allroles);

    var t = {};
    for (var i in _methods) {
      var tp = getPermittedACLSpecial(_methods[i]);
      tp = Array.isArray(tp) ? tp : allroles;
      // tp = Array.isArray(tp) ? tp : [];
      t[_methods[i]] = tp;
    }

    // --------------------------------------
    var nongestiune = RolesACLNonGestiuneModules();

    var hasgs = hasgestiunesac;
    if (nongestiune.indexOf(module) != -1) {
      hasgs = false;
      setHasGestiuneSac(hasgs);
    }
    
    // --------------------------------------

    // --------------------------------------
    if (hasgs) {
      var cbparams = {};
      var otherdata = {};
      otherdata.uuidsac = "-1";
      otherdata.suffix = "_gestiunesac";

      cbparams.otherdata = otherdata;

      t[otherdata.suffix] = {};
      for (var i in _methods) {
        var tp = getPermittedACLSpecial(_methods[i], "", cbparams);
        tp = Array.isArray(tp) ? tp : allroles;
        // tp = Array.isArray(tp) ? tp : [];
        t[cbparams.otherdata.suffix][_methods[i]] = tp;
      }
    }
    // --------------------------------------

    setMethodsACL(t);

    logger("MyRolesACLContainerBlock_methods", module, _methods);

    setMethods(_methods);
  }, [module]);

  // -------------------------------------------------
  useEffect(() => {
    if (!methods.length) return;

    setIsLoading(false);
  }, [methods]);

  // -------------------------------------------------
  const handleSetMethodACL = (method, values, cbparams) => {
    logger("handleSetMethodACL333111", method, values);

    var t = methodsACL;
    if (
      cbparams != undefined &&
      cbparams.otherdata != undefined &&
      cbparams.otherdata.suffix
      != undefined)
    {
      if (t[cbparams.otherdata.suffix] == undefined) t[cbparams.otherdata.suffix] = {};
      t[cbparams.otherdata.suffix][method] = values;
    } else
    {
      t[method] = values;
    }
    
    setMethodsACL(t);

    updateRoleACL(module, method, values, cbparams);
  };

  // -------------------------------------------------
  const handleToggleOpened = () => {
    setIsOpened(!isOpened);
  };

  // -------------------------------------------------
  const processMethods = () => {
    if (!isOpened) return <></>;

    return (
      <>
        {methods.map((method, i) => {
          return processModuleMethod(method, module);
        })}
      </>
    );
  };

  // -------------------------------------------------
  const processMethods_Gestiune = () => {
    if (!isOpened) return <></>;
    if (!hasgestiunesac) return <></>;



    var cbparams = {};
    var otherdata = {};
    otherdata.uuidsac = "-1";
    otherdata.suffix = "_gestiunesac";

    cbparams.otherdata = otherdata;

    return (
      <>
        {methods.map((method, i) => {
          return processModuleMethod(
            method,
            module,
            cbparams,
            LL("Gestiune SAC")
          );
        })}
      </>
    );
  };

  // -------------------------------------------------
  const processModuleMethod = (method, module, cbparams, group) => {
    cbparams = cbparams != undefined ? cbparams : {};
    group = group != undefined ? group : "";
    var k = uuid();

    if (
      cbparams != undefined &&
      cbparams.otherdata != undefined &&
      cbparams.otherdata.suffix != undefined
    ) {
      var m = methodsACL[cbparams.otherdata.suffix][method];
    } else {
      var m = methodsACL[method];
    }

    return (
      <MyRolesACLMethod
        key={k}
        _RolesObjects={rolesObjects}
        _Module={module}
        _Method={method}
        _OtherData={cbparams}
        _Group={group}
        _Selecteds={m}
        _handleCheck={handleSetMethodACL}
      />
    );
  };

  // -------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell
          scope="row"
          sx={{ width: "100%", background: "#eee", py: 1 }}
          colspan={1 + rolesObjects.length}
          onClick={handleToggleOpened}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            <IconButton aria-label="delete" size="small" sx={{ mr: 2 }}>
              {isOpened ? (
                <ExpandLessIcon fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
            {LL("ACLBlockModule")} {module}
          </Typography>
        </TableCell>
      </TableRow>
      {processMethods()}
      {processMethods_Gestiune()}
    </>
  );
};

export { MyRolesACLContainerBlock };
