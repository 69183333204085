import React from "react";

import { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";


import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import TextField from "@mui/material/TextField";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import DateObject from "react-date-object";
import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";

const MyRangeSliderShow = ({ cb, filterobj, ...props }) => {
  
  const { LL } = useResource(false);
  
    var _startyear =
    filterobj &&
      filterobj.values[0] != undefined &&
      filterobj.values[0] &&
      filterobj.values[0] != -1
      ? filterobj.values[0]
      : 1990;
  var _endyear =
    filterobj &&
      filterobj.values[1] != undefined &&
      filterobj.values[1] &&
      filterobj.values[1] != -1
      ? filterobj.values[1]
      : new Date().getFullYear();
 
  var _label = filterobj && filterobj.label != undefined ? filterobj.label : "";
  
  const [valueT, setValue] = useState([_startyear, _endyear]);
  const [filter, setFilter] = useState(filterobj);


  const [label, setLabel] = useState(_label);


  logger("MyDateRangePickerShow___1111", filterobj);

  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    cb(filter);
  };

  const showValue = () => {
    
    return (
      <>
        {_startyear} - {_endyear}
      </>
    );
  };

  return ( _startyear == 1990 && _endyear == new Date().getFullYear()) ? <></> : (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
        {label}:
      </Typography>
      <Typography sx={{ marginRight: 1 }}>{showValue()}</Typography>

      <IconButton
        color="primary"
        aria-label="close"
        component="span"
        size="small"
        sx={{ marginRight: 3 }}
        onClick={() => {
          ExecCallBack();
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export { MyRangeSliderShow };
