import React from "react";

import { useEffect, useState, useMemo } from "react";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import TableCell from "@mui/material/TableCell";

import { CommonTools } from "api/CommonTools";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Checkbox from "@mui/material/Checkbox";

import { useResource } from "hook/useResource";

import { visuallyHidden } from "@mui/utils";
import { logger } from "hoc/logger";

const MyTextField = ({
  obj,
  defaultobjdto,
  otherdata,
  _fieldkey,
  _label,
  setObj,

  ...props
}) => {
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  
  const { LL } = useResource(false);

  useEffect(() => {
    if (!_fieldkey) {
      setName("");
      return;
    }
    setName(_fieldkey);
  }, [obj, _fieldkey, otherdata]);

  useEffect(() => {
    if (!name) return;

    if (_label) {
      setLabel(_label);
    } else {
      setLabel(LL(name));
    }

    if (!obj || !obj[name]) {
      setValue("");
    } else {
      setValue(obj[name]);
    }
  }, [name]);

  const handleChange = (e) => {
    if (!name) return;

    var _obj = obj;
    _obj[name] = e.target.value;
    setObj(_obj);

    setValue(e.target.value);
  };

  return !name ? (
    <></>
  ) : (
    <TextField
      {...props}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      type="text"
      fullWidth
      color="secondary"
      size="small"
      margin="normal"
      variant="standard"
    />
  );
};

export { MyTextField };
