import { FetchGet, FetchPost } from "api/RequestTools";

import { DosarSACDTO } from "dto/dosarsac/DosarSACDTO";
import { DocumentLinkDTO } from "dto/documents/DocumentLinkDTO";

import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import uuid from "react-uuid";
import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class AparatCafeaDTO {
  getMainObjPart() {
    return "AparatCafea";
  }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "dataamplasareint",
      "dataexcludereint",
      "isinuse",
      "dataamplasaremicrotime",
      "dataexcluderemicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numarinventar",
      "model",
      "datecontor",
      "dataamplasare_text",
      "dataamplasaretime_text",
      "dataexcludere_text",
      "dataexcluderetime_text"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return ["documentids",];
  }

  getFieldsStrArray() {
    return ["document"];
  }


  getFieldsDTOObjects() {
    return [
      { field: "sacobj", _key: "DosarSAC" },
      { field: "documentobj", _key: "Document" },
    ];
  }

  // getFieldsDTOObjectsArray() {
  //   return [
  //     { field: "documentamplasareobjects", _key: "Document" },
  //     { field: "documentexcludereobjects", _key: "Document" },
  //   ];
  // }
  
  getFieldsDTOObjectsArrayLink() {
    return [
      {
        field: "documentamplasarelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentamplasareobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentamplasareids",
        mainobjidparam: "iddocument",
      },
      {
        field: "documentexcluderelinkobjects",
        _key: "DocumentLink",
        mainobjfield: "documentexcludereobjects",
        mainobjkey: "Document",
        mainobjobjparam: "documentobj",
        mainidfield: "documentexcludereids",
        mainobjidparam: "iddocument",
      },
    ];
  }

  

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  
  }

  getDefaultOrderBy() {
    return "numarinventar";
  }

  getLabel() {
    return this.numarinventar;
  }
 
  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
      otherdata.uuidsac != undefined &&
      otherdata.uuidsac
        ? otherdata.uuidsac
        : false;

    var _headCells = [];

    _headCells[_headCells.length] = {
      id: "numarinventar",
      numeric: false,
      disablePadding: true,
      label: LL("TD_Numarinventar"),
    };

    if (!uuidsac) {
      _headCells[_headCells.length] = {
        id: "idsac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idsac"),
      };
    }

    _headCells[_headCells.length] = {
      id: "datecontor",
      numeric: false,
      disablePadding: true,
      label: LL("TD_datecontor"),
    };

    _headCells[_headCells.length] = {
      id: "dataamplasareint",
      numeric: false,
      disablePadding: true,
      label: LL("TD_dataamplasareint"),
    };

    _headCells[_headCells.length] = {
      id: "model",
      numeric: false,
      disablePadding: true,
      label: LL("TD_model"),
    };

    _headCells[_headCells.length] = {
      id: "isinuse",
      numeric: false,
      disablePadding: true,
      label: LL("TD_isinuse"),
    };

    _headCells[_headCells.length] = {
      id: "dataexcludereint",
      numeric: false,
      disablePadding: true,
      label: LL("TD_dataexcludereint"),
    };

    return _headCells;
  }
}
