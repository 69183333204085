import React from "react";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";


import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Grid from "@mui/material/Grid";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { CommonTools } from "api/CommonTools";
import uuid from "react-uuid";
// -------------------------

import { TypeFunctieDTO } from "dto/nomenclatoare/TypeFunctieDTO";

const DetailTypeFunctie = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  // -------------------------------------------------

  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeFunctieDTO()
  );
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [backdata, setBackData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(false);

  // -------------------------------------------------

  const setObj = (_obj) => {
    updateObj(_obj);
    setSpecialHash(uuid());
  };

  // -------------------------------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
  ];

  // -------------------------------------------------

  useEffect(() => {
    DTOTools.execObjViewJurnal(obj, otherdata);
  }, [obj]);

  useEffect(() => {
    FilterTools.processOtherDataDetail(
      defaultobjdto,
      obj,
      otherdata,
      setBackData,
      setIsLoading,
      setObj,
      {}
    );
  }, [_mlotherdata]);

  // -------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />
      <Container maxWidth="md" className="containerBtnTop">
        <MyNavBt
          href={DTOTools.getListRoute(obj, otherdata)}
          _setmlgenobj={_setmlgenobj}
          _otherdata={backdata}
        >
          <IconButton
            aria-label="delete"
            color="primary"
            sx={{ alignSelf: "start", mr: 2 }}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </MyNavBt>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {obj.denumire}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {obj.code}
          </Typography>
        </Box>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          sx={{
            marginLeft: "auto",
            alignSelf: "end",
          }}
          onClick={() =>
            _setmlgenobj(DTOTools.getEditObject(obj, otherdata, backdata))
          }
        >
          {LL("BT_Edit")}
        </Button>
      </Container>
      <Divider />
    </>
  );
};;;

export { DetailTypeFunctie };
