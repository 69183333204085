import { BancaDTO } from "dto/nomenclatoare/BancaDTO";
import { DTOTools } from "api/DTOTools";
export class ContBancarDTO {
  getMainObjPart() { return "ContBanca"; }
  getFieldsInt() {
    return [
      "id",
      "parentid",
      "idbanca",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "parentclass",
      "contbancar",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
      { field: "bancaobj", _key: "Banca" },
    ];
  }
  
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  static getAddDataFromArr(rez, objects) {
    if (Array.isArray(objects) && objects.length) {
      for (var i in objects) {
        if (objects[i].id) rez["contbancars[" + i + "][id]"] = objects[i].id;
        if (objects[i].uuid)
          rez["contbancars[" + i + "][uuid]"] = objects[i].uuid;
        if (objects[i].contbancar)
          rez["contbancars[" + i + "][contbancar]"] = objects[i].contbancar;
        if (objects[i].idbanca)
          rez["contbancars[" + i + "][idbanca]"] = objects[i].idbanca;
      }
    }
    return rez;
  }
}
