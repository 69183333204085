import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";
import { CommonTools } from "api/CommonTools";

import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { FilterTools } from "api/FilterTools";
import Button from '@mui/material/Button';

// ---------------------------------

const GSACDashBoard = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {

  const { LL } = useResource(false);

  logger("GSACDashBoard22334455", _mlotherdata);

  return <>GSACDashBoard {_mlotherdata.idsac}</>;
};;

export { GSACDashBoard };
