import { Outlet } from "react-router-dom";
// import { CustomLink } from "./CustomLink";

const Layout = () => {
  return (
      <Outlet />
  );
};

export { Layout };
