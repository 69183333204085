import { AppRoutes } from "./hoc/AppRoutes";

import { CookiesProvider } from "react-cookie";
// import { CookiesProvider } from "hook/useCookies";

import { AuthProvider } from "./hoc/AuthProvider";
import { ResourceProvider } from "./hoc/ResourceProvider";
import { TokenProvider } from "./hoc/TokenProvider";

import { createTheme } from "@mui/material/styles";
import { amber } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material/styles";

import { useEffect, useState, useMemo } from "react";

import { Loading } from "components/general/Loading";

import { roRO } from "@mui/material/locale";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const theme = createTheme(
  {
    palette: {
      primary: {
        light: "#DADCE0",
        main: "#202124",
        dark: "#000",
        contrastText: "#fff",
      },

      secondary: amber,
    },

    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  roRO
);
// const theme = createTheme({});

function App() {
  const [_theme, setTheme] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTheme(theme);
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <ThemeProvider theme={_theme}>
      <CookiesProvider>
        <TokenProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
              <ResourceProvider>
                <AppRoutes />
              </ResourceProvider>
            </AuthProvider>
          </LocalizationProvider>
        </TokenProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
