import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import Link from "@mui/material/Link";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { StreetDTO } from "dto/nomenclatoare/StreetDTO";
import { DTOTools } from "api/DTOTools";

const StreetSelect = ({ cb, setloaded, _objects, _label, ...props }) => {
  
  const { LL } = useResource(false);
  
  setloaded = setloaded == undefined ? false : setloaded;
  var tobjects = _objects != undefined ? _objects : false;
  logger("StreetSelectStreetSelect", tobjects);

  var label = _label != undefined ? _label : LL("Adresa");
  const [defaultValues, setDefaultValues] = useState(tobjects);
  const [objects, setObjects] = useState(tobjects);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  
  const [isLoadedStreet, setIsLoadedStreet] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new StreetDTO());
  const [otherdata, setOtherData] = React.useState({});


  useEffect(() => {
    logger("useEffect_isLoadedStreet", isLoadedStreet, setloaded);
    if (setloaded) setloaded(isLoadedStreet);
  }, [isLoadedStreet]);

  useEffect(() => {
    logger("StreetSelect useEffect open", open);
    if (!open) {
      setSearch("");
    }
  }, [open]);

  useEffect(() => {
    logger("StreetSelectuseEffectoptions", options);
    setIsLoadedStreet(true);
  }, [options]);

  useEffect(() => {
    logger("StreetSelect useEffect search", search);
    if (!search) {
      setOptions([]);
      setLoading(false);
      return;
    }

    loadData();
  }, [search]);

  useEffect(() => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    cb(objects);
  }, [objects]);

  const execLoadObjects = (_objects, data, err, params) => {
    // logger("StreetSelectexecLoadObjects", _objects, data, err, params._search);

    if (params._search != search) return;

    logger("StreetSelectexecLoadObjects", _objects, data, err, params._search);
    setOptions(_objects);
    setLoading(false);
  };

  const loadData = () => {
    
    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      { withlocations: 1, search: search },
      false,
      false,
      false,
      0,
      20,
      setLoading,
      setObjects,
      false
    );



    // logger("StreetSelectloadData");
    // var params = {};
    // // var params = filter;
    // // params.order = order;
    // // params.orderBy = orderBy;
    // params.page = 0;
    // params.rowsPerPage = 20;
    // params.search = search;
    // params.withlocations = 1;

    // // setIsLoading(true);

    // setLoading(true);
    // setIsLoadedStreet(false);
    // StreetDTO.execLoadObjects(execLoadObjects, params, {
    //   _search: search,
    // });
  };

  const handleChange = (value) => {
    logger("StreetSelect handleChange", value);

    setObjects(value);
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      id="asynchronous-demo"
      fullWidth
      open={open}
      defaultValue={defaultValues}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id == value.id &&
          option.locationcode == value.locationcode
          ? true
          : false;
      }}
      getOptionLabel={(option) => {
        if (
          typeof option === "object" &&
          !Array.isArray(option) &&
          option !== null
        ) {
          logger("AutocompleteOption", search, option.getLabelFull());
          return option.getLabelFull();
        }

        return "";
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          color="secondary"
          margin="normal"
          variant="standard"
          {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export { StreetSelect };
