import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeBrandDTO } from "dto/nomenclatoare/TypeBrandDTO";

const TypeBrand = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new TypeBrandDTO());

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { TypeBrand };
