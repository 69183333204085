import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeNumarCanaleRegistratoareDTO } from "dto/nomenclatoare/TypeNumarCanaleRegistratoareDTO";

const TypeNumarCanaleRegistratoare = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeNumarCanaleRegistratoareDTO()
  );

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { TypeNumarCanaleRegistratoare };
