import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";

import { useResource } from "hook/useResource";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const YesNoAutocomplete = ({
  obj,
  setObj,
  _idfield,
  _label,
  _nofirst,
  ...props
}) => {
  const { LL } = useResource(false);

  var nofirst = _nofirst != undefined ? 1 : 0;

  const [label, setLabel] = useState("");

  var tidfield = _idfield != undefined ? _idfield : "procesed";

  const [idfield, setIdField] = useState(tidfield);

  var _tsetCount = false;
  var tvalue = obj != undefined && obj[tidfield] ? obj[tidfield] : null;
  var tshowfield = "label";

  if (tvalue != undefined && tvalue) {
    if (tvalue == 1) {
      var tobject = { id: 1, label: LL("yes") };
    } else {
      var tobject = { id: -1, label: LL("no") };
    }
  }

  const [tsetCount, setCount] = useState(_tsetCount);
  const [showfield, setShowField] = useState(tshowfield);

  const [defaultValues, setDefaultValues] = useState(tobject);
  const [objects, setObjects] = useState(tobject);

  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isloaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState("");

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;

    var L = _label != undefined && _label ? _label : LL("procesed");

    setLabel(L);

    setIdField(_idfield);
  }, [options, obj, _label, _idfield, _label]);

  useEffect(() => {
    var toptions = [];
    toptions[toptions.length] = { id: 1, label: LL("yes") };
    toptions[toptions.length] = { id: -1, label: LL("no") };

    if (nofirst) toptions = toptions.reverse();

    setOptions(toptions);
  }, [obj, _idfield]);

  useEffect(() => {
    if (objects) {
      setValues(objects.id);
    } else {
      setValues(0);
    }
  }, [objects]);

  useEffect(() => {
    if (isloaded) return;
    setDefaultValues(tobject);
    setObjects(tobject);
    setIsLoaded(true);
  }, [options]);

  useEffect(() => {
    if (!isloaded) return;
    if (!idfield) return;
    if (!obj) return;
    if (!setObj) return;
    if (setObj == undefined) return;
    if (setObj == "undefined") return;

    var _obj = obj;

    _obj[idfield] = values;
 
    setObj(_obj);
  }, [values]);

  const handleChange = (value) => {
    setObjects(value);
  };

  return !isloaded ? (
    <></>
  ) : (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      defaultValue={defaultValues}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option[showfield] ? option[showfield] : "")}
      options={options}
      loading={loading}
      filterOptions={(x) => {
        if (!search) return x;
        x = x.filter((obj) => {
          if (!obj) return false;
          if (obj[showfield].toLowerCase().includes(search.toLowerCase()))
            return true;
          // return true;
          return false;
        });
        return x;
      }}
      renderInput={(params) => (
        <TextField
          color="secondary"
          margin="normal"
          variant="standard"
          {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );

  // var tobjects = null;
  // var label = _label != undefined ? _label : LL("isdefaultfordestination");
  // if (_id != undefined) {
  //   if (_id == 1) {
  //     tobjects = { id: 1, label: LL("yes") };
  //   } else {
  //     tobjects = { id: -1, label: LL("no") };
  //   }
  // }
  // var nofirst = _nofirst != undefined ? 1 : 0;
  // logger("YesNoAutocomplete", tobjects);
  // const [defaultValues, setDefaultValues] = useState(tobjects);
  // const [objects, setObjects] = useState(tobjects);
  // const [values, setValues] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);

  // useEffect(() => {
  //   loadData();
  // }, []);

  // useEffect(() => {
  //   if (objects == null) return;

  //   var tarr = null;
  //   // for (var i in objects) {
  //   tarr = objects.id;
  //   // }
  //   setValues(tarr);
  // }, [objects]);

  // useEffect(() => {
  //   if (!cb) return;
  //   if (cb == undefined) return;
  //   if (cb == "undefined") return;

  //   cb(values, objects);
  // }, [values]);

  // const loadData = () => {
  //   var toptions = [];
  //   toptions[toptions.length] = { id: 1, label: LL("yes") };
  //   toptions[toptions.length] = { id: -1, label: LL("no") };

  //   if (nofirst) toptions = toptions.reverse();

  //   setOptions(toptions);

  //   setLoading(false);
  // };

  // const handleChange = (value) => {
  //   setObjects(value);
  // };

  // return (
  //   <Autocomplete
  //     id="asynchronous-demo"
  //     fullWidth
  //     open={open}
  //     defaultValue={defaultValues}
  //     onOpen={() => {
  //       setOpen(true);
  //     }}
  //     onClose={() => {
  //       setOpen(false);
  //     }}
  //     onChange={(event, value) => {
  //       handleChange(value);
  //     }}
  //     isOptionEqualToValue={(option, value) => option.id === value.id}
  //     getOptionLabel={(option) => option.label}
  //     options={options}
  //     loading={loading}
  //     renderInput={(params) => (
  //       <TextField
  //         color="secondary"
  //         margin="normal"
  //         variant="standard"
  //         {...params}
  //         label={label}
  //         InputProps={{
  //           ...params.InputProps,
  //           endAdornment: (
  //             <>
  //               {loading ? (
  //                 <CircularProgress color="inherit" size={20} />
  //               ) : null}
  //               {params.InputProps.endAdornment}
  //             </>
  //           ),
  //         }}
  //       />
  //     )}
  //   />
  // );
};

export { YesNoAutocomplete };
