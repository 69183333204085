import React from "react";
import { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";

import { useResource } from "hook/useResource";

import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import { logger } from "hoc/logger";

import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";

const drawerWidthSecond = 280;

const openedMixinSecond = (theme) => ({
  width: drawerWidthSecond,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  paddingLeft: 60,
});

const closedMixinSecond = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  paddingLeft: 0,
  width: `calc(${theme.spacing(6.5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`,
  },
});

const DrawerSecond = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidthSecond,
  flexShrink: 0,
  whiteSpace: "nowrap",
  flexDirection: "column",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixinSecond(theme),
    "& .MuiDrawer-paper": openedMixinSecond(theme),
  }),
  ...(!open && {
    ...closedMixinSecond(theme),
    "& .MuiDrawer-paper": closedMixinSecond(theme),
  }),
}));

const MySecondDrawer = ({
  _setmlgenobj,
  _otherdata,
  _currentpath,
  _mainobject,
  DrawerHeader,
  ...props
}) => {
  const { LL, HASACL_METHOD } = useResource(false);

  const theme = useTheme();
  const [isopened, setIsOpened] = useState(true);
  const [group, setGroup] = useState(false);
  const [openedGroup, setOpenedGroup] = useState(-1);
  const [maingroup, setMainGroup] = useState(false);
  const [menuGroups, setMenuGroups] = useState([]);

  const [openlist, setOpenlist] = React.useState(false);

  const [openedBlockGroup, setOpenedBlockGroup] = React.useState(false);

  useEffect(() => {
    setGroupInfo();
  }, [_mainobject]);

  useEffect(() => {
    setMainGroupInfo();
  }, [group]);

  useEffect(() => {
    processList();
  }, [maingroup, _mainobject, group, maingroup]);

  const getgroupgeneral = (_key, _group) => {
    _group = _group == undefined ? false : _group;

    var mo = _mainobject ? _mainobject.toLowerCase() : "";

    if (!_group && mo == "add" + _key) _group = _key;
    if (!_group && mo == "detail" + _key) _group = _key;
    if (!_group && mo == "edit" + _key) _group = _key;
    if (!_group && mo == _key) _group = _key;

    return _group;
  };

  const setGroupInfoGestiuneSac = (mo) => {
    logger("setGroupInfoGestiuneSac", mo);

    if (mo.indexOf("gsac") == -1) return false;

    var _group = false;

    if (!_group) _group = getgroupgeneral("gsacdashboard", _group);
    if (!_group) _group = getgroupgeneral("gsacaparatcafea", _group);
    if (!_group) _group = getgroupgeneral("gsacpiloniluminare", _group);
    if (!_group) _group = getgroupgeneral("gsaccorpiluminarestradal", _group);
    if (!_group) _group = getgroupgeneral("gsacmobilier", _group);
    if (!_group) _group = getgroupgeneral("gsacutilajincalzire", _group);
    if (!_group) _group = getgroupgeneral("gsacpuncttransformare", _group);
    if (!_group) _group = getgroupgeneral("gsactraseuelectric", _group);
    if (!_group) _group = getgroupgeneral("gsaccameravideo", _group);
    if (!_group) _group = getgroupgeneral("gsacregistratorvideo", _group);
    if (!_group) _group = getgroupgeneral("gsacutilajtehnologic", _group);
    if (!_group) _group = getgroupgeneral("gsacpanoupret", _group);
    if (!_group) _group = getgroupgeneral("gsacgeneratorelectric", _group);
    if (!_group) _group = getgroupgeneral("gsactransformatorelectric", _group);
    if (!_group) _group = getgroupgeneral("gsacpistoldistribuitor", _group);

    if (!_group) _group = getgroupgeneral("gsacrca", _group);
    if (!_group) _group = getgroupgeneral("gsaccasco", _group);
    if (!_group) _group = getgroupgeneral("gsacrevizietehnica", _group);
    if (!_group) _group = getgroupgeneral("gsacdeserviretehnica", _group);

    if (!_group) _group = getgroupgeneral("gsacavizexpertizaecologica", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentadeserviretehnicapetrol", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentadeserviretehnicagaz", _group);
    if (!_group) _group = getgroupgeneral("gsacautorizatieemisiepoluanti", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentadistribuitoare", _group);
    if (!_group) _group = getgroupgeneral("gsactaxacomercializarebenzia", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentacontrol", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentasesizari", _group);
    if (!_group) _group = getgroupgeneral("gsacraportinterventie", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentaautorizatiesanitara", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentacontor", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentaverificaremetrologica", _group);
    if (!_group) _group = getgroupgeneral("gsacevidentaechipamentantiincendiar", _group);

    return _group;
  };

  const setGroupInfo = () => {
    var _group = false;

    var mo = _mainobject ? _mainobject.toLowerCase() : "";

    if (!_group) _group = setGroupInfoGestiuneSac(mo);

    if (!_group && mo == "homepage") _group = "homepage";

    if (!_group) _group = getgroupgeneral("persoanafizica", _group);
    if (!_group) _group = getgroupgeneral("persoanajuridica", _group);

    if (!_group) _group = getgroupgeneral("document", _group);
    if (!_group) _group = getgroupgeneral("rca", _group);
    if (!_group) _group = getgroupgeneral("casco", _group);
    if (!_group) _group = getgroupgeneral("avizexpertizaecologica", _group);
    if (!_group) _group = getgroupgeneral("autorizatieemisiepoluanti", _group);
    if (!_group) _group = getgroupgeneral("evidentacontrol", _group);
    if (!_group) _group = getgroupgeneral("evidentasesizari", _group);
    if (!_group) _group = getgroupgeneral("raportinterventie", _group);
    if (!_group) _group = getgroupgeneral("evidentaautorizatiesanitara", _group);
    if (!_group) _group = getgroupgeneral("evidentacontor", _group);
    if (!_group) _group = getgroupgeneral("evidentaverificaremetrologica", _group);
    if (!_group) _group = getgroupgeneral("evidentaechipamentantiincendiar", _group);
    if (!_group) _group = getgroupgeneral("evidentadistribuitoare", _group);
    if (!_group) _group = getgroupgeneral("evidentadeserviretehnicagaz", _group);
    if (!_group) _group = getgroupgeneral("evidentadeserviretehnicapetrol", _group);
    if (!_group) _group = getgroupgeneral("taxacomercializarebenzia", _group);
    if (!_group) _group = getgroupgeneral("revizietehnica", _group);
    if (!_group) _group = getgroupgeneral("deserviretehnica", _group);
    if (!_group) _group = getgroupgeneral("bunimobil", _group);
    if (!_group) _group = getgroupgeneral("transportauto", _group);
    if (!_group) _group = getgroupgeneral("transportautocascolink", _group);
    if (!_group) _group = getgroupgeneral("transportautodocumentlink", _group);
    if (!_group) _group = getgroupgeneral("transportautorcalink", _group);
    if (!_group) _group = getgroupgeneral("transportautorevizietehnicalink", _group);
    if (!_group) _group = getgroupgeneral("transportautodeserviretehnicalink", _group);
    if (!_group) _group = getgroupgeneral("dosarsac", _group);
    if (!_group) _group = getgroupgeneral("piloniluminare", _group);
    if (!_group) _group = getgroupgeneral("corpiluminarestradal", _group);
    if (!_group) _group = getgroupgeneral("mobilier", _group);
    if (!_group) _group = getgroupgeneral("utilajincalzire", _group);
    if (!_group) _group = getgroupgeneral("puncttransformare", _group);
    if (!_group) _group = getgroupgeneral("traseuelectric", _group);
    if (!_group) _group = getgroupgeneral("cameravideo", _group);
    if (!_group) _group = getgroupgeneral("registratorvideo", _group);
    if (!_group) _group = getgroupgeneral("aparatcafea", _group);
    if (!_group) _group = getgroupgeneral("utilajtehnologic", _group);
    if (!_group) _group = getgroupgeneral("panoupret", _group);
    if (!_group) _group = getgroupgeneral("generatorelectric", _group);
    if (!_group) _group = getgroupgeneral("transformatorelectric", _group);
    if (!_group) _group = getgroupgeneral("pistoldistribuitor", _group);

    if (!_group) _group = getgroupgeneral("typefunctie", _group);

    if (!_group)
      _group = getgroupgeneral("block_sistemmonitorizarevideo", _group);
    if (!_group) _group = getgroupgeneral("sistemelectroenergetic", _group);
    if (!_group) _group = getgroupgeneral("registrutransportauto", _group);

    if (!_group) _group = getgroupgeneral("typedistribuitoare", _group);
    if (!_group) _group = getgroupgeneral("typepistolpompa", _group);
    if (!_group)
      _group = getgroupgeneral("typenumarcanaleregistratoare", _group);
    if (!_group) _group = getgroupgeneral("typemobilier", _group);
    if (!_group) _group = getgroupgeneral("typeindiceprezentassg", _group);
    if (!_group) _group = getgroupgeneral("typematerial", _group);
    if (!_group) _group = getgroupgeneral("typeinventargospodaresc", _group);
    if (!_group)
      _group = getgroupgeneral("typeechipamentantiincendiar", _group);
    if (!_group) _group = getgroupgeneral("typeutilajtehnologic", _group);
    if (!_group) _group = getgroupgeneral("typedestinatiemcc", _group);
    if (!_group) _group = getgroupgeneral("typepuncttransformare", _group);
    if (!_group) _group = getgroupgeneral("typetraseu", _group);
    if (!_group) _group = getgroupgeneral("typereteaelectricaexterna", _group);
    if (!_group) _group = getgroupgeneral("typecameravideo", _group);
    if (!_group) _group = getgroupgeneral("typepanoupret", _group);
    if (!_group) _group = getgroupgeneral("typesistemcasa", _group);
    if (!_group) _group = getgroupgeneral("typesetarepanoupret", _group);
    if (!_group) _group = getgroupgeneral("typerobinetautomat", _group);
    if (!_group) _group = getgroupgeneral("typemontare", _group);
    if (!_group) _group = getgroupgeneral("typeiluminare", _group);
    if (!_group) _group = getgroupgeneral("typeutilajincalzire", _group);
    if (!_group) _group = getgroupgeneral("typecorp", _group);
    if (!_group) _group = getgroupgeneral("typemotivinterventie", _group);
    if (!_group) _group = getgroupgeneral("typeinterventie", _group);
    if (!_group) _group = getgroupgeneral("typepilon", _group);
    if (!_group) _group = getgroupgeneral("typecarosabil", _group);
    if (!_group) _group = getgroupgeneral("typebrand", _group);
    if (!_group) _group = getgroupgeneral("typemodelauto", _group);
    if (!_group) _group = getgroupgeneral("typeauto", _group);
    if (!_group) _group = getgroupgeneral("typecombustibil", _group);
    if (!_group) _group = getgroupgeneral("typemarcaauto", _group);
    if (!_group) _group = getgroupgeneral("typephone", _group);
    if (!_group) _group = getgroupgeneral("typedocument", _group);
    if (!_group) _group = getgroupgeneral("typeum", _group);
    if (!_group) _group = getgroupgeneral("typebunimobil", _group);
    if (!_group) _group = getgroupgeneral("typesex", _group);
    if (!_group) _group = getgroupgeneral("typestudii", _group);
    if (!_group) _group = getgroupgeneral("typefolosinta", _group);
    if (!_group) _group = getgroupgeneral("typehotar", _group);
    if (!_group) _group = getgroupgeneral("banca", _group);
    if (!_group) _group = getgroupgeneral("street", _group);

    if (!_group) _group = getgroupgeneral("user", _group);
    if (!_group) _group = getgroupgeneral("profile", _group);
    if (!_group) _group = getgroupgeneral("roles", _group);
    if (!_group) _group = getgroupgeneral("usersac", _group);
    if (!_group) _group = getgroupgeneral("rolesacl", _group);
    if (!_group) _group = getgroupgeneral("jurnal", _group);
    if (!_group) _group = getgroupgeneral("label", _group);

    setGroup(_group);
    setOpenedGroup(_group);
  };


  const setMainGroupInfoGestiuneSac = () => {

    var _maingroup = false;

    if (!_maingroup && group == "gsacdashboard") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacaparatcafea") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacpiloniluminare") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsaccorpiluminarestradal") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacmobilier") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacutilajincalzire") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacpuncttransformare") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsactraseuelectric") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsaccameravideo") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacregistratorvideo") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacutilajtehnologic") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacpanoupret") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacgeneratorelectric") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsactransformatorelectric") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacpistoldistribuitor") _maingroup = "gestiunesac";


    if (!_maingroup && group == "gsacrca") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacdeserviretehnica") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsaccasco") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacrevizietehnica") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacavizexpertizaecologica") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentadeserviretehnicapetrol") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentadeserviretehnicagaz") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacautorizatieemisiepoluanti") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentadistribuitoare") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsactaxacomercializarebenzia") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentacontrol") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentasesizari") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacraportinterventie") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentaautorizatiesanitara") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentacontor") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentaverificaremetrologica") _maingroup = "gestiunesac";
    if (!_maingroup && group == "gsacevidentaechipamentantiincendiar") _maingroup = "gestiunesac";


    return _maingroup;
  };

  const setMainGroupInfo = () => {
    var _maingroup = false;

    if (!_maingroup) _maingroup = setMainGroupInfoGestiuneSac();

    if (!_maingroup && group == "homepage") _maingroup = "homepage";


    if (!_maingroup && group == "persoanafizica") _maingroup = "contacte";
    if (!_maingroup && group == "persoanajuridica") _maingroup = "contacte";

    if (!_maingroup && group == "document") _maingroup = "document";
    if (!_maingroup && group == "rca") _maingroup = "document";
    if (!_maingroup && group == "casco") _maingroup = "document";
    
    if (!_maingroup && group == "avizexpertizaecologica") _maingroup = "document";
    if (!_maingroup && group == "autorizatieemisiepoluanti") _maingroup = "document";
    if (!_maingroup && group == "evidentacontrol") _maingroup = "document";
    if (!_maingroup && group == "evidentasesizari") _maingroup = "document";
    if (!_maingroup && group == "raportinterventie") _maingroup = "document";
    if (!_maingroup && group == "evidentaautorizatiesanitara") _maingroup = "document";
    if (!_maingroup && group == "evidentacontor") _maingroup = "document";
    if (!_maingroup && group == "evidentaverificaremetrologica") _maingroup = "document";
    if (!_maingroup && group == "evidentaechipamentantiincendiar") _maingroup = "document";
    if (!_maingroup && group == "evidentadistribuitoare") _maingroup = "document";
    if (!_maingroup && group == "evidentadeserviretehnicagaz") _maingroup = "document";
    if (!_maingroup && group == "evidentadeserviretehnicapetrol") _maingroup = "document";
    if (!_maingroup && group == "taxacomercializarebenzia") _maingroup = "document";
    
    if (!_maingroup && group == "revizietehnica") _maingroup = "document";
    if (!_maingroup && group == "deserviretehnica") _maingroup = "document";

    if (!_maingroup && group == "bunimobil") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportauto") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportautocascolink") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportautodocumentlink") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportautorcalink") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportautorevizietehnicalink") _maingroup = "bunimobil";
    if (!_maingroup && group == "transportautodeserviretehnicalink") _maingroup = "bunimobil";
    if (!_maingroup && group == "dosarsac") _maingroup = "bunimobil";
    if (!_maingroup && group == "piloniluminare") _maingroup = "bunimobil";
    if (!_maingroup && group == "corpiluminarestradal")
      _maingroup = "bunimobil";
    if (!_maingroup && group == "mobilier") _maingroup = "bunimobil";
    if (!_maingroup && group == "utilajincalzire") _maingroup = "bunimobil";
    if (!_maingroup && group == "puncttransformare") _maingroup = "bunimobil";
    if (!_maingroup && group == "traseuelectric") _maingroup = "bunimobil";
    if (!_maingroup && group == "cameravideo") _maingroup = "bunimobil";
    if (!_maingroup && group == "registratorvideo") _maingroup = "bunimobil";
    if (!_maingroup && group == "aparatcafea") _maingroup = "bunimobil";
    if (!_maingroup && group == "utilajtehnologic") _maingroup = "bunimobil";
    if (!_maingroup && group == "panoupret") _maingroup = "bunimobil";
    if (!_maingroup && group == "generatorelectric") _maingroup = "bunimobil";
    if (!_maingroup && group == "transformatorelectric")
      _maingroup = "bunimobil";
    if (!_maingroup && group == "pistoldistribuitor") _maingroup = "bunimobil";

    if (!_maingroup && group == "typefunctie") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typedistribuitoare")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typepistolpompa") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typenumarcanaleregistratoare")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typemobilier") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeindiceprezentassg")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "block_sistemmonitorizarevideo")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "sistemelectroenergetic")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "registrutransportauto")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typematerial") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeinventargospodaresc")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeechipamentantiincendiar")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeutilajtehnologic")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typedestinatiemcc")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typepuncttransformare")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typetraseu") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typereteaelectricaexterna")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typecameravideo") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typepanoupret") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typesistemcasa") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typerobinetautomat")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typesetarepanoupret")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typemontare") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeiluminare") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeutilajincalzire")
      _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typecorp") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typemotivinterventie") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeinterventie") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typepilon") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typecarosabil") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typebrand") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typemodelauto") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeauto") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typecombustibil") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typemarcaauto") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typephone") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typedocument") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typeum") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typebunimobil") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typesex") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typestudii") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typefolosinta") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "typehotar") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "banca") _maingroup = "nomenclatoare";
    if (!_maingroup && group == "street") _maingroup = "nomenclatoare";

    if (!_maingroup && group == "user") _maingroup = "user";
    if (!_maingroup && group == "profile") _maingroup = "user";
    if (!_maingroup && group == "roles") _maingroup = "user";
    if (!_maingroup && group == "rolesacl") _maingroup = "user";
    if (!_maingroup && group == "jurnal") _maingroup = "user";
    if (!_maingroup && group == "label") _maingroup = "user";
    if (!_maingroup && group == "usersac") _maingroup = "user";

    setMainGroup(_maingroup);
  };

  const isSelected = (_key, _suffix) => {
    if (group != _key) return false;

    _suffix = _suffix != undefined ? _suffix : false;

    if (!_suffix) return true;

    var mo = _mainobject ? _mainobject.toLowerCase() : "";

    if (mo.includes(_suffix)) return true;
    return false;
  };

  // ------------------------------------------------------------------

  const processListGroup_SistemMonitorizareVideo_isSelected = () => {
    if (isSelected("typenumarcanaleregistratoare")) return true;
    if (isSelected("typecameravideo")) return true;

    return false;
  };

  const processListGroup_SistemMonitorizareVideo_Group = () => {
    var rez = [];
    if (HASACL_METHOD("typecameravideo")) {
      rez[rez.length] = {
        key: "ms_block_typecameravideo",
        url: "/typecameravideo",
        label: LL("MS_typecameravideo"),
        childs: [],
        cgroup: "typecameravideo",
        selected: isSelected("typecameravideo"),
      };
    }
    if (HASACL_METHOD("typenumarcanaleregistratoare")) {
      rez[rez.length] = {
        key: "ms_block_typenumarcanaleregistratoare",
        url: "/typenumarcanaleregistratoare",
        label: LL("MS_typenumarcanaleregistratoare"),
        childs: [],
        cgroup: "typenumarcanaleregistratoare",
        selected: isSelected("typenumarcanaleregistratoare"),
      };
    }
    return rez;
  };

  const processListGroup_SistemMonitorizareVideo = () => {
    var rez = [];

    var childs = processListGroup_SistemMonitorizareVideo_Group();
    var isSelected = processListGroup_SistemMonitorizareVideo_isSelected();

    if (!childs.length) return rez;

    if (isSelected) {
      setOpenlist(true);
      setOpenedBlockGroup("block_sistemmonitorizarevideo");
    }

    rez[rez.length] = {
      key: "ms_block_sistemmonitorizarevideo",
      label: LL("MS_sistemmonitorizarevideo"),
      childs: childs,
      cgroup: "block_sistemmonitorizarevideo",
      selected: isSelected,
    };

    return rez;
  };

  // ------------------------------------------------------------------

  const processListGroup_SistemElectroEnergetic_isSelected = () => {
    if (isSelected("typeutilajincalzire")) return true;
    if (isSelected("typepuncttransformare")) return true;
    if (isSelected("typereteaelectricaexterna")) return true;
    if (isSelected("typeiluminare")) return true;
    if (isSelected("typepanoupret")) return true;

    return false;
  };

  const processListGroup_SistemElectroEnergetic_Group = () => {
    var rez = [];

    if (HASACL_METHOD("typeutilajincalzire")) {
      rez[rez.length] = {
        key: "ms_block_typeutilajincalzire",
        url: "/typeutilajincalzire",
        label: LL("MS_typeutilajincalzire"),
        childs: [],
        cgroup: "typeutilajincalzire",
        selected: isSelected("typeutilajincalzire"),
      };
    }

    if (HASACL_METHOD("typepuncttransformare")) {
      rez[rez.length] = {
        key: "ms_block_typepuncttransformare",
        url: "/typepuncttransformare",
        label: LL("MS_typepuncttransformare"),
        childs: [],
        cgroup: "typepuncttransformare",
        selected: isSelected("typepuncttransformare"),
      };
    }

    if (HASACL_METHOD("typereteaelectricaexterna")) {
      rez[rez.length] = {
        key: "ms_block_typereteaelectricaexterna",
        url: "/typereteaelectricaexterna",
        label: LL("MS_typereteaelectricaexterna"),
        childs: [],
        cgroup: "typereteaelectricaexterna",
        selected: isSelected("typereteaelectricaexterna"),
      };
    }

    if (HASACL_METHOD("typeiluminare")) {
      rez[rez.length] = {
        key: "ms_block_typeiluminare",
        url: "/typeiluminare",
        label: LL("MS_typeiluminare"),
        childs: [],
        cgroup: "typeiluminare",
        selected: isSelected("typeiluminare"),
      };
    }

    if (HASACL_METHOD("typepanoupret")) {
      rez[rez.length] = {
        key: "ms_block_typepanoupret",
        url: "/typepanoupret",
        label: LL("MS_typepanoupret"),
        childs: [],
        cgroup: "typepanoupret",
        selected: isSelected("typepanoupret"),
      };
    }

    return rez;
  };

  const processListGroup_SistemElectroEnergetic = () => {
    var rez = [];

    var childs = (childs = processListGroup_SistemElectroEnergetic_Group());
    var isSelected = processListGroup_SistemElectroEnergetic_isSelected();

    if (!childs.length) return rez;

    if (isSelected) {
      setOpenlist(true);
      setOpenedBlockGroup("sistemelectroenergetic");
    }
    rez[rez.length] = {
      key: "ms_block_sistemelectroenergetic",
      label: LL("MS_sistemelectroenergetic"),
      childs: childs,
      cgroup: "sistemelectroenergetic",
      selected: isSelected,
    };

    return rez;
  };

  const processListGroup_RegistruTransportAuto_isSelected = () => {
    if (isSelected("typemarcaauto")) return true;
    if (isSelected("typeauto")) return true;
    if (isSelected("typemodelauto")) return true;
    return false;
  };

  const processListGroup_RegistruTransportAuto_Group = () => {
    var rez = [];

    if (HASACL_METHOD("typemarcaauto")) {
      rez[rez.length] = {
        key: "ms_block_typemarcaauto",
        url: "/typemarcaauto",
        label: LL("MS_typemarcaauto"),
        childs: [],
        cgroup: "typemarcaauto",
        selected: isSelected("typemarcaauto"),
      };
    }

    if (HASACL_METHOD("typeauto")) {
      rez[rez.length] = {
        key: "ms_block_typeauto",
        url: "/typeauto",
        label: LL("MS_typeauto"),
        childs: [],
        cgroup: "typeauto",
        selected: isSelected("typeauto"),
      };
    }

    if (HASACL_METHOD("typemodelauto")) {
      rez[rez.length] = {
        key: "ms_block_typemodelauto",
        url: "/typemodelauto",
        label: LL("MS_typemodelauto"),
        childs: [],
        cgroup: "typemodelauto",
        selected: isSelected("typemodelauto"),
      };
    }

    return rez;
  };

  const processListGroup_RegistruTransportAuto = () => {
    var rez = [];

    var childs = processListGroup_RegistruTransportAuto_Group();

    if (!childs.length) return rez;

    var isSelected = processListGroup_RegistruTransportAuto_isSelected();
    if (isSelected) {
      setOpenlist(true);
      setOpenedBlockGroup("registrutransportauto");
    }
    rez[rez.length] = {
      key: "ms_block_registrutransportauto",
      label: LL("MS_registrutransportauto"),
      childs: childs,
      cgroup: "registrutransportauto",
      selected: isSelected,
    };

    return rez;
  };

  const processListGroup_TypeFunctie = () => {
    var rez = [];

    if (HASACL_METHOD("typefunctie")) {
      rez[rez.length] = {
        key: "ms_block_typefunctie",
        url: "/typefunctie",
        label: LL("MS_typefunctie"),
        childs: [],
        cgroup: "typefunctie",
        selected: isSelected("typefunctie"),
      };
    }

    return rez;
  };

  const processListGroup_TypeDistribuitoare = () => {
    var rez = [];

    if (HASACL_METHOD("typedistribuitoare")) {
      rez[rez.length] = {
        key: "ms_block_typedistribuitoare",
        url: "/typedistribuitoare",
        label: LL("MS_typedistribuitoare"),
        childs: [],
        cgroup: "typedistribuitoare",
        selected: isSelected("typedistribuitoare"),
      };
    }

    return rez;
  };

  const processListGroup_TypePistolPompa = () => {
    var rez = [];

    if (HASACL_METHOD("typepistolpompa")) {
      rez[rez.length] = {
        key: "ms_block_typepistolpompa",
        url: "/typepistolpompa",
        label: LL("MS_typepistolpompa"),
        childs: [],
        cgroup: "typepistolpompa",
        selected: isSelected("typepistolpompa"),
      };
    }

    return rez;
  };

  const processListGroup_TypeNumarCanaleRegistratoare = () => {
    var rez = [];

    if (HASACL_METHOD("typenumarcanaleregistratoare")) {
      rez[rez.length] = {
        key: "ms_block_typenumarcanaleregistratoare",
        url: "/typenumarcanaleregistratoare",
        label: LL("MS_typenumarcanaleregistratoare"),
        childs: [],
        cgroup: "typenumarcanaleregistratoare",
        selected: isSelected("typenumarcanaleregistratoare"),
      };
    }

    return rez;
  };

  const processListGroup_TypeMobilier = () => {
    var rez = [];

    if (HASACL_METHOD("typemobilier")) {
      rez[rez.length] = {
        key: "ms_block_typemobilier",
        url: "/typemobilier",
        label: LL("MS_typemobilier"),
        childs: [],
        cgroup: "typemobilier",
        selected: isSelected("typemobilier"),
      };
    }

    return rez;
  };

  const processListGroup_TypeIndicePrezentaSSG = () => {
    var rez = [];

    if (HASACL_METHOD("typeindiceprezentassg")) {
      rez[rez.length] = {
        key: "ms_block_typeindiceprezentassg",
        url: "/typeindiceprezentassg",
        label: LL("MS_typeindiceprezentassg"),
        childs: [],
        cgroup: "typeindiceprezentassg",
        selected: isSelected("typeindiceprezentassg"),
      };
    }

    return rez;
  };

  const processListGroup_TypeMaterial = () => {
    var rez = [];

    if (HASACL_METHOD("typematerial")) {
      rez[rez.length] = {
        key: "ms_block_typematerial",
        url: "/typematerial",
        label: LL("MS_typematerial"),
        childs: [],
        cgroup: "typematerial",
        selected: isSelected("typematerial"),
      };
    }

    return rez;
  };

  const processListGroup_TypeInventarGospodaresc = () => {
    var rez = [];

    if (HASACL_METHOD("typeinventargospodaresc")) {
      rez[rez.length] = {
        key: "ms_block_typeinventargospodaresc",
        url: "/typeinventargospodaresc",
        label: LL("MS_typeinventargospodaresc"),
        childs: [],
        cgroup: "typeinventargospodaresc",
        selected: isSelected("typeinventargospodaresc"),
      };
    }

    return rez;
  };

  const processListGroup_TypeEchipamentAntiincendiar = () => {
    var rez = [];

    if (HASACL_METHOD("typeechipamentantiincendiar")) {
      rez[rez.length] = {
        key: "ms_block_typeechipamentantiincendiar",
        url: "/typeechipamentantiincendiar",
        label: LL("MS_typeechipamentantiincendiar"),
        childs: [],
        cgroup: "typeechipamentantiincendiar",
        selected: isSelected("typeechipamentantiincendiar"),
      };
    }

    return rez;
  };

  const processListGroup_TypeUtilajTehnologic = () => {
    var rez = [];

    if (HASACL_METHOD("typeutilajtehnologic")) {
      rez[rez.length] = {
        key: "ms_block_typeutilajtehnologic",
        url: "/typeutilajtehnologic",
        label: LL("MS_typeutilajtehnologic"),
        childs: [],
        cgroup: "typeutilajtehnologic",
        selected: isSelected("typeutilajtehnologic"),
      };
    }

    return rez;
  };

  const processListGroup_TypeDestinatieMCC = () => {
    var rez = [];

    if (HASACL_METHOD("typedestinatiemcc")) {
      rez[rez.length] = {
        key: "ms_block_typedestinatiemcc",
        url: "/typedestinatiemcc",
        label: LL("MS_typedestinatiemcc"),
        childs: [],
        cgroup: "typedestinatiemcc",
        selected: isSelected("typedestinatiemcc"),
      };
    }

    return rez;
  };

  const processListGroup_TypePunctTransformare = () => {
    var rez = [];

    if (HASACL_METHOD("typepuncttransformare")) {
      rez[rez.length] = {
        key: "ms_block_typepuncttransformare",
        url: "/typepuncttransformare",
        label: LL("MS_typepuncttransformare"),
        childs: [],
        cgroup: "typepuncttransformare",
        selected: isSelected("typepuncttransformare"),
      };
    }

    return rez;
  };

  const processListGroup_TypeTraseu = () => {
    var rez = [];

    if (HASACL_METHOD("typetraseu")) {
      rez[rez.length] = {
        key: "ms_block_typetraseu",
        url: "/typetraseu",
        label: LL("MS_typetraseu"),
        childs: [],
        cgroup: "typetraseu",
        selected: isSelected("typetraseu"),
      };
    }

    return rez;
  };

  const processListGroup_TypeReteaElectricaExterna = () => {
    var rez = [];

    if (HASACL_METHOD("typereteaelectricaexterna")) {
      rez[rez.length] = {
        key: "ms_block_typereteaelectricaexterna",
        url: "/typereteaelectricaexterna",
        label: LL("MS_typereteaelectricaexterna"),
        childs: [],
        cgroup: "typereteaelectricaexterna",
        selected: isSelected("typereteaelectricaexterna"),
      };
    }

    return rez;
  };

  const processListGroup_TypeCameraVideo = () => {
    var rez = [];

    if (HASACL_METHOD("typecameravideo")) {
      rez[rez.length] = {
        key: "ms_block_typecameravideo",
        url: "/typecameravideo",
        label: LL("MS_typecameravideo"),
        childs: [],
        cgroup: "typecameravideo",
        selected: isSelected("typecameravideo"),
      };
    }

    return rez;
  };

  const processListGroup_TypeSistemCasa = () => {
    var rez = [];

    if (HASACL_METHOD("typesistemcasa")) {
      rez[rez.length] = {
        key: "ms_block_typesistemcasa",
        url: "/typesistemcasa",
        label: LL("MS_typesistemcasa"),
        childs: [],
        cgroup: "typesistemcasa",
        selected: isSelected("typesistemcasa"),
      };
    }

    return rez;
  };

  const processListGroup_TypePanouPret = () => {
    var rez = [];

    if (HASACL_METHOD("typepanoupret")) {
      rez[rez.length] = {
        key: "ms_block_typepanoupret",
        url: "/typepanoupret",
        label: LL("MS_typepanoupret"),
        childs: [],
        cgroup: "typepanoupret",
        selected: isSelected("typepanoupret"),
      };
    }

    return rez;
  };

  const processListGroup_TypeRobinetAutomat = () => {
    var rez = [];

    if (HASACL_METHOD("typerobinetautomat")) {
      rez[rez.length] = {
        key: "ms_block_typerobinetautomat",
        url: "/typerobinetautomat",
        label: LL("MS_typerobinetautomat"),
        childs: [],
        cgroup: "typerobinetautomat",
        selected: isSelected("typerobinetautomat"),
      };
    }

    return rez;
  };

  const processListGroup_TypeSetarePanouPret = () => {
    var rez = [];

    if (HASACL_METHOD("typesetarepanoupret")) {
      rez[rez.length] = {
        key: "ms_block_typesetarepanoupret",
        url: "/typesetarepanoupret",
        label: LL("MS_typesetarepanoupret"),
        childs: [],
        cgroup: "typesetarepanoupret",
        selected: isSelected("typesetarepanoupret"),
      };
    }

    return rez;
  };

  const processListGroup_TypeMontare = () => {
    var rez = [];

    if (HASACL_METHOD("typemontare")) {
      rez[rez.length] = {
        key: "ms_block_typemontare",
        url: "/typemontare",
        label: LL("MS_typemontare"),
        childs: [],
        cgroup: "typemontare",
        selected: isSelected("typemontare"),
      };
    }

    return rez;
  };

  const processListGroup_TypeIluminare = () => {
    var rez = [];

    if (HASACL_METHOD("typeiluminare")) {
      rez[rez.length] = {
        key: "ms_block_typeiluminare",
        url: "/typeiluminare",
        label: LL("MS_typeiluminare"),
        childs: [],
        cgroup: "typeiluminare",
        selected: isSelected("typeiluminare"),
      };
    }

    return rez;
  };

  const processListGroup_TypeUtilajIncalzire = () => {
    var rez = [];

    if (HASACL_METHOD("typeutilajincalzire")) {
      rez[rez.length] = {
        key: "ms_block_typeutilajincalzire",
        url: "/typeutilajincalzire",
        label: LL("MS_typeutilajincalzire"),
        childs: [],
        cgroup: "typeutilajincalzire",
        selected: isSelected("typeutilajincalzire"),
      };
    }

    return rez;
  };

  const processListGroup_TypeCorp = () => {
    var rez = [];

    if (HASACL_METHOD("typecorp")) {
      rez[rez.length] = {
        key: "ms_block_typecorp",
        url: "/typecorp",
        label: LL("MS_typecorp"),
        childs: [],
        cgroup: "typecorp",
        selected: isSelected("typecorp"),
      };
    }

    return rez;
  };

  const processListGroup_TypeMotivInterventie = () => {
    var rez = [];

    if (HASACL_METHOD("typemotivinterventie")) {
      rez[rez.length] = {
        key: "ms_block_typemotivinterventie",
        url: "/typemotivinterventie",
        label: LL("MS_typemotivinterventie"),
        childs: [],
        cgroup: "typemotivinterventie",
        selected: isSelected("typemotivinterventie"),
      };
    }

    return rez;
  };


  const processListGroup_TypeInterventie = () => {
    var rez = [];

    if (HASACL_METHOD("typeinterventie")) {
      rez[rez.length] = {
        key: "ms_block_typeinterventie",
        url: "/typeinterventie",
        label: LL("MS_typeinterventie"),
        childs: [],
        cgroup: "typeinterventie",
        selected: isSelected("typeinterventie"),
      };
    }

    return rez;
  };

  const processListGroup_TypePilon = () => {
    var rez = [];

    if (HASACL_METHOD("typepilon")) {
      rez[rez.length] = {
        key: "ms_block_typepilon",
        url: "/typepilon",
        label: LL("MS_typepilon"),
        childs: [],
        cgroup: "typepilon",
        selected: isSelected("typepilon"),
      };
    }

    return rez;
  };

  const processListGroup_TypeCarosabil = () => {
    var rez = [];

    if (HASACL_METHOD("typecarosabil")) {
      rez[rez.length] = {
        key: "ms_block_typecarosabil",
        url: "/typecarosabil",
        label: LL("MS_typecarosabil"),
        childs: [],
        cgroup: "typecarosabil",
        selected: isSelected("typecarosabil"),
      };
    }

    return rez;
  };

  const processListGroup_TypeBrand = () => {
    var rez = [];

    if (HASACL_METHOD("typebrand")) {
      rez[rez.length] = {
        key: "ms_block_typebrand",
        url: "/typebrand",
        label: LL("MS_typebrand"),
        childs: [],
        cgroup: "typebrand",
        selected: isSelected("typebrand"),
      };
    }

    return rez;
  };

  const processListGroup_TypeModelAuto = () => {
    var rez = [];

    if (HASACL_METHOD("typemodelauto")) {
      rez[rez.length] = {
        key: "ms_block_typemodelauto",
        url: "/typemodelauto",
        label: LL("MS_typemodelauto"),
        childs: [],
        cgroup: "registrutransportauto",
        selected: isSelected("typemodelauto"),
      };
    }

    return rez;
  };

  const processListGroup_TypeAuto = () => {
    var rez = [];

    if (HASACL_METHOD("typeauto")) {
      rez[rez.length] = {
        key: "ms_block_typeauto",
        url: "/typeauto",
        label: LL("MS_typeauto"),
        childs: [],
        cgroup: "registrutransportauto",
        selected: isSelected("typeauto"),
      };
    }

    return rez;
  };

  const processListGroup_TypeCombustibil = () => {
    var rez = [];

    if (HASACL_METHOD("typecombustibil")) {
      rez[rez.length] = {
        key: "ms_block_typecombustibil",
        url: "/typecombustibil",
        label: LL("MS_typecombustibil"),
        childs: [],
        cgroup: "typecombustibil",
        selected: isSelected("typecombustibil"),
      };
    }

    return rez;
  };

  const processListGroup_TypeMarcaAuto = () => {
    var rez = [];

    if (HASACL_METHOD("typemarcaauto")) {
      rez[rez.length] = {
        key: "ms_block_typemarcaauto",
        url: "/typemarcaauto",
        label: LL("MS_typemarcaauto"),
        childs: [],
        cgroup: "registrutransportauto",
        selected: isSelected("typemarcaauto"),
      };
    }

    return rez;
  };

  const processListGroup_TypePhone = () => {
    var rez = [];

    if (HASACL_METHOD("typephone")) {
      rez[rez.length] = {
        key: "ms_block_typephone",
        url: "/typephone",
        label: LL("MS_typephone"),
        childs: [],
        cgroup: "typephone",
        selected: isSelected("typephone"),
      };
    }

    return rez;
  };

  const processListGroup_TypeDocument = () => {
    var rez = [];

    if (HASACL_METHOD("typedocument")) {
      rez[rez.length] = {
        key: "ms_block_typedocument",
        url: "/typedocument",
        label: LL("MS_typedocument"),
        childs: [],
        cgroup: "typedocument",
        selected: isSelected("typedocument"),
      };
    }

    return rez;
  };

  const processListGroup_TypeUM = () => {
    var rez = [];

    if (HASACL_METHOD("typeum")) {
      rez[rez.length] = {
        key: "ms_block_typeum",
        url: "/typeum",
        label: LL("MS_typeum"),
        childs: [],
        cgroup: "typeum",
        selected: isSelected("typeum"),
      };
    }

    return rez;
  };

  const processListGroup_TypeBunImobil = () => {
    var rez = [];

    if (HASACL_METHOD("typebunimobil")) {
      rez[rez.length] = {
        key: "ms_block_typebunimobil",
        url: "/typebunimobil",
        label: LL("MS_typebunimobil"),
        childs: [],
        cgroup: "typebunimobil",
        selected: isSelected("typebunimobil"),
      };
    }

    return rez;
  };

  const processListGroup_TypeSex = () => {
    var rez = [];

    if (HASACL_METHOD("typesex")) {
      rez[rez.length] = {
        key: "ms_block_typesex",
        url: "/typesex",
        label: LL("MS_typesex"),
        childs: [],
        cgroup: "typesex",
        selected: isSelected("typesex"),
      };
    }

    return rez;
  };

  const processListGroup_TypeStudii = () => {
    var rez = [];

    if (HASACL_METHOD("typestudii")) {
      rez[rez.length] = {
        key: "ms_block_typestudii",
        url: "/typestudii",
        label: LL("MS_typestudii"),
        childs: [],
        cgroup: "typestudii",
        selected: isSelected("typestudii"),
      };
    }

    return rez;
  };

  const processListGroup_TypeFolosinta = () => {
    var rez = [];

    if (HASACL_METHOD("typefolosinta")) {
      rez[rez.length] = {
        key: "ms_block_typefolosinta",
        url: "/typefolosinta",
        label: LL("MS_typefolosinta"),
        childs: [],
        cgroup: "typefolosinta",
        selected: isSelected("typefolosinta"),
      };
    }

    return rez;
  };

  const processListGroup_TypeHotar = () => {
    var rez = [];

    if (HASACL_METHOD("typehotar")) {
      rez[rez.length] = {
        key: "ms_block_typehotar",
        url: "/typehotar",
        label: LL("MS_typehotar"),
        childs: [],
        cgroup: "typehotar",
        selected: isSelected("typehotar"),
      };
    }

    return rez;
  };

  const processListGroup_Street = () => {
    var rez = [];

    if (HASACL_METHOD("street")) {
      rez[rez.length] = {
        key: "ms_block_street",
        url: "/street",
        label: LL("MS_street"),
        childs: [],
        cgroup: "street",
        selected: isSelected("street"),
      };
    }

    return rez;
  };

  const processListGroup_Banca = () => {
    var rez = [];

    if (HASACL_METHOD("banca")) {
      rez[rez.length] = {
        key: "ms_block_banca",
        url: "/banca",
        label: LL("MS_banca"),
        childs: [],
        cgroup: "banca",
        selected: isSelected("banca"),
      };
    }

    return rez;
  };
  //--------------------------------------------------
  const processListGroup_TransportAutoReg_isSelected = () => {
    if (isSelected("transportauto")) return true;
    if (isSelected("transportautocascolink")) return true;
    if (isSelected("transportautodocumentlink")) return true;
    if (isSelected("transportautorcalink")) return true;
    if (isSelected("transportautorevizietehnicalink")) return true;
    if (isSelected("transportautodeserviretehnicalink")) return true;

    return false;
  };

  const processListGroup_TransportAutoReg_Group = () => {
    var rez = [];
    if (HASACL_METHOD("transportauto")) {
      rez[rez.length] = {
        key: "ms_block_transportauto",
        url: "/transportauto",
        label: LL("MS_transportauto"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportauto"),
      };
    }
    if (HASACL_METHOD("transportautocascolink")) {
      rez[rez.length] = {
        key: "ms_block_transportautocascolink",
        url: "/transportautocascolink",
        label: LL("MS_transportautocascolink"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportautocascolink"),
      };
    }
    if (HASACL_METHOD("transportautodocumentlink")) {
      rez[rez.length] = {
        key: "ms_block_transportautodocumentlink",
        url: "/transportautodocumentlink",
        label: LL("MS_transportautodocumentlink"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportautodocumentlink"),
      };
    };
    if (HASACL_METHOD("transportautorcalink")) {
      rez[rez.length] = {
        key: "ms_block_transportautorcalink",
        url: "/transportautorcalink",
        label: LL("MS_transportautorcalink"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportautorcalink"),
      };
    };
    if (HASACL_METHOD("transportautorevizietehnicalink")) {
      rez[rez.length] = {
        key: "ms_block_transportautorevizietehnicalink",
        url: "/transportautorevizietehnicalink",
        label: LL("MS_transportautorevizietehnicalink"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportautorevizietehnicalink"),
      };
    };

    if (HASACL_METHOD("transportautodeserviretehnicalink")) {
      rez[rez.length] = {
        key: "ms_block_transportautodeserviretehnicalink",
        url: "/transportautodeserviretehnicalink",
        label: LL("MS_transportautodeserviretehnicalink"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportautodeserviretehnicalink"),
      };
    };

    return rez;
  };

  const processListGroup_TransportAutoReg = () => {
    var rez = [];

    var childs = processListGroup_TransportAutoReg_Group();
    var isSelected = processListGroup_TransportAutoReg_isSelected();

    if (!childs.length) return rez;

    if (isSelected) {
      setOpenlist(true);
      setOpenedBlockGroup("block_transportauto");
    }

    rez[rez.length] = {
      key: "ms_block_transportauto",
      label: LL("MS_transportauto"),
      childs: childs,
      cgroup: "block_transportauto",
      selected: isSelected,
    };

    return rez;
  };
  //--------------------------------------------------
  const processListGroup_BunImobil = () => {
    var rez = [];

    if (HASACL_METHOD("bunimobil")) {
      rez[rez.length] = {
        key: "ms_block_bunimobil",
        url: "/bunimobil",
        label: LL("MS_bunimobil"),
        childs: [],
        cgroup: "bunimobil",
        selected: isSelected("bunimobil"),
      };
    }



    if (HASACL_METHOD("dosarsac")) {
      rez[rez.length] = {
        key: "ms_block_dosarsac",
        url: "/dosarsac",
        label: LL("MS_dosarsac"),
        childs: [],
        cgroup: "dosarsac",
        selected: isSelected("dosarsac"),
      };
    }

    if (HASACL_METHOD("piloniluminare")) {
      rez[rez.length] = {
        key: "ms_block_piloniluminare",
        url: "/piloniluminare",
        label: LL("MS_piloniluminare"),
        childs: [],
        cgroup: "piloniluminare",
        selected: isSelected("piloniluminare"),
      };
    }

    if (HASACL_METHOD("corpiluminarestradal")) {
      rez[rez.length] = {
        key: "ms_block_corpiluminarestradal",
        url: "/corpiluminarestradal",
        label: LL("MS_corpiluminarestradal"),
        childs: [],
        cgroup: "corpiluminarestradal",
        selected: isSelected("corpiluminarestradal"),
      };
    }

    if (HASACL_METHOD("mobilier")) {
      rez[rez.length] = {
        key: "ms_block_mobilier",
        url: "/mobilier",
        label: LL("MS_mobilier"),
        childs: [],
        cgroup: "mobilier",
        selected: isSelected("mobilier"),
      };
    }

    if (HASACL_METHOD("utilajincalzire")) {
      rez[rez.length] = {
        key: "ms_block_utilajincalzire",
        url: "/utilajincalzire",
        label: LL("MS_utilajincalzire"),
        childs: [],
        cgroup: "utilajincalzire",
        selected: isSelected("utilajincalzire"),
      };
    }

    if (HASACL_METHOD("puncttransformare")) {
      rez[rez.length] = {
        key: "ms_block_puncttransformare",
        url: "/puncttransformare",
        label: LL("MS_puncttransformare"),
        childs: [],
        cgroup: "puncttransformare",
        selected: isSelected("puncttransformare"),
      };
    }

    if (HASACL_METHOD("traseuelectric")) {
      rez[rez.length] = {
        key: "ms_block_traseuelectric",
        url: "/traseuelectric",
        label: LL("MS_traseuelectric"),
        childs: [],
        cgroup: "traseuelectric",
        selected: isSelected("traseuelectric"),
      };
    }

    if (HASACL_METHOD("cameravideo")) {
      rez[rez.length] = {
        key: "ms_block_cameravideo",
        url: "/cameravideo",
        label: LL("MS_cameravideo"),
        childs: [],
        cgroup: "cameravideo",
        selected: isSelected("cameravideo"),
      };
    }

    if (HASACL_METHOD("registratorvideo")) {
      rez[rez.length] = {
        key: "ms_block_registratorvideo",
        url: "/registratorvideo",
        label: LL("MS_registratorvideo"),
        childs: [],
        cgroup: "registratorvideo",
        selected: isSelected("registratorvideo"),
      };
    }

    if (HASACL_METHOD("aparatcafea")) {
      rez[rez.length] = {
        key: "ms_block_aparatcafea",
        url: "/aparatcafea",
        label: LL("MS_aparatcafea"),
        childs: [],
        cgroup: "aparatcafea",
        selected: isSelected("aparatcafea"),
      };
    }

    if (HASACL_METHOD("utilajtehnologic")) {
      rez[rez.length] = {
        key: "ms_block_utilajtehnologic",
        url: "/utilajtehnologic",
        label: LL("MS_utilajtehnologic"),
        childs: [],
        cgroup: "utilajtehnologic",
        selected: isSelected("utilajtehnologic"),
      };
    }

    if (HASACL_METHOD("panoupret")) {
      rez[rez.length] = {
        key: "ms_block_panoupret",
        url: "/panoupret",
        label: LL("MS_panoupret"),
        childs: [],
        cgroup: "panoupret",
        selected: isSelected("panoupret"),
      };
    }

    if (HASACL_METHOD("generatorelectric")) {
      rez[rez.length] = {
        key: "ms_block_generatorelectric",
        url: "/generatorelectric",
        label: LL("MS_generatorelectric"),
        childs: [],
        cgroup: "generatorelectric",
        selected: isSelected("generatorelectric"),
      };
    }

    if (HASACL_METHOD("transformatorelectric")) {
      rez[rez.length] = {
        key: "ms_block_transformatorelectric",
        url: "/transformatorelectric",
        label: LL("MS_transformatorelectric"),
        childs: [],
        cgroup: "transformatorelectric",
        selected: isSelected("transformatorelectric"),
      };
    }

    if (HASACL_METHOD("pistoldistribuitor")) {
      rez[rez.length] = {
        key: "ms_block_pistoldistribuitor",
        url: "/pistoldistribuitor",
        label: LL("MS_pistoldistribuitor"),
        childs: [],
        cgroup: "pistoldistribuitor",
        selected: isSelected("pistoldistribuitor"),
      };
    }
    
    return rez;
  };

  const processListGroup_TransportAuto = () => {
    var rez = [];

    if (HASACL_METHOD("transportauto")) {
      rez[rez.length] = {
        key: "ms_block_transportauto",
        url: "/transportauto",
        label: LL("MS_transportauto"),
        childs: [],
        cgroup: "transportauto",
        selected: isSelected("transportauto"),
      };
    }

    return rez;
  };

  const processListGroup_Document = () => {
    var rez = [];

    if (HASACL_METHOD("document")) {
      rez[rez.length] = {
        key: "ms_block_document",
        url: "/document",
        label: LL("MS_document"),
        childs: [],
        cgroup: "document",
        selected: isSelected("document"),
      };
    }

    if (HASACL_METHOD("rca")) {
      rez[rez.length] = {
        key: "ms_block_rca",
        url: "/rca",
        label: LL("MS_rca"),
        childs: [],
        cgroup: "document",
        selected: isSelected("rca"),
      };
    }

    if (HASACL_METHOD("casco")) {
      rez[rez.length] = {
        key: "ms_block_casco",
        url: "/casco",
        label: LL("MS_casco"),
        childs: [],
        cgroup: "document",
        selected: isSelected("casco"),
      };
    }
    if (HASACL_METHOD("avizexpertizaecologica")) {
      rez[rez.length] = {
        key: "ms_block_avizexpertizaecologica",
        url: "/avizexpertizaecologica",
        label: LL("MS_avizexpertizaecologica"),
        childs: [],
        cgroup: "document",
        selected: isSelected("avizexpertizaecologica"),
      };
    }
    if (HASACL_METHOD("autorizatieemisiepoluanti")) {
      rez[rez.length] = {
        key: "ms_block_autorizatieemisiepoluanti",
        url: "/autorizatieemisiepoluanti",
        label: LL("MS_autorizatieemisiepoluanti"),
        childs: [],
        cgroup: "document",
        selected: isSelected("autorizatieemisiepoluanti"),
      };
    }
    if (HASACL_METHOD("evidentacontrol")) {
      rez[rez.length] = {
        key: "ms_block_evidentacontrol",
        url: "/evidentacontrol",
        label: LL("MS_evidentacontrol"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentacontrol"),
      };
    }
    if (HASACL_METHOD("evidentasesizari")) {
      rez[rez.length] = {
        key: "ms_block_evidentasesizari",
        url: "/evidentasesizari",
        label: LL("MS_evidentasesizari"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentasesizari"),
      };
    }
    if (HASACL_METHOD("raportinterventie")) {
      rez[rez.length] = {
        key: "ms_block_raportinterventie",
        url: "/raportinterventie",
        label: LL("MS_raportinterventie"),
        childs: [],
        cgroup: "document",
        selected: isSelected("raportinterventie"),
      };
    }
    if (HASACL_METHOD("evidentaautorizatiesanitara")) {
      rez[rez.length] = {
        key: "ms_block_evidentaautorizatiesanitara",
        url: "/evidentaautorizatiesanitara",
        label: LL("MS_evidentaautorizatiesanitara"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentaautorizatiesanitara"),
      };
    }
    if (HASACL_METHOD("evidentacontor")) {
      rez[rez.length] = {
        key: "ms_block_evidentacontor",
        url: "/evidentacontor",
        label: LL("MS_evidentacontor"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentacontor"),
      };
    }
    if (HASACL_METHOD("evidentaverificaremetrologica")) {
      rez[rez.length] = {
        key: "ms_block_evidentaverificaremetrologica",
        url: "/evidentaverificaremetrologica",
        label: LL("MS_evidentaverificaremetrologica"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentaverificaremetrologica"),
      };
    }
    if (HASACL_METHOD("evidentaechipamentantiincendiar")) {
      rez[rez.length] = {
        key: "ms_block_evidentaechipamentantiincendiar",
        url: "/evidentaechipamentantiincendiar",
        label: LL("MS_evidentaechipamentantiincendiar"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentaechipamentantiincendiar"),
      };
    }
    if (HASACL_METHOD("evidentadistribuitoare")) {
      rez[rez.length] = {
        key: "ms_block_evidentadistribuitoare",
        url: "/evidentadistribuitoare",
        label: LL("MS_evidentadistribuitoare"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentadistribuitoare"),
      };
    }
    if (HASACL_METHOD("evidentadeserviretehnicagaz")) {
      rez[rez.length] = {
        key: "ms_block_evidentadeserviretehnicagaz",
        url: "/evidentadeserviretehnicagaz",
        label: LL("MS_evidentadeserviretehnicagaz"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentadeserviretehnicagaz"),
      };
    }
    if (HASACL_METHOD("evidentadeserviretehnicapetrol")) {
      rez[rez.length] = {
        key: "ms_block_evidentadeserviretehnicapetrol",
        url: "/evidentadeserviretehnicapetrol",
        label: LL("MS_evidentadeserviretehnicapetrol"),
        childs: [],
        cgroup: "document",
        selected: isSelected("evidentadeserviretehnicapetrol"),
      };
    }
    if (HASACL_METHOD("taxacomercializarebenzia")) {
      rez[rez.length] = {
        key: "ms_block_taxacomercializarebenzia",
        url: "/taxacomercializarebenzia",
        label: LL("MS_taxacomercializarebenzia"),
        childs: [],
        cgroup: "document",
        selected: isSelected("taxacomercializarebenzia"),
      };
    }

    if (HASACL_METHOD("revizietehnica")) {
      rez[rez.length] = {
        key: "ms_block_revizietehnica",
        url: "/revizietehnica",
        label: LL("MS_revizietehnica"),
        childs: [],
        cgroup: "document",
        selected: isSelected("revizietehnica"),
      };
    }

    if (HASACL_METHOD("deserviretehnica")) {
      rez[rez.length] = {
        key: "ms_block_deserviretehnica",
        url: "/deserviretehnica",
        label: LL("MS_deserviretehnica"),
        childs: [],
        cgroup: "document",
        selected: isSelected("deserviretehnica"),
      };
    }

    return rez;
  };

  const processListGroup_PersoanaFizica = () => {
    var rez = [];

    if (HASACL_METHOD("persoanafizica")) {
      rez[rez.length] = {
        key: "ms_block_persoanafizica",
        url: "/persoanafizica",
        label: LL("MS_persoanafizica"),
        childs: [],
        cgroup: "persoanafizica",
        selected: isSelected("persoanafizica"),
      };
    }

    return rez;
  };

  const processListGroup_PersoanaJuridica = () => {
    var rez = [];

    if (HASACL_METHOD("persoanajuridica")) {
      rez[rez.length] = {
        key: "ms_block_persoanajuridica",
        url: "/persoanajuridica",
        label: LL("MS_persoanajuridica"),
        childs: [],
        cgroup: "persoanajuridica",
        selected: isSelected("persoanajuridica"),
      };
    }

    return rez;
  };

  const processListGroup_User = () => {
    var rez = [];

    if (HASACL_METHOD("user")) {
      rez[rez.length] = {
        key: "ms_block_user",
        url: "/user",
        label: LL("MS_user"),
        childs: [],
        cgroup: "user",
        selected: isSelected("user"),
      };
    }

    // if (HASACL_METHOD("profile")) {
    // rez[rez.length] = {
    //   key: "ms_block_profile",
    //   url: "/profile",
    //   label: LL("MS_profile"),
    //   childs: [],
    //   cgroup: "user",
    //   selected: isSelected("profile"),
    // };
    // }

    if (HASACL_METHOD("roles")) {
      rez[rez.length] = {
        key: "ms_block_roles",
        url: "/roles",
        label: LL("MS_roles"),
        childs: [],
        cgroup: "user",
        selected: isSelected("roles"),
      };
    }

    if (HASACL_METHOD("jurnal")) {
      rez[rez.length] = {
        key: "ms_block_jurnal",
        url: "/jurnal",
        label: LL("MS_jurnal"),
        childs: [],
        cgroup: "user",
        selected: isSelected("jurnal"),
      };
    }

    if (HASACL_METHOD("label")) {
      rez[rez.length] = {
        key: "ms_block_label",
        url: "/label",
        label: LL("MS_label"),
        childs: [],
        cgroup: "user",
        selected: isSelected("label"),
      };
    }

    if (HASACL_METHOD("rolesacl")) {
      rez[rez.length] = {
        key: "ms_block_rolesacl",
        url: "/rolesacl",
        label: LL("MS_rolesacl"),
        childs: [],
        cgroup: "user",
        selected: isSelected("rolesacl"),
      };

      if (HASACL_METHOD("usersac")) {
        rez[rez.length] = {
          key: "ms_block_usersac",
          url: "/usersac",
          label: LL("MS_usersac"),
          childs: [],
          cgroup: "user",
          selected: isSelected("usersac"),
        };
      }
    }

    return rez;
  };

  const processListGroup_HomePage = () => {
    var rez = [];
    return rez;
  };

  // ----------------------------------------------

  const processList_HomePage = () => {
    var rez = [];

    rez[rez.length] = processListGroup_HomePage();

    return processList_MergeGroups(rez);
  };


  const processList_Contacte = () => {
    var rez = [];

    rez[rez.length] = processListGroup_PersoanaFizica();
    rez[rez.length] = processListGroup_PersoanaJuridica();

    return processList_MergeGroups(rez);
  };

  const processList_Document = () => {
    var rez = [];

    rez[rez.length] = processListGroup_Document();

    return processList_MergeGroups(rez);
  };

  const processList_BunImobil = () => {
    var rez = [];

    rez[rez.length] = processListGroup_BunImobil();
    rez[rez.length] = processListGroup_TransportAutoReg();
    return processList_MergeGroups(rez);
  };

  const processList_Nomenclatoare = () => {
    var rez = [];

    rez[rez.length] = processListGroup_TypeSetarePanouPret();
    rez[rez.length] = processListGroup_TypeFunctie();
    rez[rez.length] = processListGroup_TypeDistribuitoare();
    rez[rez.length] = processListGroup_TypePistolPompa();
    rez[rez.length] = processListGroup_TypeMobilier();
    rez[rez.length] = processListGroup_TypeIndicePrezentaSSG();
    rez[rez.length] = processListGroup_TypeMaterial();
    rez[rez.length] = processListGroup_TypeInventarGospodaresc();
    rez[rez.length] = processListGroup_TypeEchipamentAntiincendiar();
    rez[rez.length] = processListGroup_TypeUtilajTehnologic();
    rez[rez.length] = processListGroup_TypeDestinatieMCC();

    rez[rez.length] = processListGroup_TypeTraseu();

    rez[rez.length] = processListGroup_SistemMonitorizareVideo();

    rez[rez.length] = processListGroup_TypeSistemCasa();
    rez[rez.length] = processListGroup_TypeRobinetAutomat();
    rez[rez.length] = processListGroup_TypeMontare();

    rez[rez.length] = processListGroup_SistemElectroEnergetic();

    rez[rez.length] = processListGroup_TypeCorp();
    rez[rez.length] = processListGroup_TypeMotivInterventie();
    rez[rez.length] = processListGroup_TypeInterventie();
    rez[rez.length] = processListGroup_TypePilon();
    rez[rez.length] = processListGroup_TypeCarosabil();
    rez[rez.length] = processListGroup_TypeBrand();
    rez[rez.length] = processListGroup_TypeCombustibil();
    rez[rez.length] = processListGroup_RegistruTransportAuto();

    rez[rez.length] = processListGroup_TypePhone();
    rez[rez.length] = processListGroup_TypeDocument();
    rez[rez.length] = processListGroup_TypeUM();
    rez[rez.length] = processListGroup_TypeBunImobil();
    rez[rez.length] = processListGroup_TypeSex();
    rez[rez.length] = processListGroup_TypeStudii();
    rez[rez.length] = processListGroup_TypeFolosinta();
    rez[rez.length] = processListGroup_TypeHotar();
    rez[rez.length] = processListGroup_Banca();
    rez[rez.length] = processListGroup_Street();

    return processList_MergeGroups(rez);
  };

  const processList_MergeGroups = (objects) => {
    var rez = [];
    for (var i in objects) {
      for (var j in objects[i]) {
        rez[rez.length] = objects[i][j];
      }
    }

    rez.filter((n) => n);

    return rez;
  };

  const processList_User = () => {
    var rez = [];

    rez[rez.length] = processListGroup_User();

    return processList_MergeGroups(rez);
  };

  // *****************************************************
  // *****************************************************
  // *****************************************************


  const processList_GestiuneSac_DashBoard = (uuidsac) => {
    var rez = [];

    if (HASACL_METHOD("dashboard") ) {
      rez[rez.length] = {
        key: "ms_block_dashboard",
        url: "/gestiunesac/" + uuidsac + "/dashboard",
        label: LL("MS_dashboard"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacdashboard"),
      };
    }

    return rez;
  };

  const processList_GestiuneSac_Aparatcafea_Gestiunesac = (uuidsac) => {
    var rez = [];

    if (HASACL_METHOD("aparatcafea", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_aparatcafea_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/aparatcafea",
        label: LL("MS_aparatcafea"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacaparatcafea"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_PilonIluminare_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("piloniluminare", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_piloniluminare_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/piloniluminare",
        label: LL("MS_piloniluminare"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacpiloniluminare "),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_CorpIluminareStradal_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("corpiluminarestradal", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_corpiluminarestradal_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/corpiluminarestradal",
        label: LL("MS_corpiluminarestradal"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsaccorpiluminarestradal"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_Mobilier_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("mobilier", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_mobilier_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/mobilier",
        label: LL("MS_mobilier"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacmobilier"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_UtilajIncalzire_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("utilajincalzire", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_utilajincalzire_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/utilajincalzire",
        label: LL("MS_utilajincalzire"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacutilajincalzire"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_PunctTransformare_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("puncttransformare", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_puncttransformare_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/puncttransformare",
        label: LL("MS_puncttransformare"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacpuncttransformare"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_TraseuElectric_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("traseuelectric", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_traseuelectric_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/traseuelectric",
        label: LL("MS_traseuelectric"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsactraseuelectric"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_CameraVideo_Gestiunesac = (uuidsac) => {
    var rez = [];

    if (HASACL_METHOD("cameravideo", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_cameravideo_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/cameravideo",
        label: LL("MS_cameravideo"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsaccameravideo"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_RegistratorVideo_Gestiunesac = (uuidsac) => {
    var rez = [];

    
     
    if (HASACL_METHOD("registratorvideo", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_registratorvideo_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/registratorvideo",
        label: LL("MS_registratorvideo"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacregistratorvideo"),
      };
    }
    

    return rez;
  };
  const processList_GestiuneSac_UtilajTehnologic_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    if (HASACL_METHOD("utilajtehnologic", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_utilajtehnologic_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/utilajtehnologic",
        label: LL("MS_utilajtehnologic"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacutilajtehnologic"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_PanouPret_Gestiunesac = (uuidsac) => {
    var rez = [];

    if (HASACL_METHOD("panoupret", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_panoupret_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/panoupret",
        label: LL("MS_panoupret"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacpanoupret"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_GeneratorElectric_Gestiunesac = (uuidsac) => {
    var rez = [];

    if (HASACL_METHOD("generatorelectric", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_generatorelectric_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/generatorelectric",
        label: LL("MS_generatorelectric"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacgeneratorelectric"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_TransformatorElectric_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("transformatorelectric", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_transformatorelectric_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/transformatorelectric",
        label: LL("MS_transformatorelectric"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsactransformatorelectric"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_PistolDistribuitor_Gestiunesac = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("pistoldistribuitor", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_pistoldistribuitor_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/pistoldistribuitor",
        label: LL("MS_pistoldistribuitor"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacpistoldistribuitor"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_AvizExpertizaEcologica = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("avizexpertizaecologica", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_avizexpertizaecologica_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/avizexpertizaecologica",
        label: LL("MS_avizexpertizaecologica"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacavizexpertizaecologica"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_RevizieTehnica = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("revizietehnica", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_revizietehnica_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/revizietehnica",
        label: LL("MS_revizietehnica"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacrevizietehnica"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_CASCO = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("casco", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_casco_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/casco",
        label: LL("MS_casco"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsaccasco"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_DeservireTehnica = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("deserviretehnica", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_deserviretehnica_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/deserviretehnica",
        label: LL("MS_deserviretehnica"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacdeserviretehnica"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_RCA = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("rca", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_rca_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/rca",
        label: LL("MS_rca"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacrca"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_AutorizatieEmisiePoluanti = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("autorizatieemisiepoluanti", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_autorizatieemisiepoluanti_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/autorizatieemisiepoluanti",
        label: LL("MS_autorizatieemisiepoluanti"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacautorizatieemisiepoluanti"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaControl = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentacontrol", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentacontrol_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentacontrol",
        label: LL("MS_evidentacontrol"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentacontrol"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaSesizari = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentasesizari", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentasesizari_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentasesizari",
        label: LL("MS_evidentasesizari"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentasesizari"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_RaportInterventie = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("raportinterventie", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_raportinterventie_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/raportinterventie",
        label: LL("MS_raportinterventie"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacraportinterventie"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaAutorizatieSanitara = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentaautorizatiesanitara", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentaautorizatiesanitara_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentaautorizatiesanitara",
        label: LL("MS_evidentaautorizatiesanitara"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentaautorizatiesanitara"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaContor = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentacontor", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentacontor_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentacontor",
        label: LL("MS_evidentacontor"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentacontor"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaVerificareMetrologica = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentaverificaremetrologica", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentaverificaremetrologica_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentaverificaremetrologica",
        label: LL("MS_evidentaverificaremetrologica"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentaverificaremetrologica"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaEchipamentAntiincendiar = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentaechipamentantiincendiar", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentaechipamentantiincendiar_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentaechipamentantiincendiar",
        label: LL("MS_evidentaechipamentantiincendiar"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentaechipamentantiincendiar"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaDistribuitoare = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentadistribuitoare", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentadistribuitoare_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentadistribuitoare",
        label: LL("MS_evidentadistribuitoare"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentadistribuitoare"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaDeservireTehnicaGaz = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentadeserviretehnicagaz", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentadeserviretehnicagaz_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentadeserviretehnicagaz",
        label: LL("MS_evidentadeserviretehnicagaz"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentadeserviretehnicagaz"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_EvidentaDeservireTehnicaPetrol = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("evidentadeserviretehnicapetrol", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_evidentadeserviretehnicapetrol_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/evidentadeserviretehnicapetrol",
        label: LL("MS_evidentadeserviretehnicapetrol"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsacevidentadeserviretehnicapetrol"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac_TaxaComercializareBenzia = (uuidsac) => {
    var rez = [];

    
    
    if (HASACL_METHOD("taxacomercializarebenzia", '', _otherdata) ) {
      rez[rez.length] = {
        key: "ms_block_taxacomercializarebenzia_gestiunesac",
        url: "/gestiunesac/" + uuidsac + "/taxacomercializarebenzia",
        label: LL("MS_taxacomercializarebenzia"),
        childs: [],
        cgroup: "gestiunesac",
        selected: isSelected("gsactaxacomercializarebenzia"),
      };
    }
    
    return rez;
  };
  const processList_GestiuneSac = () => {
    var rez = [];

    var uuidsac = _otherdata != undefined && _otherdata.uuidsac != undefined ? _otherdata.uuidsac : false;

    if (!uuidsac) return rez;

    logger("processList_GestiuneSac", _otherdata);

    rez[rez.length] = processList_GestiuneSac_DashBoard(uuidsac);
    rez[rez.length] = processList_GestiuneSac_Aparatcafea_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_PilonIluminare_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_CorpIluminareStradal_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_Mobilier_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_TraseuElectric_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_CameraVideo_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_RegistratorVideo_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_UtilajTehnologic_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_PanouPret_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_GeneratorElectric_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_TransformatorElectric_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_PistolDistribuitor_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_PunctTransformare_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_UtilajIncalzire_Gestiunesac(uuidsac);
    rez[rez.length] = processList_GestiuneSac_AvizExpertizaEcologica(uuidsac);
    rez[rez.length] = processList_GestiuneSac_DeservireTehnica(uuidsac);
    rez[rez.length] = processList_GestiuneSac_RevizieTehnica(uuidsac);
    rez[rez.length] = processList_GestiuneSac_RCA(uuidsac);
    rez[rez.length] = processList_GestiuneSac_CASCO(uuidsac);

    rez[rez.length] = processList_GestiuneSac_AutorizatieEmisiePoluanti(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaControl(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaSesizari(uuidsac);
    rez[rez.length] = processList_GestiuneSac_RaportInterventie(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaAutorizatieSanitara(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaContor(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaVerificareMetrologica(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaEchipamentAntiincendiar(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaDistribuitoare(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaDeservireTehnicaGaz(uuidsac);
    rez[rez.length] = processList_GestiuneSac_EvidentaDeservireTehnicaPetrol(uuidsac);
    rez[rez.length] = processList_GestiuneSac_TaxaComercializareBenzia(uuidsac);
    return processList_MergeGroups(rez);
  };

  // *****************************************************
  // *****************************************************
  // *****************************************************


  const processList = () => {

    logger("processList123122211", maingroup, _mainobject);

    setOpenedBlockGroup(-1);
    setOpenlist(false);

    var groups = [];

    if (maingroup == "homepage") groups = processList_HomePage();
    if (maingroup == "contacte") groups = processList_Contacte();
    if (maingroup == "document") groups = processList_Document();
    if (maingroup == "bunimobil") groups = processList_BunImobil();
    if (maingroup == "nomenclatoare") groups = processList_Nomenclatoare();
    if (maingroup == "user") groups = processList_User();
    if (maingroup == "gestiunesac") groups = processList_GestiuneSac();


    setMenuGroups(groups);
  };

  const processGroupsItem = (obj, i) => {
    var cn = obj.selected
      ? "active-link menuPrimarySecondFirst"
      : "menuPrimarySecondFirst";
    return Array.isArray(obj.childs) && obj.childs.length ? (
      <>
        <ListItemButton className={cn}>
          {obj.url ? (
            <MyNavBt href={obj.url} _setmlgenobj={_setmlgenobj}>
              {obj.label}
            </MyNavBt>
          ) : (
            <span
              onClick={() => {
                if (obj.cgroup == openedBlockGroup && openlist) {
                  setOpenedBlockGroup(false);
                  setOpenlist(false);
                } else {
                  setOpenedBlockGroup(obj.cgroup);
                  setOpenlist(true);
                }
              }}
            >
              {obj.label}
            </span>
          )}
          {obj.cgroup == openedBlockGroup && openlist ? (
            <ExpandLess
              onClick={() => {
                setOpenedBlockGroup(false);
                setOpenlist(false);
              }}
            />
          ) : (
            <ExpandMore
              onClick={() => {
                setOpenedBlockGroup(obj.cgroup);
                setOpenlist(true);
              }}
            />
          )}
        </ListItemButton>
        <Collapse
          in={obj.cgroup == openedBlockGroup && openlist}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {obj.childs.map((obj2, j) => {
              var cn2 = obj2.selected
                ? " menuPrimarySecond selected"
                : "menuPrimarySecond ";
              return (
                <ListItemButton className={cn2}>
                  {obj2.url ? (
                    <MyNavBt href={obj2.url} _setmlgenobj={_setmlgenobj}>
                      <Tooltip title={obj2.label} placement="bottom">
                        <span>{obj2.label}</span>
                      </Tooltip>
                    </MyNavBt>
                  ) : (
                    <>{obj2.label}</>
                  )}
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </>
    ) : (
      <ListItemButton className={cn}>
        {obj.url ? (
          <MyNavBt href={obj.url} _setmlgenobj={_setmlgenobj}>
            <Tooltip title={obj.label} placement="bottom">
              <span>{obj.label}</span>
            </Tooltip>
          </MyNavBt>
        ) : (
          <>{obj.label}</>
        )}
      </ListItemButton>
    );
  };

  const processGroups = () => {
    return (
      <List>
        {menuGroups.map((obj, i) => {
          return processGroupsItem(obj, i);
        })}
      </List>
    );
  };

  return menuGroups && menuGroups.length ? (
    <Box>
      <DrawerSecond variant="permanent" open={isopened}>
        <DrawerHeader />

        <Divider />

        {processGroups()}
      </DrawerSecond>

      <IconButton
        className="drawerOCbtn OCclosed"
        color="inherit"
        aria-label="open drawer"
        size="small"
        onClick={() => {
          setIsOpened(true);
        }}
        edge="start"
        sx={{
          marginRight: 2,
          marginLeft: -1.5,
          ...(isopened && { display: "none" }),
        }}
      >
        <ChevronRightIcon />
      </IconButton>

      <IconButton
        className="drawerOCbtn OCopened"
        color="inherit"
        size="small"
        onClick={() => {
          setIsOpened(false);
        }}
        sx={{
          marginRight: 2,
          marginLeft: -1.5,
          ...(!isopened && { display: "none" }),
        }}
      >
        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </Box>
  ) : (
    <Box style={{ width: 60 }}></Box>
  );
};

export { MySecondDrawer };
