
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class EvidentaSesizariDTO {


  getMainObjPart() { return "EvidentaSesizari"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datainregistrareint",
      "orainregistrareint",
      "minutinregistrareint",
      "datainregistraremicrotime",
      "datatransmiteremicrotime",
      "datatransmitereint",
      "oratransmitereint",
      "minuttransmitereint",
      "status",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "datainregistrare_text",
      "datainregistraretime_text",
      "datatransmitere_text",
      "datatransmiteretime_text",
      
      "numepersoanaresponsabila",
      "prenumepersoanaresponsabila",
      "contractormentenanta",
      
      "note",
      "defectiune",
      "mentiuniindeplinire",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "statusobj", _key: "Status" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   


  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.datainregistrare_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];
  _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_evidentasesizari"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "datainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      };
      _headCells[_headCells.length] ={
        id: "orainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating_ora_min"),
      };
      _headCells[_headCells.length] ={
        id: "datatransmitereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Transmitere data"),
      }
      _headCells[_headCells.length] ={
        id: "oratransmitereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Transmitere_ora_min"),
      }
      
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of evidentasesizari"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "numepersoanaresponsabila",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numepersoanaresponsabila"),
      };
      _headCells[_headCells.length] ={
        id: "prenumepersoanaresponsabila",
        numeric: false,
        disablePadding: true,
        label: LL("TD_prenumepersoanaresponsabila"),
      };
      _headCells[_headCells.length] ={
        id: "contractormentenanta",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contractormentenanta"),
      };
      _headCells[_headCells.length] ={
        id: "defectiune",
        numeric: false,
        disablePadding: true,
        label: LL("TD_defectiune"),
      };
      _headCells[_headCells.length] ={
        id: "mentiuniindeplinire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_mentiuniindeplinire"),
      };
      _headCells[_headCells.length] ={
        id: "status",
        numeric: false,
        disablePadding: true,
        label: LL("TD_status"),
      };
      _headCells[_headCells.length] ={
        id: "note",
        numeric: false,
        disablePadding: true,
        label: LL("TD_note"),
      };

    return _headCells;
  }

}
