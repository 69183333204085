import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";


import { useResource } from "hook/useResource";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import Link from "@mui/material/Link";

import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";

const GenAutocompleteSearch = ({
  obj,
  setObj,
  _idfield,
  _objfield,
  _defaultobjdto,
  _label,
  _otherdata,
  _showfield,
  _multiple,
  _loadonempty,
  ...props
}) => {
  const { LL } = useResource(false);
  

  var tobjects = obj != undefined && obj[_objfield] ? obj[_objfield] : [];
  var tvalue = obj != undefined && obj[_idfield] ? obj[_idfield] : null;
  var tshowfield = _showfield != undefined && _showfield ? _showfield : "denumire";
  
  var _multipleattr = _multiple != undefined ? _multiple : true;
  var _loadonempty = _loadonempty != undefined ? _loadonempty : false;
  
  const [loadonempty, setLoadOnEmpty] = useState(_loadonempty);
  const [multiple, setMultiple] = useState(_multipleattr);

  const [showfield, setShowField] = useState(tshowfield);
  const [idfield, setIdField] = useState(_idfield);
  const [objfield, setObjField] = useState(_objfield);

  const [defaultValues, setDefaultValues] = useState(tobjects);
  const [objects, setObjects] = useState(tobjects);

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isloaded, setIsLoaded] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const [label, setLabel] = useState("");

  const [defaultobjdto, setDefaultObjDTO] = React.useState(_defaultobjdto);
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());
    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);

    setIdField(_idfield);
    setObjField(_objfield);
    setDefaultObjDTO(_defaultobjdto);
  }, [
    options,
    obj,
    _label,
    _idfield,
    _objfield,
    _defaultobjdto,
    _label,
    _otherdata,
  ]);

  const loadData = () => {
    if (!search && !loadonempty) {
      setOptions([]);
      setLoading(false);
      return;
    }
    
    var filter = otherdata && otherdata.filter ? otherdata.filter : {};
    if (search) filter.search = search;
    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      filter,
      false,
      false,
      false,
      0,
      20,
      setLoading,
      setOptions,
      false
    );

  };

  useEffect(() => {
    loadData();
  }, [obj, _idfield, _objfield, search, otherdata]);

  useEffect(() => {
    if (multiple) {
      var tarr = [];
      for (var i in objects) {
        tarr[tarr.length] = objects[i].id;
      }
      setValues(tarr);
    } else {
      if (objects) {
        setValues(objects.id);
      } else {
        setValues(false);
      }
    }
  }, [objects]);

  useEffect(() => {
    if (isloaded) return;
    setDefaultValues(tobjects);
    setObjects(tobjects);
    setIsLoaded(true);
  }, [options]);


  useEffect(() => {
    if (!isloaded) return;
    if (!idfield) return;
    if (!objfield) return;
    if (!obj) return;
    if (!setObj) return;
    if (setObj == undefined) return;
    if (setObj == "undefined") return;

    var _obj = obj;

    if (multiple) {  
      var ids = [];
      for (var i in objects) {
        ids[ids.length] = objects[i].id;
      }

      _obj[idfield] = ids;
      _obj[objfield] = objects;
      setObj(_obj);
    } else {
      _obj[idfield] = objects.id;
      _obj[objfield] = objects;
    }

    setObj(_obj);
  }, [values]);

  const handleChange = (value) => {
    setObjects(value);
  };

  useEffect(() => {
    if (!open) {
      setSearch("");
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      multiple={multiple}
      fullWidth
      open={open}
      defaultValue={defaultValues}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.getLabel()}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          color="secondary"
          margin="normal"
          variant="standard"
          {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export { GenAutocompleteSearch };
