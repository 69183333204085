import { FetchGet, FetchPost } from "api/RequestTools";


import { logger } from "hoc/logger";
import { CommonTools } from "api/CommonTools";

import uuid from "react-uuid";

import MD5 from "crypto-js/md5";
import { DTOTools } from "api/DTOTools";

export class GenTypeDenumireDTO {
  
  getFieldsInt() {
    return [
      "id",      
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "denumire",
    ];
  }

  getFieldsFloat() {
    return [
    ];
  }

  getFieldsIntArray() {
    return [
    ];
  }

  getFieldsStrArray() {
    return [
    ];
  }
  getFieldsDTOObjects() {
    return [
     
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
    ];
  }
  getFieldsDTOObjectsArrayLink() {
    return [

    ];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
  }

  getDefaultOrderBy() {
    return "denumire";
  }

  getLabel() {
    return this.denumire;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
      otherdata.uuidsac != undefined &&
      otherdata.uuidsac
        ? otherdata.uuidsac
        : false;

    var _headCells = [];

    _headCells[_headCells.length] = {
      id: "denumire",
      numeric: false,
      disablePadding: true,
      label: LL("TD_denumire"),
    };

    return _headCells;
  }

}
