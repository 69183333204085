import React from "react";

import { useEffect, useState } from "react";
import { MessagesContainer } from "components/elements/Messages/MessagesContainer";

import { MainLayout } from "components/core/MainLayout/MainLayout";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import Link from "@mui/material/Link";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { GenAutocompleteSearch } from "components/elements/Autocomplete/GenAutocompleteSearch";

import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";

const AutocompleteFilterFondator = (
  // { cb, _object, filterobj, _multiple, _tobj, ...props }) => {
  { cb, _object, obj, _multiple, _tobj, _otherdata, ...props }
) => {
  const { LL } = useResource(false);

  var _label = obj && obj.label != undefined ? obj.label : "";

  const [label, setLabel] = useState("");

  var _multipleattr = _multiple != undefined ? _multiple : true;
  var tidfield = "values";
  var tobjfield = "valuesobjects";

  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);
  const [multiple, setMultiple] = useState(_multipleattr);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new PersoanaFizicaDTO()
  );
  const [otherdata, setOtherData] = React.useState({});

  const updateFilter = (obj) => {
    // if (!isloaded) return;
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;
  
    cb(obj, "", obj[idfield]);
  };

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label]);

  return (
    <GenAutocompleteSearch
      obj={obj}
      setObj={updateFilter}
      _multiple={multiple}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _otherdata={otherdata}
    />
  );
};

export { AutocompleteFilterFondator };
