import { logger } from "hoc/logger";

class RouteTools {
  // -----------------------------------------
  static parseLocation_general(tpaths, parsed, suffix) {
    var mo = suffix;
    var info = {};
    info = { ...parsed, ...info };

    if (tpaths[1] == "add") {
      mo = "Add" + suffix;
    } else if (tpaths[1] == "detail" && tpaths[2]) {
      mo = "Detail" + suffix;
      info.uuid = tpaths[2];
    } else if (tpaths[1] == "edit" && tpaths[2]) {
      mo = "Edit" + suffix;
      info.uuid = tpaths[2];
    }

    logger("RouteTools2222", tpaths, mo, parsed, info);

    return { _mainobject: mo, _info: info };
  }

  // -----------------------------------------
  static parseLocation_gestiunesac(tpaths, parsed, location) {
    var info = {};
    info = { ...parsed, ...info };
    info.uuidsac = tpaths[1];
    info._hashinfo = tpaths[1];

    var tp = tpaths.slice(2);

    var modul = tp.length ? tp[0] : "";

    switch (modul) {
      case "dashboard":
        return RouteTools.parseLocation_general(tp, info, "GSACDashBoard");
      case "aparatcafea":
        return RouteTools.parseLocation_general(tp, info, "GSACAparatCafea");
      case "cameravideo":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACCameraVideo"
        );
      case "piloniluminare":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACPilonIluminare"
        );
      case "corpiluminarestradal":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACCorpIluminareStradal"
        );
      case "mobilier":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACMobilier"
        );
      case "utilajincalzire":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACUtilajIncalzire"
        );
      case "puncttransformare":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACPunctTransformare"
        );
      case "traseuelectric":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACTraseuElectric"
        );
      case "registratorvideo":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACRegistratorVideo"
        );
      case "utilajtehnologic":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACUtilajTehnologic"
        );
      case "panoupret":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACPanouPret"
        );
      case "generatorelectric":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACGeneratorElectric"
        );
      case "transformatorelectric":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACTransformatorElectric"
        );
      case "pistoldistribuitor":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACPistolDistribuitor"
        );
      case "avizexpertizaecologica":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACAvizExpertizaEcologica"
        );
      case "evidentadeserviretehnicapetrol":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaDeservireTehnicaPetrol"
        );
      case "evidentadeserviretehnicagaz":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaDeservireTehnicaGaz"
        );
      case "autorizatieemisiepoluanti":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACAutorizatieEmisiePoluanti"
        );
      case "evidentadistribuitoare":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaDistribuitoare"
        );
      case "taxacomercializarebenzia":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACTaxaComercializareBenzia"
        );
      case "evidentacontrol":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaControl"
        );
      case "evidentasesizari":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaSesizari"
        );
      case "raportinterventie":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACRaportInterventie"
        );
      case "rca":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACRCA"
        );
      case "casco":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACCASCO"
        );
      case "revizietehnica":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACRevizieTehnica"
        );
      case "deserviretehnica":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACDeservireTehnica"
        );
      case "evidentaautorizatiesanitara":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaAutorizatieSanitara"
        );
      case "evidentacontor":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaContor"
        );
      case "evidentaverificaremetrologica":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaVerificareMetrologica"
        );
      case "evidentaechipamentantiincendiar":
        return RouteTools.parseLocation_general(
          tp, info,
          "GSACEvidentaEchipamentAntiincendiar"
        );

      default:
        return RouteTools.parseLocation_homepage(location, parsed, tpaths);
    }
  }

  // -----------------------------------------
  static parseLocation_homepage(location, parsed, tpaths) {
    var info = {};
    info = { ...parsed, ...info };

    return { _mainobject: "HomePage", _info: info };
  }

  // -----------------------------------------
  static parseLocation(location) {
    const queryString = require("query-string");

    const parsed = queryString.parse(location.search);

    logger(
      "RouteTools123123123",
      location,
      location.pathname,
      location.search,
      location.hash,
      location.state,
      location.key,
      "-----------",
      parsed,
      queryString
    );

    var tpaths = location.pathname.split("/");
    tpaths = tpaths.filter(Boolean);

    var modul = tpaths.length ? tpaths[0] : "";

    logger("RouteTools222", modul, tpaths, parsed);

    switch (modul) {
      case "gestiunesac":
        return RouteTools.parseLocation_gestiunesac(tpaths, parsed, location);
      case "pistoldistribuitor":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "PistolDistribuitor"
        );
      case "transformatorelectric":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransformatorElectric"
        );
      case "generatorelectric":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "GeneratorElectric"
        );
      case "panoupret":
        return RouteTools.parseLocation_general(tpaths, parsed, "PanouPret");
      case "typesetarepanoupret":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeSetarePanouPret"
        );
      case "utilajtehnologic":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "UtilajTehnologic"
        );
      case "aparatcafea":
        return RouteTools.parseLocation_general(tpaths, parsed, "AparatCafea");
      case "registratorvideo":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "RegistratorVideo"
        );
      case "cameravideo":
        return RouteTools.parseLocation_general(tpaths, parsed, "CameraVideo");
      case "traseuelectric":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TraseuElectric"
        );
      case "puncttransformare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "PunctTransformare"
        );
      case "utilajincalzire":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "UtilajIncalzire"
        );
      case "mobilier":
        return RouteTools.parseLocation_general(tpaths, parsed, "Mobilier");
      case "corpiluminarestradal":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "CorpIluminareStradal"
        );
      case "piloniluminare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "PilonIluminare"
        );
      case "dosarsac":
        return RouteTools.parseLocation_general(tpaths, parsed, "DosarSAC");
      case "typefunctie":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeFunctie");
      case "typedistribuitoare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeDistribuitoare"
        );
      case "typepistolpompa":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypePistolPompa"
        );
      case "typenumarcanaleregistratoare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeNumarCanaleRegistratoare"
        );
      case "typemobilier":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeMobilier");
      case "typeindiceprezentassg":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeIndicePrezentaSSG"
        );
      case "typematerial":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeMaterial");
      case "typeinventargospodaresc":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeInventarGospodaresc"
        );
      case "typeechipamentantiincendiar":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeEchipamentAntiincendiar"
        );
      case "typeutilajtehnologic":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeUtilajTehnologic"
        );
      case "typedestinatiemcc":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeDestinatieMCC"
        );
      case "typepuncttransformare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypePunctTransformare"
        );
      case "typetraseu":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeTraseu");
      case "typereteaelectricaexterna":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeReteaElectricaExterna"
        );
      case "typecameravideo":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeCameraVideo"
        );
      case "typepanoupret":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypePanouPret"
        );
      case "typesistemcasa":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeSistemCasa"
        );
      case "typerobinetautomat":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeRobinetAutomat"
        );
      case "typemontare":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeMontare");
      case "typeiluminare":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeIluminare"
        );
      case "typeutilajincalzire":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeUtilajIncalzire"
        );
      case "typeinterventie":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeInterventie");
      case "typecorp":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeCorp");
      case "typemotivinterventie":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeMotivInterventie");
      case "typepilon":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypePilon");
      case "typecarosabil":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeCarosabil"
        );
      case "typebrand":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeBrand");
      case "typemodelauto":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeModelAuto"
        );
      case "typeauto":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeAuto");
      case "typecombustibil":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeCombustibil"
        );
      case "typemarcaauto":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeMarcaAuto"
        );
      case "typephone":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypePhone");
      case "typedocument":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeDocument");
      case "typeum":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeUM");
      case "typebunimobil":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeBunImobil"
        );
      case "typesex":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeSex");
      case "typestudii":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeStudii");
      case "typefolosinta":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TypeFolosinta"
        );
      case "typehotar":
        return RouteTools.parseLocation_general(tpaths, parsed, "TypeHotar");
      case "bunimobil":
        return RouteTools.parseLocation_general(tpaths, parsed, "BunImobil");
      case "transportauto":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAuto"
        );
      case "user":
        return RouteTools.parseLocation_general(tpaths, parsed, "User");
      case "roles":
        return RouteTools.parseLocation_general(tpaths, parsed, "Roles");
      case "rolesacl":
        return RouteTools.parseLocation_general(tpaths, parsed, "RolesACL");
      case "usersac":
        return RouteTools.parseLocation_general(tpaths, parsed, "UserSac");
      case "jurnal":
        return RouteTools.parseLocation_general(tpaths, parsed, "Jurnal");
      case "label":
        return RouteTools.parseLocation_general(tpaths, parsed, "Label");
      case "persoanafizica":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "PersoanaFizica"
        );
      case "persoanajuridica":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "PersoanaJuridica"
        );
      case "banca":
        return RouteTools.parseLocation_general(tpaths, parsed, "Banca");
      case "street":
        return RouteTools.parseLocation_general(tpaths, parsed, "Street");
      case "document":
        return RouteTools.parseLocation_general(tpaths, parsed, "Document");
      case "avizexpertizaecologica":
        return RouteTools.parseLocation_general(tpaths, parsed, "AvizExpertizaEcologica");
      case "evidentadeserviretehnicapetrol":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaDeservireTehnicaPetrol");
      case "evidentadeserviretehnicagaz":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaDeservireTehnicaGaz");
      case "evidentacontrol":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaControl");
      case "evidentasesizari":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaSesizari");
      case "raportinterventie":
        return RouteTools.parseLocation_general(tpaths, parsed, "RaportInterventie");
      case "evidentaautorizatiesanitara":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaAutorizatieSanitara");
      case "evidentacontor":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaContor");
      case "evidentaverificaremetrologica":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaVerificareMetrologica");
      case "evidentaechipamentantiincendiar":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaEchipamentAntiincendiar");
      case "autorizatieemisiepoluanti":
        return RouteTools.parseLocation_general(tpaths, parsed, "AutorizatieEmisiePoluanti");
      case "evidentadistribuitoare":
        return RouteTools.parseLocation_general(tpaths, parsed, "EvidentaDistribuitoare");
      case "taxacomercializarebenzia":
        return RouteTools.parseLocation_general(tpaths, parsed, "TaxaComercializareBenzia");
      case "rca":
        return RouteTools.parseLocation_general(tpaths, parsed, "RCA");
      case "casco":
        return RouteTools.parseLocation_general(tpaths, parsed, "CASCO");
      case "revizietehnica":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "RevizieTehnica"
        );
      case "deserviretehnica":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "DeservireTehnica"
        );
      case "transportautocascolink":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAutoCASCOLink"
        );
      case "transportautodocumentlink":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAutoDocumentLink"
        );
      case "transportautorcalink":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAutoRCALink"
        );
      case "transportautodeserviretehnicalink":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAutoDeservireTehnicaLink"
        );
      case "transportautorevizietehnicalink":
        return RouteTools.parseLocation_general(
          tpaths,
          parsed,
          "TransportAutoRevizieTehnicaLink"
        );
      default:
        return RouteTools.parseLocation_homepage(location, parsed, tpaths);
    }
  }

  // -----------------------------------------
  static setHistory(_tobj, location) {
    if (_tobj._route == undefined) return;
    if (!_tobj._route) return;

    logger("whisorysetHistory", _tobj);

    window.history.pushState(
      // history.pushState(
      _tobj,
      // { _mainobject: _tobj._mainobject, _otherdata: _tobj._otherdata },
      null,
      _tobj._route
    );

    var popStateEvent = new PopStateEvent("popstate", _tobj);
    dispatchEvent(popStateEvent);

    // logger("whisory setHistory", popStateEvent);
  }

  // -----------------------------------------
}

export { RouteTools };
