import React from "react";

import { styled, useTheme } from "@mui/material/styles";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { TopPart } from "components/general/TopPart";

const MyAppBar = ({
  _setmlgenobj,
  _otherdata,
  _currentpath,
  _mainobject,
  ...props
}) => {
  
  const theme = useTheme();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

  return (
    <AppBar
      
      color="primary"
      sx={{ zIndex: 1204 }}
    >
      <Toolbar className="toolbarLogo">
        <TopPart _setmlgenobj={_setmlgenobj} _mlotherdata={_otherdata} />
      </Toolbar>
    </AppBar>
  );
};

export { MyAppBar };
