
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class EvidentaDistribuitoareDTO {


  getMainObjPart() { return "EvidentaDistribuitoare"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      
      "idtypedocument",
      "idscan",
      "idtypepistolpompa",
      "idtypesistemcasa",

      "datavalabilitateavizint",
      "dataverificarestatutint",

      "datavalabilitateavizmicrotime",
      "dataverificarestatutmicrotime",
      
      "dataproducereint",
      "dataproduceremicrotime"
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      
      "nrpompe",
      "nrrezervoare",
      "supapafrina", 
      
      "computer",
      "numedistribuitor", 

      "propuneri",
      "note", 
      "datavalabilitateaviz_text",
      "datavalabilitateaviztime_text",
      "dataverificarestatut_text",
      "dataverificarestatuttime_text",
      "dataproducere_text",
      "dataproduceretime_text",

      "organcontrol",
      "lastcontrol",
      "planedcontrol"
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "typepistolpompaobj", _key: "TypePistolPompa" },
      { field: "typesistemcasaobj", _key: "TypeSistemCasa" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   
  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];

      _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_autorizatieemisiepoluanti"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "datavalabilitateavizint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_valabilitateaviz data"),
      };
      _headCells[_headCells.length] ={
        id: "dataverificarestatutint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_verificarestatut data"),
      };
      _headCells[_headCells.length] ={
        id: "dataproducereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_producere data"),
      };
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of autorizatieemisiepoluanti"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "organcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_organcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "lastcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_lastcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "planedcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_planedcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "nrrezervoare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_nrrezervoare"),
      };
      _headCells[_headCells.length] ={
        id: "nrpompe",
        numeric: false,
        disablePadding: true,
        label: LL("TD_nrpompe"),
      };
      _headCells[_headCells.length] ={
        id: "supapafrina",
        numeric: false,
        disablePadding: true,
        label: LL("TD_supapafrina"),
      };
      _headCells[_headCells.length] ={
        id: "computer",
        numeric: false,
        disablePadding: true,
        label: LL("TD_computer"),
      };
      _headCells[_headCells.length] ={
        id: "numedistribuitor",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numedistribuitor"),
      };
      _headCells[_headCells.length] ={
        id: "propuneri",
        numeric: false,
        disablePadding: true,
        label: LL("TD_propuneri"),
      };
      _headCells[_headCells.length] ={
        id: "note",
        numeric: false,
        disablePadding: true,
        label: LL("TD_note"),
      };
      _headCells[_headCells.length] ={
        id: "idtypepistolpompa",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypepistolpompa"),
      };
      _headCells[_headCells.length] ={
        id: "idtypesistemcasa",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypesistemcasa"),
      };
    return _headCells;
  }

}
