import {
  React,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import "./leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";

import { Icon } from "leaflet";
// import covidData from "./data.json";
// import icon1 from "./images/covid19.svg";

import L from "leaflet";

import { logger } from "hoc/logger";

import { Loading } from "components/general/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import uuid from "react-uuid";

import { LocationOSMDTO } from "dto/location/LocationOSMDTO";

import { DTOTools } from "api/DTOTools";


import { useResource } from "hook/useResource";

import { PersoanaJuridicaDTO } from "dto/nomenclatoare/PersoanaJuridicaDTO";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";

import Link from "@mui/material/Link";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";


const MapOSSugestion = ({ _cb, _cbcenter, _search, ...props }) => {
  
  const { LL } = useResource(false);
  
  _cb = _cb != undefined ? _cb : false;
  _cbcenter = _cbcenter != undefined ? _cbcenter : false;
  _search = _search != undefined ? _search : _search;

  const [defaultobjdto, setDefaultObjDTO] = useState(new LocationOSMDTO());
  const [otherdata, setOtherData] = useState({});

  const [search, setSearch] = useState(_search);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [obj, setObj] = useState(false);
  const [loading, setLoading] = useState(false);

  const [place_id, setPlaceId] = useState(0);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  useEffect(() => {
    if (!_cb) return;
    _cb(place_id, lat, lng);
  }, [place_id,lat,lng]);

  useEffect(() => {
    loadSugestions();
  }, [search]);

  useEffect(() => {
    loadSugestions();
  }, [search]);

  useEffect(() => {
    if (options.length) {
      var t = options[0];

      if (_cbcenter) {
        _cbcenter(t.lat, t.lon);
      }
    }
    
  }, [options]);

  const loadSugestions = () => {
    if (!search) {
      setOptions([]);
      setLoading(false);
      setPlaceId(0);
      return;
    }

    DTOTools.execLoadObjects(
      defaultobjdto,
      otherdata,
      { q: search },
      false,
      false,
      false,
      0,
      20,
      setLoading,
      setOptions,
      false,
      "",
      { _usedirectdata: 1},
      "opssearchlocation",
      true
    );
  };

  const handleChange = (_obj) => {
    setObj(_obj);
  };

  useEffect(() => {
    if (obj) {
      setPlaceId(obj.place_id);
      setLat(obj.lat);
      setLng(obj.lon);
    } else {
      setPlaceId(0);
      setLat(0);
      setLng(0);
    }
  }, [obj]);

  // ---------------------
  // ---------------------
  // ---------------------

  
  const renderOption = (props, _option) => {
    logger("MapOSSugestion1111_renderOption", _option);
    return (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, } }} {...props}>
        
       <Box sx={{p:0, m:0}}>
        <Typography class="LocationHintName">{_option.display_name ? _option.display_name : ""}</Typography>
        <Typography class="LocationHintNameType">{_option.type ? _option.type : ""}</Typography>
        </Box>
        <img
            loading="lazy"
            width="20"
            src={_option.icon}
            alt=""
          />
      </Box>
    );
  };


  return (
      <Autocomplete
        id="asynchronous-demo"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, value) => {
          handleChange(value);
        }}
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id
        }
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => renderOption(props, option)}
        options={options}
        loading={loading}
        filterOptions={(x) => {
          return x;
        }}
        renderInput={(params) => (
          <TextField
            color="secondary"
            margin="normal"
            variant="standard"
            {...params}
            label={LL("Locatii")}
            // onChange={(event) => {
            //   setSearch(event.target.value);
            // }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
  );
};
export default MapOSSugestion;
