import React from "react";

import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import DateObject from "react-date-object";
import Typography from "@mui/material/Typography";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

const MyRangeSlider = ({ cb, filterobj, ...props }) => {
  
  const { LL } = useResource(false);
  var label = filterobj && filterobj.label != undefined ? filterobj.label : "An fabricare";
  var _startyear =
    filterobj &&
      filterobj.values[0] != undefined &&
      filterobj.values[0] &&
      filterobj.values[0] != -1
      ? filterobj.values[0]
      : 1990;
  var _endyear =
    filterobj &&
      filterobj.values[1] != undefined &&
      filterobj.values[1] &&
      filterobj.values[1] != -1
      ? filterobj.values[1]
      : new Date().getFullYear();
  var _label = filterobj && filterobj.label != undefined ? filterobj.label : "";
  
  const [valueT, setValue] = useState([_startyear, _endyear]);
  const [filter, setFilter] = useState(filterobj);
  const ExecCallBack = () => {
    if (!cb) return;
    if (cb == undefined) return;
    if (cb == "undefined") return;

    var value = "";
    var values = [];
    values[values.length] = valueT[0] ? valueT[0] : -1;
    values[values.length] = valueT[1]? valueT[1] : -1;
    
    logger("MyRangeSlider 2344", values,valueT);
    cb(filter, value, values);
  };

  useEffect(() => {
    ExecCallBack();
  }, [valueT]);
  
  const valuetext = (value) => {
    return `${value}`;
  };
  const handleChange = (event, newValue) => {
    logger("MyRangeSlider 234", newValue,valueT[1]);
    setValue(newValue);
  };

  return (
    <Box >
     <Typography sx={{fontSize:13, fontWeight:'bold'}}>{label}</Typography>
      <Slider
        value={valueT}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={1990}
        step={1}
        max={new Date().getFullYear()}
      />
    </Box>
  );
}

export { MyRangeSlider };