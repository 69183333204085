import React from "react";

import { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";


import { DTOTools } from "api/DTOTools";
import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import uuid from "react-uuid";

import TextField from "@mui/material/TextField";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CloseIcon from '@mui/icons-material/Close';

import { FilterTools } from "api/FilterTools";

import { MyDateRangePicker } from "components/elements/MyDateRangePicker/MyDateRangePicker";
import { MyDateRangePickerShow } from "components/elements/MyDateRangePicker/MyDateRangePickerShow";
import { AutocompleteFilterTypeImobil }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeImobil";

import { AutocompleteFilterTypeImobilShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeImobilShow";
import { AutocompleteFilterTypeFolosinta }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeFolosinta";
import { AutocompleteFilterTypeFolosintaShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeFolosintaShow";

import { AutocompleteFilterTypeCombustibil }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeCombustibil";
import { AutocompleteFilterTypeCombustibilShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeCombustibilShow";

import { AutocompleteFilterTypeModelAuto }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeModelAuto";
import { AutocompleteFilterTypeModelAutoShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeModelAutoShow";
import { AutocompleteFilterTypeMarcaAuto }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeMarcaAuto";
import { AutocompleteFilterTypeMarcaAutoShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeMarcaAutoShow";

import { AutocompleteFilterTypeSex }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeSex";
import { AutocompleteFilterTypeSexShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeSexShow";

import { AutocompleteFilterTypeStudii }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeStudii";
import { AutocompleteFilterTypeStudiiShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeStudiiShow";

import { AutocompleteFilterFondator }
  from "components/elements/AutocompleteFilter/AutocompleteFilterFondator";
import { AutocompleteFilterFondatorShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterFondatorShow";

import { AutocompleteFilterStreet }
  from "components/elements/AutocompleteFilter/AutocompleteFilterStreet";
import { AutocompleteFilterStreetShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterStreetShow";

import { AutocompleteFilterYesNo }
  from "components/elements/AutocompleteFilter/AutocompleteFilterYesNo";
import { AutocompleteFilterYesNoShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterYesNoShow";

import { AutocompleteFilterUser }
  from "components/elements/AutocompleteFilter/AutocompleteFilterUser";
import { AutocompleteFilterUserShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterUserShow";

import { AutocompleteFilterTypeJurnal }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeJurnal";
import { AutocompleteFilterTypeJurnalShow }
  from "components/elements/AutocompleteFilter/AutocompleteFilterTypeJurnalShow";

import { MyRangeSlider } from "components/elements/MyRangeSlider/MyRangeSlider";
import { MyRangeSliderShow } from "components/elements/MyRangeSlider/MyRangeSliderShow";

const EnhancedTableToolbar = ({
  numSelected,
  label,
  filters,
  _processFilters,

  selected,
  setSelected,
  setIsLoading,
  defaultobjdto,
  _setmlmessages,
  setLocalHash,
  otherdata,
  objects,
  setObjects,
  specialsuffix,
  ...props
}) => {
  const { LL } = useResource(false);

  const [_filters, setFilters] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? uuid() : undefined;

  useEffect(() => {
    if (filters == undefined) {
      setFilters(false);
    }
    setFilters(filters);
  }, [filters]);

  const handleClick = (event) => {
    if (!_filters) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!_filters) return;
    setAnchorEl(null);
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      handleSendFilters();
    }
  };

  const processTopAction = (action) => {
    logger("processTopAction_action", action);

    if (action == "delete") {
      var params = {};
      params.uuids = selected.join();

      setIsLoading(true);

      DTOTools.execDelObjects(
        defaultobjdto,
        params,
        _setmlmessages,
        setLocalHash,
        setIsLoading,
        selected,
        setSelected,
        objects,
        setObjects,
        otherdata,
        specialsuffix
      );
    }
  };

  const updateFilter = (
    obj,
    value,
    values,
    sendfilters,
    showedvalue,
    type,
    exist
  ) => {
    if (!obj) return;
    if (sendfilters == undefined) sendfilters = false;
    if (values == undefined || !values) values = [value];
    if (type == undefined || !type) type = obj.type;

    logger("updateFilter33333", obj, value, values, type, sendfilters);

    var tf = _filters;

    if (!Array.isArray(tf)) return;

    var ntf = [];

    for (var i in tf) {
      var tobj = tf[i];
      if (tobj.key == obj.key) {
        tobj.value = value;
        tobj.values = values;
        tobj.type = type;
        if (showedvalue != undefined) tobj.showedvalue = showedvalue;
        if (exist != undefined) tobj.exist = exist;
      }

      ntf[ntf.length] = tobj;
    }

    setFilters(ntf);
    if (sendfilters) handleSendFilters();
  };

  const handleSendFilters = () => {
    if (_processFilters == undefined) return;

    var tf = FilterTools.getFiltersUrlPart(_filters);
    var t = FilterTools.parseFiltersUrl(tf);
    _processFilters(t, true);
  };

  const handleFiltersElement_TextValueChange = (e, obj) => {
    updateFilter(obj, e.target.value);
  };

  const processFiltersElement_TextValue = (obj, i) => {
    logger("processFilters 33333", obj, i);
    var name = "filtername_" + obj.key;
    return (
      <TextField
        type="text"
        fullWidth
        name={name}
        label={obj.label}
        color="secondary"
        size="small"
        margin="normal"
        value={obj.value}
        onChange={(e) => {
          handleFiltersElement_TextValueChange(e, obj);
        }}
        onKeyPress={handleKeyPress}
        variant="outlined"
      />
    );
  };

  const handleFiltersElement_RangeDateChange = (obj, value, values) => {
    logger("DateRange",values.values)
    if (values == undefined || values.values[0] == -1 || values.values[1] == -1) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    updateFilter(obj, value, values);
  };

  const processFiltersElement_RangeDate = (obj, i) => {
    logger("processFiltersElement_RangeDate_222", obj);
    return (
      <MyDateRangePicker
        cb={handleFiltersElement_RangeDateChange}
        filterobj={obj}
      />
    );
  };
  const handleFiltersElement_RangeSliderChange = (obj, value, values) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    updateFilter(obj, value, values);
  };

  const processFiltersElement_RangeSlider = (obj, i) => {
    logger("processFiltersElement_RangeSlider_1", obj);
    return (
      <MyRangeSlider
        cb={handleFiltersElement_RangeSliderChange}
        filterobj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeImobil = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteFilterTypeImobil = (obj, i) => {
    logger("processFiltersElement_AutocompleteFilterTypeImobil", obj);
    return (
      <AutocompleteFilterTypeImobil
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeFolosinta = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeFolosinta = (obj, i) => {
    return (
      <AutocompleteFilterTypeFolosinta
        cb={updateFilter}
        obj={obj}
      />
    );
  };
  const handleFiltersElement_AutocompleteFilterTypeCombustibil = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeCombustibil = (obj, i) => {
    return (
      <AutocompleteFilterTypeCombustibil
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeModelAuto = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeModelAuto = (obj, i) => {
    return (
      <AutocompleteFilterTypeModelAuto
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeMarcaAuto = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeMarcaAuto = (obj, i) => {
    return (
      <AutocompleteFilterTypeMarcaAuto
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeSex = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeSex = (obj, i) => {
    return (
      <AutocompleteFilterTypeSex
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterTypeStudii = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeStudii = (obj, i) => {
    return (
      <AutocompleteFilterTypeStudii
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterFondator = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteFondator = (obj, i) => {
    return (
      <AutocompleteFilterFondator
        cb={updateFilter}
        // filterobj={obj}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteFilterStreet = (
    obj,
    value,
    values
  ) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteStreet = (obj, i) => {
    return (
      <AutocompleteFilterStreet
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteYesNo = (obj, value, values) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteYesNo = (obj, i) => {
    return (
      <AutocompleteFilterYesNo
        cb={handleFiltersElement_AutocompleteYesNo}
        filterobj={obj}
        _nofirst
      />
    );
  };

  const handleFiltersElement_AutocompleteUser = (obj, value, values) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteUser = (obj, i) => {
    return (
      <AutocompleteFilterUser
        cb={updateFilter}
        obj={obj}
      />
    );
  };

  const handleFiltersElement_AutocompleteTypeJurnal = (obj, value, values) => {
    if (values == undefined) {
      updateFilter(obj, "", false, true, "", false);
      return;
    }
    logger("processFiltersElement_Autocomplete ", obj, values);
    updateFilter(obj, value, values);
  };

  const processFiltersElement_AutocompleteTypeJurnal = (obj, i) => {
    return (
      <AutocompleteFilterTypeJurnal
        cb={handleFiltersElement_AutocompleteTypeJurnal}
        filterobj={obj}
        nofirst
      />
    );
  };

  const processFiltersElement = (obj, i) => {
    logger("processFiltersElement2233", obj);
    if (obj.type == "textvalue") return processFiltersElement_TextValue(obj, i);
    if (obj.type == "rangedate") return processFiltersElement_RangeDate(obj, i);
    if (obj.type == "autocompletetypeimobil")
      return processFiltersElement_AutocompleteFilterTypeImobil(obj, i);
    if (obj.type == "autocompletetypefolosita")
      return processFiltersElement_AutocompleteTypeFolosinta(obj, i);
    if (obj.type == "autocompletetypecombustibil")
      return processFiltersElement_AutocompleteTypeCombustibil(obj, i);
    if (obj.type == "autocompletetypemodelauto")
      return processFiltersElement_AutocompleteTypeModelAuto(obj, i);
    if (obj.type == "autocompletetypemarcaauto")
      return processFiltersElement_AutocompleteTypeMarcaAuto(obj, i);
    if (obj.type == "rangeslider")
      return processFiltersElement_RangeSlider(obj, i);
    if (obj.type == "autocompletetypesex")
      return processFiltersElement_AutocompleteTypeSex(obj, i);
    if (obj.type == "autocompletetypestudii")
      return processFiltersElement_AutocompleteTypeStudii(obj, i);
    if (obj.type == "autocompletefondator")
      return processFiltersElement_AutocompleteFondator(obj, i);
    if (obj.type == "autocompletestreet")
      return processFiltersElement_AutocompleteStreet(obj, i);
    if (obj.type == "autocompleteyesno")
      return processFiltersElement_AutocompleteYesNo(obj, i);
    if (obj.type == "autocompleteuser")
      return processFiltersElement_AutocompleteUser(obj, i);
    if (obj.type == "autocompletetypejurnal")
      return processFiltersElement_AutocompleteTypeJurnal(obj, i);

    return <></>;
  };

  const processFiltersElementShow_TextValue = (obj, i) => {
    logger("processFiltersElementShow_TextValue 33333", obj, i);
    return (
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
          {obj.label}:
        </Typography>
        <Typography sx={{ marginRight: 1 }}>{obj.showedvalue}</Typography>

        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          size="small"
          sx={{ marginRight: 3 }}
          onClick={() => {
            updateFilter(obj, "", false, true, "", false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };

  const processFiltersElementShow_RangeDate = (obj, i) => {
    logger("processFiltersElementShow_RangeDate 33333", obj, i);
    return (
      <MyDateRangePickerShow
        cb={handleFiltersElement_RangeDateChange}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeImobil = (obj, i) => {
    logger("processFiltersElementShow_TypeImobil ", obj, i);
    return (
      <AutocompleteFilterTypeImobilShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeFolosinta = (obj, i) => {
    return (
      <AutocompleteFilterTypeFolosintaShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeCombustibil = (obj, i) => {
    return (
      <AutocompleteFilterTypeCombustibilShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeModelAuto = (obj, i) => {
    return (
      <AutocompleteFilterTypeModelAutoShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeMarcaAuto = (obj, i) => {
    return (
      <AutocompleteFilterTypeMarcaAutoShow

        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_RangeSlider = (obj, i) => {
    return (
      <MyRangeSliderShow
        cb={handleFiltersElement_RangeSliderChange}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeSex = (obj, i) => {
    return (
      <AutocompleteFilterTypeSexShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeStudii = (obj, i) => {
    return (
      <AutocompleteFilterTypeStudiiShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_Fondator = (obj, i) => {
    return (
      <AutocompleteFilterFondatorShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_Street = (obj, i) => {
    return (
      <AutocompleteFilterStreetShow

        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_YesNo = (obj, i) => {
    return (
      <AutocompleteFilterYesNoShow
        cb={handleFiltersElement_AutocompleteYesNo}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_User = (obj, i) => {
    return (
      <AutocompleteFilterUserShow
        cb={updateFilter}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow_TypeJurnal = (obj, i) => {
    return (
      <AutocompleteFilterTypeJurnalShow
        cb={handleFiltersElement_AutocompleteTypeJurnal}
        filterobj={obj}
      />
    );
  };

  const processFiltersElementShow = (obj, i) => {
    if (!obj.exist) return <></>;

    if (obj.type == "textvalue")
      return processFiltersElementShow_TextValue(obj, i);
    if (obj.type == "rangedate")
      return processFiltersElementShow_RangeDate(obj, i);
    if (obj.type == "autocompletetypeimobil")
      return processFiltersElementShow_TypeImobil(obj, i);
    if (obj.type == "autocompletetypefolosita")
      return processFiltersElementShow_TypeFolosinta(obj, i);
    if (obj.type == "autocompletetypecombustibil")
      return processFiltersElementShow_TypeCombustibil(obj, i);
    if (obj.type == "autocompletetypemodelauto")
      return processFiltersElementShow_TypeModelAuto(obj, i);
    if (obj.type == "autocompletetypemarcaauto")
      return processFiltersElementShow_TypeMarcaAuto(obj, i);
    if (obj.type == "rangeslider")
      return processFiltersElementShow_RangeSlider(obj, i);
    if (obj.type == "autocompletetypesex")
      return processFiltersElementShow_TypeSex(obj, i);
    if (obj.type == "autocompletetypestudii")
      return processFiltersElementShow_TypeStudii(obj, i);
    if (obj.type == "autocompletefondator")
      return processFiltersElementShow_Fondator(obj, i);
    if (obj.type == "autocompletestreet")
      return processFiltersElementShow_Street(obj, i);
    if (obj.type == "autocompleteyesno")
      return processFiltersElementShow_YesNo(obj, i);
    if (obj.type == "autocompleteuser")
      return processFiltersElementShow_User(obj, i);
    if (obj.type == "autocompletetypejurnal")
      return processFiltersElementShow_TypeJurnal(obj, i);

    return <></>;
  };

  const processFiltersShow = () => {
    logger("processFiltersprocessFiltersShow", _filters);
    return (
      <>
        {_filters.map((obj, i) => {
          return processFiltersElementShow(obj, i);
        })}
      </>
    );
  };

  const processFilters = () => {
    logger("processFilters", _filters);
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 2, paddingTop: 0 }}>
          {_filters.map((obj, i) => {
            return processFiltersElement(obj, i);
          })}

          <Button
            color="secondary"
            variant="contained"
            type="button"
            style={{ marginRight: "auto" }}
            onClick={handleSendFilters}
          >
            {LL("ApplyFilter")}
          </Button>
        </Box>
      </Popover>
    );
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {LL("Selected")}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {label}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title={LL("Delete")}>
          <IconButton
            onClick={() => {
              processTopAction("delete");
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : _filters ? (
        <>
          {processFiltersShow()}
          <Tooltip
            title={LL("Filter")}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>

          {processFilters()}
        </>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

export { EnhancedTableToolbar };
