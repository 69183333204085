import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypePhoneDTO } from "dto/nomenclatoare/TypePhoneDTO";

const TypePhone = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(new TypePhoneDTO());

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { TypePhone };
