import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypePistolPompaDTO } from "dto/nomenclatoare/TypePistolPompaDTO";

const TypePistolPompa = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypePistolPompaDTO()
  );

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { TypePistolPompa };
