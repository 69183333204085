
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class RaportInterventieDTO {


  getMainObjPart() { return "RaportInterventie"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datainregistrareint",
      "orainregistrareint",
      "minutinregistrareint",
      "datainregistraremicrotime",
      "idevidentasesizari",
      "status",

      "idtypemotivinterventie",
      "idtypeinterventie",
      
      "dataincepereint",
      "oraincepereint",
      "minutincepereint",
      
      "datafinisareint",
      "orafinisareint",
      "minutfinisareint",

      "echipamentinstarefunctionare",

      "datainceperemicrotime",
      "datafinisaremicrotime",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",
      "datainregistrare_text",
      "datainregistraretime_text",
      
      "numepersoanaresponsabila",
      "prenumepersoanaresponsabila",
      "contractormentenanta",
      
      "note",
      "mentiuniindeplinire",

      "oreefectivlucrate",
      "echipamentdefect",
      "descrieredefectiune",
      "cauzeidentificate",
      "lucrariefectuate",
      "pieseutilizate",
      "numepersoanaresponsabilasac",
      "prenumepersoanaresponsabilasac",

      "dataincepere_text",
      "datainceperetime_text",

      "datafinisare_text",
      "datafinisaretime_text",


    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      "controldebit"     
    ];
  }
 
  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "evidentasesizariobj", _key: "EvidentaSesizari" },
      { field: "statusobj", _key: "Status" },
      { field: "evidentasesizariobj", _key: "EvidentaSesizari" },
      { field: "typemotivinterventieobj", _key: "TypeMotivInterventie" },
      { field: "typeinterventieobj", _key: "TypeInterventie" },
    ];
  }
  getFieldsDTOObjectsArray() {
    return [
      { field: "controldebitobjects ", _key: "ControlDebit" },
    ];
  }

  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   


  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.datainregistrare_text;
    return label;
  }
  getPhone() {
    var controldebit ="";
    if (this.controldebits != undefined && this.controldebits.length) controldebit = this.controldebits[0];
    
    return controldebit;
  }
  GetListHeadCells(LL, otherdata) {
    var uuidsac =
    otherdata != undefined &&
    otherdata.uuidsac != undefined &&
    otherdata.uuidsac
      ? otherdata.uuidsac
      : false;

  var _headCells = [];
  _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_raportinterventie"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      
      _headCells[_headCells.length] ={
        id: "datainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating data"),
      };
      _headCells[_headCells.length] ={
        id: "orainregistrareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Creating_ora_min"),
      };

      _headCells[_headCells.length] ={
        id: "dataincepereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Incepere data"),
      };
      _headCells[_headCells.length] ={
        id: "oraincepereint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Incepere_ora_min"),
      };

      _headCells[_headCells.length] ={
        id: "datafinisareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Finisare data"),
      };
      _headCells[_headCells.length] ={
        id: "orafinisareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Finisare_ora_min"),
      };

      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of raportinterventie"),
      };
      _headCells[_headCells.length] ={
        id: "idevidentasesizari",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idevidentasesizari"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "numepersoanaresponsabila",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numepersoanaresponsabila"),
      };
      _headCells[_headCells.length] ={
        id: "prenumepersoanaresponsabila",
        numeric: false,
        disablePadding: true,
        label: LL("TD_prenumepersoanaresponsabila"),
      };
      _headCells[_headCells.length] ={
        id: "contractormentenanta",
        numeric: false,
        disablePadding: true,
        label: LL("TD_contractormentenanta"),
      };
      
      _headCells[_headCells.length] ={
        id: "mentiuniindeplinire",
        numeric: false,
        disablePadding: true,
        label: LL("TD_mentiuniindeplinire"),
      };
      _headCells[_headCells.length] ={
        id: "status",
        numeric: false,
        disablePadding: true,
        label: LL("TD_status"),
      };
      _headCells[_headCells.length] ={
        id: "note",
        numeric: false,
        disablePadding: true,
        label: LL("TD_note"),
      };

      _headCells[_headCells.length] ={
        id: "oreefectivlucrate",
        numeric: false,
        disablePadding: true,
        label: LL("TD_oreefectivlucrate"),
      };
      _headCells[_headCells.length] ={
        id: "echipamentdefect",
        numeric: false,
        disablePadding: true,
        label: LL("TD_echipamentdefect"),
      };
      _headCells[_headCells.length] ={
        id: "descrieredefectiune",
        numeric: false,
        disablePadding: true,
        label: LL("TD_descrieredefectiune"),
      };
      _headCells[_headCells.length] ={
        id: "cauzeidentificate",
        numeric: false,
        disablePadding: true,
        label: LL("TD_cauzeidentificate"),
      };

      _headCells[_headCells.length] ={
        id: "lucrariefectuate",
        numeric: false,
        disablePadding: true,
        label: LL("TD_lucrariefectuate"),
      };
      _headCells[_headCells.length] ={
        id: "pieseutilizate",
        numeric: false,
        disablePadding: true,
        label: LL("TD_pieseutilizate"),
      };
      _headCells[_headCells.length] ={
        id: "numepersoanaresponsabilasac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_numepersoanaresponsabilasac"),
      };
      _headCells[_headCells.length] ={
        id: "prenumepersoanaresponsabilasac",
        numeric: false,
        disablePadding: true,
        label: LL("TD_prenumepersoanaresponsabilasac"),
      };
      _headCells[_headCells.length] ={
        id: "echipamentinstarefunctionare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_echipamentinstarefunctionare"),
      };
      _headCells[_headCells.length] ={
        id: "controldebit",
        numeric: false,
        disablePadding: true,
        label: LL("TD_controldebit"),
      };
      _headCells[_headCells.length] ={
        id: "idtypeinterventie",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typeinterventie"),
      };
      _headCells[_headCells.length] ={
        id: "idtypemotivinterventie",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typemotivinterventie"),
      };
      
    return _headCells;
  }

}
