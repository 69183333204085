import React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import {MyNavBt} from "components/elements/MyNavBt/MyNavBt";


const MyBreadcrumbs = ({ childs, _setmlgenobj, ...props }) => {
  
  const processItem = (item, i) => {
    var key = "breadcrumbs_" + i;
    return item.url ? (
      <MyNavBt
        href={item.url}
        underline="hover"
        key={key}
        _setmlgenobj={_setmlgenobj}
        color="primary"
      >
        {item.label}
      </MyNavBt>
    ) : (
      <span key={key} color="secondary">
        {item.label}
      </span>
    );
  };

  return (
    <Breadcrumbs separator="›" className="breadcrumbs" aria-label="breadcrumb" color="primary">
      {childs.map((item, i) => {
        return processItem(item, i);
      })}
    </Breadcrumbs>
  );
};

export { MyBreadcrumbs };
