import { useLocation, Navigate } from 'react-router-dom';

import { useAuth } from 'hook/useAuth';
import { logger } from "hoc/logger";



const RequireAuth = ({children}) => {
    const location = useLocation();
    const { user } = useAuth();

    logger('eeeeeee rrrrrrr tttttttt');
    logger(user);
 
    if (!user) {
        return <Navigate to="/login" state={{from: location}} />
    }
    return children;
}

export { RequireAuth }