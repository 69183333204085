import React from "react";

import { useEffect, useState } from "react";

import { logger } from "hoc/logger";

import { useNavigate } from "react-router-dom";

import uuid from "react-uuid";

import { MessagesItem } from "components/elements/Messages/MessagesItem";

import Stack from "@mui/material/Stack";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { positions } from "@mui/system";
 
// import { PersoanaFizicaDTO } from "dto/nomenclatoare/PersoanaFizicaDTO";

// const MessagesContainer = (_objects, cb, ...props) => {
const MessagesContainer = (props) => {
  
  const cb = props.cb ? props.cb : false;
  const goto = props.goto ? props.goto : false;
  const delay = props.delay ? props.delay : 0;

  const navigate = useNavigate();

  var [initobjects, setInitObjects] = useState(
    props._objects ? props._objects : []
  );
  var [objects, setObjects] = useState([]);

  useEffect(() => {
    if (!goto) return;

    if (delay) {
      setTimeout(function () {
        navigate(goto);
      }, delay);
    } else {
      navigate(goto);
    }
  }, [goto]);

  useEffect(() => {
    var to = [];

    for (var i in initobjects) {
      var t = initobjects[i];
      t._key = uuid();
      to[to.length] = t;
    }

    setObjects(to);
  }, [initobjects]);

  const RemoveOne = (uuid) => {
    // logger("RemoveOne", uuid);
    var t = [];
    var to = objects;

    for (var i in to) {
      if (to[i]._key == uuid) continue;
      t[t.length] = to[i];
    }

    setObjects(t);
    if (!t.length) handleClear();
  };

  const handleClear = () => {
    if (!cb) return;
    cb(false);
  };

  const processItem = (item, i) => {
    var key = uuid();

    return <MessagesItem key={key} item={item} cb={RemoveOne} />;
  };

  const processRemoveButton = () => {
    if (objects.length <= 1) return <></>;
    return (
      <IconButton aria-label="CloseIcon" onClick={handleClear}>
        <CloseIcon size="small" />
      </IconButton>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "5px",
          right: "5px",
          maxWidth: "500px",
          p: 1,
          zIndex: "tooltip",
          boxShadow: 2,
          background: "#fff",
          borderRadius: "10px",
        }}
      >
        {processRemoveButton()}
        <Stack sx={{}} spacing={1}>
          {objects.map((item, i) => {
            return processItem(item, i);
          })}
        </Stack>
      </Box>
    </>
  );
};

export { MessagesContainer };
