import React from "react";
import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { GenAutocompleteSimple } from "components/elements/Autocomplete/GenAutocompleteSimple";
import { StatusDTO } from "dto/core/StatusDTO";

const StatusAutocomplete = ({
  obj,
  setObj,
  _idfield,
  _objfield,
  _label,
  _otherdata,
  _criteria,
  ...props
}) => {
  const { LL } = useResource(false);

  const [label, setLabel] = useState("");

  var tidfield = _idfield != undefined ? _idfield : "idstatus";
  var tobjfield = _objfield != undefined ? _objfield : "statusobj";
  var tcriteria = _criteria != undefined ? _criteria : "clasificator";

  const [criteria, setCriteria] = useState(tcriteria);
  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new StatusDTO());
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    if (totherdata.filter == undefined) {
      totherdata.filter = {};
    }
    if (totherdata.filter.criteria == undefined) {
      totherdata.filter.criteria = criteria;
    }

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label, criteria]);

  return (
    <GenAutocompleteSimple
      obj={obj}
      setObj={setObj}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _otherdata={otherdata}
      _showfield={"name"}
    />
  );
};

export { StatusAutocomplete };
