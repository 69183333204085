import { React, StrictMode } from "react";
// import ReactDOM from 'react-dom';
import './css/index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
      </BrowserRouter>
  </StrictMode>
);

// ReactDOM.render(
//   <StrictMode>
//     <BrowserRouter>
//       <App/>
//     </BrowserRouter>
//   </StrictMode>,
//   document.getElementById('root')
// );