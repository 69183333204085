import { useEffect, useState } from "react";
import { logger } from "hoc/logger";

import Link from "@mui/material/Link";

import Box from "@mui/material/Box";

const MyPhoto = ({ fileobj, url, withfull, w, h, nw, ...props }) => {
  
  fileobj = fileobj == undefined ? false : fileobj;
  url = url == undefined ? false : url;

  withfull = withfull == undefined ? false : withfull;

  w = w == undefined ? 0 : w;
  h = h == undefined ? 0 : h;
  nw = nw == undefined ? 0 : nw;

  const [_withfull, setWithFull] = useState(withfull);
  const [_fileobj, setFileObj] = useState(fileobj);

  const [_w, setWidth] = useState(w);
  const [_h, setHeight] = useState(h);
  const [_nw, setNoWrap] = useState(nw);

  const [_url, setURL] = useState(url);

  const [_fullurl, setFullURL] = useState("");
  const [_miniurl, setMiniURL] = useState("");
  const [_name, setName] = useState("");
  
  const getMiniSuffix = () => {
    if (!_fileobj) return ""; 
    if (!_fileobj.fullurl) return ""; 

    if (!_w && !_h && !_nw) return "";
    
    var rez = (_fileobj.fullurl.indexOf('?') == -1) ? '?' : '';
    
    rez += _w ? '&w=' + _w : '';
    rez += _h ? '&h=' + _h : '';
    rez += _nw ? "&nw=1" : '';
      
    return rez;
  };

  useEffect(() => {
    if (_fileobj)
    {
      setFullURL(_fileobj.fullurl);
      let murl = _fileobj.fullurl + getMiniSuffix();
      setMiniURL(murl);
      setName(_fileobj.name);
    } else if (_url)
    {
      setFullURL(_url);
      setMiniURL(_url);
      setName(_url);
    }
  }, [_withfull, _fileobj, _w, _h, _nw, _url]);

  const processObjFull = () => {
    return (
      <Link href={_fullurl} target="_blank" color="primary" underline="always">
        <img src={_miniurl} alt={_name} loading="lazy" />
      </Link>
    );
  };

  const processObj = () => {
    return <img src={_miniurl} alt={_name} loading="lazy" />;
  };

  const process = () => {
    if (!_fullurl) return <></>;
    if (!_miniurl) return <></>;

    if (_withfull) return processObjFull();
    return processObj();
  };

  return <Box>{process()}</Box>;
};

export { MyPhoto };
