
import { logger } from "hoc/logger";
import { DTOTools } from "api/DTOTools";

export class EvidentaDeservireTehnicaGazDTO {


  getMainObjPart() { return "EvidentaDeservireTehnicaGaz"; }
  getFieldsInt() {
    return [
      "id",
      "idsac",
      "idtypedocument",
      "idscan",
      "datavalabilitateavizint",
      "dataverificarestatutint",
      "dataexploatareint",
      "dataeeint",
      "isexpirat",
      "idtypemontare",
      "dataexpirareint",
      "isconservat",
      "dataconservareint",
      "dataverificareizolaregazoductint",
      "semnalizatorgaze",
      
      "datavalabilitateavizmicrotime",
      "dataverificarestatutmicrotime",
      "dataexploataremicrotime",
      "dataexpiraremicrotime",

      "dataconservaremicrotime",
      "dataverificareizolaregazoductmicrotime",
      "dataeemicrotime",
    ];
  }

  getFieldsStr() {
    return [
      "uuid",
      "numar",

      "datavalabilitateaviz_text",
      "datavalabilitateaviztime_text",

      "dataverificarestatut_text",
      "dataverificarestatuttime_text",

      "dataexploatare_text",
      "dataexploataretime_text",

      "dataexpirare_text",
      "dataexpiraretime_text",

      "dataconservare_text",
      "dataconservaretime_text",

      "dataverificareizolaregazoduct_text",
      "dataverificareizolaregazoducttime_text",

      "dataee_text",
      "dataeetime_text",

      "organcontrol",
      "lastcontrol",
      "planedcontrol",
      
      "protectiecorozie",
      "verificareizolaregazoduct",
      "anfabricare",
    ];
  }

  getFieldsFloat() {
    return [];
  }

  getFieldsIntArray() {
    return [
      
    ];
  }

  getFieldsStrArray() {
    return [
      
    ];
  }


  getFieldsDTOObjects() {
    return [
      { field: "scanobj", _key: "Files" },
      { field: "typedocumentobj", _key: "TypeDocument" },
      { field: "sacobj", _key: "DosarSAC" },
      { field: "typemontareobj", _key: "TypeMontare" },

    ];
  }


  getFieldsDTOObjectsArrayLink() {
    return [
    
    ];
  }
  getFieldsUploadArray() {
    return ["filescan"];
  }

  constructor(data = null) {
    DTOTools.parseConstructorData(this, data);
   


  }

  getDefaultOrderBy() {
    return "numar";
  }

  getLabel() {
    var label = this.numar + " " + this.datainregistrare_text;
    return label;
  }

  GetListHeadCells(LL, otherdata) {
    var uuidsac =
      otherdata != undefined &&
      otherdata.uuidsac != undefined &&
      otherdata.uuidsac
        ? otherdata.uuidsac
        : false;

    var _headCells = [];

    _headCells[_headCells.length] ={
        id: "numar",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Number_evidentadeserviretehnicagaz"),
      };
      _headCells[_headCells.length] ={
        id: "idtypedocument",
        numeric: false,
        disablePadding: true,
        label: LL("TD_typedocument"),
      };
      _headCells[_headCells.length] ={
        id: "idtypemontare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_idtypemontare"),
      };
      _headCells[_headCells.length] ={
        id: "datavalabilitateavizint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Valabilitateaviz data"),
      };
      _headCells[_headCells.length] ={
        id: "dataverificarestatutint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Verificarestatut data"),
      };
      _headCells[_headCells.length] ={
        id: "dataexploatareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Exploatare data"),
      };
      _headCells[_headCells.length] ={
        id: "dataeeint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Ee data"),
      };
      _headCells[_headCells.length] ={
        id: "dataexpirareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Expirare data"),
      };
      _headCells[_headCells.length] ={
        id: "dataconservareint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Conservare data"),
      }
      _headCells[_headCells.length] ={
        id: "dataverificareizolaregazoductint",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Verificareizolaregazoduct data"),
      };
      _headCells[_headCells.length] ={
        id: "idscan",
        numeric: false,
        disablePadding: true,
        label: LL("TD_Scan of evidentadeserviretehnicagaz"),
      };
      if (!uuidsac) {
        _headCells[_headCells.length] = {
          id: "idsac",
          numeric: false,
          disablePadding: true,
          label: LL("TD_idsac"),
        };
      }
      _headCells[_headCells.length] ={
        id: "organcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_organcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "lastcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_lastcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "planedcontrol",
        numeric: false,
        disablePadding: true,
        label: LL("TD_planedcontrol"),
      };
      _headCells[_headCells.length] ={
        id: "isexpirat",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isexpirat"),
      };
      _headCells[_headCells.length] ={
        id: "isconservat",
        numeric: false,
        disablePadding: true,
        label: LL("TD_isconservat"),
      };
      _headCells[_headCells.length] ={
        id: "anfabricare",
        numeric: false,
        disablePadding: true,
        label: LL("TD_anfabricare"),
      };
      _headCells[_headCells.length] ={
        id: "protectiecorozie",
        numeric: false,
        disablePadding: true,
        label: LL("TD_protectiecorozie"),
      };
      _headCells[_headCells.length] ={
        id: "verificareizolaregazoduct",
        numeric: false,
        disablePadding: true,
        label: LL("TD_verificareizolaregazoduct"),
      };
    

    return _headCells;
  }

}
