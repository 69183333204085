import React from "react";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import Grid from "@mui/material/Grid";

import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";

// -------------------------
import { useAuth } from "hook/useAuth";
import { UserDTO } from "dto/user/UserDTO";
import uuid from "react-uuid";

import Link from "@mui/material/Link";
import { MyTextField } from "components/elements/MyFormComponents/MyTextField";

const UserPage = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  // -------------------------------------------------
  const { LL } = useResource(false);

  const [isLoading, setIsLoading] = useState(false);

  // --------------------------

  const { user } = useAuth(false);

  // -------------------------------------------------

  useEffect(() => {
    logger("MyUser", user);
  });

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Box
        maxWidth="md"
        component="div"
        sx={{ py: 1, px: 2, borderRadius: 2, my: 4, border: "1px solid #eee" }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
          {LL("UserInfo")}
        </Typography>
        <Divider />

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("UserName")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>{user.identifier}</Typography>
          </Grid>
          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("Nume")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {user.persoanafizicaobj ? user.persoanafizicaobj.nume : ""}
            </Typography>
          </Grid>
          <Grid item xs="3" align="right">
            <Typography sx={{ color: "#aaa" }}>{LL("Prenume")}</Typography>
          </Grid>
          <Grid item xs="9">
            <Typography>
              {user.persoanafizicaobj ? user.persoanafizicaobj.prenume : ""}
            </Typography>
          </Grid>
          <Divider />
        </Grid>
      </Box>
      <form
          //       onSubmit={(event) => {
          //           DTOTools.execAddObj(
          //               event,
          //               defaultobjdto,
          //               obj,
          //               otherdata,
          //               backdata,
          //               setObj,
          //               setIsLoading,
          //               _setmlmessages,
          //               _setmlgenobj,
          //               {}
          //           );
          // }}
        >
        <Box
          maxWidth="md"
          component="div"
          sx={{
            py: 1,
            px: 2,
            borderRadius: 2,
            my: 4,
            border: "1px solid #eee",
          }}
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs="3" align="right">
              <Typography sx={{ color: "#aaa" }}>
                {LL("CurrentPassword")}
              </Typography>
            </Grid>
            <Grid item xs="9">
              <MyTextField _fieldkey="password" />
            </Grid>
            <Grid item xs="3" align="right">
              <Typography sx={{ color: "#aaa" }}>
                {LL("NewPassword")}
              </Typography>
            </Grid>
            <Grid item xs="9">
              <MyTextField _fieldkey="password" />
            </Grid>
            <Grid item xs="3" align="right">
              <Typography sx={{ color: "#aaa" }}>
                {LL("ConfirmPassword")}
              </Typography>
            </Grid>
            <Grid item xs="9">
              <MyTextField _fieldkey="password" />
            </Grid>

            <Grid item xs="12" align="right">
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                style={{ marginLeft: "auto" }}
              >
                {LL("BT_Save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export { UserPage };
