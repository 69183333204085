import React from "react";

import { GenDetailTypeDenumire } from "components/elements/MyGenComponents/GenDetailTypeDenumire";
// -------------------------

import { TypeMobilierDTO } from "dto/nomenclatoare/TypeMobilierDTO";

const DetailTypeMobilier = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeMobilierDTO()
  );

  return (
    <GenDetailTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};

export { DetailTypeMobilier };
