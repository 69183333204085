import { React } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";

import { MainLayout } from "components/core/MainLayout/MainLayout";

import { Layout } from "components/core/Layout/Layout";
import { Login } from "components/auth/Login/Login";

import { RequireAuth } from "hoc/RequireAuth";

import { useToken } from "hook/useToken";
import { useAuth } from "hook/useAuth";

import { Loading } from "components/general/Loading";

import { logger } from "hoc/logger";


const AppRoutes = () => {
  let { token, getToken } = useToken();
  let { user, SetSavedUser } = useAuth();
  let [isLoading, setIsLoading] = useState(true);

  
    logger("AppRoutes111111");

  useEffect(() => {
    CheckToken();
  }, [token]);
  useEffect(() => {
    CheckUser();
  }, [user]);

  // ---------------------
  const CheckUser = () => {
    logger("AppRoutes CheckUser");
    logger(token);

    if (!token) {
      SetSavedUser(SaveUser);
    }
  };

  // ---------------------
  const CheckToken = () => {
    logger("AppRoutes CheckToken");

    if (!token) {
      getToken(SaveToken);
    }
  };

  // ---------------------
  const SaveToken = (_t) => {
    logger("AppRoutes SaveToken");
  };

  // ---------------------
  const SaveUser = (_t) => {
    logger("AppRoutes SaveUser");
    setIsLoading(false);
    // setToken(_t);
  };

  // ---------------------

  return isLoading ? (
    <Loading />
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
          <Route index element={<MainLayout />} />
          
        {/* ----------------------------------------- */}
        <Route path="*" element={<MainLayout _mainobject="EditTypePhone" />} />
      </Route>
    </Routes>
  );
};

export { AppRoutes };
