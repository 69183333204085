import React from "react";

import { GenListTypeDenumire } from "components/elements/MyGenComponents/GenListTypeDenumire";

// ---------------------------------

import { TypeFolosintaDTO } from "dto/nomenclatoare/TypeFolosintaDTO";

const TypeFolosinta = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new TypeFolosintaDTO()
  );

  return (
    <GenListTypeDenumire
      _setmlgenobj={_setmlgenobj}
      _mlotherdata={_mlotherdata}
      _setmlmessages={_setmlmessages}
      _defaultobjdto={defaultobjdto}
      {...props}
    />
  );
};;

export { TypeFolosinta };
