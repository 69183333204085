import React from "react";

import { useEffect, useState } from "react";

import { GenAutocompleteSearch } from "components/elements/Autocomplete/GenAutocompleteSearch";

import { useResource } from "hook/useResource";

import { DocumentDTO } from "dto/documents/DocumentDTO";

const DocumentAutocomplete = ({
  obj,
  setObj,
  _idfield,
  _objfield,
  _label,
  _otherdata,
  _multiple,
  ...props
}) => {
  const { LL } = useResource(false);

  const [label, setLabel] = useState("");

  var _multipleattr = _multiple != undefined ? _multiple : true;
  var tidfield = _idfield != undefined ? _idfield : "documentids";
  var tobjfield = _objfield != undefined ? _objfield : "documentobjects";

  const [idfield, setIdField] = useState(tidfield);
  const [objfield, setObjField] = useState(tobjfield);
  const [multiple, setMultiple] = useState(_multipleattr);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(
    new DocumentDTO()
  );
  const [otherdata, setOtherData] = React.useState({});

  useEffect(() => {
    if (obj == undefined) return;
    if (!obj) return;
    if (defaultobjdto == undefined) return;
    if (!defaultobjdto) return;

    var L =
      _label != undefined && _label
        ? _label
        : LL(defaultobjdto.getMainObjPart());

    var totherdata = _otherdata != undefined ? _otherdata : {};

    setLabel(L);
    setOtherData(totherdata);
  }, [obj, _label]);

  return (
    <GenAutocompleteSearch
      obj={obj}
      setObj={setObj}
      _multiple={multiple}
      _idfield={idfield}
      _objfield={objfield}
      _defaultobjdto={defaultobjdto}
      _label={label}
      _otherdata={otherdata}
    />
  );
};

export { DocumentAutocomplete };
