import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import uuid from "react-uuid";

// -----------------------------

import { EvidentaDeservireTehnicaPetrolDTO } from "dto/documents/EvidentaDeservireTehnicaPetrolDTO";
import { YesNoAutocomplete } from "components/elements/Autocomplete/YesNoAutocomplete";
import { SacAutocomplete } from "components/elements/Autocomplete/SacAutocomplete";
import { TypeDocumentAutocomplete } from "components/elements/Autocomplete/TypeDocumentAutocomplete";
import { TypeMontareAutocomplete } from "components/elements/Autocomplete/TypeMontareAutocomplete";


import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";
import { MyTextField } from "components/elements/MyFormComponents/MyTextField";

import DownloadIcon from "@mui/icons-material/Download";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { MyFileField } from "components/elements/MyFormComponents/MyFileField";
const EditEvidentaDeservireTehnicaPetrol = ({
    _setmlgenobj,
    _mlotherdata,
    _setmlmessages,
    ...props
}) => {
    // -------------------------------------------------------
    const { LL } = useResource(false);

    const [defaultobjdto, setDefaultObjDTO] = React.useState(new EvidentaDeservireTehnicaPetrolDTO());
    const [otherdata, setOtherData] = React.useState(_mlotherdata);

    const [backdata, setBackData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    var [specialHash, setSpecialHash] = useState(uuid());
    var [obj, updateObj] = useState(false);
    // -------------------------------------------------------

    const setObj = (_obj) => {
        updateObj(_obj);
        setSpecialHash(uuid());
    };
    // -------------------------------------------------------

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: DTOTools.getListRoute(obj, otherdata),
            label: LL("crumb_" + defaultobjdto.getMainObjPart()),
        },
    ];
    // -------------------------------------------------------

    useEffect(() => {
        FilterTools.processOtherDataEdit(
            defaultobjdto,
            obj,
            otherdata,
            setBackData,
            setIsLoading,
            setObj,
            {}
        );
    }, [_mlotherdata]);
    // -------------------------------------------------------

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

            <form
                onSubmit={(event) => {
                    DTOTools.execAddObj(
                        event,
                        defaultobjdto,
                        obj,
                        otherdata,
                        backdata,
                        setObj,
                        setIsLoading,
                        _setmlmessages,
                        _setmlgenobj,
                        {}
                    );
                }}>
                <Container maxWidth="md" className="containerBtnTop">
                    <MyNavBt
                        href={DTOTools.getListRoute(obj, otherdata)}
                        _setmlgenobj={_setmlgenobj}
                        _otherdata={backdata}
                        _setmlgenobjval={DTOTools.getDetailObject(obj, otherdata, backdata)}
                    >
                        <IconButton aria-label="delete" color="primary">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </MyNavBt>
                    <Typography variant="h6">{LL("TopTitle_Edit_Document")}</Typography>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        style={{ marginLeft: "auto" }}
                    >
                        {LL("BT_Save")}
                    </Button>
                </Container>
                <Divider />

                <Container maxWidth="md">
                    <TypeDocumentAutocomplete obj={obj} setObj={setObj} />
                    <TypeMontareAutocomplete obj={obj} setObj={setObj} />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="numar"
                        required
                    />
                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="datavalabilitateaviz"
                    />
                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="dataverificarestatut"
                    />
                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="dataexploatare"
                    />

                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="dataexpirare"
                    />
                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="dataconservare"
                    />

                    <MyDatePicker
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="datadefectoscopie"
                    />
                    <MyFileField
                        label={LL("Scan")}
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="filescan"
                    />

                    <SacAutocomplete obj={obj} setObj={setObj} />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="organcontrol"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="lastcontrol"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="planedcontrol"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="anfabricare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="aninstalare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="nrrezervoare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="nrrezervoareinutilizare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="voluminutilizare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="haspasaportuzina"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="pasaportuzina"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="termenexploatare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="hasvalabiltabelcalibrare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="valabiltabelcalibrare"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="hasvalabilavizexpertiza"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="valabilavizexpertiza"
                        required
                    />


                    <YesNoAutocomplete
                        obj={obj}
                        setObj={setObj}
                        _nofirst
                        _idfield={"isexpirat"}
                        _label={LL("Expirat")}
                    />
                    <YesNoAutocomplete
                        obj={obj}
                        setObj={setObj}
                        _nofirst
                        _idfield={"isconservat"}
                        _label={LL("Conservat")}
                    />
                </Container>
            </form>
        </>
    );
};

export { EditEvidentaDeservireTehnicaPetrol };
