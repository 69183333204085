import React from "react";

import { useEffect, useState, useMemo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { EnhancedTableToolbar } from "components/elements/TableTools/EnhancedTableToolbar";
import { EnhancedTableHead } from "components/elements/TableTools/EnhancedTableHead";

import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import { useResource } from "hook/useResource";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { MyTableCellList } from "components/elements/TableTools/MyTableCellList";
import { logger } from "hoc/logger";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import { FilterTools } from "api/FilterTools";
import Button from "@mui/material/Button";

// ---------------------------------

import { EvidentaAutorizatieSanitaraDTO } from "dto/documents/EvidentaAutorizatieSanitaraDTO";

import Link from "@mui/material/Link";

const EvidentaAutorizatieSanitara = ({ _setmlgenobj, _mlotherdata, _setmlmessages, ...props }) => {
  const { LL } = useResource(false);

  const [isLoading, setIsLoading] = useState(true);

  const [objects, setObjects] = useState([]);
  const [localHash, setLocalHash] = useState(uuid());
  const [filter, setFilter] = useState({});
  const [filters, setFilters] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);

  // --------------------------
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("numar");
  // --------------------------

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new EvidentaAutorizatieSanitaraDTO());
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    { label: LL("crumb_EvidentaAutorizatieSanitara") },
  ];

  useEffect(() => {
    setHeadCells(defaultobjdto.GetListHeadCells(LL, otherdata));
  }, [otherdata, defaultobjdto]);

  useEffect(() => {
    FilterTools.processOtherDataList(
      defaultobjdto,
      otherdata,
      setPage,
      processFilters,
      setOrder,
      setOrderBy,
      setRowsPerPage,
      setLocalHash
    );
  }, [_mlotherdata]);

  const processFilters = (tfilters, setfirstpage) => {
    setfirstpage = setfirstpage ? setfirstpage : false;

    var _filter = {};    
    _filter.withsac = 1;
    var _filters = [];

    var key = "search";
    var _tfobj = FilterTools.parseFiltersObject_TextValue(LL, tfilters, key);
    _filters[_filters.length] = _tfobj;

    if (_tfobj.exist) {
      _filter[key] = _tfobj.value;
    }

    setFilter(_filter);
    setFilters(_filters);
    if (setfirstpage) setPage(0);
  };




  useEffect(() => {
    if (!filters) return;
    if (!filters.length) return;
    DTOTools.execLoadObjects(defaultobjdto, otherdata, filter, filters, order, orderBy, page, rowsPerPage, setIsLoading, setObjects, setTotal);
    FilterTools.putListURL(defaultobjdto, otherdata, _setmlgenobj, filters, order, orderBy, page, rowsPerPage, localHash);
  }, [filter, filters, order, orderBy, page, rowsPerPage, localHash]);


  const processScan = (obj) => {
    if (!obj) return <></>;
    if (!obj.scanobj) return <></>;
    if (!obj.scanobj.fullurl) return <></>;

    if (obj == undefined) return <></>;
    if (obj.scanobj == undefined) return <></>;
    if (obj.scanobj.fullurl == undefined) return <></>;

    if (obj == "undefined") return <></>;
    if (obj.scanobj == "undefined") return <></>;
    if (obj.scanobj.fullurl == "undefined") return <></>;

    return (
      <>
        <Link
          href={obj.scanobj.fullurl}
          target="_blank"
          color="primary"
          underline="always"
        >
          {obj.scanobj.name}
        </Link>
      </>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <MyNavBt
        className={"addBtnGeneral"}
        href="/evidentaautorizatiesanitara/add"
        _setmlgenobj={_setmlgenobj}
        _otherdata={{
          _backdata: FilterTools.parseBackData({
            _order: order,
            _page: page,
            _rpp: rowsPerPage,
            _filter: filter,
            _filters: filters,
            _orderBy: orderBy,
            _otherdata: otherdata,
            _defaultObjDTO: defaultobjdto,
          }),
        }}
      >
        <Button variant="contained" color="secondary" startIcon={<AddIcon />}>
          {LL("BT_Add")}
        </Button>
      </MyNavBt>

      <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={0}>
        <EnhancedTableToolbar
          selected={selected} setSelected={setSelected} setIsLoading={setIsLoading} defaultobjdto={defaultobjdto} _setmlmessages={_setmlmessages} otherdata={otherdata} objects={objects} setObjects={setObjects}
          numSelected={selected.length}

          _processFilters={processFilters}
          filters={filters}
          label={LL("TopTitle_List_Document")}
        />

        <TableContainer sx={{ minWidth: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
            className="cursorPointer"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              objects={objects}
              setSelected={setSelected}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              rowCount={CommonTools.getRowCountForCheckbox(
                rowsPerPage,
                objects
              )}
              headCells={headCells}
            />

            <TableBody>
              {CommonTools.stableSort(objects, order, orderBy).map((obj, i) => {
                const isItemSelected = CommonTools.isSelected(
                  obj.uuid,
                  selected
                );
                const labelId = `enhanced-table-checkbox-${obj.uuid}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={obj.uuid}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) =>
                        CommonTools.handleClickSelect(
                          event,
                          obj.uuid,
                          selected,
                          setSelected
                        )
                      }
                    >
                      {CommonTools.processCheckBox(
                        obj,
                        isItemSelected,
                        labelId
                      )}
                    </TableCell>
                    <MyTableCellList
                      sx={{ width: "5%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="numar"
                      headCells={headCells}
                    >
                      {obj.numar}
                    </MyTableCellList>
                    
                    <MyTableCellList
                      sx={{ width: "10%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idtypedocument"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.typedocumentobj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "5%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="datavalabilitateint"
                      headCells={headCells}
                    >
                      {obj.datavalabilitate_text}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "5%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="dataverificarestatutint"
                      headCells={headCells}
                    >
                      {obj.dataverificarestatut_text}
                    </MyTableCellList>
                 
                    <MyTableCellList
                      sx={{ width: "15%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idscan"
                      headCells={headCells}
                    >{processScan(obj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "5%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="idsac"
                      headCells={headCells}
                    >
                      {CommonTools.GenType(obj.sacobj)}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "10%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="organcontrol"
                      headCells={headCells}
                    >
                      {obj.organcontrol}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "10%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="lastcontrol"
                      headCells={headCells}
                    >
                      {obj.lastcontrol}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "10%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="planedcontrol"
                      headCells={headCells}
                    >
                      {obj.planedcontrol}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "5%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="status"
                      headCells={headCells}
                    >
                      {obj.statusobj.name}
                    </MyTableCellList>
                    <MyTableCellList
                      sx={{ width: "10%" }}
                      obj={obj}
                      defaultobjdto={defaultobjdto}
                      otherdata={otherdata}
                      order={order}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filter={filter}
                      filters={filters}
                      orderBy={orderBy}
                      _setmlgenobj={_setmlgenobj}
                      setIsLoading={setIsLoading}
                      _fieldkey="note"
                      headCells={headCells}
                    >
                      {obj.note}
                    </MyTableCellList>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => {
            FilterTools.changePage(newPage, setPage);
          }}
          onRowsPerPageChange={(e) => {
            FilterTools.changeRowsPerPage(e, setRowsPerPage, setPage);
          }}
        />
      </Paper>

      <FormControlLabel
        control={
          <Switch
            checked={dense}
            onChange={(e) => {
              FilterTools.changeDense(e, setDense);
            }}
          />
        }
        label={LL("VizualizareCompacta")}
      />
    </>
  );
};

export { EvidentaAutorizatieSanitara };
