import { GenTypeDenumireDTO } from "dto/core/GenTypeDenumireDTO";

export class TypeMotivInterventieDTO extends GenTypeDenumireDTO {
  getMainObjPart() {
    return "TypeMotivInterventie";
  }

  constructor(data = null) {
    super(data);
  }
}
