import React from "react";

import { useEffect, useState, useMemo } from "react";

import Box from "@mui/material/Box";

import TableCell from "@mui/material/TableCell";

import { CommonTools } from "api/CommonTools";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Checkbox from "@mui/material/Checkbox";

import { visuallyHidden } from "@mui/utils";
import { logger } from "hoc/logger";


const checkShow = (field, cells) =>
{
  if (cells == undefined) return false;
  if (!cells.length) return false;

  for (var i in cells)
  {
    if (cells[i].id != field) continue;
    return true;
  }
  return false;
} 

const MyTableCellList = ({
  children,

  obj,
  defaultobjdto,
  otherdata,
  order,
  page,
  rowsPerPage,
  filter,
  filters,
  orderBy,
  _setmlgenobj,
  setIsLoading,

  _fieldkey,
  headCells,

  ...props
}) => {
  
  const [needShow, setNeedShow] = useState(false);

  useEffect(() => {
    var result = checkShow(_fieldkey, headCells);
    setNeedShow(result);
  }, [_fieldkey, headCells]);

  return (!needShow) ? (<></>) : (
    <TableCell
      {...props}
      scope="row"
      onClick={(event) =>
        CommonTools.genClickCell(
          event,
          obj,
          defaultobjdto,
          otherdata,
          order,
          page,
          rowsPerPage,
          filter,
          filters,
          orderBy,
          _setmlgenobj,
          setIsLoading
        )
      }
    >
      {children}
    </TableCell>
  );
};

export { MyTableCellList };
