import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";
import uuid from "react-uuid";

import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { MyTextField } from "components/elements/MyFormComponents/MyTextField";

// -----------------------------


import { LabelDTO } from "dto/label/LabelDTO";

import { YesNoAutocomplete } from "components/elements/Autocomplete/YesNoAutocomplete";
import { DestinationUMAutocomplete } from "components/elements/Autocomplete/DestinationUMAutocomplete";



const EditLabel = ({
  _setmlgenobj,
  _mlotherdata,
  _setmlmessages,
  ...props
}) => {
  // -------------------------------------------------------
  const { LL } = useResource(false);

  const [defaultobjdto, setDefaultObjDTO] = React.useState(new LabelDTO());
  const [otherdata, setOtherData] = React.useState(_mlotherdata);

  const [backdata, setBackData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  var [specialHash, setSpecialHash] = useState(uuid());
  var [obj, updateObj] = useState(false);
  // -------------------------------------------------------

  const setObj = (_obj) => {
    updateObj(_obj);
    setSpecialHash(uuid());
  };
  // -------------------------------------------------------

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: DTOTools.getListRoute(obj, otherdata),
      label: LL("crumb_" + defaultobjdto.getMainObjPart()),
    },
  ];
  // -------------------------------------------------------

  useEffect(() => {
    FilterTools.processOtherDataEdit(
      defaultobjdto,
      obj,
      otherdata,
      setBackData,
      setIsLoading,
      setObj,
      {}
    );
  }, [_mlotherdata]);
  // -------------------------------------------------------

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

      <form
        onSubmit={(event) => {
          DTOTools.execAddObj(
            event,
            defaultobjdto,
            obj,
            otherdata,
            backdata,
            setObj,
            setIsLoading,
            _setmlmessages,
            _setmlgenobj,
            {}
          );
        }}
      >
        <Container maxWidth="md" className="containerBtnTop">
          <MyNavBt
            href={DTOTools.getDetailRoute(obj, otherdata)}
            _setmlgenobj={_setmlgenobj}
            _otherdata={backdata}
            _setmlgenobjval={DTOTools.getDetailObject(obj, otherdata, backdata)}
          >
            <IconButton aria-label="delete" color="primary">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </MyNavBt>
          <Typography variant="h6">{LL("TopTitle_Edit_Label")}</Typography>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            {LL("BT_Save")}
          </Button>
        </Container>
        <Divider />

        <Container maxWidth="md">
          <Typography>{obj.identifier}</Typography>
          <YesNoAutocomplete
            obj={obj}
            setObj={setObj}
            _nofirst
            _idfield={"procesed"}
            _label={LL("procesed")}
          />
          <MyTextField
            setObj={setObj}
            obj={obj}
            defaultobjdto={defaultobjdto}
            otherdata={otherdata}
            _fieldkey="value"
          />
        </Container>
      </form>
    </>
  );
};

export { EditLabel };
