import React from "react";

import { useEffect, useState, useMemo } from "react";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import TableCell from "@mui/material/TableCell";

import { CommonTools } from "api/CommonTools";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Checkbox from "@mui/material/Checkbox";

import { useResource } from "hook/useResource";

import { visuallyHidden } from "@mui/utils";
import { logger } from "hoc/logger";

const MyFileField = ({
  obj,
  defaultobjdto,
  otherdata,
  _fieldkey,
  _label,
  setObj,
  multiple,

  ...props
}) => {
  var tmultiple = multiple != undefined ? multiple : false;
  const [_multiple, setMultiple] = useState(tmultiple);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  
  const { LL } = useResource(false);

  useEffect(() => {
    if (!_fieldkey) {
      setName("");
      return;
    }
    setName(_fieldkey);
  }, [obj, _fieldkey, otherdata]);

  useEffect(() => {
    if (!name) return;

    if (_label) {
      setLabel(_label);
    } else {
      setLabel(LL(name));
    }

    if (!obj || !obj[name]) {
      setValue("");
    } else {
      setValue(obj[name]);
    }
  }, [name]);

  const handleChange = (e) => {
    if (!name) return;

    var t = e.target.files;

    var _obj = obj;

    if (_multiple)
    {
      _obj[name] = t;
    } else
    {
      // if (t != undefined && Array.isArray(t) && t.length)
      if (t != undefined && t.length)
      {
        _obj[name] = t[0];
      } else {
        _obj[name] = [];
      }
    }

    setObj(_obj);
  };

  return !name ? (
    <></>
  ) : (
    <TextField
      {...props}
      multiple={_multiple}
      type="file"
      onChange={handleChange}
      fullWidth
      color="secondary"
      size="small"
      margin="normal"
      variant="standard"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export { MyFileField };
