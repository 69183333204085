import React from "react";
import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { GenAutocompleteSearch } from "components/elements/Autocomplete/GenAutocompleteSearch";
import { RolesDTO } from "dto/user/RolesDTO";

const RolesAutocomplete =({ obj,
  setObj,
    _idfield,
    _objfield,
    _label,
    _otherdata,
    ...props  }) => {
  
      const { LL } = useResource(false);

      const [label, setLabel] = useState("");
    
      var tidfield = _idfield != undefined ? _idfield : "idroles";
      var tobjfield = _objfield != undefined ? _objfield : "rolesobjects";
    
      const [idfield, setIdField] = useState(tidfield);
      const [objfield, setObjField] = useState(tobjfield);
    
      const [defaultobjdto, setDefaultObjDTO] = React.useState(
        new RolesDTO()
      );
      const [otherdata, setOtherData] = React.useState({});
    
      useEffect(() => {
        if (obj == undefined) return;
        if (!obj) return;
        if (defaultobjdto == undefined) return;
        if (!defaultobjdto) return;
    
        var L =
          _label != undefined && _label
            ? _label
            : LL(defaultobjdto.getMainObjPart());
    
        var totherdata = _otherdata != undefined ? _otherdata : {};

        if (totherdata.filter == undefined) {
          totherdata.filter = {};
        }
        if (totherdata.filter.excludesystem == undefined) {
          totherdata.filter.excludesystem = '1';
        }
        
        setLabel(L);
        setOtherData(totherdata);
      }, [obj, _label]);
    
      return (
        <GenAutocompleteSearch
          obj={obj}
          setObj={setObj}
          _idfield={idfield}
          _objfield={objfield}
          _defaultobjdto={defaultobjdto}
          _label={label}
          _otherdata={otherdata}
          _showfield={"identifier"}
          _loadonempty={true}
        />
      );
};

export { RolesAutocomplete };