import React from "react";

import { useEffect, useState } from "react";
import { Loading } from "components/general/Loading";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";


import { useResource } from "hook/useResource";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { logger } from "hoc/logger";
import { MyNavBt } from "components/elements/MyNavBt/MyNavBt";

import uuid from "react-uuid";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
// ----------------------------------
import { StatusAutocomplete } from "components/elements/Autocomplete/StatusAutocomplete";
import { TypeDocumentAutocomplete } from "components/elements/Autocomplete/TypeDocumentAutocomplete";
import { SacAutocomplete } from "components/elements/Autocomplete/SacAutocomplete";

import { NumericAutocomplete } from "components/elements/Autocomplete/NumericAutocomplete";
import { EvidentaSesizariDTO } from "dto/documents/EvidentaSesizariDTO";

import { MyDatePicker } from "components/elements/MyDatePicker/MyDatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import { CommonTools } from "api/CommonTools";
import { DTOTools } from "api/DTOTools";
import { FilterTools } from "api/FilterTools";
import { MyTextField } from "components/elements/MyFormComponents/MyTextField";
import { MyFileField } from "components/elements/MyFormComponents/MyFileField";

const AddEvidentaSesizari = ({
    _setmlgenobj,
    _mlotherdata,
    _setmlmessages,
    ...props
}) => {

    const { LL } = useResource(false);

    const [defaultobjdto, setDefaultObjDTO] = React.useState(new EvidentaSesizariDTO());
    const [otherdata, setOtherData] = React.useState(_mlotherdata);

    const [isLoading, setIsLoading] = useState(false);
    const [backdata, setBackData] = useState({});

    var [specialHash, setSpecialHash] = useState(uuid());
    var [obj, updateObj] = useState(new EvidentaSesizariDTO());
    const setObj = (_obj) => {
        updateObj(_obj);
        setSpecialHash(uuid());
    }

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: DTOTools.getListRoute(obj, otherdata),
            label: LL("crumb_" + defaultobjdto.getMainObjPart()),
        },
        { label: LL("crumb_Add" + defaultobjdto.getMainObjPart()) },
    ];

    useEffect(() => {
        if (_mlotherdata._backdata != undefined) {
            setBackData(_mlotherdata._backdata);
        }
    }, [_mlotherdata]);

    // *************************************************************


    return isLoading ? (
        <Loading />
    ) : (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} _setmlgenobj={_setmlgenobj} />

            <form
                onSubmit={(event) => {
                    DTOTools.execAddObj(
                        event,
                        defaultobjdto,
                        obj,
                        otherdata,
                        backdata,
                        setObj,
                        setIsLoading,
                        _setmlmessages,
                        _setmlgenobj,
                        {}
                    );
                }}
            >
                <Container maxWidth="md" className="containerBtnTop">
                    <MyNavBt
                        href={DTOTools.getListRoute(obj, otherdata)}
                        _setmlgenobj={_setmlgenobj}
                        _otherdata={backdata}
                    >
                        <IconButton aria-label="delete" color="primary">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </MyNavBt>
                    <Typography variant="h6">
                        {LL("TopTitle_Add_" + defaultobjdto.getMainObjPart())}
                    </Typography>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        style={{ marginLeft: "auto" }}
                    >
                        {LL("BT_Save")}
                    </Button>
                </Container>
                <Divider />

                <Container maxWidth="md">
                    <TypeDocumentAutocomplete obj={obj} setObj={setObj} />

                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="numar"
                        required
                    />
                    <FormGroup
                        sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}
                    >
                        <Box sx={{ width: "70%" }}>
                            <MyDatePicker
                                setObj={setObj}
                                obj={obj}
                                defaultobjdto={defaultobjdto}
                                otherdata={otherdata}
                                disableFuture
                                _fieldkey="datainregistrare"
                            />
                        </Box>
                        <Box sx={{ width: "15%", paddingLeft: 2 }}>
                            <NumericAutocomplete obj={obj} setObj={setObj}
                                _idfield="orainregistrareint"
                                _label={LL("orainregistrareint")}
                            />
                        </Box>
                        <Box sx={{ width: "15%", paddingLeft: 2 }}>
                            <NumericAutocomplete obj={obj} setObj={setObj}
                                _idfield="minutinregistrareint"
                                _label={LL("minutinregistrareint")}
                                _max={59}
                            />
                        </Box>
                    </FormGroup>
                    <FormGroup
                        sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}
                    >
                        <Box sx={{ width: "70%" }}>
                            <MyDatePicker
                                setObj={setObj}
                                obj={obj}
                                defaultobjdto={defaultobjdto}
                                otherdata={otherdata}
                                _fieldkey="datatransmitere"
                            />
                        </Box>
                        <Box sx={{ width: "15%", paddingLeft: 2 }}>
                            <NumericAutocomplete obj={obj} setObj={setObj}
                                _idfield="oratransmitereint"
                                _label={LL("oratransmitereint")}
                            />
                        </Box>
                        <Box sx={{ width: "15%", paddingLeft: 2 }}>
                            <NumericAutocomplete obj={obj} setObj={setObj}
                                _idfield="minuttransmitereint"
                                _label={LL("minuttransmitereint")}
                                _max={59}
                            />
                        </Box>
                    </FormGroup>

                    <MyFileField
                        label={LL("Scan")}
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="filescan"
                    />

                    <SacAutocomplete obj={obj} setObj={setObj} />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="numepersoanaresponsabila"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="prenumepersoanaresponsabila"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="contractormentenanta"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="defectiune"
                        required
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="mentiuniindeplinire"
                        required
                    />
                    <StatusAutocomplete
                        obj={obj}
                        setObj={setObj}
                        _idfield="status"
                        _criteria="evidentasesizaristatusid"
                    />
                    <MyTextField
                        setObj={setObj}
                        obj={obj}
                        defaultobjdto={defaultobjdto}
                        otherdata={otherdata}
                        _fieldkey="note"
                        required
                    />
                </Container>
            </form>
        </>
    );
};

export { AddEvidentaSesizari };
