import React from "react";

import uuid from "react-uuid";

import { useEffect, useState } from "react";

import { LocationDTO } from "dto/location/LocationDTO";

import { Loading } from "components/general/Loading";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import MapOSSelectSugestion from "components/elements/Map/MapOSSelectSugestion";

import { StreetAutocomplete } from "components/elements/Autocomplete/StreetAutocomplete";
import { LocationFullSelect } from "components/elements/LocationSelect/LocationFullSelect";

import TextField from "@mui/material/TextField";

import { MyTextField } from "components/elements/MyFormComponents/MyTextField";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import { logger } from "hoc/logger";

import { useResource } from "hook/useResource";

const FullAddressSelect = ({
  obj,
  setObj,
  _latfield,
  _lngfield,
  _placeidfield,
  _idstreetfield,
  _streetobjfield,
  _locationcodesfield,
  _streetnumberfield,
  _locationcodefield,
  _fulllocationfield,

  ...props
}) => {
  const { LL } = useResource(false);

  var tlatfield = _latfield != undefined ? _latfield : "lat";
  var tlngfield = _lngfield != undefined ? _lngfield : "lng";
  var tplaceidfield = _placeidfield != undefined ? _placeidfield : "osmplaceid";
  var tidstreetfield =
    _idstreetfield != undefined ? _idstreetfield : "idstreet";
  var tstreetobjfield =
    _streetobjfield != undefined ? _streetobjfield : "streetobj";
  var tlocationcodesfield =
    _locationcodesfield != undefined ? _locationcodesfield : "locationcodes";
  var tstreetnumberfield =
    _streetnumberfield != undefined ? _streetnumberfield : "streetnumber";
  var tlocationcodefield =
    _locationcodefield != undefined ? _locationcodefield : "locationcode";
  var tfulllocationfield =
    _fulllocationfield != undefined ? _fulllocationfield : "fulllocation";

  let _usecentermap = obj && obj.id != undefined && obj.id ? false : true;
  let _lat =
    obj && obj[tlatfield] != undefined && !isNaN(obj[tlatfield])
      ? obj[tlatfield]
      : undefined;
  let _lng =
    obj && obj[tlngfield] != undefined && !isNaN(obj[tlngfield])
      ? obj[tlngfield]
      : undefined;
  let _place_id = obj && obj[tplaceidfield] != undefined ? obj[tplaceidfield] : undefined;

  let _idstreet =
    obj && obj[tidstreetfield] != undefined ? obj[tidstreetfield] : 0;
  let _streetobj =
    obj && obj[tstreetobjfield] != undefined && obj[tstreetobjfield].id != undefined
      ? obj[tstreetobjfield]
      : {};
  let _locationcodes =
    obj && obj[tlocationcodesfield] != undefined
      ? obj[tlocationcodesfield]
      : [];
  let _streetnumber =
    obj && obj[tstreetnumberfield] != undefined ? obj[tstreetnumberfield] : "";
  let _fulllocation =
    obj && obj[tfulllocationfield] != undefined ? obj[tfulllocationfield] : "";

  let _locationcode =
    obj && obj[tlocationcodefield] != undefined ? obj[tlocationcodefield] : 0;

  let _zoom = props.zoom != undefined ? props.zoom : 15;

  let _lastlocationcode = _locationcodes.length
    ? _locationcodes[_locationcodes.length - 1]
    : -1;

  // -------------------------------

  const [usecentermap, setUseCenterMap] = useState(_usecentermap);
  const [latfield, setLatField] = useState(tlatfield);
  const [lngfield, setLngField] = useState(tlngfield);
  const [placeidfield, setPlaceIdField] = useState(tplaceidfield);
  const [idstreetfield, setIdStreetField] = useState(tidstreetfield);
  const [streetobjfield, setStreetObjField] = useState(tstreetobjfield);
  const [locationcodesfield, setLocationCodesField] =
    useState(tlocationcodesfield);
  const [streetnumberfield, setStreetNumberField] =
    useState(tstreetnumberfield);
  const [fulllocationfield, setFullLocationField] =
    useState(tfulllocationfield);
  const [locationcodefield, setLocationCodeField] =
    useState(tlocationcodefield);

  // -------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadedLocation, setIsLoadedLocation] = useState(false);
  const [isLoadedStreet, setIsLoadedStreet] = useState(false);

  const [locationcodes, setLocationCodes] = useState(_locationcodes);
  const [lastlocationcode, setLastLocationCode] = useState(_lastlocationcode);
  const [locationnames, setLocationNames] = useState([]);
  const [locationclearnames, setLocationClearNames] = useState([]);
  const [streetnumber, setStreetNumber] = useState(_streetnumber);
  const [fulllocation, setFullLocation] = useState(_fulllocation);

  const [idstreet, setIdStreet] = useState(_idstreet);
  const [streetobj, setStreetObj] = useState(_streetobj);

  const [locationsearch, setLocationSearch] = useState("");

  const [lat, setLat] = useState(_lat);
  const [lng, setLng] = useState(_lng);
  const [place_id, setPlaceId] = useState(_place_id);
  const [zoom, setZoom] = useState(_zoom);
  const [streetCount, setStreetCount] = useState(0);

  // -----------------------------------------------------------------------

  const [init_locationcodes, setInitLocationCodes] = useState(_locationcodes);
  const [init_lastlocationcode, setInitLastLocationCode] =
    useState(_lastlocationcode);
  const [init_locationnames, setInitLocationNames] = useState([]);
  const [init_locationclearnames, setInitLocationClearNames] = useState([]);
  const [init_streetnumber, setInitStreetNumber] = useState(_streetnumber);
  const [init_fulllocation, setInitFullLocation] = useState(_fulllocation);

  const [init_idstreet, setInitIdStreet] = useState(_idstreet);
  const [init_streetobj, setInitStreetObj] = useState(_streetobj);

  const [init_locationsearch, setInitLocationSearch] = useState("");

  const [init_lat, setInitLat] = useState(_lat);
  const [init_lng, setInitLng] = useState(_lng);
  const [init_place_id, setInitPlaceId] = useState(_place_id);
  const [init_zoom, setInitZoom] = useState(_zoom);

  // -----------------------------------------------------------------------
  const setLocalObj = (_obj) => {
    if (!setObj) return;
    setObj(_obj);
    // setSpecialHash(uuid());
  };

  // -----------------------------------------------------------------------
  const SaveLocations = (_objects) => {
    if (!obj) return;

    var _tobj = obj;

    if (!_objects) {
      setLocationCodes([]);
      setLocationNames([]);
      setLocationClearNames([]);
      setLastLocationCode(-1);
      setIsLoadedStreet(false);

      setUseCenterMap(true);

      _tobj[fulllocationfield] = "";
      _tobj[locationcodefield] = -1;
      _tobj[locationcodesfield] = [];

      _tobj[idstreetfield] = 0;
      _tobj[streetnumberfield] = "";
      setStreetNumber("");
      _tobj[streetobjfield] = {};

      setLocalObj(_tobj);

      return;
    }

    var _fullname = "";
    var _codes = [];
    var _names = [];
    var _clearnames = [];
    for (var i in _objects) {
      if (!_objects[i]) continue;
      if (_objects[i] == undefined) continue;
      if (!_objects[i].id) continue;
      if (_objects[i].id == undefined) continue;

      _codes[_codes.length] = _objects[i].code;
      _names[_names.length] = _objects[i].name;
      _clearnames[_clearnames.length] = _objects[i].nameclear;

      _fullname += _fullname ? ", " : "";
      _fullname += _objects[i].name;
    }

    setLocationCodes(_codes);
    setLocationNames(_names);
    setLocationClearNames(_clearnames);

    var lc = _codes.length ? _codes[_codes.length - 1] : -1;
    lc = _codes.length && !_codes[0] ? -1 : lc;
    lc = !lc ? -1 : lc;
    setLastLocationCode(lc);

    _tobj[fulllocationfield] = _fullname;
    _tobj[locationcodefield] = lc;
    _tobj[locationcodesfield] = _codes;

    if (init_lastlocationcode == lc) {
      if (init_idstreet) {
        setIsLoadedStreet(true);
        _tobj[streetnumberfield] = init_streetnumber;
        setStreetNumber(init_streetnumber);
      } else {
        setIsLoadedStreet(false);
        _tobj[streetnumberfield] = "";
        setStreetNumber("");
      }
      _tobj[idstreetfield] = init_idstreet;
      _tobj[streetobjfield] = init_streetobj;
    } else {
      setIsLoadedStreet(false);

      _tobj[idstreetfield] = 0;
      _tobj[streetnumberfield] = "";
      setStreetNumber("");
      _tobj[streetobjfield] = {};
    }

    if (
      _tobj.id &&
      init_idstreet == _tobj[idstreetfield] &&
      init_lastlocationcode == _tobj[locationcodefield] &&
      init_streetnumber == _tobj[streetnumberfield]
    ) {
      setUseCenterMap(false);
    } else {
      setUseCenterMap(true);
    }

    setLocalObj(_tobj);
  };

  const updateStreetCount = (c) => {
    setStreetCount(c);
  };

  const updateStreetObj = (_obj) => {
    if (!obj) return;

    var _tobj = obj;

    _tobj[idstreetfield] = _obj[idstreetfield];
    _tobj[streetobjfield] = _obj[streetobjfield];

    if (
      init_idstreet == _tobj[idstreetfield] &&
      init_lastlocationcode == _tobj[locationcodefield]
    ) {
      _tobj[streetnumberfield] = init_streetnumber;
      setStreetNumber(init_streetnumber);
    } else {
      _tobj[streetnumberfield] = "";
      setStreetNumber("");
    }

    if (_tobj[idstreetfield]) {
      setIsLoadedStreet(true);
      setIdStreet(_tobj[idstreetfield]);
      setStreetObj(_tobj[streetobjfield]);
    } else {
      _tobj[streetnumberfield] = "";
      setIsLoadedStreet(false);
      setIdStreet(0);
      setStreetObj({});
      setStreetNumber("");
    }

    if (
      _tobj.id &&
      init_idstreet == _tobj[idstreetfield] &&
      init_lastlocationcode == _tobj[locationcodefield] &&
      init_streetnumber == _tobj[streetnumberfield]
    ) {
      setUseCenterMap(false);
    } else {
      setUseCenterMap(true);
    }

    setLocalObj(_tobj);

    logger("updateStreetObj121212", _obj);

    return;
  };

  const updateStreetNumber = (_obj) => {
    logger("updateStreetNumber121212", _obj);

    var _tobj = obj;

    _tobj[streetnumberfield] = _obj[streetnumberfield];
    setStreetNumber(_tobj[streetnumberfield]);

    if (
      _tobj.id &&
      init_idstreet == _tobj[idstreetfield] &&
      init_lastlocationcode == _tobj[locationcodefield] &&
      init_streetnumber == _tobj[streetnumberfield]
    ) {
      setUseCenterMap(false);
    } else {
      setUseCenterMap(true);
    }

    setLocalObj(_tobj);
  };

  const updateMap = (_obj) => {
    logger("updateMap121212", _obj);

    var _tobj = obj;

    _tobj[latfield] = _obj[latfield];
    _tobj[lngfield] = _obj[lngfield];
    _tobj[placeidfield] = _obj[placeidfield];

    if (
      _tobj.id &&
      init_idstreet == _tobj[idstreetfield] &&
      init_lastlocationcode == _tobj[locationcodefield] &&
      init_streetnumber == _tobj[streetnumberfield]
    ) {
      setUseCenterMap(false);
    } else {
      setUseCenterMap(true);
    }

    setLocalObj(_tobj);
  };

  useEffect(() => {
    // var rez = 'moldova,';
    var rez = "";
    if (locationclearnames.length) {
      // rez += rez ? "," : "";
      var t = locationclearnames[locationclearnames.length - 1];
      t = !t ? "" : t;
      t = t == undefined ? "" : t;
      t = t == "undefined" ? "" : t;
      rez += t;
      // rez += locationclearnames.join(",");
    }
    if (streetobj && streetobj != undefined && streetobj != "undefined") {
      var t = streetobj.name;
      t = !t ? "" : t;
      t = t == undefined ? "" : t;
      t = t == "undefined" ? "" : t;

      if (t) {
        rez += rez ? "," : "";
        rez += streetobj.name;
      }
    }
    if (streetnumber) {
      var t = streetnumber;
      t = !t ? "" : t;
      t = t == undefined ? "" : t;
      t = t == "undefined" ? "" : t;

      if (t) {
        rez += rez ? "," : "";
        rez += streetnumber;
      }
    }

    if (rez) {
      rez = "moldova," + rez;
    }

    setLocationSearch(rez);
  }, [locationnames, streetobj, streetnumber]);

  // selectedcodes = selectedcodes == undefined ? [] : selectedcodes;
  // parentcode = parentcode == undefined ? 0 : parentcode;
  // level = level == undefined ? 1 : level;
  // level = parseInt(level);

  const setStateIsLoadedLocation = (val) => {
    setIsLoadedLocation(val);
  };

  const setStateIsLoadedStreet = (val) => {
    logger("setStateIsLoadedStreet -> ", val);
    setIsLoadedStreet(val);
  };

  const ExecCallBack = () => {
    if (!isLoadedLocation) return;
    if (!isLoadedStreet) return;

    // if (!cb) return;
    // if (cb == undefined) return;
    // if (cb == "undefined") return;

    logger(
      "ExecCallBackExecCallBack2121212121 ",

      "\n locationcodes => ",
      locationcodes,
      "\n init_locationcodes => ",
      init_locationcodes,

      "\n idstreet => ",
      idstreet,
      "\n init_idstreet => ",
      init_idstreet,

      "\n streetnumber => ",
      streetnumber,
      "\n init_streetnumber => ",
      init_streetnumber,

      "\n streetobj => ",
      streetobj,
      "\n init_streetobj => ",
      init_streetobj,

      "\n lat => ",
      lat,
      "\n init_lat => ",
      init_lat,

      "\n lng => ",
      lng,
      "\n init_lng => ",
      init_lng,

      "\n place_id => ",
      place_id,
      "\n init_place_id => ",
      init_place_id
    );

    // cb(locationcodes, idstreet, streetnumber, streetobj, lat, lng, place_id);
  };

  useEffect(() => {
    ExecCallBack();
  }, [
    locationcodes,
    streetnumber,
    idstreet,
    streetobj,
    lat,
    lng,
    place_id,
    isLoadedLocation,
  ]);

  useEffect(() => {
    logger("FullAddressSelec311211", obj);
  }, [obj]);

  useEffect(() => {
    logger("SaveLocationslocationcodes", lastlocationcode);
  }, [lastlocationcode]);

  const processStreet = () => {
    // return <></>;
    if (!isLoadedLocation) return <></>;
    if (lastlocationcode <= 0) return <></>;

    var t = lastlocationcode ? lastlocationcode : -1;

    logger("processStreet121212_lastlocationcode:", t, lastlocationcode);

    return (
      <StreetAutocomplete
        key={"StreetAutocomplete_" + t + "_container"}
        obj={obj}
        setObj={updateStreetObj}
        _hideOnEmpty={true}
        _setCount={updateStreetCount}
        _idfield={idstreetfield}
        _objfield={streetobjfield}
        _otherdata={{ filter: { locationcode: t } }}
      />
    );
  };

  const processStreetNumber = () => {
    // return <></>;
    if (!isLoadedLocation) return <></>;
    if (!isLoadedStreet) return <></>;

    return (
      <MyTextField
        setObj={updateStreetNumber}
        obj={obj}
        defaultobjdto={{}}
        otherdata={{}}
        _fieldkey="streetnumber"
      />
    );
  };

  const processMap = () => {
    if (!isLoadedLocation) return <></>;
    // if (!isLoadedStreet && streetCount > 0) return <></>;

    return (
      <MapOSSelectSugestion
        key={"MapOSSelectSugestion_" + locationsearch}
        _zoom={zoom}
        _latfield={latfield}
        _lngfield={lngfield}
        _placeidfield={placeidfield}
        _init_lat={init_lat}
        _init_lng={init_lng}
        _usecentermap={usecentermap}
        obj={obj}
        setObj={updateMap}
        _search={locationsearch}
      />
    );
  };

  return (
    <Box maxWidth="md" component="div">
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs="6" align="left">
          <LocationFullSelect
            cb={SaveLocations}
            setloaded={setStateIsLoadedLocation}
            selectedcodes={locationcodes}
          />
          {processStreet()}
          {processStreetNumber()}
        </Grid>
        <Grid item xs="6" align="right">
          {processMap()}
        </Grid>
      </Grid>
    </Box>
  );
};

export { FullAddressSelect };
